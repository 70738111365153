import ReactModal from 'react-modal';

import styles from './css/SelectTherapistModal.module.css';
import { useContext, useState } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import { ReactComponent as Icon_check } from '../../../../../assets/member/signup/icon_check.svg';
import { EssentialMark } from '../../../../member/component/EssentialMark';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getTherapistList, TherapistList } from '../ts/AssignedMemberApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectTherapist } from '../../../../../features/selectTherapistModalReducer';
import { RootState } from '../../../../../store';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

export const SelectTherapistModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 200,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };
    const dispatch = useDispatch();
    const { selectTherapistVisible, selectTherapistOpenModal } = useContext(ModalContext);
    const { selectTherapist, selectProgram } = useSelector((state: RootState) => state.selectTherapistModal);

    const programList = ['TMS', '대면 CBT'];

    const [selectProgramType, setSelectProgarmType] = useState<string>(selectProgram.type);

    const { data, isLoading, error } = useQuery<TherapistList>({
        queryKey: ['therapistList'],
        queryFn: () => getTherapistList(), // TypeScript에서 null이 아님을 보장
        placeholderData: keepPreviousData,
    });

    const saveAssignTherapist = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .put(`/api/${memberInfo?.memberRole}/${selectProgram.registerId}/${selectTherapist.therapistId}/v1`)
            .then((response) => {
                if (response.data.result) {
                    selectTherapistOpenModal(false);
                    window.location.reload();
                }
            });
    };

    const setTherapist = (therapist: string, therapistId: number) => {
        dispatch(updateSelectTherapist({ therapistId: therapistId, therapist: therapist }));
    };

    const changeTherapist = (role: string) => {
        switch (role) {
            case 'therapist':
                return '치료자';
            case 'manager':
                return '매니저';
        }
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={selectTherapistVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div className={styles.title_header_container}>
                    <span className="text_22_NotoSansKR_Bold">담당 상담사 선택</span>
                    <button onClick={() => selectTherapistOpenModal(false)}>
                        <Icon_x />
                    </button>
                </div>
                <div className={styles.middel_title_header_container}>
                    <span
                        className="text_18_NotoSansKR_Medium"
                        style={{
                            color: '#626466',
                        }}
                    >
                        각 프로그램의 담당 상담사를 선택해 주세요.
                    </span>
                </div>
                <div className={styles.program_list_container}>
                    {/* {programList.map((item, index) => ( */}
                    <button
                        // onClick={() => setSelectProgarmType(item)}
                        className={`${styles.program_button} ${
                            selectProgramType === selectProgram.type ? styles.activate : styles.deactivate
                        }`}
                    >
                        <span
                            className={`${
                                selectProgramType === selectProgram.type
                                    ? 'text_18_NotoSansKR_Bold'
                                    : 'text_18_NotoSansKR_Medium'
                            }`}
                            style={{
                                color: selectProgramType === selectProgram.type ? '#3a5074' : '#9D9FA2',
                            }}
                        >
                            {selectProgram.type}
                        </span>
                    </button>
                    {/* ))} */}
                </div>
                <div className={styles.therapist_list_container}>
                    {data?.picGroups.map((item, index) => (
                        <div
                            key={index.toString()}
                            style={{
                                borderBottom: '0.0625rem solid #E3E5EA',
                            }}
                        >
                            <div className={styles.therapist_offical}>
                                <span
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#9D9FA2',
                                    }}
                                >
                                    {changeTherapist(item.memberRole)}
                                </span>
                            </div>
                            <div className={styles.therapist_content_container}>
                                {item.picInfos.map((value, idx) => (
                                    <button
                                        key={idx.toString()}
                                        onClick={() => setTherapist(value.memberName, value.memberId)}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span className="text_16_NotoSansKR_Medium">{value.memberName}</span>
                                        {selectTherapist.therapist === value.memberName && (
                                            <Icon_check width={'1.5rem'} height={'1.5rem'} />
                                        )}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.button_container}>
                    <button
                        onClick={() => selectTherapistOpenModal(false)}
                        className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                    >
                        취소
                    </button>
                    <button
                        className={`${styles.save_button} text_16_NotoSansKR_Bold`}
                        onClick={() => saveAssignTherapist()}
                    >
                        선택완료
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};
