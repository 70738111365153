import { D } from '@tanstack/react-query-devtools/build/legacy/ReactQueryDevtools-Cn7cKi7o';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { decryptData } from '../../../../../utils/croptojs';
import html2canvas from 'html2canvas';
import { useDispatch } from 'react-redux';
import { resetBlackBoardData } from '../../../../../features/session/blackboardReducer';

export interface SessionMapApi {
    workBooks: [
        {
            registerId: number;
            workBookName: string;
            workBookType: string;
            addPay: boolean;
            sessionMap: {
                totalSessionCnt: number;
                finishCnt: number;
                feedbackCnt: number;
                registerSessionIds: number[];
            };
            assignmentMap: {
                totalAssignmentCnt: number;
                finishCnt: number;
                registerAssignmentIds: number[];
            };
        }
    ];
    message: string;
    result: boolean;
}

// 워크북 세션 데이터 가져오기
export const getSessionData = async (sessionData: string) => {
    const sessionId = decryptData(sessionData);
    return await axiosInstance.get(`/api/workBook/field/${sessionId}/v1`).then((response) => response.data);
};

// 워크북 과제 데이터 가져오기
export const getAssignData = async (sessionData: string) => {
    const assignId = decryptData(sessionData);
    return await axiosInstance.get(`/api/workBook/field/assign/${assignId}/v1`).then((response) => response.data);
};

// 세션 메뉴 가져오기
export const getSessionMenu = async (programData: string) => {
    const programId = decryptData(programData);
    return await axiosInstance.get(`/api/workBook/cbtStatus/${programId}/v1`).then((response) => response.data);
};

// 치료자 피드백 저장
export const saveSessionFeedback = async (fieldId: number, field: any) => {
    const memberInfo = getMemberInfo();
    const location = window.location.pathname;
    const requestData = location.includes('/session/assigned')
        ? {
              assignmentFieldId: fieldId,
              fields: field,
              therapistName: memberInfo?.memberName,
          }
        : {
              sessionFieldId: fieldId,
              fields: field,
              therapistName: memberInfo?.memberName,
          };

    return await axiosInstance
        .put(`/api/${memberInfo?.memberRole}/field/v1`, requestData)
        .then((response) => response.data);
};

// 치료자 피드백 삭제
export const deleteSessionFeedback = async (fieldId: number, index: number, field: any) => {
    const memberInfo = getMemberInfo();
    const location = window.location.pathname;
    const requestData = location.includes('/session/assigned')
        ? {
              assignmentFieldId: fieldId,
              fields: field,
              therapistName: memberInfo?.memberName,
          }
        : {
              sessionFieldId: fieldId,
              fields: field,
              therapistName: memberInfo?.memberName,
          };

    return await axiosInstance
        .delete(`/api/${memberInfo?.memberRole}/field/${fieldId}/${index}/v1`)
        .then((response) => response.data);
};

// 회원 지도
export const getSessionMap = async (): Promise<SessionMapApi> => {
    return await axiosInstance.get(`/api/workBook/map/v1`).then((response) => response.data);
};

// 전자칠판 PDf 저장
export const exportPDF = async (contentRef: any, session: any, resetData: any) => {
    const memberInfo = getMemberInfo();
    if (contentRef) {
        // 1. 콘텐츠의 너비와 높이 측정
        const contentWidth = contentRef.offsetWidth; // 콘텐츠 너비
        const contentHeight = contentRef.scrollHeight; // 콘텐츠 전체 높이

        // 2. 높은 해상도로 캡처
        const canvasImage = await html2canvas(contentRef, {
            scale: 1,
        });

        const imageData = canvasImage.toDataURL('image/jpeg', 0.7); // jpeg 포맷 사용

        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/session/txt/v1`, {
                sessionBase64: imageData,
                registerSessionId: decryptData(session!),
            })
            .then((response) => {
                if (response.data.result) {
                    resetData();
                }
            });

        // 3. PDF 생성
        // const pdf = new jsPDF({
        //     orientation: 'portrait', // 세로 방향으로 설정
        //     unit: 'mm',
        //     format: [contentWidth * 0.264583, contentHeight * 0.264583], // 픽셀을 mm로 변환
        //     // putOnlyUsedFonts: true,
        //     // floatPrecision: 16,
        // });

        // // 4. 이미지 추가
        // pdf.addImage(imageData, 'JPEG', 0, 0, contentWidth * 0.264583, contentHeight * 0.264583); // 비율 유지하여 추가
        // // 5. PDF 저장
        // pdf.save('session_page.pdf');
    }
};
