import ReactModal from 'react-modal';

import styles from './css/AddCountModal.module.css';
import { useContext, useState } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import { EssentialMark } from '../../../../member/component/EssentialMark';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { updateCount } from '../../../../../features/addCountReducer';

export const AddCountModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 200,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const dispatch = useDispatch();

    const { remainingTimes, workBookName } = useSelector((state: RootState) => state.addCount);

    const { onConfirm, addCountVisible, addCountOpenModal } = useContext(ModalContext);
    const [modify, setModify] = useState<boolean>(false);

    const addCount = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance.put(`/api/${memberInfo?.memberRole}/remainTimes/v1`, {});
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={addCountVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div className={styles.title_header_container}>
                    <span className="text_22_NotoSansKR_Bold">회기 추가</span>
                    <button onClick={() => addCountOpenModal(false)}>
                        <Icon_x />
                    </button>
                </div>
                <div className={styles.input_content_container}>
                    <div className={styles.input_container}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            {workBookName} 진행가능 횟수
                        </span>
                        <input
                            type="number"
                            className={`${styles.input} ${!modify ? styles.readonly : ''} text_16_Inter_Regular`}
                            readOnly={!modify}
                            value={remainingTimes}
                            onChange={(e) => dispatch(updateCount(e.target.value))}
                        />
                    </div>
                </div>
                <div className={styles.button_container}>
                    <button
                        onClick={() => addCountOpenModal(false)}
                        className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                    >
                        취소
                    </button>
                    {modify ? (
                        <button onClick={addCount} className={`${styles.save_button} text_16_NotoSansKR_Bold`}>
                            저장
                        </button>
                    ) : (
                        <button
                            onClick={() => setModify(!modify)}
                            className={`${styles.save_button} text_16_NotoSansKR_Bold`}
                        >
                            수정
                        </button>
                    )}
                </div>
            </div>
        </ReactModal>
    );
};
