import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DrawingPoint {
    x: number;
    y: number;
    width: number;
    color: string;
}

interface Blackboard {
    blackboardVisible: boolean;
    colorPickerVisible: boolean;
    feedbackVisible: boolean;
    drawings: DrawingPoint[][];
    lineWidth: number;
    lineColor: string;
    eraserVisible: boolean;
    contentRef: any;
}

const initialState: Blackboard = {
    blackboardVisible: false,
    colorPickerVisible: false,
    feedbackVisible: false,
    drawings: [],
    lineWidth: 1.5,
    lineColor: '#000000',
    eraserVisible: false,
    contentRef: null,
};

const blackboardReducer = createSlice({
    name: 'blackboard',
    initialState,
    reducers: {
        updateBlackboardVisible: (state, action: PayloadAction<boolean>) => {
            state.blackboardVisible = action.payload;
        },
        updateColorPickerVisible: (state, action: PayloadAction<boolean>) => {
            state.colorPickerVisible = action.payload;
        },
        updateFeedbackVisible: (state, action: PayloadAction<boolean>) => {
            state.feedbackVisible = action.payload;
        },
        updateLineWidth: (state, action: PayloadAction<number>) => {
            state.lineWidth = action.payload;
        },
        updateLineColor: (state, action: PayloadAction<string>) => {
            state.lineColor = action.payload;
        },
        updateEraserVisible: (state, action: PayloadAction<boolean>) => {
            state.eraserVisible = action.payload;
        },
        addDrawingDown: (state, action: PayloadAction<DrawingPoint>) => {
            state.drawings.push([{ ...action.payload }]);
        },
        addDrawingMove: (state, action: PayloadAction<DrawingPoint>) => {
            if (state.drawings.length > 0) {
                state.drawings[state.drawings.length - 1].push(action.payload);
            }
        },
        setDrawings: (state, action: PayloadAction<DrawingPoint[][]>) => {
            state.drawings = action.payload;
        },
        setContentRef: (state, action: PayloadAction<any>) => {
            state.contentRef = action.payload; // RefObject를 상태로 저장
        },
        eraseDrawing: (state, action: PayloadAction<{ currentX: number; currentY: number; eraserWidth: number }>) => {
            const { currentX, currentY, eraserWidth } = action.payload;
            state.drawings = state.drawings
                .map((line) =>
                    line.filter((point, index) => {
                        const distance = Math.sqrt((point.x - currentX) ** 2 + (point.y - currentY) ** 2);
                        const isStartOrEnd = index === 0 || index === line.length - 1;
                        return distance > eraserWidth / 2 || isStartOrEnd;
                    })
                )
                .filter((line) => line.length > 0);
        },
        clearDrawings(state) {
            state.drawings = [];
        },
        resetBlackBoardData: () => initialState,
    },
});

export const {
    updateBlackboardVisible,
    updateColorPickerVisible,
    updateFeedbackVisible,
    updateLineWidth,
    updateLineColor,
    updateEraserVisible,
    setDrawings,
    eraseDrawing,
    addDrawingMove,
    addDrawingDown,
    setContentRef,
    clearDrawings,
    resetBlackBoardData,
} = blackboardReducer.actions;
export default blackboardReducer.reducer;
