import React, { useEffect, useState } from 'react';
import './App.css';
import './assets/fonts/Font.scss';
import { BrowserRouter } from 'react-router-dom';
import { MainRoutes } from './route/MainRoutes';
import { messaging } from './firebase';
import { getToken, onMessage } from 'firebase/messaging';
import { useDispatch } from 'react-redux';
import { updateNewNotificationVisible } from './features/notificationReducer';

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        const requestNotificationPermission = async () => {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                try {
                    const registration = await navigator.serviceWorker.ready;
                    const currentToken = await getToken(messaging, {
                        vapidKey:
                            'BGVqESpMXdgfbKroQQ8SSF3Vk1JUyLZlf_2bhYqDjslX6i8SRG0IU7ViK-Uotwoqqzx__HuxSMpHp8ctjsC6HLM',
                        serviceWorkerRegistration: registration,
                    }).then((token) => {
                        const storedToken = localStorage.getItem('firebaseToken');
                        // const parsedToken = storedToken ? JSON.parse(storedToken) : null;

                        // 기존 토큰과 비교하여 변경된 경우에만 저장
                        // if (!parsedToken || parsedToken.firebase_token !== token) {
                        localStorage.setItem('firebaseToken', JSON.stringify({ firebase_token: token }));
                        // 자바 서버에 토큰 저장 로직 추가
                        // }
                    });
                } catch (error) {
                    console.error('Error getting token: ', error);
                }
            } else {
                console.error('Notification permission denied.');
            }
        };

        requestNotificationPermission();

        // 메시지 수신 핸들러 설정
        const unsubscribeOnMessage = onMessage(messaging, (payload) => {
            if (payload.notification) {
                const notificationTitle = payload.notification.title;
                if (notificationTitle) {
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/favicon.ico',
                        data: {
                            url: 'https://yd-icbt.co.kr', // 원하는 URL로 변경
                        },
                    };
                    dispatch(updateNewNotificationVisible(true));
                    // 브라우저 알림 표시
                    new Notification(notificationTitle, notificationOptions);
                }
            }
        });

        return () => {
            unsubscribeOnMessage();
        };
    }, []);

    return (
        <div>
            <BrowserRouter>
                {/* 공통 View */}
                {/* 토큰 x */}
                <MainRoutes />
            </BrowserRouter>
        </div>
    );
}

export default App;
