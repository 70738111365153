import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

// 결제 내역조회
export const getPaymentList = async () => {
    const memberInfo = getMemberInfo();
    const companyId = memberInfo?.memberRole === 'representative' ? 0 : memberInfo?.loginBranch.companyId;
    return await axiosInstance
        .post(`/api/${memberInfo?.memberRole}/payment/${companyId}/v1`)
        .then((response) => response.data);
};

// 결제 내역 상세조회
export const getPaymentDetail = async (paymentId: number) => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .get(`/api/${memberInfo?.memberRole}/payment/${paymentId}/v1`)
        .then((response) => response.data);
};

// 결제정보 상품리스트
export const getPaymentProductList = async (paymentId: number) => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .get(`/api/${memberInfo?.memberRole}/payment/${paymentId}/register/v1`)
        .then((response) => response.data);
};
