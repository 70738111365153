import styles from './css/FooterComponent.module.css';
import { ReactComponent as Icon_header_logo } from '../assets/icon_header_logo.svg';
import { useNavigate } from 'react-router-dom';

export const FooterComponent = () => {
    const navigate = useNavigate();
    const openFtc = () => {
        const { frm1 }: any = document;
        let url = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=' + frm1.wrkr_no.value;

        window.open(url, 'bizCommPop', 'width=750, height=700;');
    };
    return (
        <div className={styles.footer_container}>
            <div className={styles.policy_container}>
                <button onClick={() => navigate('/public/privacyPolicy')}>
                    <span
                        className="text_14_NotoSansKR_Bold"
                        style={{
                            color: '#3A5074',
                        }}
                    >
                        개인정보 처리방침
                    </span>
                </button>
                <div className={styles.length_line} />
                <button onClick={() => navigate('/public/sensitivenInfo')}>
                    <span
                        className="text_14_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        민감정보 처리방침
                    </span>
                </button>
                <div className={styles.length_line} />
                <button onClick={() => navigate('/public/uniqueIdentifier')}>
                    <span
                        className="text_14_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        고유식별정보 처리방침
                    </span>
                </button>
            </div>
            <div className={styles.policy_content_container}>
                <div>
                    <Icon_header_logo className={styles.icon_header_logo} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                    }}
                >
                    <span className="text_18_NotoSansKR_Bold">주식회사 와이디퍼포먼스</span>
                    <div className={styles.policy_content}>
                        <div className={styles.registration_number}>
                            <span
                                className="text_16_NotoSansKR_Regular"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                사업자 등록번호: 247-81-03508
                                <button
                                    onClick={openFtc}
                                    className="text_16_NotoSansKR_Regular"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    [사업자정보확인]
                                </button>
                            </span>
                            <div className={styles.content_container}></div>
                            <span
                                className="text_16_NotoSansKR_Regular"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                대표: 윤동욱
                            </span>
                        </div>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            통신판매업 신고번호: 제 2024-부산강서구-0860호
                        </span>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            회사 주소: 부산광역시 강서구 명지국제2로 27, 613호(명지동, 대산골든스퀘어)
                        </span>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            회사 전화번호: 070-4406-4735
                        </span>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            이메일주소: ydpsychoedu@gmail.com
                        </span>
                    </div>
                </div>
            </div>
            <form name="frm1">
                <input name="wrkr_no" type="hidden" value="2478103508" />
            </form>
        </div>
    );
};

export const FooterBasicComponent = () => {
    const navigate = useNavigate();

    const openFtc = () => {
        const { frm1 }: any = document;
        let url = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=' + frm1.wrkr_no.value;

        window.open(url, 'bizCommPop', 'width=750, height=700;');
    };
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                boxSizing: 'border-box',
                flexDirection: 'column',
                gap: '1.5rem',
                paddingBottom: '3.75rem',
            }}
        >
            <div className={styles.policy_container}>
                <button onClick={() => navigate('/public/privacyPolicy')}>
                    <span
                        className="text_14_NotoSansKR_Bold"
                        style={{
                            color: '#3A5074',
                        }}
                    >
                        개인정보 처리방침
                    </span>
                </button>
                <div className={styles.length_line} />
                <button onClick={() => navigate('/public/sensitivenInfo')}>
                    <span
                        className="text_14_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        민감정보 처리방침
                    </span>
                </button>
                <div className={styles.length_line} />
                <button onClick={() => navigate('/public/uniqueIdentifier')}>
                    <span
                        className="text_14_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        고유식별정보 처리방침
                    </span>
                </button>
            </div>
            <div className={styles.policy_content_container}>
                <div>
                    <Icon_header_logo className={styles.icon_header_logo} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.75rem',
                    }}
                >
                    <span className="text_18_NotoSansKR_Bold">주식회사 와이디퍼포먼스</span>
                    <div className={styles.policy_content}>
                        <div className={styles.registration_number}>
                            <span
                                className="text_16_NotoSansKR_Regular"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                사업자 등록번호: 247-81-03508
                                <button
                                    onClick={openFtc}
                                    className="text_16_NotoSansKR_Regular"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    [사업자정보확인]
                                </button>
                            </span>
                            <div className={styles.content_container}></div>
                            <span
                                className="text_16_NotoSansKR_Regular"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                대표: 윤동욱
                            </span>
                        </div>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            통신판매업 신고번호: 제 2024-부산강서구-0860호
                        </span>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            회사 주소: 부산광역시 강서구 명지국제2로 27, 613호(명지동, 대산골든스퀘어)
                        </span>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            회사 전화번호: 070-4406-4735
                        </span>
                        <span
                            className="text_16_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            이메일주소: ydpsychoedu@gmail.com
                        </span>
                    </div>
                </div>
            </div>
            <form name="frm1">
                <input name="wrkr_no" type="hidden" value="2478103508" />
            </form>
        </div>
    );
};
