import { useContext, useState } from 'react';
import styles from './css/SettingComponent.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettingMenu, updateSettingVisible } from '../features/headerReducer';
import { RootState } from '../store';
import { ReactComponent as Icon_setting_check } from '../assets/public/icon_setting_check.svg';
import { ReactComponent as Icon_profile } from '../assets/public/icon_profile.svg';
import { ReactComponent as Icon_notification_setting } from '../assets/public/icon_notification_setting.svg';
import { ReactComponent as Icon_logout } from '../assets/public/icon_logout.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetBlackBoardData } from '../features/session/blackboardReducer';
import { exportPDF } from '../pages/home/cbt/session/ts/SessionApi';
import { ModalContext } from '../context/ModalContext';

export const Setting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const params = new URLSearchParams(window.location.search);
    const session = params.get('session');
    const { twoButtonOpenModal } = useContext(ModalContext);
    const { settingVisible, settingMenu } = useSelector((state: RootState) => state.header);
    const { blackboardVisible, contentRef } = useSelector((state: RootState) => state.blackboard);
    const [menuList, setMenuList] = useState<{ title: string; path: string; icon: any }[]>([
        { title: '프로필 수정', path: 'memberInfo', icon: Icon_profile },
        { title: '알림 설정', path: 'notificationSetting', icon: Icon_notification_setting },
        { title: '로그아웃', path: 'memberInfo', icon: Icon_logout },
    ]);
    const exists = menuList.some((item) => location.pathname.includes(item.path));

    // 페이지 이동
    const movePage = (url: string) => {
        if (blackboardVisible) {
            twoButtonOpenModal(
                () => {},
                () => {
                    exportPDF(contentRef, session!, dispatch(resetBlackBoardData()));
                    navigate(url);
                },
                '전자칠판 종료',
                '제출하지 않고 페이지를 벗어날 경우, 입력한 내용은 저장되지 않습니다. 하단의 저장 버튼 또는 페이지 내 상단 전자칠판 종료 버튼을 눌러 저장을 완료해 주세요.',
                '취소',
                '저장'
            );
        } else {
            navigate(url);
        }
    };

    const settingNavigate = (title: string) => {
        const queryParams = new URLSearchParams(location.search);

        switch (title) {
            case '프로필 수정':
                if (currentPath.includes('/yd/patient/program')) {
                    movePage(`/yd/patient/memberInfo?${queryParams.toString()}`);
                } else {
                    movePage(`memberInfo?${queryParams.toString()}`);
                }
                dispatch(updateSettingVisible(!settingVisible));
                break;
            case '알림 설정':
                if (currentPath.includes('/yd/patient/program')) {
                    movePage(`/yd/patient/notificationSetting?${queryParams.toString()}`);
                } else {
                    movePage(`notificationSetting?${queryParams.toString()}`);
                }
                dispatch(updateSettingVisible(!settingVisible));
                break;
            case '로그아웃':
                // navigate('logout');
                localStorage.removeItem('userToken');
                window.location.href = '/public/login';
                dispatch(updateSettingVisible(!settingVisible));
                break;
        }
    };

    const SettingMenu = () => {
        return (
            <>
                {menuList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            dispatch(updateSettingMenu(item.title));
                            settingNavigate(item.title);
                        }}
                        className={`${styles.button} ${
                            exists && settingMenu === item.title ? styles.activate : styles.decativate
                        }`}
                    >
                        <div className={styles.button_content}>
                            <item.icon
                                width={'1.5rem'}
                                height={'1.5rem'}
                                className={`${
                                    exists && settingMenu === item.title ? styles.icon_activate : styles.icon_deactivate
                                }`}
                            />
                            <div
                                className={`${
                                    exists && settingMenu === item.title
                                        ? 'text_16_NotoSansKR_Bold'
                                        : 'text_16_NotoSansKR_Medium'
                                }`}
                            >
                                {item.title}
                            </div>
                        </div>
                        {exists && settingMenu === item.title && <Icon_setting_check />}
                    </button>
                ))}
            </>
        );
    };

    return (
        <div className={styles.modal_main_container}>
            <SettingMenu />
        </div>
    );
};
