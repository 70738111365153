import { EmailDropdown } from '../pages/member/component/EmailDropdown';
import styles from './css/MemberInfoComponent.module.css';
import { ReactComponent as Icon_arrowbottom } from '../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_arrowright } from '../assets/member/signup/icon_arrowright.svg';

import { EssentialMark } from '../pages/member/component/EssentialMark';
import { SquareCheckBox, Toggle } from './PublicComponent';
import styled from 'styled-components';
import { getMemberInfo } from '../utils/GetMemberInfo';
import { useContext, useState } from 'react';
import { ModalContext } from '../context/ModalContext';
import axiosInstance from '../utils/AxiosInstanceJava';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import {
    updateEmailAddress,
    updateEmailVisible,
    updateMemberMarketing,
    updateMemberNaverId,
    updateMemberNewPassword,
    updateMemberNewRecheckPassword,
    updateMemberNickName,
    updateMemberSms,
} from '../features/editProfileReducer';

const Title = styled.span`
    color: #626466;
`;

export const BasicInfo = ({ data, editMemberInfo }: { data: any; editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { emailVisible, memberEmailAddress } = useSelector((state: RootState) => state.editProfile);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();

    const identityVeification = async () => {
        try {
            const { form_chk }: any = document;
            const left = window.innerWidth / 2 - 500 / 2;
            const top = window.innerHeight / 2 - 800 / 2;
            const option = `'width=500, height=600, fullscreen=no, menubar=no, status=no, toolbar=no,titlebar=yes, location=no, scrollbar=no'left=${left}, top=${top}`;
            const returnUrl = 'https://yd-icbt.co.kr/public/auth/identity';
            const res = await axiosInstance.post(`/api/auth/nice/token/v1`, {
                returnurl: returnUrl,
            });

            const identity_key = JSON.stringify({
                iv: res.data.iv,
                key: res.data.key,
                hmac_key: res.data.hmac_key,
                req_no: res.data.req_no,
            });

            sessionStorage.setItem('identity_key', identity_key);
            if (form_chk && res.data) {
                window.open('', 'popupChk', option);
                form_chk.target = 'popupChk';
                form_chk.enc_data.value = res.data.enc_data;
                form_chk.token_version_id.value = res.data.token_version_id;
                form_chk.integrity_value.value = res.data.integrity_value;
                form_chk.iv.value = res.data.iv;
                form_chk.key.value = res.data.key;
                form_chk.hmac_key.value = res.data.hmac_key;
                document.body.appendChild(form_chk);
                form_chk.submit();
                document.body.removeChild(form_chk);
            }
        } catch (error) {
            console.error('Error during identity verification:', error);
            oneButtonOpenModal(() => {}, '', '본인인증 중 오류가 발생했습니다.', '확인');
        }
    };

    return (
        <div className={styles.basic_info_container}>
            <div className="text_22_NotoSansKR_Bold">기본정보</div>
            <div
                style={{
                    display: 'flex',
                    width: '6.875rem',
                    height: '6.875rem',
                    borderRadius: '100px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '0.5rem',
                    color: '#F07F34',
                    backgroundColor: '#FEEFE4',
                }}
                className="text_40_NotoSansKR_Bold"
            >
                {data?.member.memberName[0]}
            </div>
            <div className={styles.basic_info}>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Title className="text_18_NotoSansKR_Bold">이름</Title>
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.25rem',
                                }}
                                onClick={identityVeification}
                            >
                                <span
                                    className="text_18_NotoSansKR_Bold"
                                    style={{
                                        color: '#3a5074',
                                    }}
                                >
                                    본인인증
                                </span>
                                <Icon_arrowright className={styles.icon_arrowright} />
                            </button>
                        </div>
                        <input
                            type="text"
                            className={`${`${styles.read_only_input} text_18_NotoSansKR_Regular`} text_18_NotoSansKR_Regular`}
                            defaultValue={data?.member.memberName}
                            readOnly
                        />
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#FF5833',
                            }}
                        >
                            ※ 이름, 휴대폰 번호, 성별 변경이 필요하신 경우 휴대폰 본인인증을 완료하시면 자동으로 일괄
                            변경됩니다.
                        </span>
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            이메일 주소
                            <EssentialMark />
                        </Title>
                        <div className={styles.multi_input_container}>
                            <input
                                className={`${styles.input} text_18_Inter_Regular`}
                                value={data?.member.memberEmail.split('@')[0]}
                                onChange={() => {}}
                            />
                            <span>@</span>
                            <button
                                onClick={() => dispatch(updateEmailVisible(!emailVisible))}
                                className={styles.email_dropdown}
                            >
                                <span className="text_18_Inter_Regular">{memberEmailAddress}</span>
                                <Icon_arrowbottom className={styles.icon_arrowbottm} />
                                {emailVisible && (
                                    <EmailDropdown
                                        updateAddress={(item: string) => {
                                            dispatch(updateEmailAddress(item));
                                            dispatch(updateEmailVisible(!emailVisible));
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">생년월일</Title>
                        <input
                            type="text"
                            className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                            defaultValue={data?.member.memberBirth
                                .toString()
                                .replace(/^(\d{4})(\d{2})(\d{2})$/, '$1/$2/$3')}
                            readOnly
                        />
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            보호자 휴대폰 번호
                            <EssentialMark />
                        </Title>
                        <div className={styles.multi_input_container}>
                            <input
                                type="text"
                                className={`${styles.input} text_18_NotoSansKR_Regular`}
                                value={data?.member.memberPhone.split('-')[0]}
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.input} text_18_NotoSansKR_Regular`}
                                value={data?.member.memberPhone.split('-')[1]}
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.input} text_18_NotoSansKR_Regular`}
                                value={data?.member.memberPhone.split('-')[2]}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.info_container}>
                    <div className={`${styles.input_container} ${styles.margin_bottom}`}>
                        <Title className="text_18_NotoSansKR_Bold">아이디</Title>
                        <input
                            type="text"
                            className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                            defaultValue={data?.member.memberAccount}
                            readOnly
                        />
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">휴대폰 번호</Title>
                        <div className={styles.multi_input_container}>
                            <input
                                type="text"
                                className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                                defaultValue={data?.member.memberPhone.split('-')[0]}
                                readOnly
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                                defaultValue={data?.member.memberPhone.split('-')[1]}
                                readOnly
                            />
                            <span>-</span>
                            <input
                                type="text"
                                className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                                defaultValue={data?.member.memberPhone.split('-')[2]}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">성별</Title>
                        <input
                            type="text"
                            className={`${styles.read_only_input} text_18_NotoSansKR_Regular`}
                            defaultValue={'남자'}
                            readOnly
                        />
                    </div>
                </div>
            </div>

            <button
                className={`${styles.save_info_button} text_18_NotoSansKR_Bold`}
                onClick={() => editMemberInfo('basic')}
            >
                기본정보 저장
            </button>
            <form name="form_chk" method="GET" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb">
                <input type="hidden" name="m" value="service" />
                <input type="hidden" name="token_version_id" value="" />
                <input type="hidden" name="enc_data" value="" />
                <input type="hidden" name="integrity_value" value="" />
                <input type="hidden" name="iv" value="" />
                <input type="hidden" name="key" value="" />
                <input type="hidden" name="hmac_key" value="" />
            </form>
        </div>
    );
};

export const ChangePassword = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { oneButtonOpenModal } = useContext(ModalContext);
    const { memberNewPassword, memberNewRecheckPassword } = useSelector((state: RootState) => state.editProfile);

    const changePasswordRole = () => {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{4,}$/;
        if (memberNewPassword === '') {
            return oneButtonOpenModal(() => {}, '', '새로운 비밀번호를 입력해 주세요.', '확인');
        } else if (memberNewRecheckPassword === '') {
            return oneButtonOpenModal(() => {}, '', '비밀번호를 재입력해 주세요.', '확인');
        } else if (!passwordRegex.test(memberNewPassword)) {
            return oneButtonOpenModal(
                () => {},
                '',
                '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상 입력 가능)',
                '확인'
            );
        } else if (memberNewPassword !== memberNewRecheckPassword) {
            return oneButtonOpenModal(() => {}, '', '비밀번호가 일치하지 않습니다.', '확인');
        }
        editMemberInfo('password');
    };

    const onChangeText = (text: string, type: string) => {
        switch (type) {
            case 'newPassword':
                return dispatch(updateMemberNewPassword(text));
            case 'newRecheckPassword':
                return dispatch(updateMemberNewRecheckPassword(text));
        }
    };

    return (
        <div className={styles.basic_info_container}>
            <div className="text_22_NotoSansKR_Bold">비밀번호 변경</div>
            <div className={styles.basic_info}>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            새로운 비밀번호
                            <EssentialMark />
                        </Title>
                        <input
                            type="password"
                            className={`${`${styles.input} text_18_NotoSansKR_Regular`} text_18_NotoSansKR_Regular`}
                            value={memberNewPassword}
                            onChange={(e) => onChangeText(e.target.value, 'newPassword')}
                        />
                    </div>
                </div>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            새로운 비밀번호 확인 <EssentialMark />
                        </Title>
                        <input
                            type="password"
                            className={`${styles.input} text_18_NotoSansKR_Regular`}
                            value={memberNewRecheckPassword}
                            onChange={(e) => onChangeText(e.target.value, 'newRecheckPassword')}
                        />
                    </div>
                </div>
            </div>
            <button className={`${styles.save_info_button} text_18_NotoSansKR_Bold`} onClick={changePasswordRole}>
                비밀번호 저장
            </button>
        </div>
    );
};

export const AdditionalInfo = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { memberNickName, naverId } = useSelector((state: RootState) => state.editProfile);

    const changeMemberNickName = (text: string) => {
        dispatch(updateMemberNickName(text));
    };

    const changeMemberNaverId = (text: string) => {
        dispatch(updateMemberNaverId(text));
    };

    return (
        <div className={styles.basic_info_container}>
            <div className="text_22_NotoSansKR_Bold">부가정보 입력</div>
            <div className={styles.basic_info}>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            닉네임
                            <span
                                className="text_18_NotoSansKR_Regular"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                (선택)
                            </span>
                        </Title>
                        <input
                            className={`${`${styles.input} text_18_NotoSansKR_Regular`} text_18_NotoSansKR_Regular`}
                            value={memberNickName}
                            onChange={(e) => {
                                changeMemberNickName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.info_container}>
                    <div className={styles.input_container}>
                        <Title className="text_18_NotoSansKR_Bold">
                            네이버 카페 아이디
                            <span
                                className="text_18_NotoSansKR_Regular"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                (선택)
                            </span>
                        </Title>
                        <input
                            className={`${styles.input} text_18_NotoSansKR_Regular`}
                            value={naverId}
                            onChange={(e) => {
                                changeMemberNaverId(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <button
                className={`${styles.save_info_button} text_18_NotoSansKR_Bold`}
                onClick={() => editMemberInfo('additional')}
            >
                부가정보 저장
            </button>
        </div>
    );
};

export const EventPolicy = ({ editMemberInfo }: { editMemberInfo: Function }) => {
    const dispatch = useDispatch();
    const { sms, marketing } = useSelector((state: RootState) => state.editProfile);
    const { oneButtonOpenModal } = useContext(ModalContext);
    return (
        <div className={styles.event_policy_container}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className="text_22_NotoSansKR_Bold">
                    이벤트 정보 수신
                    <EssentialMark />
                </div>
                <Toggle fn={() => {}} />
            </div>
            <div className={styles.event_content_container}>
                <div className={styles.event_content}>
                    <button onClick={() => editMemberInfo('push')} className={styles.event_item}>
                        <SquareCheckBox bool={marketing} size={'1.5rem'} />
                        <span className="text_18_NotoSansKR_Medium">
                            광고성 마케팅 수신/마케팅 활용 동의
                            <span
                                className="text_18_NotoSansKR_Regular"
                                style={{
                                    color: '#9D9FA2',
                                }}
                            >
                                (선택)
                            </span>
                        </span>
                    </button>
                    <div
                        onClick={() => {
                            oneButtonOpenModal(
                                () => {},
                                '광고성 정보 수신 및 마케팅 활용 동의',
                                `수집목적: 마케팅 활용\n수집항목: 휴대폰 번호, 이메일 주소\n이용목적: 신규 서비스 및 프로그램 안내 등 마케팅 활용 및 광고성 정보 전달\n개인정보의 보유 및 이용 기간: 회원탈퇴 시 또는 법정 의무 보유기간`,
                                '확인'
                            );
                        }}
                        className={styles.event_detail_button}
                    >
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: '#3A5074',
                            }}
                        >
                            내용보기
                        </span>
                        <Icon_arrowright className={styles.icon_arrowright} />
                    </div>
                </div>
                <button onClick={() => editMemberInfo('push')} className={styles.event_content}>
                    <div className={styles.event_item}>
                        <SquareCheckBox bool={sms} size={'1.5rem'} />
                        <span className="text_18_NotoSansKR_Medium">SMS</span>
                    </div>
                </button>
            </div>
        </div>
    );
};
