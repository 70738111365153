import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Signup {
    identityInfo: [name: string, mobileno: string, birthdate: number, gender: string];
    memberEmail: string;
    emailAddress: string;
    emailAddressVisible: boolean;
    memberAccount: string;
    memberPassword: string;
    userRecheckPw: string;
    organList: { companyId: number; branchName: string }[];
    selectedOrgan: { companyId: number; branchName: string }[];
    snsType: string;
}

const initialState: Signup = {
    identityInfo: ['', '', 0, ''], // 수정된 부분
    memberEmail: '',
    emailAddress: '선택해 주세요.',
    emailAddressVisible: false,
    memberAccount: '',
    memberPassword: '',
    userRecheckPw: '',
    organList: [],
    selectedOrgan: [],
    snsType: 'normal',
};

export const eventsSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        updateUserIdentityInfo: (
            state,
            action: PayloadAction<{ utf8_name: string; mobileno: string; birthdate: number; gender: string }>
        ) => {
            state.identityInfo[0] = action.payload.utf8_name;
            state.identityInfo[1] = action.payload.mobileno.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'); // 정규식 수정
            state.identityInfo[2] = action.payload.birthdate;
            state.identityInfo[3] = action.payload.gender === '1' ? '남' : '여';
        },
        updatememberEmail: (state, action: PayloadAction<string>) => {
            state.memberEmail = action.payload; // 새로운 이벤트 추가
        },
        updateEmailAddress: (state, action: PayloadAction<string>) => {
            state.emailAddress = action.payload;
        },
        updateEmailAddressVisible: (state, action: PayloadAction<boolean>) => {
            state.emailAddressVisible = action.payload;
        },
        updateMemberAccount: (state, action: PayloadAction<string>) => {
            state.memberAccount = action.payload; // 새로운 이벤트 추가
        },
        updateMemberPassword: (state, action: PayloadAction<string>) => {
            state.memberPassword = action.payload; // 새로운 이벤트 추가
        },
        updateUserRecheckPw: (state, action: PayloadAction<string>) => {
            state.userRecheckPw = action.payload; // 새로운 이벤트 추가
        },
        updateOrganList: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.organList.push(...action.payload);
        },
        updateSelectedOrgan: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.selectedOrgan.push(...action.payload);
        },

        updateSelectedOrganNormal: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.selectedOrgan = action.payload;
        },
        updateSnsType: (state, action: PayloadAction<string>) => {
            state.snsType = action.payload;
        },
        resetSignup: () => initialState,
    },
});

export const {
    updateUserIdentityInfo,
    updatememberEmail,
    updateEmailAddress,
    updateEmailAddressVisible,
    updateMemberAccount,
    updateMemberPassword,
    updateUserRecheckPw,
    updateOrganList,
    updateSelectedOrgan,
    updateSelectedOrganNormal,
    updateSnsType,
    resetSignup,
} = eventsSlice.actions;
export default eventsSlice.reducer;
