import { AdditionalInfo, BasicInfo, ChangePassword, EventPolicy } from '../../component/MemberInfoComponent';
import styles from './css/MemberInfo.module.css';
import { ReactComponent as Icon_arrowright } from '../../assets/member/signup/icon_arrowright.svg';
import { useContext, useEffect } from 'react';
import { ModalContext } from '../../context/ModalContext';
import axiosInstance from '../../utils/AxiosInstanceJava';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { updateEmailAddress, updateMemberMarketing, updateMemberSms } from '../../features/editProfileReducer';
import { useLocation } from 'react-router-dom';
import { FooterBasicComponent } from '../../component/FooterComponent';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getMemberInfo } from './ts/Public';

export const MemberInfo = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath = location.pathname;
    const newMemberCheck = currentPath === '/yd/patient/memberInfo' ? true : false;
    const { twoButtonOpenModal, oneButtonOpenModal } = useContext(ModalContext);
    const {
        memberName,
        memberAccount,
        memberPhoneNumber,
        memberBirth,
        memberGender,
        memberEmail,
        memberNewPassword,
        memberNewRecheckPassword,
        memberNickName,
        naverId,
        marketing,
        sms,
        type,
    } = useSelector((state: RootState) => state.editProfile);

    const { data, isLoading, error } = useQuery({
        queryKey: ['getMemberInfo'],
        queryFn: () => getMemberInfo(),
        placeholderData: keepPreviousData,
    });

    const deleteGuide = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                deleteAccount();
            },
            '탈퇴 안내',
            '탈퇴 버튼 선택 시, 계정은 삭제되며 복구되지 않습니다. 정말 탈퇴하시겠습니까?',
            '취소',
            '탈퇴'
        );
    };

    const deleteAccount = async () => {
        setTimeout(() => {
            oneButtonOpenModal(() => {}, '', '회원탈퇴가 정상적으로 완료되었습니다.', '확인');
        }, 200);
        localStorage.removeItem('userToken');
        window.location.replace('/public/login');
        // await axiosInstance.delete(`/api/member/me/v1`).then((response) => {
        //     if (response.data.result) {
        //         setTimeout(() => {
        //             oneButtonOpenModal(() => {}, '', '회원탈퇴가 정상적으로 완료되었습니다.', '확인');
        //         }, 200);
        //         localStorage.removeItem('userToken');
        //         window.location.replace('/public/login');
        //     }
        // });
    };

    const editMemberInfo = async (type: string) => {
        const requestData = {
            memberEmail: memberEmail,
            memberPassword: memberNewPassword,
            memberNickName: memberNickName,
            naverId: naverId,
            marketing: marketing,
            sms: sms,
            type: type,
        };
        await axiosInstance.put('/api/member/me/v1', requestData).then((response) => {
            console.log(response.data);
            if (response.data.result) {
                switch (type) {
                    case 'basic':
                        return oneButtonOpenModal(() => {}, '', '입력하신 내용으로 기본정보가 변경되었습니다.', '확인');
                    case 'password':
                        return oneButtonOpenModal(() => {}, '', '입력하신 내용으로 비밀번호가 변경되었습니다.', '확인');
                    case 'additional':
                        return oneButtonOpenModal(() => {}, '', '입력하신 내용으로 부가정보가 변경되었습니다.', '확인');
                    case 'push':
                        dispatch(updateMemberMarketing(!marketing));
                        dispatch(updateMemberSms(!sms));
                        return oneButtonOpenModal(
                            () => {},
                            '',
                            `수집목적: 마케팅 활용\n
            수집항목: 휴대폰 번호, 이메일 주소\n
            이용목적: 신규 서비스 및 프로그램 안내 등 마케팅 활용 및 광고성 정보 전달\n
            개인정보의 모유 및 이용 기간: 회원탈퇴 시 또는 법정 의무 보유기`,
                            '확인'
                        );
                }
            }

            oneButtonOpenModal(() => {}, '', '회원탈퇴가 정상적으로 완료되었습니다.', '확인');
        });
    };

    return (
        <div className={`${newMemberCheck ? styles.program_select_main_container : styles.main_container}`}>
            <div className="text_32_NotoSansKR_Bold">프로필 수정</div>
            <div className={styles.content_container}>
                <BasicInfo data={data} editMemberInfo={(type: string) => editMemberInfo(type)} />
                <ChangePassword editMemberInfo={(type: string) => editMemberInfo(type)} />
                {data?.member.memberRole === 'user' && (
                    <AdditionalInfo editMemberInfo={(type: string) => editMemberInfo(type)} />
                )}
                <EventPolicy editMemberInfo={(type: string) => editMemberInfo(type)} />
                <button
                    onClick={deleteGuide}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.25rem',
                    }}
                >
                    <span
                        className="text_22_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                            textDecoration: 'underline',
                        }}
                    >
                        회원탈퇴
                    </span>
                    <Icon_arrowright width={'1rem'} height={'1rem'} color="#626466" />
                </button>
            </div>
            {newMemberCheck && <FooterBasicComponent />}
        </div>
    );
};
