import React from 'react';

import moment from 'moment';

import styles from './css/PaymentReceipt.module.css';
import { BasicHeader } from '../../component/HeaderComponent';
import { MainButton } from '../member/component/MainButton';
import { ReactComponent as Icon_payment_checkout } from '../../assets/payment/icon_payment_checkout.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPaymentReceipt } from './ts/payment';
import { decryptData, encryptData } from '../../utils/croptojs';
import { FooterBasicComponent } from '../../component/FooterComponent';
export const PaymentReceipt = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const moid = params.get('moid');
    const navigate = useNavigate();
    const today = new Date();

    const { data, isLoading, error } = useQuery({
        queryKey: ['paymentReceipt', moid],
        queryFn: () => getPaymentReceipt(moid!),
        enabled: moid !== null,
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const formattedDate = moment(data?.paymentInfo.authDate, 'YYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss');

    return (
        <div className={styles.main_container}>
            <BasicHeader />
            <div className={styles.content_container}>
                <Icon_payment_checkout
                    style={{
                        width: '5rem',
                        height: '5rem',
                        marginBottom: '1.25rem',
                    }}
                />
                <div
                    className={`text_32_NotoSansKR_Bold`}
                    style={{
                        marginBottom: '2.5rem',
                    }}
                >
                    결제가 완료되었습니다.
                </div>
                <div className={styles.receipt_content_container}>
                    <div className={styles.receipt_content}>
                        <div className={`${styles.receipt_content_title} text_22_NotoSansKR_Bold`}>
                            참여 프로그램 정보
                        </div>
                        <div className={styles.item_container}>
                            <div className={styles.item}>
                                <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>결제일자</div>
                                <span className="text_18_Inter_Medium">{formattedDate}</span>
                            </div>
                            <div className={styles.item}>
                                <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>주문번호</div>
                                <span className="text_18_Inter_Medium">{data.paymentInfo.moid}</span>
                            </div>
                            <div className={styles.item}>
                                <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>프로그램 유형</div>
                                <div className={styles.item_content}>
                                    <span className="text_18_NotoSansKR_Medium">{data.paymentInfo.goodsName}</span>
                                    {'\n'}
                                    <span
                                        className="text_18_NotoSansKR_Regular"
                                        style={{
                                            color: '#9D9FA2',
                                        }}
                                    >
                                        {data.paymentInfo.productInfo.productDescription}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.receipt_content}>
                        <div className={`${styles.receipt_content_title} text_22_NotoSansKR_Bold`}>선택 옵션</div>
                        <div className={styles.item_container}>
                            <div className={styles.item}>
                                <div className={styles.item_title}>
                                    <span className="text_18_NotoSansKR_Medium">결제유형</span>
                                    {'\n'}
                                    <span
                                        className="text_18_NotoSansKR_Regular"
                                        style={{
                                            color: '#626466',
                                        }}
                                    >
                                        {data.paymentInfo.goodsName} 프로그램 가격 유형
                                    </span>
                                </div>
                                <span className="text_18_Inter_Medium">
                                    {data.paymentInfo.productInfo.discountPrice / 10000}
                                    <span className="text_18_NotoSansKR_Medium">만원</span>
                                </span>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.item_title}>
                                    <span className="text_18_NotoSansKR_Medium">결제횟수</span>
                                    {'\n'}
                                    <span
                                        className="text_18_NotoSansKR_Regular"
                                        style={{
                                            color: '#626466',
                                        }}
                                    >
                                        {data.paymentInfo.goodsName} 프로그램 결제횟수
                                    </span>
                                </div>
                                <span className="text_18_Inter_Medium">1회</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.receipt_content}>
                        <div className={`${styles.receipt_content_title} text_22_NotoSansKR_Bold`}>결제 정보</div>
                        <div className={styles.item_container}>
                            <div className={styles.item}>
                                <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>결제자명</div>
                                <span className="text_18_NotoSansKR_Medium">{data.paymentInfo.buyerName}</span>
                            </div>
                            <div className={styles.item}>
                                <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>총 결제금액</div>
                                <span className="text_18_Inter_Medium">
                                    {data.paymentInfo.amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    <span className="text_18_NotoSansKR_Medium">원</span>
                                </span>
                            </div>
                            <div className={styles.item}>
                                <div className={`${styles.item_title} text_18_NotoSansKR_Medium`}>결제수단</div>
                                <div className={styles.item_content}>
                                    <span className="text_18_NotoSansKR_Medium">
                                        {data.paymentInfo.cardInfo.cardName}({data.paymentInfo.cardInfo.acquCardName})
                                    </span>
                                    {'\n'}
                                    <span
                                        className="text_18_NotoSansKR_Regular"
                                        style={{
                                            color: '#9D9FA2',
                                        }}
                                    >
                                        일시불
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: '16.875rem',
                }}
            >
                <MainButton
                    title={'주호소 증상 선택하기'}
                    onClick={() => {
                        const params = new URLSearchParams();
                        params.append('page', '1');
                        params.append('session', encryptData(data.registerSessionId));
                        params.append('program', encryptData(data.registerId));
                        params.append('payment', encryptData('true'));

                        switch (data.paymentInfo.productInfo.workBookList[0].type) {
                            case 'face':
                                navigate(`/yd/patient/face/session?${params}`);
                                break;
                            default:
                                navigate(`/yd/patient/nonface/session?${params}`);
                                break;
                        }
                    }}
                />
            </div>
            <div
                style={{
                    width: '75rem',
                    marginTop: '3.75rem',
                }}
            >
                <FooterBasicComponent />
            </div>
        </div>
    );
};
