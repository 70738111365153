import React, { useContext, useEffect, useState } from 'react';

import styles from './css/PaymentOptions.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Icon_circle_check } from '../../../../assets/payment/icon_circle_checkbox.svg';
import { ReactComponent as Icon_circle_checkedBox } from '../../../../assets/payment/icon_circle_checkedBox.svg';
import styled from 'styled-components';
import CryptoJS from 'crypto-js'; // CryptoJS import 추가
import { ModalContext } from '../../../../context/ModalContext';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { Product } from '../../../payment/ts/payment';
import axiosInstance from '../../../../utils/AxiosInstanceJava';
import { FooterComponent } from '../../../../component/FooterComponent';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getProgramData } from './ts/PaymentOptionsApi';

interface PaymentData {
    PayMethod: string;
    GoodsName: string;
    Amt: string;
    MID: string;
    Moid: string;
    BuyerName: string;
    BuyerEmail: string;
    BuyerTel: string;
    ReturnURL: string;
    VbankExpDate: string;
    NpLang: string;
    GoodsCl: string;
    TransType: string;
    CharSet: string;
    ReqReserved: string;
    EdiDate: string; // YYYYMMDDHHMISS 형식으로 설정 필요
    SignData: string; // 서명 데이터는 나중에 설정
    ConnWithIframe: string; // iframe 사용 설정
}

interface ItemButtonProps {
    $isChecked: number;
    $type: string;
    $index: number;
    $lastIndex: number;
}

const getBorderAndBackground = (props: ItemButtonProps) => {
    const exists = props.$isChecked === props.$index;
    return {
        borderColor: exists ? '#3a5074' : '#E3E5EA',
        backgroundColor: exists ? '#f6f9ff' : '#FFF',
    };
};
// 기본 버튼 스타일
const ItemButton = styled.button<ItemButtonProps>`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 23rem;
    padding: 1.25rem 1.5rem;
    gap: 0.75rem;
    background: ${(props) => getBorderAndBackground(props).backgroundColor};
    border: 0.0625rem solid ${(props) => getBorderAndBackground(props).borderColor};
    border-radius: 0.625rem;
    cursor: pointer;
    transition: background 0.3s;
`;

export const PaymentOptions = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramsData = queryParams.get('paymentData');
    const pushData = JSON.parse(paramsData!);
    const navigate = useNavigate();

    const { data, isLoading, error } = useQuery<Product>({
        queryKey: ['getProgramData', pushData.workBookProductId],
        queryFn: () => getProgramData(pushData.workBookProductId),
        enabled: pushData.workBookProductId !== null,
        placeholderData: keepPreviousData,
    });

    const [isChecked, setIsChecked] = useState<number>(0);
    const [discountPrice, setDiscountPrice] = useState<string>('');

    const paymentApproval = async () => {
        const memberInfo = getMemberInfo();
        const discount = discountPrice === '' ? 0 : discountPrice;
        await axiosInstance
            .put(`/api/${memberInfo?.memberRole}/payment/v1`, {
                pushAlarmId: pushData.pushAlarmId,
                discountPrice: discount,
                approvalCheck: true,
                payPrice: Number(data?.product.discountPrice) - Number(discountPrice),
                separateCount: isChecked + 1,
            })
            .then((response) => {
                if (response.data.result) {
                    navigate(-1);
                }
            });
    };

    const IconCircleCheck = ({ index }: { index: number }) => {
        const exists = isChecked === index;
        if (exists) {
            return <Icon_circle_checkedBox width={'2rem'} height={'2rem'} />;
        } else {
            return <Icon_circle_check width={'2rem'} height={'2rem'} />;
        }
    };

    // 프로그램
    const Program = () => {
        return (
            <div className={styles.item_container}>
                <span className="text_22_NotoSansKR_Bold">프로그램</span>
                <div className={styles.program_content}>
                    <div className={styles.title}>
                        <span className="text_18_NotoSansKR_Medium">{data?.product.productName}</span>
                        <span className="text_18_NotoSansKR_Regular" style={{ color: '#9D9FA2' }}>
                            진행회기: {data?.product.productDescription}
                        </span>
                    </div>

                    <div className={styles.price}>
                        <span className="text_18_Inter_Medium">
                            {data?.product.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            <span className="text_18_NotoSansKR_Medium">원</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    // 결제 횟수
    const Count = () => {
        if (data?.type === 'face') {
            return (
                <div className={styles.item_container}>
                    <span className="text_22_NotoSansKR_Bold">결제 횟수</span>
                    <span
                        className={`text_18_NotoSansKR_Regular`}
                        style={{
                            color: '#626466',
                        }}
                    >
                        인지행동치료(대면형) 프로그램의 결제 횟수를 선택해 주세요.
                    </span>
                    <div className={styles.item_button_container}>
                        <ItemButton
                            onClick={() => setIsChecked(0)}
                            $isChecked={isChecked}
                            $type="count"
                            $index={0}
                            $lastIndex={0}
                        >
                            <IconCircleCheck index={0} />
                            <span
                                className={`${
                                    isChecked === 0 ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'
                                }`}
                                style={{
                                    color: isChecked === 0 ? '#3A5074' : '#020202',
                                }}
                            >
                                1회 (한 번에 결제)
                            </span>
                        </ItemButton>
                        {data.splitAllowed && (
                            <ItemButton
                                onClick={() => setIsChecked(1)}
                                $isChecked={isChecked}
                                $type="count"
                                $index={1}
                                $lastIndex={1}
                            >
                                <IconCircleCheck index={1} />
                                <span
                                    className={`${
                                        isChecked === 1 ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'
                                    }`}
                                    style={{
                                        color: isChecked === 1 ? '#3A5074' : '#020202',
                                    }}
                                >
                                    2회 (두 번 나눠서 결제)
                                </span>
                            </ItemButton>
                        )}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const DisCount = ({ changeDiscountPrice }: { changeDiscountPrice: Function }) => {
        const [price, setPrice] = useState<string>(discountPrice);
        const handleInputChange = (e: any) => {
            // 입력값에서 숫자만 허용
            const inputValue = e.target.value.replace(/[^0-9]/g, '');
            setPrice(inputValue); // 숫자로만 설정
        };

        return (
            <div className={styles.item_container}>
                <span className="text_22_NotoSansKR_Bold">할인 금액</span>
                <span
                    className={`text_18_NotoSansKR_Regular`}
                    style={{
                        color: '#626466',
                    }}
                >
                    할인 금액을 입력해 주세요.
                </span>
                <div className={styles.item_button_container}>
                    <input
                        type="text"
                        className={`${styles.discount_input} text_18_NotoSansKR_Regular`}
                        placeholder="할인 금액 입력"
                        value={price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={handleInputChange}
                    />
                    <button
                        onClick={() => changeDiscountPrice(price)}
                        className={`${styles.discount_save_button} text_18_NotoSansKR_Bold`}
                    >
                        적용
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.content_container}>
                <div className={styles.payment_content_container}>
                    <span className="text_32_NotoSansKR_Bold">결제 옵션 설정</span>
                    <Program />
                    <Count />
                    <DisCount changeDiscountPrice={(text: string) => setDiscountPrice(text)} />
                </div>
                <div className={styles.payment_container}>
                    <div>
                        <div className={styles.total_price}>
                            <span className="text_22_NotoSansKR_Bold">총 결제금액</span>
                            <span className="text_22_Inter_Bold">
                                {(Number(data?.product.discountPrice) - Number(discountPrice))
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                <span className="text_22_NotoSansKR_Bold">원</span>
                            </span>
                        </div>
                        <button
                            onClick={paymentApproval}
                            className={`${styles.payment_button} text_18_NotoSansKR_Bold`}
                        >
                            결제요청 승인
                        </button>
                    </div>
                    <button
                        onClick={() => navigate(-1)}
                        className={`${styles.payment_cancel_button} text_18_NotoSansKR_Bold`}
                    >
                        취소
                    </button>
                </div>
            </div>
        </div>
    );
};
