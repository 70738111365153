import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { ModalProvider } from './context/ModalContext';
import { WindowWidthProvider } from './context/WindowWidthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { InactivityProvider } from './context/InactivityContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <WindowWidthProvider>
            <InactivityProvider>
                <QueryClientProvider client={queryClient}>
                    <ModalProvider>
                        <App />
                    </ModalProvider>
                </QueryClientProvider>
            </InactivityProvider>
        </WindowWidthProvider>
    </Provider>
);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
            console.log('Service Worker registered successfully:', registration);
        })
        .catch((err) => {
            console.error('Service Worker registration failed:', err);
        });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();

reportWebVitals();
