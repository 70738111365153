import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import styles from './css/Representative.module.css';

import { ManagerAdministratorHeader } from '../../../../component/HeaderComponent';
import { RepresentativeLeftMenu } from '../../../../component/MenuComponent';
import { AssignedDetail } from '../assignedDetail/AssignedDetail';
import { MemberInfo } from '../../../public/MemberInfo';
import { NotificationSetting } from '../../../public/NotificationSetting';
import { RepresentativeAssignedMember } from '../assignedMember/RepresentativeAssignedMember';
import { ReservationStatus } from '../reservationStatus/ReservationStatus';
import { Authority } from '../authority/Authority';
import { ProgramManagement } from '../program/ProgramManagement';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PaymentDetails } from '../paymentDetails/PaymentDetails';
import { FooterComponent } from '../../../../component/FooterComponent';
import { PaymentOptions } from '../paymentOptions/PaymentOptions';
import { useState } from 'react';
import { encryptData } from '../../../../utils/croptojs';
import { TherapistFaceCBT } from '../../cbt/session/therapist/TherapistFaceCBT';
import { TherapistNonFaceCBT } from '../../cbt/session/therapist/TherapistNonFaceCBT';
import { TherapistAssignedMember } from '../assignedMember/TherapistAssignedMember';

export const Representative = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const visible = useSelector((state: RootState) => state.navVisible);

    const headerTitle = () => {
        if (encryptedData) {
            const session = encryptedData.toString();
            switch (session) {
                case '1':
                    return '세션1: ';

                case '2':
                    return '세션2: ';

                case '3':
                    return '세션3: ';

                case '4':
                    return '세션4: ';

                case '5':
                    return '세션5: ';

                case '6':
                    return '세션6: ';

                case '7':
                    return '세션7: ';

                case '8':
                    return '세션8: ';

                case '9':
                    return '세션9: ';

                case '10':
                    return '세션10: ';
            }
        }
    };

    return (
        <div className={styles.main_container}>
            <ManagerAdministratorHeader title={''} />
            <RepresentativeLeftMenu />
            <div className={`${styles.content_container} ${visible.navVisible ? styles.activate : styles.deactivate}`}>
                <Routes>
                    <Route path="assignedMember" element={<RepresentativeAssignedMember />} />
                    <Route path="assignedMember/detail/*" element={<AssignedDetail />} />
                    <Route path="reservationStatus" element={<ReservationStatus />} />
                    <Route path="paymentDetails" element={<PaymentDetails />} />
                    <Route path="paymentOptions" element={<PaymentOptions />} />
                    <Route path="authority" element={<Authority />} />
                    <Route path="program" element={<ProgramManagement />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                    <Route
                        path="assignedMember/patientCBT/face"
                        element={
                            shouldRedirect ? (
                                <Navigate
                                    to={`/yd/therapist/assignedMember/patientCBT/face/session?page=${encodeURIComponent(
                                        encryptData('1')
                                    )}`}
                                    replace
                                />
                            ) : (
                                <TherapistAssignedMember />
                            )
                        }
                    />
                    <Route path="assignedMember/patientCBT/face/*" element={<TherapistFaceCBT />} />
                    <Route
                        path="assignedMember/patientCBT/nonface"
                        element={
                            shouldRedirect ? (
                                <Navigate
                                    to={`/yd/therapist/assignedMember/patientCBT/nonface/session?page=${encodeURIComponent(
                                        encryptData('1')
                                    )}`}
                                    replace
                                />
                            ) : (
                                <TherapistAssignedMember />
                            )
                        }
                    />
                    <Route path="assignedMember/patientCBT/nonface/*" element={<TherapistNonFaceCBT />} />
                </Routes>
            </div>

            <div
                style={{
                    marginLeft: currentPath.includes('patientCBT') ? '16.25rem' : '',
                }}
            >
                <FooterComponent />
            </div>
        </div>
    );
};
