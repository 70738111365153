import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Program } from '../pages/home/official/program/ts/ProgramManagementApi';

interface ProgramDetail {
    workBookProductId: number;
    productName: string;
    originalPrice: number | string;
    discount: number | string;
    workBooks: {
        workBookId: number;
        price: number;
        workBookName: string;
    }[];
    onSale: boolean;
    productDescription: string;
    categoryList: { workBookId: number; price: number | string; workBookName: string }[];
    statusList: string[];
    programCategoryVisible: boolean;
    programStatusVisible: boolean;
    message: string;
    result: boolean;
}

const initialState: ProgramDetail = {
    workBookProductId: 0,
    productName: '',
    originalPrice: '',
    discount: '',
    workBooks: [],
    onSale: false,
    productDescription: '',
    categoryList: [{ workBookId: 0, price: '', workBookName: '' }],
    statusList: ['노출', '비노출'],
    programCategoryVisible: false,
    programStatusVisible: false,
    message: '',
    result: false,
};

const programModalReducer = createSlice({
    name: 'programModal',
    initialState,
    reducers: {
        updateSelectProgramDetail: (state, action: PayloadAction<Program>) => {
            const { workBookProductId, productName, originalPrice, discount, workBooks, onSale, productDescription } =
                action.payload;
            state.workBookProductId = workBookProductId;
            state.productName = productName;
            state.originalPrice = originalPrice;
            state.discount = discount;
            state.workBooks = workBooks;
            state.onSale = onSale;
            state.productDescription = productDescription;
        },
        updateProductId: (state, action: PayloadAction<number>) => {
            state.workBookProductId = action.payload;
        },
        updateProductName: (state, action: PayloadAction<string>) => {
            state.productName = action.payload;
        },
        updateOriginPrice: (state, action: PayloadAction<string>) => {
            state.originalPrice = action.payload;
        },
        updateDiscount: (state, action: PayloadAction<string>) => {
            state.discount = action.payload;
        },
        updateWorkBooks: (
            state,
            action: PayloadAction<{ workBookId: number; price: number; workBookName: string }[]>
        ) => {
            state.workBooks = action.payload;
        },
        updateCategoryList: (
            state,
            action: PayloadAction<{ workBookId: number; price: number | string; workBookName: string }[]>
        ) => {
            state.categoryList = action.payload;
        },
        updateProgramStatus: (state, action: PayloadAction<boolean>) => {
            state.onSale = action.payload;
        },
        updateProgramDescription: (state, action: PayloadAction<string>) => {
            state.productDescription = action.payload;
        },
        updateProgramCategoryVisible: (state, action: PayloadAction<boolean>) => {
            state.programCategoryVisible = action.payload;
        },
        updateProgramStatusVisible: (state, action: PayloadAction<boolean>) => {
            state.programStatusVisible = action.payload;
        },
        programDataReset: () => initialState,
    },
});

export const {
    updateSelectProgramDetail,
    updateProductId,
    updateProductName,
    updateOriginPrice,
    updateDiscount,
    updateWorkBooks,
    updateCategoryList,
    updateProgramStatus,
    updateProgramDescription,
    updateProgramCategoryVisible,
    updateProgramStatusVisible,
    programDataReset,
} = programModalReducer.actions;
export default programModalReducer.reducer;
