import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getMemberInfo } from '../utils/GetMemberInfo';

interface PaymentDetails {
    companyId: number;
    pageNum: number;
    roleVisible: { index: number; visible: boolean };
    organVisible: { index: number; visible: boolean };
    employeeSettingVisible: { index: number; visible: boolean };
    employeeSettingMenuList: string[];
    checkAry: { companyId: number; branchName: string }[];
    isSelectedTitle: string;
    payStatusList: { title: string; type: number }[];
    chartTitleList: string[];
    isSelectedPayStatus: number;
}
const memberInfo = getMemberInfo();
const initialState: PaymentDetails = {
    companyId: memberInfo?.loginBranch ? memberInfo?.loginBranch.companyId : 0,
    pageNum: 0,
    roleVisible: { index: -1, visible: false },
    organVisible: { index: -1, visible: false },
    employeeSettingVisible: { index: -1, visible: false },
    employeeSettingMenuList: ['권한 범위 설정', '직원 삭제'],
    checkAry: [],
    isSelectedTitle: '모든 기관',
    payStatusList: [
        { title: '전체', type: -1 },
        { title: '결제완료', type: 1 },
        { title: '부분취소', type: 3 },
        { title: '전체취소', type: 2 },
    ],
    chartTitleList: ['결제상태', '주문번호', '프로그램 유형', '결제자명', '결제수단', '총 결제금액', '결제일시', ''],
    isSelectedPayStatus: -1,
};

const paymentDetailsReducer = createSlice({
    name: 'paymentDetails',
    initialState,
    reducers: {
        updatePageNum: (state, action: PayloadAction<number>) => {
            state.pageNum = action.payload;
        },
        updateSelectOrgan: (state, action: PayloadAction<{ companyId: number; branchName: string }>) => {
            const { companyId, branchName } = action.payload;
            state.companyId = companyId;
            state.isSelectedTitle = branchName;
        },
        updateRoleVisible: (state, action: PayloadAction<{ index: number; visible: boolean }>) => {
            state.roleVisible = action.payload;
        },
        updateBranchVisible: (state, action: PayloadAction<{ index: number; visible: boolean }>) => {
            state.organVisible = action.payload;
        },
        updateEmployeeSettingVisible: (state, action: PayloadAction<{ index: number; visible: boolean }>) => {
            state.employeeSettingVisible = action.payload;
        },
        updateCheckAry: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.checkAry = action.payload;
        },
        updateIsSelectedPayStatus: (state, action: PayloadAction<number>) => {
            state.isSelectedPayStatus = action.payload;
        },
        resetModal: (state) => {
            state.roleVisible = { index: -1, visible: false };
            state.organVisible = { index: -1, visible: false };
            state.employeeSettingVisible = { index: -1, visible: false };
        },
    },
});

export const {
    updatePageNum,
    updateSelectOrgan,
    updateRoleVisible,
    updateBranchVisible,
    updateEmployeeSettingVisible,
    updateCheckAry,
    updateIsSelectedPayStatus,
    resetModal,
} = paymentDetailsReducer.actions;
export default paymentDetailsReducer.reducer;
