import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './features/loginReducer';
import memberTypeMenuReducer from './features/memberTypeMenuReducer';
import signupReducer from './features/signupReducer';
import leftNavReducer from './features/leftNavReducer';
import headerReducer from './features/headerReducer';
import faceSessionReducer from './features/faceSessionReducer';
import reservationReducer from './features/reservationReducer';
import scheduleModalReducer from './features/scheduleModalReducer';
import authorityReducer from './features/authorityReducer';
import programModalReducer from './features/programReducer';
import editProfileReducer from './features/editProfileReducer';
import sessionReducer from './features/session/sessionReducer';
import sessionMenuReducer from './features/sessionMenuReducer';
import blackboardReducer from './features/session/blackboardReducer';
import paymentDetailsReducer from './features/paymentDetailsReducer';
import addCountReducer from './features/addCountReducer';
import notificationReducer from './features/notificationReducer';
import selectTherapistModalReducer from './features/selectTherapistModalReducer';
import paymentDetailModalReducer from './features/paymentDetailModalReducer';

export const store = configureStore({
    reducer: {
        events: reservationReducer,
        login: loginReducer,
        signup: signupReducer,
        memberType: memberTypeMenuReducer,
        navVisible: leftNavReducer,
        header: headerReducer,
        faceSession: faceSessionReducer,
        scheduleModal: scheduleModalReducer,
        authority: authorityReducer,
        programModal: programModalReducer,
        editProfile: editProfileReducer,
        addCount: addCountReducer,
        selectTherapistModal: selectTherapistModalReducer,
        // menu
        sessionMenu: sessionMenuReducer,
        paymentDetails: paymentDetailsReducer,
        paymentDetailModal: paymentDetailModalReducer,

        // Session
        session: sessionReducer,
        blackboard: blackboardReducer,

        // notification
        notification: notificationReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
