import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './css/TherapistCBT.module.css';
import { TherapistFirstSession } from './therapistFaceSession/TherapistFirstSession';
import { TherapistSecondSession } from './therapistFaceSession/TherapistSecondSession';
import { TherapistThirdSession } from './therapistFaceSession/TherapistThirdSession';
import { TherapistFourthSession } from './therapistFaceSession/TherapistFourthSession';
import { TherapistFifthSession } from './therapistFaceSession/TherapistFifthSession';
import { TherapistSixthSession } from './therapistFaceSession/TherapistSixthSession';
import { TherapistSeventhSession } from './therapistFaceSession/TherapistSeventhSession';
import { TherapistEighthSession } from './therapistFaceSession/TherapistEighthSession';
import { TherapistNinthSession } from './therapistFaceSession/TherapistNinthSession';
import { TherapistTenthSession } from './therapistFaceSession/TherapistTenthSession';
import { SessionMap } from '../../SessionMap';
import { MemberInfo } from '../../../../public/MemberInfo';
import { NotificationSetting } from '../../../../public/NotificationSetting';
import { TherapistCBTMenu } from './component/TherapistCBTComponent';

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionMenu } from '../ts/SessionApi';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSessionMenu } from '../../../../../features/sessionMenuReducer';
import eraserCursor from '../../../../../assets/eraser_test.jpg';
import penCursor from '../../../../../assets/pen_test.png';
import html2canvas from 'html2canvas';
import { RootState } from '../../../../../store';
import { CanvasOptionsModal } from '../../component/CanvasOptionsModal';
import { CanvasColorPicker } from '../../component/CanvasColorPicker';
import jsPDF from 'jspdf';
import axios from 'axios';
import { setContentRef } from '../../../../../features/session/blackboardReducer';
import { TherapistAssignedFirstSession } from './therapistAssigned/assigned/TherapistAssignedFirstSession';
import { TherapistAssignedSecondSession } from './therapistAssigned/assigned/TherapistAssignedSecondSession';
import { TherapistAssignedThirdSession } from './therapistAssigned/assigned/TherapistAssignedThirdSession';
import { TherapistAssignedFourthSession } from './therapistAssigned/assigned/TherapistAssignedFourthSession';
import { TherapistAssignedFifthSession } from './therapistAssigned/assigned/TherapistAssignedFifthSession';
import { TherapistAssignedSixthSession } from './therapistAssigned/assigned/TherapistAssignedSixthSession';
import { TherapistAssignedSeventhSession } from './therapistAssigned/assigned/TherapistAssignedSeventhSession';
import { TherapistAssignedEighthSession } from './therapistAssigned/assigned/TherapistAssignedEighthSession';
import { TherapistAssignedNinthSession } from './therapistAssigned/assigned/TherapistAssignedNinthSession';
import { TherapistAssignedTenthSession } from './therapistAssigned/assigned/TherapistAssignedTenthSession';
import { ModalContext } from '../../../../../context/ModalContext';

export const TherapistFaceCBT = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { blackboardVisible, colorPickerVisible, lineWidth, lineColor, eraserVisible, feedbackVisible } = useSelector(
        (state: RootState) => state.blackboard
    );
    const { oneButtonOpenModal } = useContext(ModalContext);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const programId = queryParams.get('program');

    const { data, isLoading, error } = useQuery({
        queryKey: ['therapistSessionMenu', programId],
        queryFn: () => getSessionMenu(programId!),
        placeholderData: keepPreviousData,
        enabled: programId !== null,
    });

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [drawingURL, setDrawingURL] = useState<string | null>(null);

    const [canvasHeight, setCanvasHeight] = useState(window.innerHeight);
    const [canvasWidth, setCanvasWidth] = useState(window.innerWidth);

    const [text, setText] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [lastX, setLastX] = useState<number>(0);
    const [lastY, setLastY] = useState<number>(0);
    const [color, setColor] = useState<string>('#171717'); // 기본 펜 색상
    const [eraserWidth, setEraserWidth] = useState<number>(10); // 기본 지우개 두께
    const [cursorStyle, setCursorStyle] = useState(`16 16, auto`); // 기본 커서 스타일
    const [drawings, setDrawings] = useState<{ x: number; y: number; width: number; color: string }[][]>([]); // 색상과 굵기를 포함한 그린 내용을 저장할 상태

    // Text to Image
    const drawText = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');

        // 캔버스 초기화
        ctx!.clearRect(0, 0, canvas.width, canvas.height);

        // 텍스트 스타일 설정
        ctx!.fillStyle = 'black';
        ctx!.font = '30px Arial';
        ctx!.textAlign = 'center';
        ctx!.textBaseline = 'middle';

        // 텍스트 그리기
        ctx!.fillText(text, canvas.width / 2, canvas.height / 2);
    };

    const downloadPDF = async () => {
        if (contentRef.current) {
            try {
                const contentWidth = contentRef.current.offsetWidth; // 콘텐츠 너비
                const contentHeight = contentRef.current.scrollHeight; // 콘텐츠 전체 높이
                // 1. CDN 링크에서 텍스트 파일 다운로드
                const txtUrl = '/pdf/session_base6.txt'; // 텍스트 파일의 CDN 링크
                const response = await axios.get(txtUrl, { responseType: 'text' });

                const textContent = response.data;

                // 3. Base64 이미지 추출 (예시: 텍스트 내용에서 Base64 이미지 패턴을 찾기)
                const base64ImageMatch = textContent.match(/data:image\/(png|jpg|jpeg);base64,[A-Za-z0-9+/=]+/);
                const base64Image = base64ImageMatch ? base64ImageMatch[0] : null;

                // CORS 에러 처리
                if (base64Image) {
                    const pdf = new jsPDF({
                        orientation: 'portrait', // 세로 방향으로 설정
                        unit: 'mm',
                        format: [contentWidth * 0.264583, contentHeight * 0.264583], // 픽셀을 mm로 변환
                        // putOnlyUsedFonts: true,
                        // floatPrecision: 16,
                    });

                    // 4. 이미지 추가
                    pdf.addImage(base64Image, 'JPEG', 0, 0, contentWidth * 0.264583, contentHeight * 0.264583); // 비율 유지하여 추가
                    // 5. PDF 저장
                    pdf.save('session_page.pdf');
                } else {
                    console.warn('Base64 image not found in text content.');
                }
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
    };

    // TextImage + DrawingImage Save
    const exportPDF = async () => {
        if (contentRef.current) {
            // 1. 콘텐츠의 너비와 높이 측정
            const contentWidth = contentRef.current.offsetWidth; // 콘텐츠 너비
            const contentHeight = contentRef.current.scrollHeight; // 콘텐츠 전체 높이

            // 2. 높은 해상도로 캡처
            const canvasImage = await html2canvas(contentRef.current, {
                scale: 1,
            });

            const imageData = canvasImage.toDataURL('image/jpeg', 0.7); // jpeg 포맷 사용
            localStorage.setItem('drawingData', JSON.stringify(imageData));
            // 3. PDF 생성
            const pdf = new jsPDF({
                orientation: 'portrait', // 세로 방향으로 설정
                unit: 'mm',
                format: [contentWidth * 0.264583, contentHeight * 0.264583], // 픽셀을 mm로 변환
                // putOnlyUsedFonts: true,
                // floatPrecision: 16,
            });

            // 4. 이미지 추가
            pdf.addImage(imageData, 'JPEG', 0, 0, contentWidth * 0.264583, contentHeight * 0.264583); // 비율 유지하여 추가
            // 5. PDF 저장
            pdf.save('session_page.pdf');
        }
    };
    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas || !blackboardVisible) return;

        const rect = canvas.getBoundingClientRect();

        let currentX: number;
        let currentY: number;

        if (e.type === 'touchstart') {
            const touch = (e as React.TouchEvent<HTMLCanvasElement>).touches[0];
            currentX = touch.clientX - rect.left; // touch의 clientX를 사용
            currentY = touch.clientY - rect.top; // touch의 clientY를 사용
        } else {
            currentX = (e as React.MouseEvent<HTMLCanvasElement>).nativeEvent.offsetX; // MouseEvent에서 직접 가져오기
            currentY = (e as React.MouseEvent<HTMLCanvasElement>).nativeEvent.offsetY; // MouseEvent에서 직접 가져오기
        }

        setIsDrawing(true);
        setLastX(currentX);
        setLastY(currentY);

        // 새로운 선 시작
        if (!eraserVisible) {
            setDrawings((prev) => [...prev, [{ x: currentX, y: currentY, width: lineWidth, color: lineColor }]]); // 굵기와 색상을 함께 저장
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas || !isDrawing) return;

        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        const currentX =
            e.type === 'touchmove'
                ? (e as React.TouchEvent<HTMLCanvasElement>).touches[0].clientX - rect.left
                : (e as React.MouseEvent<HTMLCanvasElement>).clientX - rect.left;

        const currentY =
            e.type === 'touchmove'
                ? (e as React.TouchEvent<HTMLCanvasElement>).touches[0].clientY - rect.top
                : (e as React.MouseEvent<HTMLCanvasElement>).clientY - rect.top;

        if (isDrawing) {
            if (eraserVisible) {
                ctx!.clearRect(
                    currentX - eraserWidth / 2,
                    currentY - eraserWidth / 2,
                    eraserWidth * 2,
                    eraserWidth * 2
                );

                setDrawings((prevDrawings) => {
                    return prevDrawings
                        .map((line) => {
                            return line.filter((point) => {
                                // 현재 포인트와 지우개 영역 내에 있는 포인트를 제거
                                return !(
                                    point.x >= currentX - eraserWidth / 2 &&
                                    point.x <= currentX + eraserWidth / 2 &&
                                    point.y >= currentY - eraserWidth / 2 &&
                                    point.y <= currentY + eraserWidth / 2
                                );
                            });
                        })
                        .filter((line) => line.length > 0); // 빈 선 제거
                });
            } else {
                // 이전 선의 굵기와 색기로 그리기
                const currentDrawing = drawings[drawings.length - 1]; // 마지막 선
                const currentLineWidth = currentDrawing[currentDrawing.length - 1].width; // 마지막 굵기
                const currentLineColor = currentDrawing[currentDrawing.length - 1].color; // 마지막 색상

                ctx!.lineWidth = currentLineWidth; // 현재 굵기로 설정
                ctx!.strokeStyle = currentLineColor; // 현재 색기로 설정
                ctx!.beginPath();
                ctx!.moveTo(lastX, lastY);
                ctx!.lineTo(currentX, currentY);
                ctx!.stroke();
                ctx!.closePath();

                // 현재 좌표를 마지막 선에 추가
                setDrawings((prev) => {
                    const newDrawings = [...prev];
                    newDrawings[newDrawings.length - 1].push({
                        x: currentX,
                        y: currentY,
                        width: currentLineWidth,
                        color: currentLineColor,
                    });
                    return newDrawings;
                });
            }
        }

        // 마지막 좌표 업데이트
        setLastX(currentX);
        setLastY(currentY);
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        e.preventDefault();
        setIsDrawing(false);
    };

    useEffect(() => {
        if (data) {
            dispatch(updateSessionMenu(data));
        }
    }, [data, dispatch]);

    useEffect(() => {
        const updateCanvasHeight = () => {
            if (contentRef.current) {
                const height = contentRef.current.offsetHeight;
                const width = contentRef.current.offsetWidth;
                setCanvasHeight(height);
                setCanvasWidth(width);
            }
        };

        // setTimeout을 사용하여 DOM 요소가 렌더링된 후 높이 업데이트
        const timer = setTimeout(updateCanvasHeight, 3000);

        // resize 이벤트 핸들러 추가
        window.addEventListener('resize', updateCanvasHeight);
        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', updateCanvasHeight);
        };
    }, []);

    useEffect(() => {
        // exportPDF();
        if (contentRef.current) {
            dispatch(setContentRef(contentRef.current));
        }
    }, [drawings]);

    if (isLoading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;
    if (!data) return <div>No data available</div>; // data가 없을 때 처리

    const SessionPage = () => {
        const session = Number(encryptedData!);
        switch (session) {
            case 1:
                return <TherapistFirstSession />;
            case 2:
                return <TherapistSecondSession />;
            case 3:
                return <TherapistThirdSession />;
            case 4:
                return <TherapistFourthSession />;
            case 5:
                return <TherapistFifthSession />;
            case 6:
                return <TherapistSixthSession />;
            case 7:
                return <TherapistSeventhSession />;
            case 8:
                return <TherapistEighthSession />;
            case 9:
                return <TherapistNinthSession />;
            case 10:
                return <TherapistTenthSession />;
        }
    };

    const AssignedSessionPage = () => {
        const session = Number(encryptedData!);
        switch (session) {
            case 1:
                return <TherapistAssignedFirstSession />;
            case 2:
                return <TherapistAssignedSecondSession />;
            case 3:
                return <TherapistAssignedThirdSession />;
            case 4:
                return <TherapistAssignedFourthSession />;
            case 5:
                return <TherapistAssignedFifthSession />;
            case 6:
                return <TherapistAssignedSixthSession />;
            case 7:
                return <TherapistAssignedSeventhSession />;
            case 8:
                return <TherapistAssignedEighthSession />;
            case 9:
                return <TherapistAssignedNinthSession />;
            case 10:
                return <TherapistAssignedTenthSession />;
        }
    };

    return (
        <div className={styles.main_container}>
            <TherapistCBTMenu />
            <div className={`${styles.content_container}`}>
                <div
                    ref={contentRef}
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <Routes>
                        <Route path="session" element={SessionPage()} />
                        <Route path="session/assigned" element={AssignedSessionPage()} />
                    </Routes>
                    {((drawings.length === 0 && blackboardVisible) || drawings.length > 0) && (
                        <canvas
                            ref={canvasRef}
                            width={canvasWidth}
                            height={canvasHeight}
                            style={{
                                position: 'absolute',
                                display: feedbackVisible ? 'none' : 'block',
                                zIndex: 10,
                                cursor: cursorStyle,
                                touchAction: 'none',
                            }}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseLeave}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}
                            onTouchEnd={handleMouseLeave}
                        />
                    )}
                </div>
                <Routes>
                    <Route path="sessionMap" element={<SessionMap />} />
                    <Route path="memberInfo" element={<MemberInfo />} />
                    <Route path="notificationSetting" element={<NotificationSetting />} />
                </Routes>

                {blackboardVisible && <CanvasOptionsModal />}
                {blackboardVisible && colorPickerVisible && <CanvasColorPicker />}
                {/* <div
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        marginBottom: '2rem',
                    }}
                >
                    <button onClick={exportPDF}>전자칠판 저장 테스트</button>{' '}
                    <button onClick={downloadPDF}>PDF 다운로드 테스트</button>
                </div> */}
            </div>
        </div>
    );
};
