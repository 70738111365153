import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Notification {
    newNotificationVisible: boolean;
}

const initialState: Notification = {
    newNotificationVisible: false,
};

export const notificationReducer = createSlice({
    name: 'login',
    initialState,
    reducers: {
        updateNewNotificationVisible: (state, action: PayloadAction<boolean>) => {
            state.newNotificationVisible = action.payload;
        },
    },
});

export const { updateNewNotificationVisible } = notificationReducer.actions;
export default notificationReducer.reducer;
