import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from '../../../css/FirstSession.module.css';
import '../../../../../cbt/css/PublicSessionStyle.css';

import Img1 from '../../../../../../../assets/face/session1/session1_1.png';
import Img2 from '../../../../../../../assets/face/session1/session1_2.png';
import Img3 from '../../../../../../../assets/face/session1/session1_3.png';
import Img_brain from '../../../../../../../assets/face/session1/session1_brain.png';
import Img4 from '../../../../../../../assets/face/session1/session1_4.png';

import {
    AdvantagesAndGoals,
    DesiredSymptomsAndReasons,
    ThoughtFeelInput,
    VideoPopup,
} from '../../../component/FirstSessionComponent';
import { SubmitFeedback, SubmitWorkbook } from '../../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { ModalContext } from '../../../../../../../context/ModalContext';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getAssignData, getSessionData } from '../../../ts/SessionApi';
import { resetData, updateSessionPage } from '../../../../../../../features/session/sessionReducer';
import { getMemberInfo } from '../../../../../../../utils/GetMemberInfo';
import { decryptData } from '../../../../../../../utils/croptojs';
import axiosInstance from '../../../../../../../utils/AxiosInstanceJava';

export const TherapistAssignedFirstSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const encryptedData = queryParams.get('session');
    const payment = queryParams.get('payment');
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const { registerSessionId, fields } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal, twoButtonOpenModal } = useContext(ModalContext);

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getAssignData', encryptedData],
        queryFn: () => getAssignData(encryptedData!),
        enabled: encryptedData !== null,
        placeholderData: keepPreviousData,
    });

    const sessionRole = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                saveSessionData();
            },
            '피드백 작성 완료 확인',
            '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
            '취소',
            '확인'
        );
    };

    // 워크북 제출
    const saveSessionData = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/feedback/assignment/${decryptData(encryptedData!)}/v1`)
            .then((response) => {
                const res = response.data;
                if (res.result) {
                    switch (res.message) {
                        case 'SUBMIT_FEEDBACK_SUCCESS':
                            refetch();
                            break;
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);
    return (
        <div className={'therapist_main_container'}>
            <div style={{}}>
                <img alt="1" src={Img1} className={styles.img1} />
                <img alt="2" src={Img2} className={styles.img} />
                <img alt="3" src={Img3} className={styles.img} />
                <div className={styles.thought_feel}>
                    <div className={styles.thought_feel_container}>
                        <img alt="brain" src={Img_brain} className={styles.img} />
                        <ThoughtFeelInput />
                    </div>
                </div>
                <DesiredSymptomsAndReasons currentSession={currentSession} />
                <AdvantagesAndGoals />
                <img alt="4" src={Img4} className={styles.img} />
            </div>
            <SubmitFeedback onClick={sessionRole} />
        </div>
    );
};
