import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalContext } from '../../../../../context/ModalContext';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import { ReactComponent as Icon_schedule_time } from '../../../../../assets/public/icon_schedule_time.svg';
import { ReactComponent as Icon_dash } from '../../../../../assets/public/icon_dash.svg';
import { ReactComponent as Icon_arrowbottom } from '../../../../../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_reserve_cancel } from '../../../../../assets/public/icon_reserve_cancel.svg';
import styles from './css/ScheduleModal.module.css';
import { EssentialMark } from '../../../../member/component/EssentialMark';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import { ScheduleDatePicker } from './ScheduleDatePicker';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { TimePicker } from './TimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    resetModal,
    resetScheduleData,
    updateMemo,
    updatePatientPickerVisible,
    updateProgramPickerVisible,
    updateReservationDate,
    updateReservationStatus,
    updateReserveTime,
    updateSelectPatient,
    updateSelectProgarm,
    updateTimePickerVisible,
} from '../../../../../features/scheduleModalReducer';
import { ProgramPicker } from './ProgramPicker';
import { PatientPicker } from './PatientPicker';
import moment from 'moment';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

export const ScheduleModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 200,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '97%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const { scheduleVisible, scheduleOpenModal, oneButtonOpenModal, twoButtonOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const dispatch = useDispatch();
    const {
        timePickerVisible,
        programPickerVisible,
        patientPickerVisible,
        originStartTime,
        originEndTime,
        patientMemberInfo,
        workBookName,
        therapistNames,
        registerSessionId,
        sessionProgress,
        reservationStatus,
        memo,
        reservationDate,
        calendarId,
        startTime,
        endTime,
    } = useSelector((state: RootState) => state.scheduleModal);

    useEffect(() => {
        if (calendarId && calendarId !== 0) {
            getReverseInfo();
        }
    }, []);

    const getReverseInfo = async () => {
        await axiosInstance.post(`/api/${memberInfo?.memberRole}/reserve/${calendarId}/v1`).then((response) => {
            if (response.data.result) {
                const res = response.data.reserveInfo;
                dispatch(updateMemo(res.memo ? res.memo : ''));
                dispatch(
                    updateSelectProgarm({
                        registerSessionId: res.registerSessionId,
                        workBookName: res.workBookName,
                        therapistNames: res.therapistNames,
                        sessionProgress: res.sessionProgress,
                    })
                );
                // 내담자 정보
                dispatch(
                    updateSelectPatient({
                        memberId: res.memberInfo.memberId,
                        memberName: res.memberInfo.memberName,
                    })
                );
                dispatch(updateReservationStatus(res.reservationStatus));
                dispatch(
                    updateReserveTime({
                        startTime: res.startTime,
                        originStartTime: convertTo12HourFormat(res.startTime),
                        endTime: res.endTime,
                        originEndTime: convertTo12HourFormat(res.endTime),
                    })
                );
            }
        });
    };

    // 시간 표기 변환
    const convertTo12HourFormat = (time24: string) => {
        // 입력된 시간 문자열을 ":"로 분리

        const [hour24, minutes] = time24.split(':');

        // 24시간 형식을 정수로 변환
        let hour = parseInt(hour24, 10);
        let period = '오전'; // 기본적으로 오전으로 설정

        // 12시간 형식으로 변환
        if (hour >= 12) {
            period = '오후';
            if (hour > 12) {
                hour -= 12; // 12보다 큰 경우 12를 빼줌
            }
        } else if (hour === 0) {
            hour = 12; // 0시를 12시로 변환
        }

        // HH:mm 형식으로 변환
        const formattedHour = hour.toString(); // 시간은 2자리로 표현할 필요 없음
        const formattedMinutes = minutes.padStart(2, '0'); // 분은 항상 2자리로 표현

        // 변환된 시간 반환
        return `${period} ${formattedHour}:${formattedMinutes}`;
    };

    const patientName = () => {
        if (patientMemberInfo.memberName) {
            return patientMemberInfo.memberName.replace(/\s*\([^)]*\)/, '').trim();
        } else {
            return '';
        }
    };

    const cancelButton = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                dispatch(resetScheduleData());
                scheduleOpenModal(!scheduleVisible);
            },
            '',
            '저장하지 않고 페이지를 벗어날 경우, 지금까지 작성한 내용이 사라집니다.',
            '취소',
            '확인'
        );
    };

    const headerTitle = () => {
        if (calendarId) {
            return '예약 상세정보';
        } else {
            return '일정 추가';
        }
    };

    // 예약 일정 추가 정규식
    const addReserveRole = () => {
        if (patientMemberInfo.memberName === '') {
            oneButtonOpenModal(() => {}, '', '내담자를 먼저 선택해 주세요.', '확인');
        } else if (originStartTime === '' || originEndTime === '') {
            oneButtonOpenModal(() => {}, '', '예약시간을 선택해 주세요.', '확인');
        } else if (workBookName.length === 0) {
            oneButtonOpenModal(() => {}, '', '프로그램을 선택해 주세요.', '확인');
        } else {
            addReserve();
        }
    };

    // 예약 일정 추가
    const addReserve = async () => {
        const requestData = {
            calendarId: calendarId,
            memberId: patientMemberInfo.memberId,
            therapistNames: therapistNames,
            registerSessionId: registerSessionId,
            workBookName: workBookName,
            sessionProgress: sessionProgress,
            memo: memo,
            reservationDate: reservationDate,
            startTime: startTime,
            endTime: endTime,
        };

        await axiosInstance.post(`/api/${memberInfo?.memberRole}/reserve/v1`, requestData).then((response) => {
            if (response.data.result) {
                oneButtonOpenModal(
                    () => {
                        window.location.reload();
                    },
                    '',
                    '예약 내역이 저장되었습니다.',
                    '확인'
                );
            }
        });
    };

    const openTimePicker = (type: string) => {
        dispatch(updateTimePickerVisible({ visible: !timePickerVisible, type: type }));
    };

    // 예약 일정 취소
    const cancelReverseRole = async (reservationStatus: number) => {
        twoButtonOpenModal(
            () => {
                editReserve();
            },
            () => {},
            '',
            '예약을 취소하시겠습니까?',
            '확인',
            '취소'
        );
    };

    // 예약 일정 수정
    const editReserve = async () => {
        const requestData = {
            calendarId: calendarId,
            memberId: patientMemberInfo.memberId,
            therapistNames: therapistNames,
            registerSessionId: registerSessionId,
            workBookName: workBookName,
            sessionProgress: sessionProgress,
            memo: memo,
            reservationDate: reservationDate,
            startTime: startTime,
            endTime: endTime,
        };

        await axiosInstance
            .put(`/api/${memberInfo?.memberRole}/reserve/${reservationStatus}/v1`, requestData)
            .then((response) => {
                if (response.data.result) {
                    oneButtonOpenModal(
                        () => {
                            window.location.reload();
                        },
                        '',
                        '변경된 사항이 저장되었습니다.',
                        '확인'
                    );
                }
            });
    };

    const ReserveCancel = () => {
        if (reservationStatus === 1) {
            return (
                <div className={styles.reserve_cancel}>
                    <Icon_reserve_cancel />
                    <span
                        className="text_16_NotoSansKR_Medium"
                        style={{
                            color: '#fff',
                        }}
                    >
                        취소된 예약
                    </span>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={scheduleVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div>
                    <div className={styles.title}>
                        <div className={`${styles.reserve_cancel_container} text_24_NotoSansKR_Bold`}>
                            {headerTitle()}
                            <ReserveCancel />
                        </div>
                        <button onClick={cancelButton}>
                            <Icon_x className={styles.icon} />
                        </button>
                    </div>

                    <div className={styles.content_container}>
                        <div className={styles.content}>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    예약일
                                    <EssentialMark />
                                </div>
                                <ScheduleDatePicker />
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    내담자 이름
                                    <EssentialMark />
                                </div>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            dispatch(resetModal());
                                            dispatch(updatePatientPickerVisible(!patientPickerVisible));
                                        }}
                                        className={styles.drop_down_button}
                                    >
                                        <span
                                            className="text_16_NotoSansKR_Regular"
                                            style={{
                                                color: patientMemberInfo.memberName ? '#020202' : '#9D9FA2',
                                            }}
                                        >
                                            {patientMemberInfo.memberName ? patientName() : '내담자 선택'}
                                        </span>
                                        <Icon_arrowbottom />
                                    </button>
                                    {patientPickerVisible && <PatientPicker />}
                                </div>
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    프로그램
                                    <span
                                        className="text_16_NotoSansKR_Medium"
                                        style={{
                                            color: '#FF5833',
                                        }}
                                    >
                                        {/* (복수선택 가능) */}
                                        <EssentialMark />
                                    </span>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <button
                                        onClick={() => {
                                            if (patientMemberInfo.memberName === '') {
                                                oneButtonOpenModal(
                                                    () => {},
                                                    '',
                                                    '내담자를 먼저 선택해 주세요.',
                                                    '확인'
                                                );
                                            } else {
                                                dispatch(resetModal());
                                                dispatch(updateProgramPickerVisible(!programPickerVisible));
                                            }
                                        }}
                                        className={styles.drop_down_button}
                                    >
                                        <span
                                            className="text_16_NotoSansKR_Regular"
                                            style={{
                                                color: workBookName ? '#020202' : '#9D9FA2',
                                            }}
                                        >
                                            {workBookName ? workBookName : '프로그램 선택'}
                                        </span>
                                        <Icon_arrowbottom />
                                    </button>
                                    {programPickerVisible && <ProgramPicker />}
                                </div>
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    담당 상담사
                                    <EssentialMark />
                                </div>
                                <input
                                    className={`${styles.input_readonly} text_16_NotoSansKR_Regular`}
                                    readOnly
                                    defaultValue={therapistNames.join(',')}
                                />
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    예약 시간
                                    <EssentialMark />
                                </div>
                                <div className={styles.schedule_time_container}>
                                    <Icon_schedule_time className={styles.icon} />
                                    <div className={styles.button_container}>
                                        <button
                                            onClick={() => {
                                                dispatch(resetModal());
                                                openTimePicker('left');
                                            }}
                                            className={`${styles.time_button} text_16_NotoSansKR_Regular`}
                                            style={{
                                                color: originStartTime === '' ? '#9D9FA2' : '#020202',
                                            }}
                                        >
                                            {originStartTime === '' ? '시작 시간 선택' : originStartTime}
                                        </button>
                                        <Icon_dash />
                                        <button
                                            onClick={() => {
                                                dispatch(resetModal());
                                                openTimePicker('right');
                                            }}
                                            className={`${styles.time_button} text_16_NotoSansKR_Regular`}
                                            style={{
                                                color: originEndTime === '' ? '#9D9FA2' : '#020202',
                                            }}
                                        >
                                            {originEndTime === '' ? '종료 시간 선택' : originEndTime}
                                        </button>
                                    </div>
                                    {timePickerVisible && <TimePicker />}
                                </div>
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    차트번호
                                    <EssentialMark />
                                </div>
                                <input
                                    className={`${styles.input_readonly} text_16_NotoSansKR_Regular`}
                                    readOnly
                                    defaultValue={patientMemberInfo.memberId !== 0 ? patientMemberInfo.memberId : ''}
                                />
                            </div>
                            <div className={styles.input_container}>
                                <div
                                    className="text_16_NotoSansKR_Bold"
                                    style={{
                                        color: '#626466',
                                    }}
                                >
                                    진행 회기
                                    <EssentialMark />
                                </div>
                                <input
                                    className={`${styles.input_readonly} text_16_NotoSansKR_Regular`}
                                    readOnly
                                    defaultValue={`${workBookName}: ${sessionProgress}회`}
                                    value={`${workBookName}: ${sessionProgress}회`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.memo_container}>
                        <div
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            메모
                        </div>
                        <textarea
                            className={`${styles.memo} text_16_NotoSansKR_Regular`}
                            onChange={(e) => dispatch(updateMemo(e.target.value))}
                            value={memo}
                        />
                    </div>
                    <div className={styles.no_show}>
                        <span
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            참여 여부
                        </span>
                        <div className={styles.content}>
                            <button
                                onClick={() => {
                                    dispatch(updateReservationStatus(3));
                                }}
                            >
                                <SquareCheckBox bool={reservationStatus === 3 ? true : false} size="1.5rem" />
                            </button>
                            <span
                                className="text_16_NotoSansKR_Bold"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                참여완료
                            </span>
                        </div>
                        <div className={styles.content}>
                            <button
                                onClick={() => {
                                    dispatch(updateReservationStatus(2));
                                }}
                            >
                                <SquareCheckBox bool={reservationStatus === 2 ? true : false} size="1.5rem" />
                            </button>
                            <span
                                className="text_16_NotoSansKR_Bold"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                노쇼
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.button_container}>
                    {calendarId !== 0 ? (
                        <button
                            onClick={() => cancelReverseRole(1)}
                            className={`${styles.schedule_cancel_button} text_18_NotoSansKR_Bold`}
                        >
                            예약 취소
                        </button>
                    ) : (
                        <div></div>
                    )}
                    <div>
                        <button onClick={cancelButton} className={`${styles.cancel_button} text_18_NotoSansKR_Bold`}>
                            취소
                        </button>
                        <button
                            onClick={() => {
                                if (calendarId === 0) {
                                    addReserveRole();
                                } else {
                                    editReserve();
                                }
                            }}
                            className={`${styles.save_button} text_18_NotoSansKR_Bold`}
                        >
                            저장
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};
