import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from '../../css/FirstSession.module.css';
import '../../../css/PublicSessionStyle.css';

import Img_brain from '../../../../../../assets/face/session1/session1_brain.png';

import { DesiredSymptomsAdvantageGoal, ThoughtFeelInput } from '../../component/FirstSessionComponent';
import { useLocation } from 'react-router-dom';
import { updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { resetData } from '../../../../../../features/session/sessionReducer';
import { useDispatch } from 'react-redux';
import { keepPreviousData } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getSessionData } from '../../ts/SessionApi';
import { SubmitFeedback } from '../../component/PublicSessionComponent';
import { getMemberInfo } from '../../../../../../utils/GetMemberInfo';
import { decryptData } from '../../../../../../utils/croptojs';
import axiosInstance from '../../../../../../utils/AxiosInstanceJava';
import { ModalContext } from '../../../../../../context/ModalContext';

export const TherapistFirstSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');

    const { oneButtonOpenModal, twoButtonOpenModal } = useContext(ModalContext);

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        enabled: encryptedData !== null,
        placeholderData: keepPreviousData,
    });

    const sessionRole = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                saveSessionData();
            },
            '피드백 작성 완료 확인',
            '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
            '취소',
            '확인'
        );
    };

    // 피드백 제출
    const saveSessionData = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/feedback/${decryptData(encryptedData!)}/v1`)
            .then((response) => {
                const res = response.data;
                if (res.result) {
                    switch (res.message) {
                        case 'SUBMIT_FEEDBACK_SUCCESS':
                            oneButtonOpenModal(
                                () => {
                                    refetch();
                                },
                                '',
                                '워크북 수정이 완료되었습니다.',
                                '확인'
                            );

                            break;
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);
    return (
        <div className={'therapist_main_container'}>
            <div className={styles.thought_feel}>
                <div className={styles.thought_feel_container}>
                    <div>
                        <img alt="brain" src={Img_brain} className={styles.img} />
                    </div>
                    <ThoughtFeelInput />
                </div>
            </div>
            <DesiredSymptomsAdvantageGoal />
            <SubmitFeedback onClick={sessionRole} />
        </div>
    );
};
