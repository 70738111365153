import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

interface Pageable {
    paged: boolean;
    pageNumber: number;
    pageSize: number;
    offset: number;
    sort: Sort;
    unpaged: boolean;
}

interface Sort {
    sorted: boolean;
    empty: boolean;
    unsorted: boolean;
}

interface WorkBookInfo {
    workBookType: string;
    workBookName: string;
    finishedCnt: number;
    totalCnt: number;
    finishedDate: string;
    recentFeed: number;
    therapistList: {
        therapistId: number;
        therapistName: string;
    }[];
    registerSessionId: number;
    registerId: number;
}

interface Member {
    memberId: number;
    memberName: string;
    workBookInfos: WorkBookInfo[];
}

// RepresentativeMemberList에서 사용되는 타입
export interface RepresentativeMemberList {
    patientList: {
        totalElements: number;
        totalPages: number;
        pageable: Pageable;
        size: number;
        content: Member[];
        number: number;
        sort: Sort;
        numberOfElements: number;
        first: boolean;
        last: boolean;
        empty: boolean;
    };
    message: string;
    branchCategories: {
        companyId: number;
        branchName: string;
        count: number;
    }[];
    result: boolean;
}

// TherapistMemberList에서 사용되는 타입
export interface TherapistMemberList {
    members: {
        totalElements: number;
        totalPages: number;
        pageable: Pageable;
        size: number;
        content: {
            memberName: string;
            memberId: number;
            sessionInfos: WorkBookInfo[]; // 여기에 WorkBookInfo를 재사용
        }[];
        number: number;
        sort: Sort;
        numberOfElements: number;
        first: boolean;
        last: boolean;
        empty: boolean;
    };
    message: string;
    result: boolean;
}

export interface TherapistList {
    picGroups: {
        memberRole: string;
        picInfos: {
            memberName: string;
            memberId: number;
            memberRole: string;
        }[];
    }[];
}

// 시스템 관리자 회원 리스트
export const getRepresentativeMemberList = async (
    companyId: number,
    pageNum: number
): Promise<RepresentativeMemberList> => {
    const memberInfo = getMemberInfo();

    return await axiosInstance
        .post(`/api/${memberInfo?.memberRole}/member/list/v1`, {
            companyId: companyId,
            pageNum: pageNum,
            feedback: false,
            pageable: {
                page: 0,
                size: 1,
                sort: ['string'],
            },
        })
        .then((response) => response.data);
};

// 시스템 관리자 회원 리스트
export const getAdministratorMemberList = async (
    companyId: number,
    pageNum: number
): Promise<RepresentativeMemberList> => {
    const memberInfo = getMemberInfo();

    return await axiosInstance
        .post(`/api/${memberInfo?.memberRole}/member/list/v1`, {
            companyId: companyId,
            pageNum: pageNum,
            feedback: false,
            pageable: {
                page: 0,
                size: 1,
                sort: ['string'],
            },
        })
        .then((response) => response.data);
};

// 매니저 회원 리스트
export const getManagerMemberList = async (type: string, pageNum: number): Promise<RepresentativeMemberList> => {
    const memberInfo = getMemberInfo();

    return axiosInstance
        .post(`/api/${memberInfo?.memberRole}/member/list/v1`, {
            pageNum: pageNum,
            type: type,
            feedback: false,
            pageable: {
                page: 0,
                size: 1,
                sort: ['string'],
            },
        })
        .then((response) => response.data);
};

// 치료자 회원 리스트
export const getTherapistMemberList = async (companyId: number, pageNum: number): Promise<TherapistMemberList> => {
    const memberInfo = getMemberInfo();

    return await axiosInstance
        .post(`/api/${memberInfo?.memberRole}/member/list/v1`, {
            companyId: companyId,
            pageNum: pageNum,
            feedback: false,
            pageable: {
                page: 0,
                size: 1,
                sort: ['string'],
            },
        })
        .then((response) => response.data);
};

// 담당 상담사 리스트
export const getTherapistList = async (): Promise<TherapistList> => {
    const memberInfo = getMemberInfo();
    return await axiosInstance
        .get(`/api/${memberInfo?.memberRole}/PIC/list/${memberInfo?.loginBranch.companyId}/v1`)
        .then((response) => response.data);
};
