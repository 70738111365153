import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PaymentDetail {
    paymentId: number;
}

const initialState: PaymentDetail = {
    paymentId: 0,
};

export const paymentDetailModalSlice = createSlice({
    name: 'paymentDetailModal',
    initialState,
    reducers: {
        updatePaymentId: (state, action: PayloadAction<number>) => {
            state.paymentId = action.payload;
        },
    },
});

export const { updatePaymentId } = paymentDetailModalSlice.actions;
export default paymentDetailModalSlice.reducer;
