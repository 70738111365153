import styles from './css/PaymentDetails.module.css';
import { ReactComponent as Icon_arrowleft } from '../../../../assets/public/icon_arrowleft.svg';
import { ReactComponent as Icon_arrowright } from '../../../../assets/member/signup/icon_arrowright.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { updatePageNum } from '../../../../features/paymentDetailsReducer';
import { PaymentRender, SearchContainer, TitleRender } from './component/PaymentDetailsComponent';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getPaymentList } from './ts/PaymentDetails';

export const PaymentDetails = () => {
    const dispatch = useDispatch();
    const { companyId, pageNum, organVisible, roleVisible, employeeSettingVisible } = useSelector(
        (state: RootState) => state.authority
    );

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getPaymentList'],
        queryFn: () => getPaymentList(),
        placeholderData: keepPreviousData,
    });

    if (isLoading) {
        return <div>loading</div>;
    }

    const nextPage = () => {
        // if (data?.members.totalPages! - 1 < pageNum) {
        //     dispatch(updatePageNum(pageNum + 1));
        // }
    };

    const prevPage = () => {
        // if (pageNum > 0) {
        //     dispatch(updatePageNum(pageNum - 1));
        // }
    };

    const selectOrgan = async () => {
        // await axiosInstance.
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">결제 내역</div>
            <TitleRender list={data} />
            <div className={styles.content_container}>
                <SearchContainer />
                <PaymentRender list={data} refetch={refetch} />
            </div>
            <div className={styles.page_container}>
                <button onClick={prevPage} className={styles.button}>
                    <Icon_arrowleft
                        className={`${styles.icon_arrow} ${pageNum > 0 ? styles.activate : styles.deactivate}`}
                    />
                </button>
                <span
                    className="text_18_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    Page{pageNum + 1}
                </span>
                <button onClick={nextPage} className={styles.button}>
                    <Icon_arrowright
                        className={`${styles.icon_arrow} ${
                            '' // data?.members.totalPages! - 1 === pageNum ? styles.deactivate : styles.activate
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};
