import styles from './css/ReservationStatus.module.css';

import { Calendar } from './component/Calendar';
import { ReactComponent as Icon_search } from '../../../../assets/therapist/icon_search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchVal } from '../../../../features/reservationReducer';
import { RootState } from '../../../../store';
import { ReservationSearch } from './component/ReservationSearch';

export const ReservationStatus = () => {
    const dispatch = useDispatch();
    const { searchVal } = useSelector((state: RootState) => state.events);

    return (
        <div className={styles.main_container}>
            <div className={styles.header}>
                <div className="text_32_NotoSansKR_Bold">{searchVal.length > 1 ? '일정 검색' : '예약 현황'}</div>
                <div className={styles.input_container}>
                    <input
                        className={`${styles.input} ${
                            searchVal.length > 0 ? styles.activate : ''
                        } text_16_NotoSansKR_Regular`}
                        placeholder="검색어 입력"
                        value={searchVal}
                        onChange={(e) => dispatch(updateSearchVal(e.target.value))}
                    />
                    <div className={styles.icon_search_container}>
                        <Icon_search className={styles.icon_search} />
                    </div>
                </div>
            </div>
            {searchVal.length > 1 ? <ReservationSearch /> : <Calendar />}
        </div>
    );
};
