import styles from '../../css/SecondSession.module.css';
import '../../../css/PublicSessionStyle.css';

import Img1 from '../../../../../../assets/face/session2/session2_1.png';
import Img2 from '../../../../../../assets/face/session2/session2_2.png';

import { useContext, useEffect, useRef, useState } from 'react';

import { ExperiencesEmotion } from '../../component/SecondSessionComponent';
import { SituationEmotion, SubmitFeedback, SubmitWorkbook } from '../../component/PublicSessionComponent';
import { getMemberInfo } from '../../../../../../utils/GetMemberInfo';
import { useLocation } from 'react-router-dom';
import { decryptData } from '../../../../../../utils/croptojs';
import axiosInstance from '../../../../../../utils/AxiosInstanceJava';
import { useQuery } from '@tanstack/react-query';
import { ModalContext } from '../../../../../../context/ModalContext';
import { keepPreviousData } from '@tanstack/react-query';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { resetData, updateField, updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { getSessionData } from '../../ts/SessionApi';

export const TherapistSecondSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const { twoButtonOpenModal, oneButtonOpenModal } = useContext(ModalContext);
    const { registerSessionId, fields, workBookSubmitted } = useSelector((state: RootState) => state.session);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' && true;
    const [physicalSymptomsList, setPhysicalSymptomsList] = useState([
        {
            style: styles.brain,
        },
        {
            style: styles.breath,
        },
        {
            style: styles.muscle_tension,
        },
        {
            style: styles.intestine,
        },
        {
            style: styles.peripheral_blood_vessels,
        },
        {
            style: styles.salivary_glands,
        },
        {
            style: styles.heart,
        },
        {
            style: styles.camouflage,
        },
        {
            style: styles.sweat_glands,
        },
    ]);

    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 현재 루트 폰트 사이즈

    const [popupStyle, setPopupStyle] = useState<{ top: number; right: number; width: number; height: number }>({
        top: 120 / rootFontSize, // px를 rem으로 변환
        right: 32 / rootFontSize, // px를 rem으로 변환
        width: 460 / rootFontSize, // px를 rem으로 변환
        height: 258 / rootFontSize, // px를 rem으로 변환
    });

    const sessionRole = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                saveSessionData();
            },
            '피드백 작성 완료 확인',
            '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
            '취소',
            '확인'
        );
    };

    // 피드백 제출
    const saveSessionData = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/feedback/${decryptData(encryptedData!)}/v1`)
            .then((response) => {
                const res = response.data;
                if (res.result) {
                    switch (res.message) {
                        case 'SUBMIT_FEEDBACK_SUCCESS':
                            oneButtonOpenModal(
                                () => {
                                    refetch();
                                },
                                '',
                                '워크북 수정이 완료되었습니다.',
                                '확인'
                            );

                            break;
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);

    const onChangePhysicalSymptoms = (index: number, text: string) => {
        const result = fields.physicalSymptoms[index];
        const regex = /^(10|[1-9])$/;

        if (regex.test(text) || text === '')
            dispatch(
                updateField({
                    index: index,
                    key: 'physicalSymptoms',
                    value: {
                        ...result,
                        patient: text,
                    },
                })
            );
    };

    return (
        <div className={'therapist_main_container'}>
            <div className={styles.image_input_container}>
                <img alt="1" src={Img1} className={styles.img1} />
                {physicalSymptomsList.map((item, index) => (
                    <div
                        className={`${styles.physical_symptoms_input_container} ${item.style} text_18_NotoSansKR_Regular`}
                    >
                        <input
                            type="number"
                            maxLength={2}
                            className={styles.physical_symptoms_input}
                            onChange={(e) => onChangePhysicalSymptoms(index, e.target.value)}
                            value={fields.physicalSymptoms[index].patient}
                        />
                        <span className="text_18_NotoSansKR_Regular">점</span>
                    </div>
                ))}
            </div>
            <img alt="2" src={Img2} className={styles.img} />
            <SituationEmotion />

            <ExperiencesEmotion />
            <SubmitFeedback onClick={sessionRole} />
        </div>
    );
};
