import { useDispatch, useSelector } from 'react-redux';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import { CategoryList } from '../ts/ProgramManagementApi';

import styles from './css/ProgramCategoryDropDown.module.css';
import { RootState } from '../../../../../store';
import {
    updateOriginPrice,
    updateProgramCategoryVisible,
    updateWorkBooks,
} from '../../../../../features/programReducer';
import { useState } from 'react';

export const ProgramCategoryDropDown = ({ list }: { list: CategoryList }) => {
    const dispatch = useDispatch();
    const { programCategoryVisible, workBooks } = useSelector((state: RootState) => state.programModal);
    const [selectWorkBookList, setSelectWorkBookList] =
        useState<{ workBookId: number; price: number; workBookName: string }[]>(workBooks);

    const oneTimeProgramList = list.categories
        .map((item) => {
            if (item.workBookName.includes('단회')) {
                return item;
            } else {
                return { workBookId: 0, price: 0, workBookName: '' };
            }
        })
        .filter((item) => item.workBookId !== 0);

    const multiTimeProgramList = list.categories
        .map((item) => {
            if (!item.workBookName.includes('단회')) {
                return item;
            } else {
                return { workBookId: 0, price: 0, workBookName: '' };
            }
        })
        .filter((item) => item.workBookId !== 0);

    const hasCommonId = (workBookName: string) =>
        selectWorkBookList.some((comparisonItem) => comparisonItem.workBookName === workBookName);

    const addWorkbook = (newWorkbook: { workBookId: number; price: number; workBookName: string }) => {
        const isDuplicate = selectWorkBookList.some((workBook) => workBook.workBookId === newWorkbook.workBookId);

        if (!isDuplicate) {
            setSelectWorkBookList([...selectWorkBookList, newWorkbook]);
        } else {
            const workBooksFilter = selectWorkBookList.filter(
                (workBook) => workBook.workBookId !== newWorkbook.workBookId
            );
            if (workBooksFilter.length === 0) {
                setSelectWorkBookList([{ workBookId: 0, price: 0, workBookName: '' }]);
            } else {
                setSelectWorkBookList(workBooksFilter);
            }
        }
    };

    const saveProgram = () => {
        const totalPriceArray = selectWorkBookList.map((item) => item.price);
        const totalOriginPrice = totalPriceArray.reduce((acc, cur) => acc + cur);
        dispatch(updateOriginPrice(totalOriginPrice.toString()));
        dispatch(updateWorkBooks(selectWorkBookList));
        dispatch(updateProgramCategoryVisible(!programCategoryVisible));
    };

    return (
        <div className={styles.program_picker}>
            <div className={styles.item_container}>
                {multiTimeProgramList.map((item, index) => (
                    <button
                        key={index.toString()}
                        className={styles.program_button}
                        style={{
                            borderTop: item.workBookName === '대면형 CBT (유형1)_단회' ? '0.0625rem solid #E3E5EA' : '',
                            paddingTop: index === 0 ? '1.25rem' : '1.75rem',
                        }}
                        onClick={() => addWorkbook(item)}
                    >
                        <SquareCheckBox bool={hasCommonId(item.workBookName)} size="1.5rem" />
                        <span className="text_16_NotoSansKR_Medium">{item.workBookName}</span>
                    </button>
                ))}
                <div
                    style={{
                        width: '100%',
                        margin: '1.25rem 0',
                        borderTop: '0.0625rem solid #E3E5EA',
                    }}
                ></div>
                {oneTimeProgramList.map((item, index) => (
                    <button
                        key={index.toString()}
                        className={styles.program_button}
                        style={{
                            marginTop: index === 0 ? 0 : '1.75rem',
                        }}
                        onClick={() => addWorkbook(item)}
                    >
                        <SquareCheckBox bool={hasCommonId(item.workBookName)} size="1.5rem" />
                        <span className="text_16_NotoSansKR_Medium">{item.workBookName}</span>
                    </button>
                ))}
            </div>
            <div className={styles.button_container}>
                <button
                    onClick={() => dispatch(updateProgramCategoryVisible(!programCategoryVisible))}
                    className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                >
                    취소
                </button>
                <button onClick={saveProgram} className={`${styles.save_button} text_16_NotoSansKR_Bold`}>
                    선택완료
                </button>
            </div>
        </div>
    );
};
