import styles from './css/PaymentDetailModal.module.css';
import { ModalContext } from '../../../../../context/ModalContext';
import { useContext } from 'react';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import ReactModal from 'react-modal';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPaymentDetail } from '../ts/PaymentDetails';
import { RootState } from '../../../../../store';
import moment from 'moment';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';

export const PaymentDetailModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 200,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '97%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const { paymentId } = useSelector((state: RootState) => state.paymentDetailModal);
    const { paymentDetailVisible, paymentDetailOpenModal, oneButtonOpenModal, paymentPartCancelOpenModal } =
        useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const dispatch = useDispatch();

    const { data, isLoading, error } = useQuery({
        queryKey: ['getPaymentDetail', paymentId],
        queryFn: () => getPaymentDetail(paymentId),
        enabled: paymentId !== null,
    });

    const paymentCancel = async () => {
        await axiosInstance
            .put(`/api/${memberInfo?.memberRole}/payment/cancel/v1`, {
                paymentDataId: data.paymentData.paymentDataId,
                cancelAmt: data.paymentData.paymentInfo.amt,
                cancelMsg: '고객요청',
            })
            .then((response) => {
                if (response.data.result) {
                    oneButtonOpenModal(
                        () => {
                            cancelButton();
                        },
                        '',
                        '결제 취소가 완료되었습니다. 취소 접수 시, 은행, 카드사 사정에 따라 최종 환불 처리는 취소 완료 이후 2~3일 정도 소요될 수 있습니다.',
                        '확인'
                    );
                }
            });
    };

    const paymentPartCancel = () => {
        paymentPartCancelOpenModal(true);
    };

    const cancelButton = () => {
        paymentDetailOpenModal(false);
    };

    if (isLoading) {
        return <div>loading</div>;
    }

    const PayStatus = ({ status }: { status: number }) => {
        switch (status) {
            case 1:
                return <div className={`${styles.item_pay_status_item} ${styles.activate}`}>결제완료</div>;
            case 2:
                return <div className={`${styles.item_pay_status_item} ${styles.deactivate}`}>전체취소</div>;
            case 3:
                return <div className={`${styles.item_pay_status_item} ${styles.deactivate}`}>부분취소</div>;
            default:
                return null;
        }
    };

    const PaymentCancelInfo = () => {
        if (data.paymentData.payStatus === 2 || data.paymentData.payStatus === 3) {
            return (
                <div className={styles.payment_info}>
                    <div className={`${styles.middle_title} text_18_NotoSansKR_Bold`}>취소/환불 정보</div>
                    <div className={styles.item_container}>
                        <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>취소 프로그램</div>
                        <div className={`text_16_NotoSansKR_Medium`}>프로그램 명</div>
                    </div>
                    <div className={styles.item_container}>
                        <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>환불 금액</div>
                        <div className={`text_16_NotoSansKR_Medium`}>금액</div>
                    </div>
                    <div className={styles.item_container}>
                        <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>환불 방법</div>
                        <div className={`text_16_NotoSansKR_Medium`}>
                            {data.paymentData.paymentInfo.cardInfo.cardName}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={paymentDetailVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div className={styles.title}>
                    <span className="text_24_NotoSansKR_Bold">최근 결제 내역</span>
                    <button onClick={cancelButton}>
                        <Icon_x className={styles.icon} />
                    </button>
                </div>
                <div className={styles.content_container}>
                    <div className={styles.payment_info_container}>
                        <div className={styles.payment_info}>
                            <div className={`${styles.middle_title} text_18_NotoSansKR_Bold`}>참여 프로그램 정보</div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>결제일자</div>
                                <div className={styles.payStatus_container}>
                                    <div className={`text_16_NotoSansKR_Medium`}>
                                        {moment(data.modDate).format('YYYY-MM-DD HH:mm:ss')}
                                    </div>
                                    <PayStatus status={data.payStatus} />
                                </div>
                            </div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>주문번호</div>
                                <div className={`text_16_NotoSansKR_Medium`}>{data.orderNumber}</div>
                            </div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>프로그램 유형</div>
                                <div>
                                    <div className={`text_16_NotoSansKR_Medium`}>
                                        {data.paymentData.paymentInfo.productInfo.productName}
                                    </div>
                                    <div
                                        className={`text_16_NotoSansKR_Medium`}
                                        style={{
                                            color: '#9D9FA2',
                                        }}
                                    >
                                        {data.paymentData.paymentInfo.productInfo.productDescription}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.payment_info}>
                            <div className={`${styles.middle_title} text_18_NotoSansKR_Bold`}>결제 정보</div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>결제자명</div>
                                <div className={`text_16_NotoSansKR_Medium`}>
                                    {data.paymentData.paymentInfo.buyerName}
                                </div>
                            </div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>총 결제금액</div>
                                <div className={`text_16_NotoSansKR_Medium`}>
                                    {data.paymentData.paymentInfo.amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                </div>
                            </div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>결제수단</div>
                                <div className={`text_16_NotoSansKR_Medium`}>
                                    {data.paymentData.paymentInfo.cardInfo.cardName}
                                </div>
                            </div>
                        </div>
                        <PaymentCancelInfo />
                        {/* <div className={styles.payment_info}>
                            <div className={`${styles.middle_title} text_18_NotoSansKR_Bold`}>선택 옵션</div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>결제유형</div>
                                <div className={`text_16_NotoSansKR_Medium`}>결제일자</div>
                            </div>
                            <div className={styles.item_container}>
                                <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>결제횟수</div>
                                <div className={`text_16_NotoSansKR_Medium`}>결제일자</div>
                            </div>
                        </div> */}
                        {data.cancelData.length !== 0 && (
                            <div className={styles.payment_info}>
                                <div className={`${styles.middle_title} text_18_NotoSansKR_Bold`}>취소/환불 정보</div>
                                <div className={styles.item_container}>
                                    <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>
                                        취소 프로그램
                                    </div>
                                    <div className={`text_16_NotoSansKR_Medium`}>
                                        {data.cancelData[0].cancelWorkBookNames}
                                    </div>
                                </div>
                                <div className={styles.item_container}>
                                    <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>환불 금액</div>
                                    <div className={`text_16_NotoSansKR_Medium`}>
                                        {data.cancelData[0].cancelAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        원
                                    </div>
                                </div>
                                <div className={styles.item_container}>
                                    <div className={`${styles.item_title} text_16_NotoSansKR_Medium`}>환불 방법</div>
                                    <div className={`text_16_NotoSansKR_Medium`}>
                                        {data.paymentData.paymentInfo.cardInfo.cardName}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.payment_cancel_container}>
                        {data.payStatus === 2 ? (
                            <button
                                onClick={cancelButton}
                                className={`${styles.payment_check_button} text_18_NotoSansKR_Bold`}
                            >
                                확인
                            </button>
                        ) : (
                            <button
                                onClick={paymentPartCancel}
                                className={`${styles.payment_cancel_button} text_18_NotoSansKR_Bold`}
                            >
                                결제취소
                            </button>
                        )}
                    </div>
                    <div className={styles.payment_history_container}>
                        <div className="text_24_NotoSansKR_Bold">
                            {data.paymentData.paymentInfo.buyerName}의 지난 결제내역
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};
