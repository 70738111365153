import React, { useContext, useEffect, useState } from 'react';

import styles from './css/AssignedMember.module.css';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import {
    AssignedTherapist,
    ChartTitle,
    MemberInfoButton,
    NameChartNumber,
    ProgramType,
    RecentCompleteFeedback,
    SearchContainer,
    SelectAssignedTherapist,
    SessionCompleteDate,
    SessionProgress,
} from './AssignedMemberComponent.tsx/AssignedMemberComponent';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getTherapistMemberList } from './ts/AssignedMemberApi';
import { decryptData, encryptData } from '../../../../utils/croptojs';
import { ModalContext } from '../../../../context/ModalContext';
import { ReactComponent as Icon_arrowleft } from '../../../../assets/public/icon_arrowleft.svg';
import { updateSelectTherapist } from '../../../../features/selectTherapistModalReducer';
import { ReactComponent as Icon_arrowright } from '../../../../assets/member/signup/icon_arrowright.svg';
import { updateSelectProgram } from '../../../../features/selectTherapistModalReducer';
import { useDispatch } from 'react-redux';

const ChartTitleItem = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    ${(props) => (props.$index === props.$lastIndex ? '' : 'border-top: 0.0625rem solid #e3e5ea;')}
`;

const ChartTitleItemList = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    flexdirection: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    // ${(props) => (props.$index === props.$lastIndex ? '' : 'border-bottom: 0.0625rem solid #e3e5ea;')}
`;

const chartTitleList = ['이름(차트번호)', '프로그램유형', '세션 진행도', '세션 완료일', '최근 완료된 피드백', ''];
const officialChartTitleList = [
    '이름(차트번호)',
    '프로그램유형',
    '세션 진행도',
    '세션 완료일',
    '최근 완료된 피드백',
    '담당 상담사',
    '',
];

export const TherapistAssignedMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [pageNum, setPageNum] = useState<number>(0);
    const memberInfo = getMemberInfo();
    const companyId = memberInfo?.loginBranch.companyId!;
    const [feedbackCheck, setFeedbackCheck] = useState<boolean>(false);

    const { selectTherapistVisible, selectTherapistOpenModal } = useContext(ModalContext);
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['therapistMemberList', { companyId, pageNum }],
        queryFn: () => getTherapistMemberList(companyId, pageNum),
        placeholderData: keepPreviousData,
    });

    const moveToPatientCBT = (programType: string, sessionPage: number, sessionId: number, registerId: number) => {
        const params = new URLSearchParams();
        params.append('page', sessionPage.toString());
        params.append('session', encryptData(sessionId));
        params.append('program', encryptData(registerId));

        if (programType === 'non-face') {
            navigate(`patientCBT/nonface/session?${params}`);
        } else if (programType === 'face') {
            navigate(`patientCBT/face/session?${params}`);
        }
    };

    const nextPage = () => {
        if (data?.members.totalPages! - 1 < pageNum) {
            setPageNum(pageNum + 1);
        }
    };

    const prevPage = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1);
        }
    };

    const memberDetail = (memberId: number) => {
        const queryString = `data=${encodeURIComponent(encryptData(memberId))}`;
        navigate(`detail?${queryString}`);
    };

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {chartTitleList.map((item, index) => (
                        <ChartTitle key={index.toString()} title={item} />
                    ))}
                </div>
                {data?.members.content ? (
                    <ul className={styles.chart_content_container}>
                        {data?.members.content.map((item, index) => (
                            <>
                                <ChartTitleItem
                                    key={index.toString()}
                                    $index={index}
                                    $lastIndex={data?.members.content.length - 1}
                                >
                                    <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                                    <ProgramType programType={item.sessionInfos[0].workBookName} />
                                    <SessionProgress
                                        sessionProgress={item.sessionInfos[0].finishedCnt}
                                        maxProgress={item.sessionInfos[0].totalCnt}
                                        moveToPatientCBT={() =>
                                            moveToPatientCBT(
                                                item.sessionInfos[0].workBookType,
                                                item.sessionInfos[0].finishedCnt,
                                                item.sessionInfos[0].registerSessionId,
                                                item.sessionInfos[0].registerId
                                            )
                                        }
                                        workBookName={item.sessionInfos[0].workBookName}
                                    />
                                    <SessionCompleteDate completeDate={item.sessionInfos[0].finishedDate} />
                                    <RecentCompleteFeedback recentCompleteSession={item.sessionInfos[0].recentFeed} />
                                    <MemberInfoButton memberId={item.memberId} />
                                </ChartTitleItem>
                                {item.sessionInfos.map((value, idx) => {
                                    return (
                                        idx !== 0 && (
                                            <ChartTitleItemList
                                                key={idx.toString() + 'child'}
                                                $index={idx}
                                                $lastIndex={data?.members.content.length - 1}
                                            >
                                                <NameChartNumber name={''} chartNumber={null} />
                                                <ProgramType programType={value.workBookName} />
                                                <SessionProgress
                                                    sessionProgress={value.finishedCnt}
                                                    maxProgress={value.totalCnt}
                                                    moveToPatientCBT={moveToPatientCBT}
                                                    workBookName={value.workBookName}
                                                />
                                                <SessionCompleteDate completeDate={value.finishedDate} />
                                                <RecentCompleteFeedback recentCompleteSession={value.recentFeed} />
                                                <MemberInfoButton memberId={null} />
                                            </ChartTitleItemList>
                                        )
                                    );
                                })}
                            </>
                        ))}
                    </ul>
                ) : (
                    <div className={styles.chart_nondata_container}>
                        <span
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            아직 담당 중인 회원이 없습니다.
                        </span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <div className={styles.middle_title_container}>
                <div
                    className={`${styles.middle_title} ${styles.activate} text_18_NotoSansKR_Bold`}
                    style={{
                        color: '#3A5074',
                    }}
                >
                    담당 중인 회원
                    <span>({data?.members.content.length})</span>
                </div>
            </div>
            <div className={styles.content_container}>
                <SearchContainer
                    feedbackCheck={feedbackCheck}
                    changeFeedbackCheck={() => setFeedbackCheck(!feedbackCheck)}
                />
                <AssignedRender />
            </div>
            <div className={styles.page_container}>
                <button onClick={prevPage} className={styles.button}>
                    <Icon_arrowleft
                        className={`${styles.icon_arrow} ${pageNum > 0 ? styles.activate : styles.deactivate}`}
                    />
                </button>
                <span
                    className="text_18_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    Page{pageNum + 1}
                </span>
                <button onClick={nextPage} className={styles.button}>
                    <Icon_arrowright
                        className={`${styles.icon_arrow} ${
                            data?.members.totalPages! - 1 === pageNum ? styles.deactivate : styles.activate
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};

export const OfficialAssignedMember = () => {
    const navigate = useNavigate();
    // const role = memberInfo.memberRole !== 'user' && memberInfo.memberRole !== 'employee';
    const [feedbackCheck, setFeedbackCheck] = useState<boolean>(false);

    const [pageNum, setPageNum] = useState<number>(0);
    const memberInfo = getMemberInfo();
    const companyId = memberInfo?.loginBranch.companyId!;

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['therapistMemberList', { companyId, pageNum }],
        queryFn: () => getTherapistMemberList(companyId, pageNum),
    });
    const [assignedMemberOfficialList, setAssignedMemberOfficialList] = useState([
        {
            memberId: 1,
            name: '김회원',
            chartNumber: 1145,
            programType: '비대면 CBT',
            background: '#EDF2FE',
            sessionProgress: 2,
            maxProgress: 8,
            completeDate: '2024-11-11',
            recentCompleteSession: '세션3',
            assignedTherapist: '치료자명',
        },
    ]);

    const nextPage = () => {
        if (data?.members.totalPages! - 1 < pageNum) {
            setPageNum(pageNum + 1);
        }
    };

    const prevPage = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1);
        }
    };

    const moveToPatientCBT = (programType: string) => {
        if (programType === 'non-face') {
            navigate('patientCBT/nonface/firstSession');
        } else if (programType === 'face') {
            navigate('patientCBT/face/firstSession');
        }
    };

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {officialChartTitleList.map((item, index) => (
                        <ChartTitle title={item} />
                    ))}
                </div>
                <ul className={styles.chart_content_container}>
                    {data?.members.content.map((item, index) => (
                        <ChartTitleItem
                            key={index.toString()}
                            $index={index}
                            $lastIndex={data.members.content.length - 1}
                        >
                            <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                            <ProgramType programType={item.sessionInfos[0].workBookName} />
                            <SessionProgress
                                sessionProgress={item.sessionInfos[0].finishedCnt}
                                maxProgress={item.sessionInfos[0].totalCnt}
                                moveToPatientCBT={() => moveToPatientCBT(item.sessionInfos[0].workBookName)}
                                workBookName={item.sessionInfos[0].workBookName}
                            />
                            <SessionCompleteDate completeDate={item.sessionInfos[0].finishedDate} />
                            <MemberInfoButton memberId={item.memberId} />
                        </ChartTitleItem>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <div className={styles.middle_title_container}>
                <div
                    className={`${styles.middle_title} text_18_NotoSansKR_Bold`}
                    style={{
                        color: '#3A5074',
                    }}
                >
                    담당 중인 회원
                    <span>({data?.members.content.length})</span>
                </div>
            </div>
            <div className={styles.content_container}>
                <SearchContainer
                    feedbackCheck={feedbackCheck}
                    changeFeedbackCheck={() => setFeedbackCheck(!feedbackCheck)}
                />
                <AssignedRender />
            </div>
            <div
                className={styles.page_container}
                style={{
                    marginTop: data?.members.content.length === 0 ? '23.69rem' : '2.5rem',
                }}
            >
                <button onClick={prevPage} className={styles.button}>
                    <Icon_arrowleft
                        className={`${styles.icon_arrow} ${pageNum > 0 ? styles.activate : styles.deactivate}`}
                    />
                </button>
                <span
                    className="text_18_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    Page{pageNum + 1}
                </span>
                <button onClick={nextPage} className={styles.button}>
                    <Icon_arrowright
                        className={`${styles.icon_arrow} ${
                            data?.members.totalPages! - 1 === pageNum ? styles.deactivate : styles.activate
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};
