import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Event {
    calendarId: number;
    memberId: number;
    memberName: string;
    registerSessionId: number;
    workBookName: string;
    sessionProgress: string;
    therapistNames: string[];
    reservationDate: string;
    startTime: string;
    endTime: string;
}

interface Events {
    reserves: Event[];
    message: string;
    result: boolean;
}

interface AddReserve {
    searchVal: string;
    registerSessionId: number;
    workBookName: string;
    sessionProgress: string;
    memo: string;
    reservationDate: string; // 문자열로 변경
    startTime: string;
    endTime: string;
    events: Events; // 'evnets'를 'events'로 수정
    selectFilter: string[];
    eventHover: { visible: boolean; calendarId: number; memo: string };
}

const initialState: AddReserve = {
    searchVal: '',
    registerSessionId: 0,
    workBookName: '',
    sessionProgress: '',
    memo: '',
    reservationDate: '', // 초기 상태에서 공백으로 설정
    startTime: '',
    endTime: '',
    events: {
        reserves: [
            {
                calendarId: 0,
                memberId: 0,
                memberName: '',
                registerSessionId: 0,
                workBookName: '',
                sessionProgress: '',
                therapistNames: [],
                reservationDate: '',
                startTime: '',
                endTime: '',
            },
        ],
        message: '',
        result: false,
    },
    selectFilter: ['대면 CBT (유형1)', '대면 CBT (유형2)', '이완치료', 'TMS', '심리상담'],
    eventHover: { visible: false, calendarId: 0, memo: '' },
};

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        addEvent: (state, action: PayloadAction<{ list: Events }>) => {
            // state.events = list; // 새로운 이벤트 추가
        },
        updateEvent: (state, action: PayloadAction<Events>) => {
            state.events = action.payload;
        },
        removeEvent: (state, action: PayloadAction<{ id: number }>) => {
            // const { id } = action.payload;
            // state.events = state.events.filter((event) => event.id !== id); // 특정 이벤트 제거
        },
        updateSearchVal: (state, action: PayloadAction<string>) => {
            state.searchVal = action.payload;
        },
        updateSelectFilter: (state, action: PayloadAction<string[]>) => {
            state.selectFilter = action.payload;
        },
        updateEventHoverVisible: (
            state,
            action: PayloadAction<{ visible: boolean; calendarId: number; memo: string }>
        ) => {
            state.eventHover = action.payload;
        },
    },
});

export const { addEvent, updateEvent, removeEvent, updateSearchVal, updateSelectFilter, updateEventHoverVisible } =
    reservationSlice.actions;
export default reservationSlice.reducer;
