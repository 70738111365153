import { MouseEventHandler } from 'react';
import '../css/Feedback.css';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

export const WriteFeedbackTextarea = ({
    onChange,
    save,
    cancel,
    height,
    data,
}: {
    onChange: Function;
    save: MouseEventHandler<HTMLButtonElement>;
    cancel: MouseEventHandler<HTMLButtonElement>;
    height: string;
    data: { feedbackCheck: boolean; feedback: string; therapistName: string };
}) => {
    const { finished } = useSelector((state: RootState) => state.session);
    const { feedbackVisible } = useSelector((state: RootState) => state.blackboard);
    const memberInfo = getMemberInfo();
    return (
        <div className={'feedback_container'}>
            <div
                className={'feedback_content_container'}
                style={{
                    height: height,
                }}
            >
                {data.feedbackCheck ? (
                    <div className={`feedback_title text_18_NotoSansKR_Bold`}>{data.therapistName}님의 피드백</div>
                ) : (
                    <div className={`feedback_title text_18_NotoSansKR_Bold`}>피드백 작성</div>
                )}
                <textarea
                    className={`feedback_textarea ${data.feedbackCheck ? 'edit' : ''} text_18_NotoSansKR_Bold`}
                    value={data.feedback}
                    onChange={(e) => onChange(e.target.value)}
                    readOnly={data.feedbackCheck}
                />
            </div>
            {(memberInfo?.memberRole === 'therapist' || memberInfo?.memberRole === 'representative') &&
            feedbackVisible ? (
                data.feedbackCheck ? (
                    <div className="button_container">
                        <button onClick={cancel} className={`cancel_button text_16_NotoSansKR_Bold`}>
                            삭제
                        </button>
                        <button onClick={save} className={`save_button text_16_NotoSansKR_Bold`}>
                            수정
                        </button>
                    </div>
                ) : (
                    <div className="button_container">
                        <button onClick={cancel} className={`cancel_button text_16_NotoSansKR_Bold`}>
                            취소
                        </button>
                        <button onClick={save} className={`save_button text_16_NotoSansKR_Bold`}>
                            저장
                        </button>
                    </div>
                )
            ) : null}
        </div>
    );
};
