import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getMemberInfo } from '../utils/GetMemberInfo';

const memberInfo = getMemberInfo();
interface AddCount {
    registerId: number;
    remainingTimes: string;
    workBookName: string;
}

const initialState: AddCount = {
    registerId: 0,
    remainingTimes: '',
    workBookName: '',
};

export const addCountReducer = createSlice({
    name: 'addCount',
    initialState,
    reducers: {
        updateCountData: (
            state,
            action: PayloadAction<{ registerId: number; remainingTimes: string; workBookName: string }>
        ) => {
            state.registerId = action.payload.registerId;
            state.remainingTimes = action.payload.remainingTimes;
            state.workBookName = action.payload.workBookName;
        },
        updateCount: (state, action: PayloadAction<string>) => {
            state.remainingTimes = action.payload;
        },
    },
});

export const { updateCountData, updateCount } = addCountReducer.actions;
export default addCountReducer.reducer;
