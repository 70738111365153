import styles from '../../css/SeventhSession.module.css';
import '../../../css/PublicSessionStyle.css';

import {
    RealictResults,
    SocraticQuestionAnswer,
    SocraticQuestionAnswerNonFace,
    SocraticQuestionAnswerStep,
    SubmitFeedback,
    SubmitWorkbook,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { ModalContext } from '../../../../../../context/ModalContext';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSessionData } from '../../ts/SessionApi';
import { getMemberInfo } from '../../../../../../utils/GetMemberInfo';
import axiosInstance from '../../../../../../utils/AxiosInstanceJava';
import { decryptData } from '../../../../../../utils/croptojs';
import { resetData, updateSessionPage } from '../../../../../../features/session/sessionReducer';

export const TherapistSeventhSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const { twoButtonOpenModal, oneButtonOpenModal } = useContext(ModalContext);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });

    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 현재 루트 폰트 사이즈

    const [popupStyle, setPopupStyle] = useState<{ top: number; right: number; width: number; height: number }>({
        top: 120 / rootFontSize, // px를 rem으로 변환
        right: 32 / rootFontSize, // px를 rem으로 변환
        width: 460 / rootFontSize, // px를 rem으로 변환
        height: 258 / rootFontSize, // px를 rem으로 변환
    });

    const sessionRole = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                saveSessionData();
            },
            '피드백 작성 완료 확인',
            '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
            '취소',
            '확인'
        );
    };

    // 피드백 제출
    const saveSessionData = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/feedback/${decryptData(encryptedData!)}/v1`)
            .then((response) => {
                const res = response.data;
                if (res.result) {
                    switch (res.message) {
                        case 'SUBMIT_FEEDBACK_SUCCESS':
                            oneButtonOpenModal(
                                () => {
                                    refetch();
                                },
                                '',
                                '워크북 수정이 완료되었습니다.',
                                '확인'
                            );

                            break;
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);
    return (
        <div className={'therapist_main_container'}>
            <div ref={contentRef}>
                <SocraticQuestionAnswerNonFace />
                <RealictResults />
            </div>
            <SubmitFeedback onClick={sessionRole} />
        </div>
    );
};
