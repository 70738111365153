import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

export const ScheduleHover = () => {
    const { eventHover } = useSelector((state: RootState) => state.events);
    return (
        <div
            style={{
                position: 'absolute',
                display: 'flex',
                boxSizing: 'border-box',
                bottom: '2.625rem',
                width: '96%',
                height: 'auto',
                borderRadius: '0.625rem',
                padding: '0.75rem',
                zIndex: 100,
                background: 'rgba(0, 0, 0, 0.80)',
            }}
        >
            <span
                className="text_14_NotoSansKR_Regular"
                style={{
                    color: '#FFF',
                }}
            >
                {eventHover.memo}
            </span>
        </div>
    );
};
