import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { updateMemberType } from '../../../../features/memberTypeMenuReducer';

interface MemberTypeButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $isSelected: boolean; // 추가적인 prop 정의
}

const MemberTypeButton = styled.button<MemberTypeButtonProps>`
    width: 15.875rem;
    height: 4.125rem;
    border-bottom: ${(props) => (props.$isSelected ? '0.25rem solid #3A5074' : '0.0625rem solid #E3E5EA')};
    color: ${(props) => (props.$isSelected ? '#3A5074' : '#9D9FA2')};
    background-color: #fff;
`;

export const MemberTypeMenu = ({
    menuList,
    selectedIndex,
    reset,
}: {
    menuList: string[];
    selectedIndex: number;
    reset: Function;
}) => {
    const dispatch = useDispatch();

    const [type, setType] = useState<number>(selectedIndex);

    const selectType = (index: number) => {
        setType(index);
        reset();
        dispatch(updateMemberType(index));
    };

    return (
        <div
            style={{
                width: '31.75rem',
            }}
        >
            {menuList.map((item, index) => (
                <MemberTypeButton
                    key={index.toString()}
                    className={`text_18_NotoSansKR_${type === index ? 'Bold' : 'Medium'}`}
                    $isSelected={type === index}
                    onClick={() => selectType(index)}
                >
                    {item}
                </MemberTypeButton>
            ))}
        </div>
    );
};
