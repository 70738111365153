import { useDispatch, useSelector } from 'react-redux';
import styles from './css/TimePicker.module.css';
import { RootState } from '../../../../../store';
import { updateReserveTime } from '../../../../../features/scheduleModalReducer';

export const TimePicker = () => {
    const { timePickerModalState } = useSelector((state: RootState) => state.scheduleModal);
    const dispatch = useDispatch();

    // 오전 12시부터 오후 11시45분까지
    const generateTimeSlots = () => {
        const timeSlots = [];
        const startHour = 0; // 오전 12시 (자정)
        const endHour = 23; // 오후 11시
        const interval = 15; // 15분 단위

        for (let hour = startHour; hour <= endHour; hour++) {
            for (let minutes = 0; minutes < 60; minutes += interval) {
                // 오전/오후 구분을 위해 12시간 형식으로 변환
                const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // 12시 형식으로 변환
                const period = hour < 12 ? '오전' : '오후'; // 오전/오후 구분
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

                // 시간 문자열 생성
                const timeString = `${period} ${formattedHour}:${formattedMinutes}`;
                timeSlots.push(timeString);
            }
        }

        return timeSlots;
    };

    // 선택한 시간 변환
    const convertSelectedTime = (selectedTime: string) => {
        // 변환된 배열을 만들기 위해 원래 배열을 복사합니다

        // 선택한 시간을 변환
        const index = timeArray.findIndex((time) => time.includes(selectedTime));
        if (index !== -1) {
            // "오전" 또는 "오후"를 제거하고 HH:mm 형식으로 변환
            const [period, hour, minutes] = timeArray[index].split(/[\s:]+/);

            // 24시간 형식으로 변환
            let hour24 = parseInt(hour, 10);
            if (period === '오후' && hour24 !== 12) {
                hour24 += 12; // 오후 12시가 아닐 경우 12를 더함
            } else if (period === '오전' && hour24 === 12) {
                hour24 = 0; // 오전 12시는 0으로 변환
            }

            // HH:mm 형식으로 변환
            const formattedHour = hour24.toString().padStart(2, '0'); // 2자리 형식으로 변환
            const formattedMinutes = minutes.padStart(2, '0'); // 2자리 형식으로 변환

            // 변환된 시간
            const formattedTime = `${formattedHour}:${formattedMinutes}`;
            console.log(selectedTime);
            if (timePickerModalState === 'left') {
                dispatch(
                    updateReserveTime({
                        startTime: formattedTime,
                        originStartTime: selectedTime,
                        endTime: '',
                        originEndTime: '',
                    })
                );
            } else {
                dispatch(
                    updateReserveTime({
                        startTime: '',
                        originStartTime: '',
                        endTime: formattedTime,
                        originEndTime: selectedTime,
                    })
                );
            }
        }
    };

    const timeArray = generateTimeSlots();

    return (
        <div
            className={`${styles.time_picker} ${
                timePickerModalState === 'left' ? styles.position_left : styles.position_right
            }`}
        >
            {timeArray.map((item, index) => (
                <button
                    key={index.toString()}
                    className={styles.button}
                    onClick={() => {
                        convertSelectedTime(item);
                    }}
                >
                    <div className={`${styles.title} text_16_NotoSansKR_Medium`}>{item}</div>
                </button>
            ))}
        </div>
    );
};
