import axiosInstance from '../../../utils/AxiosInstanceJava';

export interface NotificationType {
    pushAlarms: {
        pushAlarmId: number;
        memberIds: number[];
        category: string;
        pushType: string;
        title: string;
        content: string;
        approvalCheck: number;
        memberId: number;
        regDate: string;
        modDate: string;
    }[];
    message: string;
    result: boolean;
}

export const getNotification = async (): Promise<NotificationType> => {
    return await axiosInstance.get('/api/member/pushAlarm/v1').then((response) => response.data);
};

export const getMemberInfo = async () => {
    return await axiosInstance.get(`/api/member/me/v1`).then((response) => response.data);
};
