// 휴대폰 정보

import { jwtDecode } from 'jwt-decode';

// interface NavigatorWithConnection extends Navigator {
//     connection?: NetworkInformation;
//     mozConnection?: NetworkInformation;
//     webkitConnection?: NetworkInformation;
// }

// interface NetworkInformation {
//     downlink: number;
//     effectiveType: string;
//     onchange?: (event: Event) => void;
//     rtt: number;
//     saveData: boolean;
//     type: string;
// }

// // 회원 정보
export const getMemberInfo = () => {
    const data = localStorage.getItem('userToken');
    if (data !== null) {
        const result = JSON.parse(data);
        const decode: {
            memberId: number;
            companyBranches: { companyId: number; branchName: string }[];
            loginBranch: { companyId: number; branchName: string };
            memberAccount: string;
            memberEmail: string;
            memberName: string;
            memberPhone: string;
            memberBirth: number;
            memberRole: string;
            snsType: string;
            regDate: number;
            modDate: number;
            iat: number;
            exp: number;
        } = jwtDecode(result.accessToken);
        return decode;
    }
};
