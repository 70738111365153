import { useEffect, useState } from 'react';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { SquareCheckBox } from '../../../../../component/PublicComponent';
import styles from './css/ProgramPicker.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { updateProgramPickerVisible, updateSelectProgarm } from '../../../../../features/scheduleModalReducer';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

interface WorkBookInfos {
    registerId: number;
    registerSessionId: number;
    workBookName: string;
    therapistNames: string[];
    sessionNo: number;
    check: boolean;
}

export const ProgramPicker = () => {
    const dispatch = useDispatch();
    const { programPickerVisible, registerSessionId, calendarId, patientMemberInfo } = useSelector(
        (state: RootState) => state.scheduleModal
    );
    const [pageLoading, setPageLoading] = useState<boolean>(false);

    const [workBookList, setWorkBookList] = useState<WorkBookInfos[]>([]);

    useEffect(() => {
        getProgramList();
    }, []);

    const getProgramList = async () => {
        const memberInfo = getMemberInfo();

        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/reserve/list/workBook/${patientMemberInfo.memberId}/v1`)
            .then((response) => {
                if (response.data.result) {
                    response.data.workBookInfos.map((item: WorkBookInfos, index: number) => {
                        if (registerSessionId === item.registerSessionId) {
                            item.check = true;
                        } else {
                            item.check = false;
                        }
                    });
                    setWorkBookList(response.data.workBookInfos);
                }
            })
            .finally(() => {
                setPageLoading(true);
            });
    };

    const selectProgram = (index: number) => {
        const updateAry = workBookList.map((item, idx) => ({
            ...item,
            check: idx === index, // 선택된 인덱스만 true로 설정
        }));
        setWorkBookList(updateAry);
    };

    const saveProgram = () => {
        const selectedProgram = workBookList.find((item) => item.check === true); // 선택된 프로그램 찾기

        if (selectedProgram) {
            dispatch(
                updateSelectProgarm({
                    registerSessionId: selectedProgram.registerSessionId, // 단일 값으로 설정
                    workBookName: selectedProgram.workBookName, // 단일 값으로 설정
                    therapistNames: selectedProgram.therapistNames, // 첫 번째 이름만 사용
                    sessionProgress: selectedProgram.sessionNo, // 단일 값으로 설정
                })
            );
        }
        dispatch(updateProgramPickerVisible(false));
    };

    return pageLoading ? (
        <div className={styles.program_picker}>
            <div className={styles.item_container}>
                {workBookList.map((item, index) => (
                    <button
                        key={index.toString()}
                        className={styles.program_button}
                        onClick={() => selectProgram(index)}
                    >
                        <SquareCheckBox bool={item.check} size="1.5rem" />
                        <span className="text_16_NotoSansKR_Medium">{item.workBookName}</span>
                    </button>
                ))}
            </div>
            <div className={styles.button_container}>
                <button
                    onClick={() => dispatch(updateProgramPickerVisible(false))}
                    className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}
                >
                    취소
                </button>
                <button onClick={saveProgram} className={`${styles.save_button} text_16_NotoSansKR_Bold`}>
                    선택완료
                </button>
            </div>
        </div>
    ) : null;
};
