import '../../../../../cbt/css/PublicSessionStyle.css';

import { useContext, useEffect, useRef, useState } from 'react';
import { ExperiencesEmotion } from '../../../component/SecondSessionComponent';
import { SituationEmotion, SubmitFeedback, SubmitWorkbook } from '../../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { resetData, updateSessionPage } from '../../../../../../../features/session/sessionReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { ModalContext } from '../../../../../../../context/ModalContext';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getAssignData } from '../../../ts/SessionApi';
import { getMemberInfo } from '../../../../../../../utils/GetMemberInfo';
import { decryptData } from '../../../../../../../utils/croptojs';
import axiosInstance from '../../../../../../../utils/AxiosInstanceJava';

export const TherapistAssignedSecondSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const encryptedData = queryParams.get('session');
    const payment = queryParams.get('payment');
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const { registerSessionId, fields } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal, twoButtonOpenModal } = useContext(ModalContext);

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getAssignData', encryptedData],
        queryFn: () => getAssignData(encryptedData!),
        enabled: encryptedData !== null,
        placeholderData: keepPreviousData,
    });

    const sessionRole = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                saveSessionData();
            },
            '피드백 작성 완료 확인',
            '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
            '취소',
            '확인'
        );
    };

    // 워크북 제출
    const saveSessionData = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/feedback/assignment/${decryptData(encryptedData!)}/v1`)
            .then((response) => {
                const res = response.data;
                if (res.result) {
                    switch (res.message) {
                        case 'SUBMIT_FEEDBACK_SUCCESS':
                            refetch();
                            break;
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);

    return (
        <div className={'therapist_main_container'}>
            <SituationEmotion />

            <ExperiencesEmotion />
            <SubmitFeedback onClick={sessionRole} />
        </div>
    );
};
