import { useLocation, useNavigate } from 'react-router-dom';
import styles from './css/PatientCBTComponent.module.css';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Icon_arrowright } from '../../../../../../assets/member/signup/icon_arrowright.svg';
import { ReactComponent as Icon_session } from '../../../../../../assets/face/icon_session.svg';
import { ReactComponent as Icon_file } from '../../../../../../assets/face/icon_file.svg';
import { decryptData, encryptData } from '../../../../../../utils/croptojs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { resetData } from '../../../../../../features/session/sessionReducer';
import { ModalContext } from '../../../../../../context/ModalContext';
import { resetBlackBoardData } from '../../../../../../features/session/blackboardReducer';
import { exportPDF } from '../../ts/SessionApi';

interface MenuButtonProps {
    $isSelectedMenu: number;
    $menu: number;
    $isSelectedAssignedMenu: number;
    $activate: boolean;
}

const PatientMenu = styled.div<{ $isSelectedMenu: string; $menu: string }>`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    padding: 0.3125rem 0;
    width: 6.9375rem;
    ${(props) =>
        props.$isSelectedMenu === props.$menu
            ? 'border-radius: 7px; background: linear-gradient(90deg, #4D717F 0%, #313E6D 100%); color:#fff;'
            : 'color: #9D9FA2;'}
`;

const SessionMenuButton = styled.div<MenuButtonProps>`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0.687rem 1.0625rem;
    border-radius: 0.625rem;
    gap: 1rem;
    ${(props) =>
        props.$isSelectedAssignedMenu === 0 && props.$isSelectedMenu === props.$menu
            ? `color: #3A5074; background-color: #E9F0FD`
            : 'color: #D2D5D9'}
`;
const SessionAssignedMenu = styled.div<MenuButtonProps>`
    display: flex;
    margin-top: 0.94rem;
    width: 100%;
    height: 2.875rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 0.625rem;
    ${(props) =>
        props.$activate && props.$isSelectedAssignedMenu !== 0 && props.$isSelectedMenu === props.$menu
            ? `color: #3A5074; background-color: #E9F0FD`
            : 'color: #D2D5D9'}
`;

const faceSessionList = [
    { title: '세션1', url: '1', assignment: '세션1 과제' },
    { title: '세션2', url: '2', assignment: '세션2 과제' },
    { title: '세션3', url: '3', assignment: '세션3 과제' },
    { title: '세션4', url: '4', assignment: '세션4 과제' },
    { title: '세션5', url: '5', assignment: '세션5 과제' },
    { title: '세션6', url: '6', assignment: '세션6 과제' },
    { title: '세션7', url: '7', assignment: '세션7 과제' },
    { title: '세션8', url: '8', assignment: '세션8 과제' },
    { title: '세션9', url: '9', assignment: '세션9 과제' },
    { title: '세션10', url: '10', assignment: '세션10 과제' },
];

const nonFaceSessionList = [
    { title: '세션1', url: '1', assignment: '세션1 과제' },
    { title: '세션2', url: '2', assignment: '세션2 과제' },
    { title: '세션3', url: '3', assignment: '세션3 과제' },
    { title: '세션4', url: '4', assignment: '세션4 과제' },
    { title: '세션5', url: '5', assignment: '세션5 과제' },
    { title: '세션6', url: '6', assignment: '세션6 과제' },
    { title: '세션7', url: '7', assignment: '세션7 과제' },
    { title: '세션8', url: '8', assignment: '세션8 과제' },
];

export const TherapistCBTMenu = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const programId = queryParams.get('program');
    const session = queryParams.get('session');

    const { twoButtonOpenModal } = useContext(ModalContext);
    const { menus } = useSelector((state: RootState) => state.sessionMenu);
    const { blackboardVisible, contentRef } = useSelector((state: RootState) => state.blackboard);

    const menuList = ['대면', '비대면'];
    const navigate = useNavigate();
    const currentCBT = location.pathname.includes('/face')
        ? 'face'
        : location.pathname.includes('/nonface')
        ? 'nonface'
        : '';

    const [isSelectedMenu, setIsSelectedMenu] = useState<string>(currentCBT === 'face' ? '대면' : '비대면');
    const [isSelectedSessionMenu, setIsSelectedSessionMenu] = useState<number>(Number(encryptedData!));

    const [isSelectedAssignedMenu, setIsSelectedAssignedMenu] = useState<number>(
        location.pathname.includes('/session/assigned') ? Number(encryptedData!) : 0
    );

    // 페이지 이동
    const movePage = (url: string) => {
        if (blackboardVisible) {
            twoButtonOpenModal(
                () => {},
                () => {
                    exportPDF(contentRef, session!, dispatch(resetBlackBoardData()));
                    navigate(url);
                },
                '전자칠판 종료',
                '제출하지 않고 페이지를 벗어날 경우, 입력한 내용은 저장되지 않습니다. 하단의 저장 버튼 또는 페이지 내 상단 전자칠판 종료 버튼을 눌러 저장을 완료해 주세요.',
                '취소',
                '저장'
            );
        } else {
            navigate(url);
        }
    };

    // 세션 이동
    const moveSession = (page: string, sessionId: string, programId: string) => {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('session', encryptData(sessionId));
        params.append('program', encryptData(programId));
        movePage(`session?${params}`);
    };

    // 세션 과제 이동
    const moveAssignedSession = (page: string, assignedSessionId: string, programId: string) => {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('session', encryptData(assignedSessionId));
        params.append('program', encryptData(programId));
        movePage(`session/assigned?${params}`);
    };

    const memberDetail = (memberId: string) => {
        const queryString = `data=${encodeURIComponent(encryptData(memberId))}`;
        movePage(`/yd/official/assignedMember/detail?${queryString}`);
    };

    const SessionRender = () => {
        return (
            <ul className={styles.menu_list_container}>
                {menus.map((item, index) => (
                    <li className={styles.li_menu} key={index.toString()}>
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (item.sessionAccess) {
                                    setIsSelectedAssignedMenu(0);
                                    setIsSelectedSessionMenu(item.sessionNo);
                                    moveSession(
                                        item.sessionNo.toString(),
                                        item.registerSessionId.toString(),
                                        item.registerId.toString()
                                    );
                                }
                            }}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <SessionMenuButton
                                $isSelectedMenu={Number(encryptedData)}
                                $isSelectedAssignedMenu={isSelectedAssignedMenu}
                                $menu={item.sessionNo}
                                $activate={location.pathname.includes('/patientCBT')}
                            >
                                <Icon_session
                                    className={styles.icon_menu}
                                    color={
                                        item.sessionAccess
                                            ? isSelectedSessionMenu === item.sessionNo
                                                ? '#3A5074'
                                                : '#020202'
                                            : '#D2D5D9'
                                    }
                                />
                                <span
                                    className={`${
                                        isSelectedSessionMenu === item.sessionNo
                                            ? 'text_16_NotoSansKR_Bold'
                                            : 'text_16_NotoSansKR_Medium'
                                    }`}
                                    style={{
                                        color: item.sessionAccess
                                            ? isSelectedSessionMenu === item.sessionNo
                                                ? '#3A5074'
                                                : '#020202'
                                            : '#D2D5D9',
                                    }}
                                >
                                    세션{item.sessionNo}
                                </span>
                            </SessionMenuButton>
                        </Link>
                        {item.sessionNo !== 1 &&
                            item.sessionNo !== 10 &&
                            location.pathname.includes('/face') &&
                            encryptedData! === item.sessionNo.toString() &&
                            item.assignAccess && (
                                <Link
                                    to="#"
                                    onClick={(e) => {
                                        if (item.sessionAccess) {
                                            setIsSelectedAssignedMenu(item.assignmentNo);
                                            moveAssignedSession(
                                                item.assignmentNo.toString(),
                                                item.registerAssignmentId.toString(),
                                                item.registerId.toString()
                                            );
                                        } else {
                                            e.preventDefault();
                                        }
                                    }}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <SessionAssignedMenu
                                        $isSelectedMenu={Number(encryptedData)}
                                        $isSelectedAssignedMenu={isSelectedAssignedMenu}
                                        $menu={item.assignmentNo}
                                        $activate={location.pathname.includes('/session/assigned')}
                                    >
                                        <Icon_file
                                            className={styles.icon_menu}
                                            color={
                                                item.assignAccess
                                                    ? isSelectedAssignedMenu === item.assignmentNo
                                                        ? '#3A5074'
                                                        : '#020202'
                                                    : '#D2D5D9'
                                            }
                                        />
                                        <span
                                            className={`${
                                                isSelectedAssignedMenu === item.assignmentNo
                                                    ? 'text_16_NotoSansKR_Bold'
                                                    : 'text_16_NotoSansKR_Medium'
                                            }`}
                                            style={{
                                                color: item.assignAccess
                                                    ? isSelectedAssignedMenu === item.assignmentNo
                                                        ? '#3A5074'
                                                        : '#020202'
                                                    : '#D2D5D9',
                                            }}
                                        >
                                            세션{item.assignmentNo} 과제
                                        </span>
                                    </SessionAssignedMenu>
                                </Link>
                            )}
                    </li>
                ))}
            </ul>
        );
    };

    const MenuRender = () => {
        return (
            <div className={styles.menu_container}>
                {menuList.map((item, index) => (
                    <PatientMenu
                        key={index.toString()}
                        className={isSelectedMenu === item ? `text_16_NotoSansKR_Bold` : `text_16_NotoSansKR_Medium`}
                        $isSelectedMenu={isSelectedMenu}
                        $menu={item}
                    >
                        {item}
                    </PatientMenu>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <button onClick={() => memberDetail('25')} className={styles.patient_info_button}>
                <span className="text_14_NotoSansKR_Medium">김회원님의 상세정보</span>
                <Icon_arrowright className={styles.icon_arrowright} />
            </button>
            <MenuRender />
            <div
                className="text_14_Inter_Bold"
                style={{
                    color: '#9D9FA2',
                }}
            >
                SESSION
            </div>
            <SessionRender />
        </div>
    );
};
