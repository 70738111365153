import styled from 'styled-components';
import styles from '../css/Login.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoginMemberAccount, updateLoginMemberPassword } from '../../../../features/loginReducer';
import { RootState } from '../../../../store';
import { PasswordToggle } from '../../../../component/PublicComponent';

interface CustomInputProps {
    $isFocused: boolean;
}

const CustomInput = styled.input<CustomInputProps>`
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    padding: 1.18rem 1.25rem;
    border-radius: 0.625rem;
    outline: ${(props) => (props.$isFocused ? '0.1875rem solid #3A5074' : '0.0625rem solid #e3e5ea')};
    background: #fff;
`;

interface InputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    isFocused: boolean;
    setIsFocused: (focused: boolean) => void;
}

interface PasswordInputProps extends InputProps {
    loginRule: () => void;
    isPasswordVisible: boolean;
    setIsPasswordVisible: (visible: boolean) => void;
}

export const LoginIdInput: React.FC<InputProps> = ({ onChange, value, isFocused, setIsFocused }) => (
    <div className={styles.input_content_container}>
        <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
            아이디
        </span>
        <CustomInput
            $isFocused={isFocused}
            className={'text_18_NotoSansKR_Regular'}
            placeholder="아이디 입력"
            value={value}
            autoComplete="off"
            type="text"
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        />
    </div>
);

export const LoginPasswordInput: React.FC<PasswordInputProps> = ({
    onChange,
    value,
    isFocused,
    setIsFocused,
    loginRule,
    isPasswordVisible,
    setIsPasswordVisible,
}) => (
    <div className={styles.input_content_container}>
        <span className="text_18_NotoSansKR_Bold" style={{ color: '#626466' }}>
            비밀번호
        </span>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
            }}
        >
            <CustomInput
                $isFocused={isFocused}
                className={'text_18_NotoSansKR_Regular'}
                placeholder="비밀번호 입력"
                value={value}
                autoComplete="off"
                type={isPasswordVisible ? 'text' : 'password'}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        loginRule();
                    }
                }}
            />
            <div
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                style={{ position: 'absolute', right: '1.25rem' }}
            >
                <PasswordToggle bool={isPasswordVisible} />
            </div>
        </div>
    </div>
);

export const LoginInput: React.FC<{ loginRule: () => void }> = ({ loginRule }) => {
    const dispatch = useDispatch();
    const login = useSelector((state: RootState) => state.login);

    const [isIdFocused, setIsIdFocused] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    return (
        <form>
            <div className={styles.input_container}>
                <LoginIdInput
                    onChange={(e) => dispatch(updateLoginMemberAccount(e.target.value))}
                    value={login.memberAccount}
                    isFocused={isIdFocused}
                    setIsFocused={setIsIdFocused}
                />
                <LoginPasswordInput
                    onChange={(e) => dispatch(updateLoginMemberPassword(e.target.value))}
                    value={login.memberPassword}
                    isFocused={isPasswordFocused}
                    setIsFocused={setIsPasswordFocused}
                    loginRule={loginRule}
                    isPasswordVisible={isPasswordVisible}
                    setIsPasswordVisible={setIsPasswordVisible}
                />
            </div>
        </form>
    );
};
