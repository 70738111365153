import { useCallback, useEffect, useState } from 'react';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import styles from './css/PatientPicker.module.css';
import { log } from 'console';
import { useDispatch } from 'react-redux';
import { updateSelectPatient } from '../../../../../features/scheduleModalReducer';
import { ReactComponent as Icon_search } from '../../../../../assets/therapist/icon_search.svg';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';

interface MemberList {
    group: string;
    memberInfos: { memberId: number; memberName: string }[];
}

export const PatientPicker = () => {
    const dispath = useDispatch();

    const [memberList, setMemberList] = useState<MemberList[]>([]);

    const [searchVal, setSearchVal] = useState<string>('');

    useEffect(() => {
        getMemberList();
    }, []);

    const getMemberList = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/reserve/list/member/${memberInfo?.loginBranch.companyId}/v1`)
            .then((response) => {
                if (response.data.result) {
                    setMemberList(response.data.memberInfos);
                }
            });
    };

    // const memberListRedefine = (response: { memberId: number; memberName: string }[]) => {
    //     const grouped: MemberList[] = [];

    //     response.forEach((item) => {
    //         const firstChar = item.memberName.charAt(0); // 첫 글자 가져오기
    //         const initialConsonant = getInitialConsonant(firstChar); // 초성을 가져오는 함수 호출

    //         // 해당 초성으로 그룹이 존재하는지 확인
    //         let group = grouped.find((g) => g.group === initialConsonant);

    //         // 그룹이 없으면 새로 생성
    //         if (!group) {
    //             group = { group: initialConsonant, memberInfos: [] };
    //             grouped.push(group);
    //         }

    //         // 해당 그룹에 아이템 추가
    //         group.memberInfos.push(item);
    //     });
    //     setMemberList(grouped);
    // };

    // // 초성 체크 반환
    // const getInitialConsonant = (char: string) => {
    //     const code = char.charCodeAt(0);
    //     if (code >= 0xac00 && code <= 0xd7a3) {
    //         // 한글 음절 범위
    //         const initialIndex = Math.floor((code - 0xac00) / 588);
    //         const initials = [
    //             'ㄱ',
    //             'ㄲ',
    //             'ㄴ',
    //             'ㄷ',
    //             'ㄸ',
    //             'ㄹ',
    //             'ㅁ',
    //             'ㅂ',
    //             'ㅃ',
    //             'ㅅ',
    //             'ㅆ',
    //             'ㅇ',
    //             'ㅈ',
    //             'ㅉ',
    //             'ㅊ',
    //             'ㅋ',
    //             'ㅌ',
    //             'ㅍ',
    //             'ㅎ',
    //         ];
    //         return initials[initialIndex]; // 초성 반환
    //     }
    //     return ''; // 한글이 아닐 경우 빈 문자열 반환
    // };

    const searchItems = (groupedData: MemberList[]) => {
        if (searchVal.length < 2) {
            return groupedData; // 두 글자 미만 입력 시 원본 배열로 돌아감
        }

        const searchTerm = searchVal.trim();
        const result: MemberList[] = [];
        const matchingGroups: MemberList[] = [];
        const unmatchedGroups: MemberList[] = [];

        // 각 그룹을 순회하며 검색
        groupedData.forEach((group) => {
            const matchingItems = group.memberInfos.filter((item) => item.memberName.startsWith(searchTerm));

            // 그룹 결과에 추가
            const unmatchedItems = group.memberInfos.filter((item) => !item.memberName.startsWith(searchTerm));

            // 일치하는 이름이 있을 경우
            if (matchingItems.length > 0) {
                // 일치하는 그룹을 수집
                matchingGroups.push({
                    group: group.group,
                    memberInfos: [
                        ...matchingItems, // 일치하는 항목
                        ...unmatchedItems, // 나머지 항목
                    ],
                });
            } else {
                // 일치하는 이름이 없으면 원래 그룹 유지
                unmatchedGroups.push(group);
            }
        });

        // 일치하는 그룹을 최상위에 추가하고, 나머지 그룹을 뒤에 붙임
        result.push(...matchingGroups, ...unmatchedGroups);

        // 그룹과 memberInfos가 있는 항목만 반환
        return result;
    };
    // JSX 부분에서 검색어에 따라 검색 기능을 호출
    return (
        <div className={styles.patient_picker}>
            <div className={styles.input_container}>
                <input
                    className={`${styles.input} text_16_NotoSansKR_Regular`}
                    onChange={(e) => {
                        if (e.target.value.length > 1) {
                            setSearchVal(e.target.value);
                        }
                    }}
                    placeholder="두 글자 이상 입력"
                />
                <Icon_search className={styles.icon_search} />
            </div>
            <div className={styles.content_container}>
                {searchItems(memberList).map((item, index) => (
                    <div key={index.toString()} className={styles.content}>
                        <div className={`${styles.group} text_16_NotoSansKR_Bold`} style={{ color: '#9D9FA2' }}>
                            {item.group}
                        </div>
                        <div className={styles.group_item_container}>
                            {item.memberInfos.map((value, idx) => (
                                <button
                                    key={idx.toString()}
                                    onClick={() =>
                                        dispath(
                                            updateSelectPatient({
                                                memberId: value.memberId,
                                                memberName: value.memberName,
                                            })
                                        )
                                    }
                                >
                                    <span className="text_16_NotoSansKR_Medium">{value.memberName}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
