import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ModalContext } from '../../../../../context/ModalContext';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import { ReactComponent as Icon_arrowbottom } from '../../../../../assets/member/icon_arrowbottom.svg';
import styles from './css/ProgramModal.module.css';
import { EssentialMark } from '../../../../member/component/EssentialMark';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CategoryList, Program, getProgramCategory, getProgramDetail } from '../ts/ProgramManagementApi';
import {
    programDataReset,
    updateCategoryList,
    updateDiscount,
    updateOriginPrice,
    updateProductName,
    updateProgramCategoryVisible,
    updateProgramDescription,
    updateProgramStatusVisible,
    updateSelectProgramDetail,
} from '../../../../../features/programReducer';
import { ProgramCategoryDropDown } from './ProgramCategoryDropDown';
import { ProgramStatusDropDown } from './ProgramStatusDropDown';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';

export const ProgramModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 130,
        },
        content: {
            border: 'none',
            width: '100%',
            height: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const { programVisible, programOpenModal } = useContext(ModalContext);
    const dispatch = useDispatch();

    const {
        workBookProductId,
        productName,
        originalPrice,
        workBooks,
        onSale,
        discount,
        productDescription,
        programCategoryVisible,
        programStatusVisible,
    } = useSelector((state: RootState) => state.programModal);

    const {
        data: programDetail,
        isLoading: programLoading,
        error: programError,
    } = useQuery<Program>({
        queryKey: ['programDetail', workBookProductId],
        queryFn: () => getProgramDetail(workBookProductId),
        enabled: workBookProductId !== 0,
        placeholderData: keepPreviousData,
    });

    const {
        data: categoryList,
        isLoading: categoryLoading,
        error: categoryError,
    } = useQuery<CategoryList>({
        queryKey: ['programCategory'],
        queryFn: () => getProgramCategory(),
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        if (programDetail && categoryList) {
            saveProgramDetail(programDetail);
            saveCategoryList(categoryList);
        }
    }, [programDetail]);

    const saveProgramDetail = (data: Program) => {
        dispatch(updateSelectProgramDetail(data));
    };

    const saveCategoryList = (data: CategoryList) => {
        dispatch(updateCategoryList(data.categories));
    };

    // 로딩 중일 때
    if (programLoading || categoryLoading) {
        return <div>Loading...</div>;
    }

    // 오류 발생 시
    if (programError) {
        return <div>Error: {programError.message}</div>;
    }

    // 배열 특정 키값만 뽑아서 새로운 배열
    const extractValues = (): any[] => {
        return workBooks.map((item) => item['workBookId']);
    };

    const addProgram = async () => {
        const workBookIdList = extractValues();
        const requestData = {
            workBookProductId: workBookProductId,
            productName: productName,
            workBookIdList: workBookIdList,
            productDescription: productDescription,
            originalPrice: Number(String(originalPrice).replace(/,/g, '')),
            onSale: onSale,
            discount: Number(discount),
        };
        await axiosInstance.post(`/api/representative/product/v1`, requestData).then((response) => {
            if (response.data.result) {
                programOpenModal(!programVisible);
            }
        });
    };

    const editProgram = async () => {
        const workBookIdList = extractValues();
        const requestData = {
            workBookProductId: workBookProductId,
            productName: productName,
            workBookIdList: workBookIdList,
            productDescription: productDescription,
            originalPrice: Number(String(originalPrice).replace(/,/g, '')),
            onSale: onSale,
            discount: discount,
        };
        await axiosInstance.put(`/api/representative/product/v1`, requestData).then((response) => {
            if (response.data.result) {
                programOpenModal(!programVisible);
            }
        });
    };

    const removeProgram = async () => {
        await axiosInstance.delete(`/api/representative/product/${workBookProductId}/v1`).then((response) => {
            if (response.data.result) {
                programOpenModal(!programVisible);
            }
        });
    };

    const cancelButton = () => {
        dispatch(programDataReset());
        programOpenModal(!programVisible);
    };

    // 가격 단위 변경
    const formatNumber = (number: number | string) => {
        const sanitizedNumber = typeof number === 'string' ? number.replace(/[^0-9]/g, '') : number.toString();

        // 숫자로 변환
        const price = Number(sanitizedNumber);

        // 유효한 숫자인지 확인
        if (isNaN(price)) {
            return ''; // NaN일 경우 빈 문자열 반환
        }
        return new Intl.NumberFormat('ko-KR').format(price).toString();
    };

    const onChangeText = (text: string, type: string) => {
        switch (type) {
            case 'productName':
                return dispatch(updateProductName(text));
            case 'originalPrice':
                return dispatch(updateOriginPrice(text));
            case 'disCount':
                return dispatch(updateDiscount(text));
            case 'productDescription':
                return dispatch(updateProgramDescription(text));
        }
    };

    const headerTitle = () => {
        if (workBookProductId) {
            return '프로그램 상세정보';
        } else {
            return '프로그램 추가';
        }
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={programVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div>
                    <div className={styles.title}>
                        <div className={`${styles.reserve_cancel_container} text_24_NotoSansKR_Bold`}>
                            {headerTitle()}
                        </div>
                        <button onClick={cancelButton}>
                            <Icon_x width={'1.5rem'} height={'1.5rem'} color="#020202" />
                        </button>
                    </div>

                    <div className={styles.content_container}>
                        <div className={styles.input_container}>
                            <div
                                className="text_16_NotoSansKR_Bold"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                프로그램명
                                <EssentialMark />
                            </div>
                            <input
                                className={`${styles.input} text_16_NotoSansKR_Regular`}
                                value={productName}
                                onChange={(e) => onChangeText(e.target.value, 'productName')}
                                placeholder="프로그램명 입력"
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '1.75rem',
                            }}
                        >
                            <div className={styles.content}>
                                <div className={styles.input_container}>
                                    <div
                                        className="text_16_NotoSansKR_Bold"
                                        style={{
                                            color: '#626466',
                                        }}
                                    >
                                        기존가
                                    </div>
                                    <input
                                        className={`${styles.input} text_16_NotoSansKR_Regular`}
                                        value={originalPrice === '' ? '' : formatNumber(originalPrice)}
                                        onChange={(e) => onChangeText(e.target.value, 'originalPrice')}
                                        placeholder="0,000,000"
                                    />
                                </div>
                                <div className={styles.input_container}>
                                    <div
                                        className="text_16_NotoSansKR_Bold"
                                        style={{
                                            color: '#626466',
                                        }}
                                    >
                                        카테고리
                                        <span
                                            className="text_16_NotoSansKR_Medium"
                                            style={{
                                                color: '#FF5833',
                                            }}
                                        >
                                            (복수선택 가능)
                                            <EssentialMark />
                                        </span>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <button
                                            onClick={() => {
                                                dispatch(updateProgramCategoryVisible(!programCategoryVisible));
                                                dispatch(updateProgramStatusVisible(false));
                                            }}
                                            className={styles.drop_down_button}
                                        >
                                            <span className="text_16_NotoSansKR_Regular">
                                                {workBooks.length === 0 ? (
                                                    <span
                                                        style={{
                                                            color: '#9D9FA2',
                                                        }}
                                                    >
                                                        카테고리 선택
                                                    </span>
                                                ) : (
                                                    workBooks.map((item) => item.workBookName).join(', ')
                                                )}
                                            </span>
                                            <Icon_arrowbottom width={'1rem'} height={'1rem'} />
                                        </button>
                                        {programCategoryVisible && <ProgramCategoryDropDown list={categoryList!} />}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.content}>
                                <div className={styles.input_container}>
                                    <div
                                        className="text_16_NotoSansKR_Bold"
                                        style={{
                                            color: '#626466',
                                        }}
                                    >
                                        최종 판매가
                                        <EssentialMark />
                                    </div>
                                    <input
                                        className={`${styles.input} text_16_NotoSansKR_Regular`}
                                        value={discount === '' ? '' : formatNumber(discount)}
                                        onChange={(e) => onChangeText(e.target.value, 'disCount')}
                                        placeholder="숫자만 입력"
                                    />
                                </div>
                                <div className={styles.input_container}>
                                    <div
                                        className="text_16_NotoSansKR_Bold"
                                        style={{
                                            color: '#626466',
                                        }}
                                    >
                                        상태
                                        <EssentialMark />
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <button
                                            onClick={() => {
                                                dispatch(updateProgramStatusVisible(!programStatusVisible));
                                                dispatch(updateProgramCategoryVisible(false));
                                            }}
                                            className={styles.drop_down_button}
                                        >
                                            <span className="text_16_NotoSansKR_Regular">
                                                {onSale ? '노출' : '비노출'}
                                            </span>
                                            <Icon_arrowbottom width={'1rem'} height={'1rem'} />
                                        </button>
                                        {programStatusVisible && <ProgramStatusDropDown />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.memo_container}>
                        <div
                            className="text_16_NotoSansKR_Bold"
                            style={{
                                color: '#626466',
                            }}
                        >
                            프로그램 설명
                        </div>
                        <textarea
                            className={`${styles.memo} text_16_NotoSansKR_Regular`}
                            onChange={(e) => onChangeText(e.target.value, 'productDescription')}
                            value={productDescription}
                            placeholder="예시) 진행회기: 인지행동치료(대면형)프로그램 10회 + 이완치료 7회 + TMS15회"
                        />
                    </div>
                </div>
                <div className={styles.button_container}>
                    {workBookProductId !== 0 ? (
                        <button
                            onClick={() => removeProgram()}
                            className={`${styles.schedule_cancel_button} text_18_NotoSansKR_Bold`}
                        >
                            삭제
                        </button>
                    ) : (
                        <div className={``}></div>
                    )}
                    <div>
                        <button onClick={cancelButton} className={`${styles.cancel_button} text_18_NotoSansKR_Bold`}>
                            취소
                        </button>
                        <button
                            onClick={() => {
                                if (workBookProductId === 0) {
                                    addProgram();
                                } else {
                                    editProgram();
                                }
                            }}
                            className={`${styles.save_button} text_18_NotoSansKR_Bold`}
                        >
                            저장
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};
