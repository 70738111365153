import styles from './css/PaymentPartCancelModal.module.css';
import { ModalContext } from '../../../../../context/ModalContext';
import { useContext, useState } from 'react';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';
import ReactModal from 'react-modal';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPaymentDetail, getPaymentProductList } from '../ts/PaymentDetails';
import { RootState } from '../../../../../store';
import moment from 'moment';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { SquareCheckBox } from '../../../../../component/PublicComponent';

interface SelectWorkBook {
    registerId: number;
    paymentId: number;
    workBookName: string;
    remainingTimes: number;
}

export const PaymentPartCancelModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 201,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const { paymentId } = useSelector((state: RootState) => state.paymentDetailModal);
    const { paymentPartCancelOpenModal, paymentDetailOpenModal, paymentPartCancelVisible, oneButtonOpenModal } =
        useContext(ModalContext);
    const [selectWorkBookList, setSelectWorkBookList] = useState<SelectWorkBook[]>([]);
    const [cancelPrice, setCancelPrice] = useState<string>('');
    const memberInfo = getMemberInfo();
    const dispatch = useDispatch();

    const { data, isLoading, error } = useQuery({
        queryKey: ['getPaymentProductList', paymentId],
        queryFn: () => getPaymentProductList(paymentId),
        enabled: paymentId !== null,
    });

    const paymentCancel = async () => {
        if (selectWorkBookList.length === 0) {
            return oneButtonOpenModal(() => {}, '', '취소하실 프로그램을 선택해 주세요.', '확인');
        } else if (data.workBooks.length !== selectWorkBookList.length && (cancelPrice === '' || cancelPrice === '0')) {
            return oneButtonOpenModal(() => {}, '', '취소하실 프로그램의 총 금액을 입력해 주세요.', '확인');
        } else {
            const registerIds = selectWorkBookList.map((item) => item.registerId);
            const requestData = {
                paymentId: paymentId,
                registerIds: registerIds,
                cancelAmt: Number(cancelPrice),
                cancelMsg: '고객요청',
                partialCancelCode: data.workBooks.length === selectWorkBookList.length ? false : true,
            };
            await axiosInstance
                .put(`/api/${memberInfo?.memberRole}/payment/cancel/v1`, requestData)
                .then((response) => {
                    if (response.data.result) {
                        oneButtonOpenModal(
                            () => {
                                cancelButton();
                            },
                            '',
                            '결제 취소가 완료되었습니다. 취소 접수 시, 은행, 카드사 사정에 따라 최종 환불 처리는 취소 완료 이후 2~3일 정도 소요될 수 있습니다.',
                            '확인'
                        );
                    } else {
                        switch (response.data.message) {
                            case 'CANCEL_AMT_EXCEEDED_ORIGINAL_PRICE':
                            case 'CANCEL_AMT_EXCEEDED_PAY_AMT':
                                return oneButtonOpenModal(
                                    () => {},
                                    '',
                                    '결제 취소 가능한 금액을 초과하였습니다. 금액을 다시 한번 확인 후 재 입력해주세요.',
                                    '확인'
                                );
                        }
                    }
                });
        }
    };

    if (isLoading) {
        return <div>loading</div>;
    }

    // 모달창 종료
    const cancelButton = () => {
        paymentPartCancelOpenModal(false);
        paymentDetailOpenModal(false);
    };

    // 선택한 프로그램체크
    const hasCommonId = (workBookName: string) =>
        selectWorkBookList.some((comparisonItem) => comparisonItem.workBookName === workBookName);

    // 프로그램 선택(중복X)
    const addWorkbook = (newWorkbook: SelectWorkBook) => {
        const isDuplicate = selectWorkBookList.some((workBook) => workBook.registerId === newWorkbook.registerId);

        if (!isDuplicate) {
            setSelectWorkBookList([...selectWorkBookList, newWorkbook]);
        } else {
            const workBooksFilter = selectWorkBookList.filter(
                (workBook) => workBook.registerId !== newWorkbook.registerId
            );
            if (workBooksFilter.length === 0) {
                setSelectWorkBookList([
                    {
                        registerId: 0,
                        paymentId: 0,
                        workBookName: '',
                        remainingTimes: -1,
                    },
                ]);
            } else {
                setSelectWorkBookList(workBooksFilter);
            }
        }
    };

    // 총 취소금액 입력(숫자만)
    const handleInputChange = (e: any) => {
        // 입력값에서 숫자만 허용
        const inputValue = e.target.value.replace(/[^0-9]/g, '');
        setCancelPrice(inputValue); // 숫자로만 설정
    };

    const ProgramListRender = () => {
        return (
            <div className={styles.program_list_container}>
                {data.workBooks.map((item: SelectWorkBook, index: number) => (
                    <button className={styles.item} onClick={() => addWorkbook(item)}>
                        <SquareCheckBox bool={hasCommonId(item.workBookName)} size="1.5rem" />
                        <span
                            className="text_16_NotoSansKR_Medium"
                            style={{
                                marginLeft: '0.75rem',
                            }}
                        >
                            {item.workBookName}
                        </span>
                        <span
                            className="text_16_NotoSansKR_Medium"
                            style={{
                                marginLeft: '0.5rem',
                                color: '#FF5833',
                            }}
                        >
                            (남은횟수: {item.remainingTimes}회)
                        </span>
                    </button>
                ))}
            </div>
        );
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={paymentPartCancelVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <div className={styles.title_container}>
                    <span className="text_22_NotoSansKR_Bold">취소할 프로그램 선택</span>
                    <button onClick={() => paymentPartCancelOpenModal(false)}>
                        <Icon_x />
                    </button>
                </div>
                <ProgramListRender />
                <div className={styles.total_cancel_price_container}>
                    <span
                        className="text_16_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        총 취소금액 입력
                    </span>
                    <input
                        className={styles.input}
                        value={cancelPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={styles.button_container}>
                    <button onClick={cancelButton} className={`${styles.cancel_button} text_16_NotoSansKR_Bold`}>
                        취소
                    </button>
                    <button
                        onClick={paymentCancel}
                        className={`${styles.save_button} text_16_NotoSansKR_Bold`}
                        style={{
                            color: '#FFF',
                        }}
                    >
                        결제취소 완료
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};
