import { ReactComponent as Icon_search } from '../../../../../assets/therapist/icon_search.svg';
import styles from './css/PaymentDetailsComponent.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { updateIsSelectedOfficial } from '../../../../../features/authorityReducer';
import moment from 'moment';

import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { EmployeeList } from '../../authority/ts/AuthorityApi';
import { encryptData } from '../../../../../utils/croptojs';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';
import { updatePaymentId } from '../../../../../features/paymentDetailModalReducer';
import { updateIsSelectedPayStatus, updateSelectOrgan } from '../../../../../features/paymentDetailsReducer';

interface OrganList {
    index: number;
    companyId: number;
    branchName: string;
}

interface EmployeeData {
    memberId: number;
    memberName: string;
    memberRole: string;
    branches: {
        companyId: number;
        branchName: string;
    }[];
    modDate: string;
}

export const TitleRender = ({ list }: { list: any }) => {
    const dispatch = useDispatch();
    const { isSelectedTitle } = useSelector((state: RootState) => state.paymentDetails);

    return (
        <div className={styles.middle_title_container}>
            {list.branchCategories.map(
                (item: { companyId: number; branchName: string; count: number }, index: number) => (
                    <button
                        key={index.toString()}
                        onClick={() =>
                            dispatch(updateSelectOrgan({ companyId: item.companyId, branchName: item.branchName }))
                        }
                        className={`${styles.middle_title} ${
                            isSelectedTitle === item.branchName ? styles.activate : styles.deactivate
                        } ${
                            isSelectedTitle === item.branchName
                                ? 'text_18_NotoSansKR_Bold'
                                : 'text_18_NotoSansKR_Medium'
                        }`}
                    >
                        {item.branchName}
                        <span>({item.count})</span>
                    </button>
                )
            )}
        </div>
    );
};

export const SearchContainer = () => {
    const dispatch = useDispatch();
    const { payStatusList, isSelectedPayStatus } = useSelector((state: RootState) => state.paymentDetails);

    return (
        <div className={styles.search_container}>
            <div className={styles.input_container}>
                <input className={`${styles.input} text_16_NotoSansKR_Regular`} placeholder="검색어 입력" />
                <div className={styles.icon_search}>
                    <Icon_search width={'1.5rem'} height={'1.5rem'} />
                </div>
            </div>
            <div className={styles.filter_button_container}>
                {payStatusList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => dispatch(updateIsSelectedPayStatus(item.type))}
                        className={`${styles.filter_button} ${
                            isSelectedPayStatus === item.type ? styles.activate : styles.deactivate
                        } ${
                            isSelectedPayStatus === item.type ? 'text_16_NotoSansKR_Bold' : 'text_16_NotoSansKR_Medium'
                        } `}
                    >
                        {item.title}
                    </button>
                ))}
            </div>
        </div>
    );
};

export const PaymentRender = ({ list, refetch }: { list: any; refetch: Function }) => {
    const dispatch = useDispatch();
    const { organVisible, chartTitleList, roleVisible, employeeSettingVisible, isSelectedPayStatus } = useSelector(
        (state: RootState) => state.paymentDetails
    );

    const officialMemberListFilter = () => {
        let filterList = list.payments.content;
        // if (isSelectedTitle.branchName !== '모든 기관') {
        //     filterList = officialMemberList.filter((item) => item.organ === isSelectedTitle.branchName);
        // }
        if (isSelectedPayStatus !== -1) {
            filterList = list.payments.content.filter(
                (item: { payStatus: number }) => item.payStatus === isSelectedPayStatus
            );
        }
        return filterList;
    };

    const chartTitleStyles = (index: number) => {
        switch (index) {
            case 0:
                return styles.item_pay_status;
            case 1:
                return styles.item_order_number;
            case 2:
                return styles.item_program_type;
            case 3:
                return styles.item_buyer_name;
            case 4:
                return styles.item_payment_method;
            case 5:
                return styles.item_total_amt;
            case 6:
                return styles.item_payment_date;
            case 7:
                return styles.item_payment_detail_button;
        }
    };

    const PayStatus = ({ status }: { status: number }) => {
        switch (status) {
            case 1:
                return <div className={`${styles.item_pay_status_item} ${styles.activate}`}>결제완료</div>;
            case 2:
                return <div className={`${styles.item_pay_status_item} ${styles.deactivate}`}>전체취소</div>;
            case 3:
                return <div className={`${styles.item_pay_status_item} ${styles.deactivate}`}>부분취소</div>;
            default:
                return null;
        }
    };

    return (
        <>
            <div className={styles.chart_title_container}>
                {chartTitleList.map((item, index) => (
                    <div
                        key={index.toString()}
                        className={`${chartTitleStyles(index)}  text_16_NotoSansKR_Medium`}
                        style={{
                            color: '#626466',
                        }}
                    >
                        {item}
                    </div>
                ))}
            </div>
            {officialMemberListFilter().map((item: any, index: number) => (
                <div
                    key={index.toString()}
                    className={`${styles.chart_content_container} ${
                        index === list.payments.content.length - 1 ? null : styles.border_bottom
                    }`}
                >
                    <div className={styles.item_pay_status}>
                        <PayStatus status={item.payStatus} />
                    </div>
                    <div className={styles.item_order_number}>
                        <span className="text_16_NotoSansKR_Medium">{item.orderNumber}</span>
                    </div>
                    <div className={styles.item_program_type}>
                        <span className="text_16_NotoSansKR_Medium">{item.goodsName}</span>
                    </div>
                    <div className={styles.item_buyer_name}>
                        <span className="text_16_NotoSansKR_Medium">{item.buyerName}</span>
                    </div>
                    <div className={styles.item_payment_method}>
                        <span className="text_16_NotoSansKR_Medium">{item.cardInfo.cardName}</span>
                    </div>
                    <div className={styles.item_total_amt}>
                        <span className="text_16_NotoSansKR_Medium">{item.totalAmt}원</span>
                    </div>
                    <div className={styles.item_payment_date}>
                        <span className="text_16_Inter_Medium">
                            {moment(item.modDate).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    </div>
                    <PaymentDetailButton memberId={item.memberId} paymentId={item.paymentId} />
                </div>
            ))}
        </>
    );
};

export const PaymentDetailButton = ({ memberId, paymentId }: { memberId: number; paymentId: number }) => {
    const dispatch = useDispatch();
    const { paymentDetailOpenModal } = useContext(ModalContext);

    const paymentDetail = () => {
        dispatch(updatePaymentId(paymentId));
        paymentDetailOpenModal(true);
    };

    return memberId ? (
        <button onClick={paymentDetail} className={`${styles.payment_detail_button} text_16_NotoSansKR_Bold`}>
            결제 상세정보
        </button>
    ) : (
        <div className={`${styles.payment_detail_button} text_16_NotoSansKR_Bold`}></div>
    );
};
