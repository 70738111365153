import moment from 'moment';
import styles from './css/AssigendProfile.module.css';
import { ReactComponent as Icon_dot_menu } from '../../../../../assets/public/icon_dot_menu.svg';
import { useContext, useState } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';
import { SelectAssignedTherapist } from '../../assignedMember/AssignedMemberComponent.tsx/AssignedMemberComponent';
import { ReactComponent as Icon_organ } from '../../../../../assets/public/icon_organ.svg';
import { ReactComponent as Icon_profile } from '../../../../../assets/member/profile/icon_profile.svg';
import { ReactComponent as Icon_list } from '../../../../../assets/member/profile/icon_list.svg';
import { ReactComponent as Icon_session } from '../../../../../assets/face/icon_session.svg';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { encryptData } from '../../../../../utils/croptojs';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import { updateSelectProgram, updateSelectTherapist } from '../../../../../features/selectTherapistModalReducer';
import { useDispatch } from 'react-redux';

export const AssigendProfile = ({ pageData, isSessionSelectedMenu }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { additionalInfoOpenModal, selectTherapistOpenModal, additionalInfoVisible, selectTherapistVisible } =
        useContext(ModalContext);

    const memberRole = getMemberInfo()?.memberRole;
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [memo, setMemo] = useState<string>(pageData.memberMemo);
    const [editMemoVisible, setEditMemoVisible] = useState<boolean>(pageData.memberMemo !== '' ? true : false);

    const moveToWorkbook = () => {
        const index = pageData.workBookList.findIndex(
            (item: { workBookName: string }) => item.workBookName === '대면 CBT' || item.workBookName === '비대면 CBT'
        );

        if (index !== -1) {
            const params = new URLSearchParams();
            params.append('page', pageData.cbtInfo.sessionNo.toString());
            params.append('session', encryptData(pageData.cbtInfo.registerSessionId));
            params.append('program', encryptData(pageData.workBookList[index].registerId));

            switch (pageData.workBookList[index].workBookName) {
                case '대면 CBT':
                    navigate(`/yd/official/assignedMember/patientCBT/face/session?${params}`);
                    break;
                case '비대면 CBT':
                    navigate(`/yd/official/assignedMember/patientCBT/nonface/session?${params}`);
                    break;
            }
        }
    };

    const saveMemo = async () => {
        await axiosInstance
            .put(`/api/${memberRole}/member/memo/v1`, {
                memberId: pageData.memberId,
                memo: memo,
            })
            .then((response) => {
                setEditMemoVisible(true);
            });
    };

    const ChangeTherapistButton = () => {
        if (memberRole !== 'therapist') {
            return (
                <button
                    onClick={() => {
                        dispatch(
                            updateSelectProgram({
                                registerId: isSessionSelectedMenu.registerId,
                                type: isSessionSelectedMenu.workBookName,
                            })
                        );
                        dispatch(updateSelectTherapist({ therapistId: 0, therapist: '' }));
                        selectTherapistOpenModal(!selectTherapistVisible);
                    }}
                    className={`${styles.change_therapist_button} text_16_NotoSansKR_Bold`}
                >
                    담당 상담사 변경
                </button>
            );
        } else {
            return <div></div>;
        }
    };

    const CBTButton = () => {
        let message: string = '';
        if (pageData) {
            pageData.workBookNameList.map((item: string, index: number) => {
                if (item === '대면 CBT') {
                    return (message = '대면');
                } else if (item === '비대면 CBT') {
                    return (message = '비대면');
                }
            });
            return (
                <button
                    onClick={moveToWorkbook}
                    className={`${styles.move_to_workbook_button} text_16_NotoSansKR_Bold`}
                >
                    <Icon_session className={styles.icon_session} />
                    {message} 워크북 확인
                </button>
            );
        } else {
            return <div></div>;
        }
    };

    return (
        <div className={styles.profile_container}>
            <div
                style={{
                    display: 'flex',
                    width: '6.875rem',
                    height: '6.875rem',
                    borderRadius: '100px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '0.5rem',
                    color: '#F07F34',
                    backgroundColor: '#FEEFE4',
                }}
                className="text_40_NotoSansKR_Bold"
            >
                {pageData.memberName[0]}
            </div>
            <div className={styles.profile_content_container}>
                <div className={styles.profile_content}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span className="text_32_NotoSansKR_Bold">{pageData.memberName}</span>
                        <div className={styles.dot_menu_container}>
                            <ChangeTherapistButton />
                            <CBTButton />
                            <button onClick={() => setMenuVisible(!menuVisible)}>
                                <Icon_dot_menu className={styles.icon_dot_menu} />
                            </button>
                            {menuVisible && (
                                <div className={styles.menu_container}>
                                    <button
                                        onClick={() => {
                                            setMenuVisible(!menuVisible);
                                            additionalInfoOpenModal(!additionalInfoVisible);
                                        }}
                                        className={`${styles.menu_button} text_16_NotoSansKR_Medium`}
                                    >
                                        부가정보
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div
                            className={`${styles.profile_chart_number} text_16_NotoSansKR_Medium`}
                            style={{
                                color: '#626466',
                            }}
                        >
                            차트번호:{' '}
                            <span
                                className="text_16_Inter_Medium"
                                style={{
                                    color: '#626466',
                                }}
                            >
                                {pageData.memberId}
                            </span>
                        </div>
                    </div>

                    <div
                        className={`${styles.profile_join_date} text_14_NotoSansKR_Regular`}
                        style={{
                            color: '#9D9FA2',
                        }}
                    >
                        가입일:{' '}
                        <span
                            className="text_14_Inter_Regular"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            {moment(pageData.regDate).format('YYYY-MM-DD')}
                        </span>
                    </div>
                </div>
                <div className={styles.current_program_container}>
                    <div className={`${styles.current_program} text_16_NotoSansKR_Medium`}>
                        <Icon_list
                            style={{
                                width: '1rem',
                                height: '1rem',
                            }}
                        />
                        진행중인 프로그램:
                        <span
                            style={{
                                color: '#020202',
                            }}
                        >
                            {pageData.goodsName}
                        </span>
                    </div>
                    <div className={`${styles.counsel_content} text_16_NotoSansKR_Medium`}>
                        <Icon_profile
                            style={{
                                width: '1rem',
                                height: '1rem',
                                marginRight: '0.5rem',
                            }}
                        />
                        담당 상담사:{' '}
                        <span
                            style={{
                                color: '#020202',
                            }}
                        >
                            {pageData.therapistList.length === 0 ? (
                                <SelectAssignedTherapist
                                    selectTherapistVisible={selectTherapistVisible}
                                    selectTherapistOpenModal={(visible: boolean) => {
                                        selectTherapistOpenModal(visible);
                                    }}
                                />
                            ) : (
                                pageData.therapistList.join(', ')
                            )}
                        </span>
                        <div
                            style={{
                                width: '0.0625rem',
                                height: '0.875rem',
                                margin: '0 0.75rem 0 1.25rem',
                                background: '#E3E5EA',
                            }}
                        ></div>
                        <Icon_organ
                            style={{
                                width: '1rem',
                                height: '1rem',
                                marginRight: '0.5rem',
                            }}
                        />
                        상담기관:
                        <span
                            style={{
                                color: '#020202',
                            }}
                        >
                            {pageData.branch[0].branchName}
                        </span>
                    </div>
                </div>
                <div className={styles.memo_container}>
                    <span
                        className="text_18_NotoSansKR_Bold"
                        style={{
                            color: '#626466',
                        }}
                    >
                        메모
                    </span>
                    <textarea
                        className={`${styles.memo} text_18_NotoSansKR_Regular`}
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                    />
                    {editMemoVisible ? (
                        <div className={styles.memo_button_container}>
                            <button
                                onClick={() => setEditMemoVisible(false)}
                                className={`${styles.memo_save_button} text_16_NotoSansKR_Bold`}
                            >
                                수정
                            </button>
                        </div>
                    ) : (
                        <div className={styles.memo_button_container}>
                            <button className={`${styles.memo_cancel_button} text_16_NotoSansKR_Bold`}>취소</button>
                            <button onClick={saveMemo} className={`${styles.memo_save_button} text_16_NotoSansKR_Bold`}>
                                저장
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
