import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent, updateEventHoverVisible } from '../../../../../features/reservationReducer';
import { RootState } from '../../../../../store';

import { ReactComponent as Icon_arrowleft } from '../../../../../assets/public/icon_arrowleft.svg';
import { ReactComponent as Icon_arrowright } from '../../../../../assets/member/signup/icon_arrowright.svg';

import styles from './css/Calendar.module.css';
import axiosInstance from '../../../../../utils/AxiosInstanceJava';
import moment from 'moment';
import { ModalContext } from '../../../../../context/ModalContext';
import {
    updateCalendarId,
    updateReservationDate,
    updateReservationStatus,
    updateReserveTime,
    updateScheduleMoreVisible,
    updateSelectPatient,
    updateSelectProgarm,
} from '../../../../../features/scheduleModalReducer';
import { ScheduleMore } from '../../../../../component/ScheduleMore';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { ScheduleHover } from './CalendarComponent';

interface ScheduleEvent {
    memberId: number;
    memberName: string;
    registerSessionIds: number[];
    workBookName: string;
    sessionProgresses: number[];
    reservationDate: string;
    startTime: string;
    endTime: string;
}

interface ExtendedEvent extends ScheduleEvent {
    position: number;
}
interface Schedule {
    date: string;
    time: string;
    description: string;
}

export const Calendar = () => {
    const dispatch = useDispatch();
    const { eventHover, events, selectFilter } = useSelector((state: RootState) => state.events);
    const { scheduleMoreVisible } = useSelector((state: RootState) => state.scheduleModal);
    const { scheduleOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isSelectedTimeframe, setIsSelectedTimeframe] = useState('월');
    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
    const timeframe = ['일', '주', '월'];

    const today = new Date();
    const isToday = (day: Date) => day && day.toDateString() === today.toDateString();

    useEffect(() => {
        getReserveStatus();
    }, []);

    const getReserveStatus = async () => {
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/reserve/members/v1`, {
                yearMonth: moment(today).format('YYYY-MM'),
                companyId: memberInfo?.loginBranch.companyId,
            })
            .then((response) => {
                dispatch(updateEvent(response.data));
            });
    };

    const daysInMonth = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        return new Date(year, month + 1, 0).getDate(); // 해당 월의 마지막 날
    };
    const firstDayOfMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1).getDay(); // 해당 월의 첫 번째 날의 요일
    };

    const generateCalendar = () => {
        const days: any = [];
        const totalDays = daysInMonth(currentDate);
        const firstDay = firstDayOfMonth(currentDate);

        // 첫 번째 날 이전의 빈 공간 추가
        for (let i = 0; i < firstDay; i++) {
            days.push(null); // 빈 공간
        }

        for (let i = 1; i <= totalDays; i++) {
            days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
        }
        return days;
    };

    const generateWeek = () => {
        const days = [];
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // 주의 시작일(일요일)

        for (let i = 0; i < 7; i++) {
            days.push(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i));
        }
        return days;
    };

    const calendarDays = isSelectedTimeframe === '월' ? generateCalendar() : generateWeek();

    const resetMonth = () => {
        setCurrentDate(new Date());
    };

    const goToPreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
    };

    const goToNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
    };

    const goToPreviousWeek = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7));
    };

    const goToNextWeek = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7));
    };

    const goToPreviousDay = () => {
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 1));
    };

    const goToNextDay = () => {
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1));
    };

    const updateEventHover = (type: string, calendarId: number, memo: string) => {
        if (type === 'enter') {
            dispatch(updateEventHoverVisible({ visible: true, calendarId, memo }));
        } else if (type === 'leave') {
            dispatch(updateEventHoverVisible({ visible: false, calendarId, memo }));
        }
    };

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [schedules, setSchedules] = useState<Schedule[]>([]);

    // const hexToRgba = (hex, alpha) => {
    //     let r = 0, g = 0, b = 0;

    //     // 3자리 헥사코드 (#RGB)
    //     if (hex.length === 4) {
    //         r = parseInt(hex[1] + hex[1], 16);
    //         g = parseInt(hex[2] + hex[2], 16);
    //         b = parseInt(hex[3] + hex[3], 16);
    //     }
    //     // 6자리 헥사코드 (#RRGGBB)
    //     else if (hex.length === 7) {
    //         r = parseInt(hex[1] + hex[2], 16);
    //         g = parseInt(hex[3] + hex[4], 16);
    //         b = parseInt(hex[5] + hex[6], 16);
    //     }

    //     return `rgba(${r}, ${g}, ${b}, ${alpha})`; // alpha 값을 추가
    // };

    // const colorFilter = (title: string) => {
    //     switch (title) {
    //         case '대면 CBT':
    //             return hexToRgba('#78BF79', 0.5); // 50% 투명도
    //         case '이완치료':
    //             return hexToRgba('#DCBB67', 0.5); // 50% 투명도
    //         case 'TMS':
    //             return hexToRgba('#7D6ADE', 0.5); // 50% 투명도
    //         case '심리상담':
    //             return hexToRgba('#DC63C2', 0.5); // 50% 투명도
    //         default:
    //             return 'transparent'; // 기본값 설정
    //     }
    // };

    const colorFilter = (title: string, reservationStatus: number) => {
        if (reservationStatus === 1) {
            return '#D2D5D9';
        } else if (reservationStatus === 2) {
            return '#626466';
        } else {
            switch (title) {
                case '대면 CBT (유형1)':
                    return '#78BF79';
                case '대면 CBT (유형2)':
                    return '#4D717F';
                case '이완치료':
                    return '#DCBB67';
                case 'TMS':
                    return '#7D6ADE';
                case '심리상담':
                    return '#DC63C2';
            }
        }
    };

    const reserveDetail = (item: {
        memberId: number;
        memberName: string;
        registerSessionIds: number[];
        workBookName: string;
        therapistNames: string[];
        sessionProgresses: number[];
        calendarId: number;
        reservationStatus: number;
        startTime: string;
        endTime: string;
    }) => {
        // 프로그램 관련정보
        // dispatch(
        //     updateSelectProgarm({
        //         registerSessionIds: item.registerSessionIds,
        //         workBookNames: item.workBookNames,
        //         therapistNames: item.therapistNames,
        //         sessionProgresses: item.sessionProgresses,
        //         calendarId: item.calendarId,
        //     })
        // );
        // // 내담자 정보
        // dispatch(updateSelectPatient({ memberId: item.memberId, memberName: item.memberName }));
        // dispatch(updateReservationStatus(item.reservationStatus));
        // dispatch(
        //     updateReserveTime({
        //         startTime: item.startTime,
        //         originStartTime: convertTo12HourFormat(item.startTime),
        //         endTime: item.endTime,
        //         originEndTime: convertTo12HourFormat(item.endTime),
        //     })
        // );
        dispatch(updateCalendarId(item.calendarId));
        scheduleOpenModal(true);
    };

    const weekDetail = (quarterStartTime: string, date: string) => {
        dispatch(updateReservationDate(moment(date).format('YYYY-MM-DD')));
        dispatch(
            updateReserveTime({
                startTime: quarterStartTime,
                originStartTime: convertTo12HourFormat(quarterStartTime),
                endTime: '',
                originEndTime: '',
            })
        );
        scheduleOpenModal(true);
    };

    const dayDetail = (quarterStartTime: string) => {
        dispatch(updateReservationDate(moment(selectedDate).format('YYYY-MM-DD')));
        dispatch(
            updateReserveTime({
                startTime: quarterStartTime,
                originStartTime: convertTo12HourFormat(quarterStartTime),
                endTime: '',
                originEndTime: '',
            })
        );
        scheduleOpenModal(true);
    };

    // 시간 표기 변환
    const convertTo12HourFormat = (time24: string) => {
        // 입력된 시간 문자열을 ":"로 분리
        const [hour24, minutes] = time24.split(':');

        // 24시간 형식을 정수로 변환
        let hour = parseInt(hour24, 10);
        let period = '오전'; // 기본적으로 오전으로 설정

        // 12시간 형식으로 변환
        if (hour >= 12) {
            period = '오후';
            if (hour > 12) {
                hour -= 12; // 12보다 큰 경우 12를 빼줌
            }
        } else if (hour === 0) {
            hour = 12; // 0시를 12시로 변환
        }

        // HH:mm 형식으로 변환
        const formattedHour = hour.toString(); // 시간은 2자리로 표현할 필요 없음
        const formattedMinutes = minutes.padStart(2, '0'); // 분은 항상 2자리로 표현

        // 변환된 시간 반환
        return `${period} ${formattedHour}:${formattedMinutes}`;
    };
    // 월간 뷰로 스케줄 표시
    const RenderMonthSchedule = () => {
        return (
            <>
                <div className={styles.month_weekdays}>
                    {weekdays.map((day, index) => (
                        <div key={index} className={`${styles.weekday} text_16_NotoSansKR_Medium`}>
                            {day}
                        </div>
                    ))}
                </div>
                <div className={styles.days}>
                    {calendarDays.map((day: any, index: number) => {
                        const filterReservationList =
                            day &&
                            events.reserves.filter((event) => {
                                const eventDate = new Date(event.reservationDate).toDateString();
                                const hasMatchingWorkBook = selectFilter.includes(event.workBookName);
                                return eventDate === day.toDateString() && hasMatchingWorkBook;
                            });

                        return (
                            <div
                                key={index}
                                className={styles.day}
                                style={{
                                    borderWidth:
                                        index + 1 !== calendarDays.length
                                            ? (index + 1) % 7 === 0 && index + 1 !== calendarDays.length
                                                ? '0px 0px 1px 0px'
                                                : index > 7 && index < 28
                                                ? '0px 1px 1px 0px'
                                                : index + 1 > 28
                                                ? '0px 1px 0px 0px'
                                                : '0px 1px 1px 0px'
                                            : '0px',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(updateReservationDate(moment(day).format('YYYY-MM-DD')));
                                    scheduleOpenModal(true);
                                }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedDate(day);
                                        setIsSelectedTimeframe('일');
                                    }}
                                    className={`${
                                        isToday(day) ? styles.today_child : styles.day_child
                                    } text_12_Inter_Medium`}
                                    style={{
                                        color: isToday(day) ? '#FFF' : '#020202',
                                    }}
                                >
                                    {day ? day.getDate() : ''}
                                </div>

                                {day &&
                                    filterReservationList.slice(0, 3).map((event: any, index: number) => {
                                        return (
                                            <>
                                                <button
                                                    key={index}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        dispatch(
                                                            updateReservationDate(moment(day).format('YYYY-MM-DD'))
                                                        );
                                                        reserveDetail(event);
                                                    }}
                                                    onMouseEnter={() => {
                                                        if (!eventHover.visible)
                                                            updateEventHover('enter', event.calendarId, event.memo);
                                                    }}
                                                    onMouseLeave={() => {
                                                        if (eventHover.visible)
                                                            updateEventHover('leave', event.calendarId, event.memo);
                                                    }}
                                                    className={`${styles.event} text_12_NotoSansKR_Medium`}
                                                    style={{
                                                        backgroundColor: colorFilter(
                                                            event.workBookName,
                                                            event.reservationStatus
                                                        ),
                                                    }}
                                                >
                                                    <span className={`${styles.title} text_12_NotoSansKR_Medium`}>
                                                        {event.memberId +
                                                            '/' +
                                                            event.memberName +
                                                            '/' +
                                                            event.workBookName +
                                                            '/' +
                                                            event.therapistNames.join(',') +
                                                            '/' +
                                                            event.sessionProgress +
                                                            '/'}
                                                    </span>
                                                    {eventHover.visible &&
                                                        eventHover.calendarId === event.calendarId && <ScheduleHover />}
                                                </button>
                                            </>
                                        );
                                    })}

                                {day && filterReservationList.length > 4 && (
                                    <div className={styles.schedule_button_container}>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(
                                                    updateScheduleMoreVisible({
                                                        day: day.toDateString(),
                                                        bool: true,
                                                    })
                                                );
                                            }}
                                            className={styles.more_schedule_button}
                                        >
                                            <span className="text_12_Inter_Medium" style={{ color: '#3A5074' }}>
                                                +{filterReservationList.length - 3}
                                                <span
                                                    className="text_12_NotoSansKR_Medium"
                                                    style={{ color: '#3A5074' }}
                                                >
                                                    건 더보기
                                                </span>
                                            </span>
                                            <Icon_arrowright className={styles.icon_arrowright} />
                                        </button>
                                    </div>
                                )}
                                {day && scheduleMoreVisible.bool && scheduleMoreVisible.day === day.toDateString() && (
                                    <ScheduleMore
                                        reservationList={filterReservationList}
                                        reserveDetail={reserveDetail}
                                        colorFilter={colorFilter}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    // 주간 뷰로 스케줄 표시
    const RenderWeeklySchedule = () => {
        const weekStart = new Date(selectedDate);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());

        const timeSlots = Array.from({ length: 24 }, (_, index) => {
            const hour = (index + 1) % 12 === 0 ? 12 : (index + 1) % 12;
            const period = index < 12 ? '오전' : '오후';
            return index === 23 ? '' : `${period}${hour}시`;
        });

        const startTimes = Array.from({ length: 96 }, (_, index) => {
            const hour = Math.floor(index / 4);
            const minutes = (index % 4) * 15;
            return `${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}`;
        });

        const heightAdjustment = (schedule: any) => {
            const toMinutes = (time: string) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            const startMinutes = toMinutes(schedule.startTime);
            const endMinutes = toMinutes(schedule.endTime);
            const startSlotIndex = Math.floor(startMinutes / 15);
            const endSlotIndex = Math.floor(endMinutes / 15);
            const difference = endSlotIndex - startSlotIndex;

            return difference * (3.75 / 4);
        };

        const calculatePositions = (schedules: any[]) => {
            const positions = new Map<number, number>();
            const maxOverlap = schedules.length; // 최대 겹침 수
            return schedules.map((event) => {
                const eventCopy = { ...event };
                let position = 0;

                // 겹치지 않는 위치 찾기
                while (positions.has(position) && positions.get(position)! > eventCopy.startTime) {
                    position++;
                }

                positions.set(position, eventCopy.endTime);
                eventCopy.position = position;
                eventCopy.width = 100 / maxOverlap; // 너비 조정

                return eventCopy;
            });
        };

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className={styles.weekdays}>
                    {weekdays.map((day, index) => (
                        <div key={index} className={styles.weekday}>
                            <span className="text_16_NotoSansKR_Medium">{day}</span>
                            <span className="text_24_Inter_Medium">{calendarDays[index].getDate()}</span>
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            paddingTop: '0.5%',
                            width: '4.7%',
                        }}
                    >
                        {timeSlots.map((time, index) => (
                            <div
                                key={index.toString()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    whiteSpace: 'nowrap',
                                    height: '3.75rem',
                                }}
                            >
                                <span className="text_12_NotoSansKR_Medium" style={{ color: '#626466' }}>
                                    {time}
                                </span>
                            </div>
                        ))}
                    </div>
                    {calendarDays.map((date: any, index: number) => {
                        const currentSchedules = events.reserves.filter((schedule) => {
                            const scheduleDate = new Date(schedule.reservationDate).toDateString();
                            const hasMatchingWorkBook = selectFilter.includes(schedule.workBookName);
                            return scheduleDate === date.toDateString() && hasMatchingWorkBook;
                        });
                        const positionedSchedules = calculatePositions(currentSchedules);

                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '13.8%' }} key={index}>
                                {timeSlots.map((_, timeIndex) => {
                                    const currentTimeSlot = startTimes[timeIndex * 4];

                                    return (
                                        <div
                                            key={timeIndex.toString()}
                                            style={{
                                                position: 'relative',
                                                height: '3.75rem',
                                                border: '0.0625rem solid #eee',
                                                boxSizing: 'border-box',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {Array.from({ length: 4 }).map((_, quarterIndex) => {
                                                const quarterStartTime = startTimes[timeIndex * 4 + quarterIndex];
                                                const quarterEndTime = startTimes[timeIndex * 4 + quarterIndex + 1];

                                                return (
                                                    <div
                                                        key={quarterIndex.toString()}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            weekDetail(quarterStartTime, date);
                                                        }}
                                                        style={{
                                                            flex: 1,
                                                            display: 'flex',
                                                            position: 'relative',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {positionedSchedules.map((event: any, idx) => {
                                                            const uniqueId = event.registerSessionIds;
                                                            const scheduleHeight = heightAdjustment(event);
                                                            if (
                                                                quarterStartTime <= event.startTime &&
                                                                quarterEndTime > event.startTime
                                                            ) {
                                                                const leftPosition = `${
                                                                    event.position * event.width + idx
                                                                }%`;

                                                                const zIndex = event.position + 1;

                                                                return (
                                                                    <>
                                                                        <div
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                dispatch(
                                                                                    updateReservationDate(
                                                                                        moment(date).format(
                                                                                            'YYYY-MM-DD'
                                                                                        )
                                                                                    )
                                                                                );
                                                                                reserveDetail(event);
                                                                            }}
                                                                            key={uniqueId}
                                                                            className={`${styles.event}`}
                                                                            onMouseEnter={() => {
                                                                                if (!eventHover.visible)
                                                                                    updateEventHover(
                                                                                        'enter',
                                                                                        event.calendarId,
                                                                                        event.memo
                                                                                    );
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                if (eventHover.visible)
                                                                                    updateEventHover(
                                                                                        'leave',
                                                                                        event.calendarId,
                                                                                        event.memo
                                                                                    );
                                                                            }}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                display: 'flex',
                                                                                minWidth: '30%',
                                                                                width: `${event.width}%`,
                                                                                height: `auto`,
                                                                                flexDirection: 'column',
                                                                                padding: '0.5rem',
                                                                                top: '0',
                                                                                left: leftPosition,
                                                                                zIndex: zIndex,
                                                                                alignItems: 'center',
                                                                                backgroundColor: colorFilter(
                                                                                    event.workBookName,
                                                                                    event.reservationStatus
                                                                                ),
                                                                                border: '0.0625rem solid #fff',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={`text_12_NotoSansKR_Medium`}
                                                                                style={{
                                                                                    width: '90%',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    color: '#FFF',
                                                                                }}
                                                                            >
                                                                                {event.memberId}/{event.memberName}/
                                                                                {event.workBookName}/
                                                                                {event.sessionProgresses}/{event.memo}
                                                                                <div
                                                                                    className={`${styles.time} text_12_NotoSansKR_Medium`}
                                                                                >
                                                                                    {event.startTime} - {event.endTime}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {eventHover.visible &&
                                                                            eventHover.calendarId ===
                                                                                event.calendarId && <ScheduleHover />}
                                                                    </>
                                                                );
                                                            }

                                                            return null;
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    // 일간
    const RenderDailySchedule = () => {
        const dayStart = new Date(selectedDate);
        const timeSlots = Array.from({ length: 24 }, (_, index) => {
            const hour = (index + 1) % 12 === 0 ? 12 : (index + 1) % 12;
            const period = index < 12 ? '오전' : '오후';
            return index === 23 ? '' : `${period}${hour}시`;
        });

        const startTimes = Array.from({ length: 96 }, (_, index) => {
            const hour = Math.floor(index / 4);
            const minutes = (index % 4) * 15;
            return `${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}`;
        });

        const heightAdjustment = (schedule: any) => {
            const toMinutes = (time: string) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            const startMinutes = toMinutes(schedule.startTime);
            const endMinutes = toMinutes(schedule.endTime);
            const startSlotIndex = Math.floor(startMinutes / 15);
            const endSlotIndex = Math.floor(endMinutes / 15);
            const difference = endSlotIndex - startSlotIndex;

            return difference * (3.75 / 4);
        };

        const calculatePositions = (schedules: any[]) => {
            const positions = new Map<number, number>();
            const overlapAllowance = 0.6; // 60% 겹침 허용

            return schedules.map((event) => {
                const eventCopy = { ...event };
                let position = 0;
                let isOverlapping = false;

                // 겹치지 않는 위치 찾기
                while (positions.has(position) && positions.get(position)! > eventCopy.startTime) {
                    position++;
                    isOverlapping = true;
                }

                positions.set(position, eventCopy.endTime);
                eventCopy.position = position;
                eventCopy.width = isOverlapping ? (100 / schedules.length) * (1 - overlapAllowance) : 100; // 겹치지 않으면 100%

                return eventCopy;
            });
        };

        const currentSchedules = events.reserves.filter((schedule) => {
            return new Date(schedule.reservationDate).toDateString() === dayStart.toDateString();
        });

        const positionedSchedules = calculatePositions(currentSchedules);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className={styles.weekdays}>
                    <div className={styles.weekday}>
                        <span className="text_16_NotoSansKR_Medium">{weekdays[selectedDate.getDay()]}</span>
                        <span className="text_24_Inter_Medium">{selectedDate.getDate()}</span>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            paddingTop: '0.5%',
                            width: '4.7%',
                        }}
                    >
                        {timeSlots.map((time, index) => (
                            <div
                                key={index.toString()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    whiteSpace: 'nowrap',
                                    height: '3.75rem',
                                }}
                            >
                                <span className="text_12_NotoSansKR_Medium" style={{ color: '#626466' }}>
                                    {time}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '95.3%' }}>
                        {timeSlots.map((_, timeIndex) => {
                            const currentTimeSlot = startTimes[timeIndex * 4];

                            return (
                                <div
                                    key={timeIndex.toString()}
                                    style={{
                                        position: 'relative',
                                        height: '3.75rem',
                                        border: '0.0625rem solid #eee',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {Array.from({ length: 4 }).map((_, quarterIndex) => {
                                        const quarterStartTime = startTimes[timeIndex * 4 + quarterIndex];
                                        const quarterEndTime = startTimes[timeIndex * 4 + quarterIndex + 1];

                                        return (
                                            <div
                                                key={quarterIndex.toString()}
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    position: 'relative',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {positionedSchedules.map((event: any, idx: number) => {
                                                    const uniqueId = event.registerSessionIds;
                                                    const scheduleHeight = heightAdjustment(event);

                                                    if (
                                                        quarterStartTime <= event.startTime &&
                                                        quarterEndTime > event.startTime
                                                    ) {
                                                        const leftPosition = `${event.position * event.width + idx}%`;
                                                        const zIndex = event.position + 1;

                                                        return (
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    dispatch(
                                                                        updateReservationDate(
                                                                            moment(selectedDate).format('YYYY-MM-DD')
                                                                        )
                                                                    );
                                                                    reserveDetail(event);
                                                                }}
                                                                key={uniqueId}
                                                                className={`${styles.event}`}
                                                                style={{
                                                                    position: 'absolute',
                                                                    display: 'flex',
                                                                    maxWidth: '12%',
                                                                    height: 'auto',
                                                                    flexDirection: 'column',
                                                                    padding: '0.5rem',
                                                                    top: '0',
                                                                    left: leftPosition,
                                                                    zIndex: zIndex,
                                                                    alignItems: 'center',
                                                                    backgroundColor: colorFilter(
                                                                        event.workBookName,
                                                                        event.reservationStatus
                                                                    ),
                                                                    border: '0.0625rem solid #fff',
                                                                }}
                                                            >
                                                                <div
                                                                    className={`${styles.title} text_12_NotoSansKR_Medium`}
                                                                >
                                                                    {event.memberId}/{event.memberName}/
                                                                    {event.workBookName}/{event.sessionProgresses}/
                                                                    {event.memo}
                                                                    <div
                                                                        className={`${styles.time} text_12_NotoSansKR_Medium`}
                                                                    >
                                                                        {event.startTime} - {event.endTime}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    return null;
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    return (
        events && (
            <div className={styles.calendar_container}>
                <div className={styles.calendar}>
                    <div className={styles.calendar_header}>
                        <div className={styles.navigation}>
                            <div className={styles.left_menu_container}>
                                <button
                                    className={`${styles.today_button} text_16_NotoSansKR_Bold `}
                                    onClick={resetMonth}
                                >
                                    오늘
                                </button>
                                <div className={styles.button_container}>
                                    <button
                                        className={styles.prev_button}
                                        onClick={() => {
                                            console.log(isSelectedTimeframe);
                                            isSelectedTimeframe === '월'
                                                ? goToPreviousMonth()
                                                : isSelectedTimeframe === '주'
                                                ? goToPreviousWeek()
                                                : goToPreviousDay();
                                        }}
                                    >
                                        <Icon_arrowleft className={styles.icon_arrowleft} />
                                    </button>
                                    <button
                                        className={styles.next_button}
                                        onClick={() =>
                                            isSelectedTimeframe === '월'
                                                ? goToNextMonth()
                                                : isSelectedTimeframe === '주'
                                                ? goToNextWeek()
                                                : goToNextDay()
                                        }
                                    >
                                        <Icon_arrowright className={styles.icon_arrowright} />
                                    </button>
                                </div>
                                <div className="text_24_NotoSansKR_Bold">
                                    <span className="text_24_Inter_Bold">{currentDate.getFullYear()}</span>년{' '}
                                    <span className="text_24_Inter_Bold">{currentDate.getMonth() + 1}</span>월
                                </div>
                            </div>

                            <div className={styles.timeframe}>
                                {timeframe.map((item, index) => (
                                    <button
                                        key={index.toString()}
                                        onClick={() => setIsSelectedTimeframe(item)}
                                        className={`${styles.timefram_button} ${
                                            isSelectedTimeframe === item ? styles.activate : styles.deactivate
                                        } ${
                                            isSelectedTimeframe === item
                                                ? 'text_16_NotoSansKR_Bold'
                                                : 'text_16_NotoSansKR_Medium'
                                        } `}
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {isSelectedTimeframe === '월' ? (
                        <RenderMonthSchedule />
                    ) : isSelectedTimeframe === '주' ? (
                        <RenderWeeklySchedule />
                    ) : (
                        <RenderDailySchedule />
                    )}
                </div>
            </div>
        )
    );
};
