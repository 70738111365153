import styled from 'styled-components';

import styles from './css/ScheduleMore.module.css';
import moment from 'moment';
import { ReactComponent as Icon_x } from '../assets/public/icon_x.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateScheduleMoreVisible } from '../features/scheduleModalReducer';

const ScheduleButton = styled.button`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: left;
    padding: 0.38rem 0.62rem;
    margin-bottom: 0.25rem;
    height: 1.875rem;
    width: 100%;
    border-radius: 0.3125rem;
`;

const ScheduleText = styled.span`
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
`;

export const ScheduleMore = ({ reservationList, reserveDetail, colorFilter }: any) => {
    const dispatch = useDispatch();
    const { scheduleMoreVisible } = useSelector((state: RootState) => state.scheduleModal);

    return (
        <div className={styles.schedule_more}>
            <div className={styles.header}>
                <span className="text_22_NotoSansKR_Bold">
                    {moment(reservationList[0].reservationDate).format('MM월 DD일')}
                </span>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                            updateScheduleMoreVisible({
                                day: '',
                                bool: !scheduleMoreVisible,
                            })
                        );
                    }}
                >
                    <Icon_x />
                </button>
            </div>
            <div className={styles.content_container}>
                {reservationList
                    .filter((_: any, index: number) => index > 2)
                    .map((event: any, index: number) => (
                        <ScheduleButton
                            key={index}
                            onClick={(e) => {
                                e.preventDefault();
                                reserveDetail(event);
                            }}
                            className={`text_12_NotoSansKR_Medium`}
                            style={{
                                backgroundColor: colorFilter(event.workBookName, event.reservationStatus),
                            }}
                        >
                            <ScheduleText className={`text_12_NotoSansKR_Medium`}>
                                {reservationList[index].memberId +
                                    '/' +
                                    reservationList[index].memberName +
                                    '/' +
                                    reservationList[index].workBookName +
                                    '/' +
                                    reservationList[index].sessionProgress}
                            </ScheduleText>
                        </ScheduleButton>
                    ))}
            </div>
        </div>
    );
};
