import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DisallowDuplicateSelection {
    patient: string;
    feedback: string;
    visible: boolean;
    feedbackCheck: boolean;
    therapistName: string;
}

export interface Fields {
    brain: DisallowDuplicateSelection[];
    changeBrain: DisallowDuplicateSelection[];
    desiredSymptomChange: DisallowDuplicateSelection[];
    psychologicalBarriers: DisallowDuplicateSelection[];
    myStrengths: DisallowDuplicateSelection[];
    therapyGoals: DisallowDuplicateSelection[];
    mySpecificGoals: DisallowDuplicateSelection[];
    physicalSymptoms: DisallowDuplicateSelection[];
    emotionalReactions: DisallowDuplicateSelection[];
    quiz1: DisallowDuplicateSelection[];
    quiz2: DisallowDuplicateSelection[];
    quiz3: DisallowDuplicateSelection[];
    quiz4: DisallowDuplicateSelection[];
    socraticAutoThinking: DisallowDuplicateSelection[];
    evidenceCorrectThinking: DisallowDuplicateSelection[];
    evidenceIncorrectThinking: DisallowDuplicateSelection[];
    authmaticThinkingGraph: DisallowDuplicateSelection[];
    worstOutcome: DisallowDuplicateSelection[];
    bestOutcome: DisallowDuplicateSelection[];
    realisticOutcome: DisallowDuplicateSelection[];
    advantagesOfTrustingAutoThought: DisallowDuplicateSelection[];
    letter: DisallowDuplicateSelection[];
    rationalThinkingAutoCheck: DisallowDuplicateSelection[];
    emotionsAfterChange: DisallowDuplicateSelection[];
    scoreAfterChange: DisallowDuplicateSelection[];
    lessonsLearned: DisallowDuplicateSelection[];
    physicalResponseOfEmotion: DisallowDuplicateSelection[];
    behavioralResponseOfEmotion: DisallowDuplicateSelection[];
    cognitiveResponseOfEmotion: DisallowDuplicateSelection[];
    cognitiveErrors: DisallowDuplicateSelection[];
    beforeBrain: DisallowDuplicateSelection[];
    situation: DisallowDuplicateSelection[];
    emotionIcon: DisallowDuplicateSelection[];
    emotionScore: DisallowDuplicateSelection[];
    thought: DisallowDuplicateSelection[];
    worstCaseScenario: DisallowDuplicateSelection[];
    image: DisallowDuplicateSelection[];
    selfPerception: DisallowDuplicateSelection[];
    situationThoughts: DisallowDuplicateSelection[];
    trustInThoughts: DisallowDuplicateSelection[];
    altExpAutoThought: DisallowDuplicateSelection[];
}

interface Session {
    registerSessionId: number | null;
    registerAssignmentId: number | null;
    fieldIds: any;
    fields: Fields;
    finished: boolean;
    workBookSubmitted: boolean;
    message: string;
}

const initialState: Session = {
    registerSessionId: 0,
    registerAssignmentId: 0,
    fieldIds: {},
    fields: {
        brain: Array(7).fill({ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }),
        changeBrain: Array(7).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        desiredSymptomChange: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        psychologicalBarriers: Array(3).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        myStrengths: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        therapyGoals: Array(3).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        mySpecificGoals: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        physicalSymptoms: Array(9).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        emotionalReactions: Array(3).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        quiz1: Array(4).fill({ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }),
        quiz2: Array(4).fill({ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }),
        quiz3: Array(4).fill({ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }),
        quiz4: Array(4).fill({ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }),
        socraticAutoThinking: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        evidenceCorrectThinking: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        evidenceIncorrectThinking: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        authmaticThinkingGraph: Array(7).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        worstOutcome: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        bestOutcome: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        realisticOutcome: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        advantagesOfTrustingAutoThought: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        letter: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        rationalThinkingAutoCheck: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        emotionsAfterChange: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        scoreAfterChange: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        lessonsLearned: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        physicalResponseOfEmotion: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        behavioralResponseOfEmotion: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        cognitiveResponseOfEmotion: [
            { patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' },
        ],
        cognitiveErrors: Array(3).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        beforeBrain: Array(7).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        situation: Array(3).fill({
            patient: '',
            feedback: '',
            visible: false,
            feedbackCheck: false,
            therapistName: '',
        }),
        emotionIcon: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        emotionScore: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        thought: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        worstCaseScenario: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        image: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        selfPerception: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        situationThoughts: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        trustInThoughts: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
        altExpAutoThought: [{ patient: '', feedback: '', visible: false, feedbackCheck: false, therapistName: '' }],
    },
    finished: false,
    workBookSubmitted: false,
    message: '',
};

export const sessionReducer = createSlice({
    name: 'session',
    initialState,
    reducers: {
        updateField: (
            state: Session,
            action: PayloadAction<{
                index?: number;
                key: keyof Fields;
                value: DisallowDuplicateSelection | DisallowDuplicateSelection[];
            }>
        ) => {
            const { index, key, value } = action.payload;
            const currentField = state.fields[key];

            if (Array.isArray(value)) {
                state.fields[key] = value;
            } else if (index !== undefined && currentField[index]) {
                currentField[index] = value;
            }
        },
        updateSessionPage: (state, action: PayloadAction<any>) => {
            const payload = action.payload;
            state.registerSessionId = payload.registerSessionId;
            state.registerAssignmentId = payload.registerAssignmentId;
            state.fieldIds = payload.fieldIds;
            state.finished = payload.finished;
            state.workBookSubmitted = payload.workBookSubmitted;
            Object.keys(payload.fields).forEach((key) => {
                if (key in state.fields) {
                    state.fields[key as keyof Fields] = payload.fields[key]; // 키를 keyof Fields로 캐스팅
                }
            });
        },

        updateSessionField: (
            state,
            action: PayloadAction<{ index: number; key: string; value: any; therapistName: string }>
        ) => {
            const { index, key, value, therapistName } = action.payload;

            const currentField = state.fields[key as keyof Session['fields']];

            if (currentField && currentField[index]) {
                // 드래프트를 수정하여 기존 값을 업데이트
                currentField[index] = {
                    ...currentField[index], // 기존 값을 복사
                    ...value, // 새로운 값으로 업데이트
                    feedbackCheck: true, // 추가 필드 설정
                    visible: true, // 추가 필드 설정
                    therapistName: therapistName,
                };
            }

            // 상태를 반환할 필요 없음, Immer가 자동으로 처리
        },
        changeFeedbackState: (state, action: PayloadAction<{ index: number; key: string; value: any }>) => {
            const { index, key, value } = action.payload;

            const currentField = state.fields[key as keyof Session['fields']];

            if (currentField && currentField[index]) {
                // 드래프트를 수정하여 기존 값을 업데이트
                currentField[index] = {
                    ...currentField[index], // 기존 값을 복사
                    ...value, // 새로운 값으로 업데이트
                    feedbackCheck: false, // 추가 필드 설정
                    visible: true, // 추가 필드 설정
                };
            }
        },
        deleteFeedback: (state, action: PayloadAction<{ index: number; key: string; value: any }>) => {
            const { index, key, value } = action.payload;

            const currentField = state.fields[key as keyof Session['fields']];

            if (currentField) {
                // 새로운 상태 객체 반환
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [key]: [{ patient: '', feedback: '', visible: false, feedbackCheck: false }],
                    },
                };
            }

            return state; // 변경이 없으면 기존 상태를 반환
        },
        updateQuiz: (
            state,
            action: PayloadAction<
                {
                    index: number;
                    patient: string;
                    feedback: string;
                    visible: boolean;
                    feedbackCheck: boolean;
                    therapistName: string;
                }[]
            >
        ) => {
            const payload = action.payload;

            payload.map((item, index) => {
                switch (item.index) {
                    case 0:
                        delete (payload[index] as any).index;
                        state.fields.quiz1 = payload;
                        break;
                    case 1:
                        delete (payload[index] as any).index;
                        state.fields.quiz2 = payload;
                        break;

                    case 2:
                        delete (payload[index] as any).index;
                        state.fields.quiz3 = payload;
                        break;

                    case 3:
                        delete (payload[index] as any).index;
                        state.fields.quiz4 = payload;
                        break;
                }
            });
        },
        resetData: () => initialState,
    },
});

export const {
    updateField,
    updateSessionPage,
    updateSessionField,
    deleteFeedback,
    changeFeedbackState,
    updateQuiz,
    resetData,
} = sessionReducer.actions;
export default sessionReducer.reducer;
