import React, { useEffect, useRef, useState } from 'react';
import eraserCursor from '../assets/eraser_test.jpg';
import penCursor from '../assets/pen_test.png';
import ReactPlayer from 'react-player';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const Canvas = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [text, setText] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [lastX, setLastX] = useState<number>(0);
    const [lastY, setLastY] = useState<number>(0);
    const [color, setColor] = useState<string>('#171717'); // 기본 펜 색상
    const [lineWidth, setLineWidth] = useState<number>(2); // 기본 선 두께
    const [isErasing, setIsErasing] = useState<boolean>(false); // 지우개 모드 여부
    const [eraserWidth, setEraserWidth] = useState<number>(10); // 기본 지우개 두께
    const [cursorStyle, setCursorStyle] = useState(`url(${penCursor}) 16 16, auto`); // 기본 커서 스타일

    const drawingCanvasRef = useRef<HTMLCanvasElement | null>(null);
    // Text to Image
    const drawText = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');

        // 캔버스 초기화
        ctx!.clearRect(0, 0, canvas.width, canvas.height);

        // 텍스트 스타일 설정
        ctx!.fillStyle = 'black';
        ctx!.font = '30px Arial';
        ctx!.textAlign = 'center';
        ctx!.textBaseline = 'middle';

        // 텍스트 그리기
        ctx!.fillText(text, canvas.width / 2, canvas.height / 2);
    };

    // TextImage + DrawingImage Save
    const handleSaveImage = () => {
        const backgroundCanvas = canvasRef.current;
        const drawingCanvas = drawingCanvasRef.current;

        if (!backgroundCanvas || !drawingCanvas) return;

        const combinedCanvas = document.createElement('canvas');
        const ctx = combinedCanvas.getContext('2d');
        combinedCanvas.width = backgroundCanvas.width;
        combinedCanvas.height = backgroundCanvas.height;

        // 배경 캔버스의 내용 그리기
        ctx!.drawImage(backgroundCanvas, 0, 0);
        // 드로잉 캔버스의 내용 그리기
        ctx!.drawImage(drawingCanvas, 0, 0);

        // 최종 이미지를 저장
        const dataUrl = combinedCanvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'drawing_with_text.png';
        link.click();
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        e.preventDefault(); // 기본 동작 방지

        const canvas = drawingCanvasRef.current;
        if (!canvas) return;
        const { offsetX, offsetY }: any =
            e.type === 'touchstart'
                ? (e as React.TouchEvent<HTMLCanvasElement>).touches[0]
                : (e as React.MouseEvent<HTMLCanvasElement>);

        setIsDrawing(true);
        const rect = canvas.getBoundingClientRect();
        setLastX(offsetX - rect.left);
        setLastY(offsetY - rect.top);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        e.preventDefault();
        const canvas = drawingCanvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        const currentX =
            e.type === 'touchmove'
                ? (e as React.TouchEvent<HTMLCanvasElement>).touches[0].clientX - rect.left
                : (e as React.MouseEvent<HTMLCanvasElement>).clientX - rect.left;

        const currentY =
            e.type === 'touchmove'
                ? (e as React.TouchEvent<HTMLCanvasElement>).touches[0].clientY - rect.top
                : (e as React.MouseEvent<HTMLCanvasElement>).clientY - rect.top;

        if (isDrawing) {
            if (isErasing) {
                ctx!.clearRect(currentX - eraserWidth / 2, currentY - eraserWidth / 2, eraserWidth, eraserWidth);
            } else {
                ctx!.beginPath();
                ctx!.moveTo(lastX, lastY);
                ctx!.lineTo(currentX, currentY);
                ctx!.stroke();
                ctx!.closePath();
            }
        }

        setLastX(currentX);
        setLastY(currentY);
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        e.preventDefault();
        setIsDrawing(false);
    };

    const handleGenerateImage = () => {
        drawText();
    };

    const toggleEraseMode = () => {
        setIsErasing(!isErasing); // 지우개 모드 전환
        setCursorStyle(isErasing ? `url(${penCursor}) 16 16, auto` : `url(${eraserCursor}) 16 16, auto`); // 커서 스타일 변경
    };

    const [canvasHeight, setCanvasHeight] = useState(window.innerHeight);
    const containerRef = useRef<HTMLDivElement | null>(null); // HTMLDivElement 타입으로 설정

    useEffect(() => {
        const updateCanvasHeight = () => {
            if (containerRef.current) {
                const height = containerRef.current.offsetHeight;
                setCanvasHeight(height);
            }
        };

        // setTimeout을 사용하여 DOM 요소가 렌더링된 후 높이 업데이트
        const timer = setTimeout(updateCanvasHeight, 3000);

        // resize 이벤트 핸들러 추가
        window.addEventListener('resize', updateCanvasHeight);
        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', updateCanvasHeight);
        };
    }, []);

    return (
        <div className="main-container">
            <div
                style={{
                    position: 'relative',
                }}
            >
                <h1>텍스트를 이미지로 변환하기</h1>
                <input type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder="텍스트 입력" />
                <button onClick={handleGenerateImage}>이미지 생성 /</button>
                <button onClick={handleSaveImage}>이미지 저장 /</button>
                <button onClick={toggleEraseMode}>{isErasing ? '드로잉 모드' : '지우개 모드'}</button>

                <div>
                    <label>펜 색상:</label>
                    <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                    <label>선 두께:</label>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={lineWidth}
                        onChange={(e) => setLineWidth(Number(e.target.value))}
                    />
                    <label>지우개 두께:</label>
                    <input
                        type="range"
                        min="5"
                        max="50"
                        value={eraserWidth}
                        onChange={(e) => setEraserWidth(Number(e.target.value))}
                    />
                </div>
                <canvas
                    ref={canvasRef}
                    width={500}
                    height={200}
                    style={{ border: '0.0625rem solid black', backgroundColor: '#FFF' }}
                />

                <div
                    ref={containerRef}
                    style={{
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        height: `100%`,
                    }}
                >
                    <canvas
                        ref={drawingCanvasRef}
                        width={window.innerWidth}
                        height={canvasHeight}
                        style={{
                            position: 'absolute',
                            border: '1px solid black',
                            zIndex: 10,
                            cursor: cursorStyle,
                            touchAction: 'none',
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseLeave}
                        onMouseLeave={handleMouseLeave}
                        onTouchStart={handleMouseDown}
                        onTouchMove={handleMouseMove}
                        onTouchEnd={handleMouseLeave}
                    />
                    <ReactPlayer
                        url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                        height="auto" // 비디오 높이
                        width="100%"
                        controls={true} // 기본 컨트롤 비활성화
                    />
                    <ReactPlayer
                        url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                        height="auto" // 비디오 높이
                        width="100%"
                        controls={true} // 기본 컨트롤 비활성화
                    />
                    <ReactPlayer
                        url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                        height="auto" // 비디오 높이
                        width="100%"
                        controls={true} // 기본 컨트롤 비활성화
                    />
                    <ReactPlayer
                        url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                        height="auto" // 비디오 높이
                        width="100%"
                        controls={true} // 기본 컨트롤 비활성화
                    />
                </div>
            </div>
        </div>
    );
};
