import axiosInstance from '../../../utils/AxiosInstanceJava';
import { decryptData } from '../../../utils/croptojs';

export interface Product {
    product: {
        workBookProductId: number;
        workBookList: {
            workBookId: number;
            type: string;
            workBookName: string;
            workBookDescription: string;
            price: number;
            splitAllowed: boolean;
            reservationRequired: boolean;
            regDate: string;
            modDate: string;
        }[];
        productName: string;
        productDescription: string;
        price: number;
        discountPrice: number;
    };
    memberPhone: string;
    type: string;
    splitAllowed: boolean;
    originalPrice: number;
    payPrice: number;
    message: string;
    result: boolean;
}

export interface ProductByCategory {
    productByCategory: {
        category: string;
        productList: {
            workBookProductId: number;
            workBookList: {
                workBookId: number;
                type: string;
                workBookName: string;
                workBookDescription: string;
                price: number;
                splitAllowed: boolean;
                reservationRequired: boolean;
                assignmentExist: boolean;
                regDate: string;
                modDate: string;
            }[];
            productName: string;
            productDescription: string;
            price: number;
            discountPrice: number;
        }[];
    }[];
}

// 프로그램 리스트
export const getProgramList = async (): Promise<ProductByCategory> => {
    return await axiosInstance.get('/api/workBook/sale/list/v1').then((response) => response.data);
};

// 결제정보 가져오기
export const getPaymentReceipt = async (productId: string) => {
    const moid = decryptData(productId);
    return await axiosInstance
        .get(`/api/member/payResult/v1`, {
            params: {
                Moid: moid,
            },
        })
        .then((response) => response.data);
};
