import styles from './css/CanvasOptionsModal.module.css';
import { ReactComponent as Icon_pen_bold } from '../../../../assets/blackboard/icon_pen_bold.svg';
import { ReactComponent as Icon_pen_medium } from '../../../../assets/blackboard/icon_pen_medium.svg';
import { ReactComponent as Icon_pen_thin } from '../../../../assets/blackboard/icon_pen_thin.svg';
import { ReactComponent as Icon_eraser } from '../../../../assets/blackboard/icon_eraser.svg';
import Icon_colorpicker from '../../../../assets/blackboard/icon_colorpicker.png';
import Icon_colorpicker_active from '../../../../assets/blackboard/icon_colorpicker_active.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
    updateColorPickerVisible,
    updateEraserVisible,
    updateLineWidth,
} from '../../../../features/session/blackboardReducer';

export const CanvasOptionsModal = () => {
    const dispatch = useDispatch();
    const { lineWidth, colorPickerVisible, eraserVisible } = useSelector((state: RootState) => state.blackboard);

    const changeLineWidth = (width: number) => {
        dispatch(updateLineWidth(width));
        dispatch(updateEraserVisible(false));
    };

    const colorPicker = () => {
        dispatch(updateColorPickerVisible(!colorPickerVisible));
    };

    return (
        <div className={styles.canvas_options}>
            <div className={styles.options_container}>
                <div>
                    <button onClick={() => changeLineWidth(0.5)}>
                        <Icon_pen_thin
                            className={`${styles.icon} ${lineWidth === 0.5 && !eraserVisible ? styles.active : ''}`}
                        />
                    </button>
                    <button onClick={() => changeLineWidth(1.5)}>
                        <Icon_pen_medium
                            className={`${styles.icon} ${lineWidth === 1.5 && !eraserVisible ? styles.active : ''}`}
                        />
                    </button>
                    <button onClick={() => changeLineWidth(2)}>
                        <Icon_pen_bold
                            className={`${styles.icon} ${lineWidth === 2 && !eraserVisible ? styles.active : ''}`}
                        />
                    </button>
                </div>
                <div className={styles.icon_line} />
                <button onClick={colorPicker}>
                    <img
                        src={colorPickerVisible ? Icon_colorpicker_active : Icon_colorpicker}
                        className={styles.icon}
                    />
                </button>
                <div className={styles.icon_line} />
                <button onClick={() => dispatch(updateEraserVisible(!eraserVisible))}>
                    <Icon_eraser className={`${styles.icon} ${eraserVisible ? styles.active : ''}`} />
                </button>
            </div>
        </div>
    );
};
