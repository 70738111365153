import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_CYPTO_JS_SECRET_KEY!;
export const encryptData = (data: any) => {
    return CryptoJS.AES.encrypt(String(data), secretKey).toString();
};

export const decryptData = (data: string) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
};

(window as any).encryptData = encryptData;
