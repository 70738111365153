import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getMemberInfo } from '../utils/GetMemberInfo';

interface Authority {
    companyId: number;
    pageNum: number;
    roleVisible: { index: number; visible: boolean };
    organVisible: { index: number; visible: boolean };
    employeeSettingVisible: { index: number; visible: boolean };
    employeeSettingMenuList: string[];
    checkAry: { companyId: number; branchName: string }[];
    isSelectedTitle: string;
    officialList: { title: string; type: string }[];
    chartTitleList: string[];
    isSelectedOfficial: string;
}
const memberInfo = getMemberInfo();
const initialState: Authority = {
    companyId: memberInfo?.loginBranch ? memberInfo?.loginBranch.companyId : 0,
    pageNum: 0,
    roleVisible: { index: -1, visible: false },
    organVisible: { index: -1, visible: false },
    employeeSettingVisible: { index: -1, visible: false },
    employeeSettingMenuList: ['권한 범위 설정', '직원 삭제'],
    checkAry: [],
    isSelectedTitle: '모든 기관',
    officialList: [
        { title: '전체', type: 'all' },
        { title: '치료자', type: 'therapist' },
        { title: '매니저', type: 'manager' },
        { title: '관리자', type: 'administrator' },
    ],
    chartTitleList: ['이름', '권한', '기관', '권한 변경일', ''],
    isSelectedOfficial: 'all',
};

export const authorityReducer = createSlice({
    name: 'authority',
    initialState,
    reducers: {
        updatePageNum: (state, action: PayloadAction<number>) => {
            state.pageNum = action.payload;
        },
        updateSelectOrgan: (state, action: PayloadAction<{ companyId: number; branchName: string }>) => {
            const { companyId, branchName } = action.payload;
            state.companyId = companyId;
            state.isSelectedTitle = branchName;
        },
        updateRoleVisible: (state, action: PayloadAction<{ index: number; visible: boolean }>) => {
            state.roleVisible = action.payload;
        },
        updateBranchVisible: (state, action: PayloadAction<{ index: number; visible: boolean }>) => {
            state.organVisible = action.payload;
        },
        updateEmployeeSettingVisible: (state, action: PayloadAction<{ index: number; visible: boolean }>) => {
            state.employeeSettingVisible = action.payload;
        },
        updateCheckAry: (state, action: PayloadAction<{ companyId: number; branchName: string }[]>) => {
            state.checkAry = action.payload;
        },
        updateIsSelectedOfficial: (state, action: PayloadAction<string>) => {
            state.isSelectedOfficial = action.payload;
        },
        resetModal: (state) => {
            state.roleVisible = { index: -1, visible: false };
            state.organVisible = { index: -1, visible: false };
            state.employeeSettingVisible = { index: -1, visible: false };
        },
    },
});

export const {
    updatePageNum,
    updateSelectOrgan,
    updateRoleVisible,
    updateBranchVisible,
    updateEmployeeSettingVisible,
    updateCheckAry,
    updateIsSelectedOfficial,
    resetModal,
} = authorityReducer.actions;
export default authorityReducer.reducer;
