import { useDispatch } from 'react-redux';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { updateSelectOrgan } from '../../../../features/headerReducer';
import { encryptData } from '../../../../utils/croptojs';
import { useContext } from 'react';
import { ModalContext } from '../../../../context/ModalContext';
import { getToken } from 'firebase/messaging';
import { messaging } from '../../../../firebase';

export const getFirebaseToken = () => {
    if (Notification.permission === 'granted') {
        getToken(messaging, {
            vapidKey: 'BGVqESpMXdgfbKroQQ8SSF3Vk1JUyLZlf_2bhYqDjslX6i8SRG0IU7ViK-Uotwoqqzx__HuxSMpHp8ctjsC6HLM',
        })
            .then((currentToken) => {
                if (currentToken) {
                    const storedToken = localStorage.getItem('firebaseToken');
                    const parsedToken = storedToken ? JSON.parse(storedToken) : null;
                    // 기존 토큰과 비교하여 변경된 경우에만 저장
                    // if (!parsedToken || parsedToken.firebase_token !== token) {
                    localStorage.setItem('firebaseToken', JSON.stringify({ firebase_token: currentToken }));
                    // 자바 서버에 토큰 저장 로직 추가
                    // }
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.error('Error getting token:', err);
            });
    } else if (Notification.permission === 'default') {
    } else {
    }
};

export const normalLoginFn = async (
    loginType: string,
    user: any,
    saveIdChecked: boolean,
    axiosInstance: any,
    navigate: Function,
    snsType: string,
    oneButtonOpenModal: Function
) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
        getFirebaseToken();
    }
    const firebase = localStorage.getItem('firebaseToken');
    let firebaseData: { firebase_token: string } = { firebase_token: '' };
    if (firebase !== null) {
        firebaseData = JSON.parse(firebase);
    }
    await axiosInstance
        .post(`/api/auth/login/${loginType}/v1`, {
            memberAccount: user.memberAccount,
            memberPassword: user.memberPassword,
            kakaoEmail: user.kakaoEmail,
            snsType: snsType,
            companyBranch: user.memberOrgan,
            firebaseToken: firebase ? firebaseData.firebase_token : '',
        })
        .then(async (response: any) => {
            if (response.data.result) {
                const token = response.data.tokenDTO;
                localStorage.setItem(
                    'userToken',
                    JSON.stringify({
                        accessToken: token.accessToken,
                        accessTokenExp: token.accessTokenExp,
                        refreshToken: token.refreshToken,
                        refreshTokenExp: token.refreshTokenExp,
                    })
                );
                if (saveIdChecked) {
                    localStorage.setItem(
                        'saveMemberAccount',
                        JSON.stringify({
                            memberAccount: user.memberAccount,
                        })
                    );
                } else {
                    localStorage.removeItem('saveMemberAccount');
                }
                const info = getMemberInfo();

                if (info) {
                    switch (info?.memberRole) {
                        case 'user':
                            if (response.data.workBooks) {
                                if (response.data.workBooks.length > 0) {
                                    for (const item of response.data.workBooks) {
                                        const params = new URLSearchParams();
                                        params.append('page', response.data.sessionNo);
                                        params.append('session', encryptData(response.data.registerSessionId));
                                        params.append('program', encryptData(item.registerId));
                                        if (response.data.registerSessionId) {
                                            if (item.type === 'non-face') {
                                                navigate(`/yd/patient/nonface/session?${params}`);
                                                break;
                                            } else if (item.type === 'face') {
                                                navigate(`/yd/patient/face/session?${params}`);
                                                break;
                                            }
                                        } else {
                                            if (item.type === 'face') {
                                                navigate(`/yd/patient/face/sessionMap?${params}`);
                                                break;
                                            } else {
                                                navigate(`/yd/patient/nonface/sessionMap?${params}`);
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else {
                                return navigate('/yd/patient/program/select');
                            }
                            break;
                        case 'employee':
                            break;
                        case 'therapist':
                        case 'manager':
                        case 'administrator':
                        case 'representative':
                            navigate('/yd/official');
                            break;
                    }
                }
            } else {
                loginErrorHandle(response.data.message, oneButtonOpenModal);
            }
        });
};

export const kakaoLogin = async () => {
    const rest_api_key = process.env.REACT_APP_KAKAO_REST_API_KEY;
    // const redirect_uri = 'https://192.168.0.8:3000/public/auth/kakaologin';
    const redirect_uri = 'https://yd-icbt.co.kr/public/auth/kakaologin';
    // const redirect_uri = 'http://223.130.147.159:8025/auth/kakaologin';
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = kakaoURL;
};

export const loginErrorHandle = (message: string, oneButtonOpenModal: Function) => {
    switch (message) {
        // 비밀번호 불일치
        case 'PASSWORD_NOT_MATCH':
            oneButtonOpenModal(() => {}, '', '아이디 또는 비밀번호가 잘못되었습니다.', '확인');
            break;
        // 계정이 존재하지 않을 때
        case 'THIS_ACCOUNT_IS_NOT_EXIST':
            oneButtonOpenModal(
                () => {},
                '',
                '일치하는 계정 정보가 없습니다.일반회원의 경우, 상단에 위치한 일반회원 탭 또는 직원의 경우, 직원 탭 선택 후 다시 로그인을 시도해 주세요.',
                '확인'
            );
            break;
        // 카카오 소셜검증 실패
        case 'SOCIAL_VERIFICATION_FAILED':
            oneButtonOpenModal(() => {}, '', '존재하는 계정 정보가 없습니다. 회원가입 후 다시 진행해 주세요.', '확인');
            break;
        // 지점 소속이 아닐 경우
        case 'INTERNAL_SERVER_ERROR':
            oneButtonOpenModal(
                () => {},
                '',
                '해당 기관에 소속된 계정 정보가 없습니다. 확인 후 로그인을 다시 진행해 주세요.',
                '확인'
            );
            break;
        // 직원이 회원으로 로그인 할 경우
        case 'EMPLOYEE_LOGIN_PLEASE':
            oneButtonOpenModal(() => {}, '', '로그인하실 소속기관을 선택해 주세요.', '확인');
            break;
        // 회원이 직원으로 로그인 할 경우
        case 'USER_LOGIN_PLEASE':
            oneButtonOpenModal(
                () => {},
                '',
                '해당 기관에 소속된 계정 정보가 없습니다. 확인 후 로그인을 다시 진행해 주세요.',
                '확인'
            );
            break;
        // 카카오 토큰 없을 때
        case 'KAKAO_EMAIL_IS_NOT_EXIST':
            oneButtonOpenModal(() => {}, '', '아이디 또는 비밀번호가 잘못되었습니다.', '확인');
            break;
        // 승인 대기중
        case 'WAITING_FOR_APPROVAL':
            oneButtonOpenModal(
                () => {},
                '',
                '아직 회원님의 계정이 회원가입 진행 중입니다. 회원가입 승인까지 주말, 공휴일 포함 1~2일 정도 소요될 수 있습니다.',
                '확인'
            );
            break;

        // 거절 됨
        case 'REGISTER_REJECTED':
            oneButtonOpenModal(
                () => {},
                '',
                '회원가입 신청이 거절된 계정입니다. 다시 한번 확인 후 가입을 진행해 주세요. (*일반 회원일 경우, 상단 일반회원 버튼을 눌러 회원가입 진행)',
                '확인'
            );
            break;

        // 에러가 발생할 경우
        case 'LOGIN_FAILED_ERROR':
            oneButtonOpenModal(() => {}, '', '아이디 또는 비밀번호가 잘못되었습니다.', '확인');
            break;
    }
};
