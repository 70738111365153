import { useContext, useEffect, useMemo, useRef } from 'react';

import { MAIN_SERVER, TEST_SERVER } from './BasicUrl';

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getMemberInfo } from './GetMemberInfo';

// Axios 인스턴스 생성
const axiosInstance: AxiosInstance = axios.create({
    timeout: 5000,
});

// Set을 사용하여 처리된 에러 코드를 저장
const errorHandledRef = new Set<string>();

// 요청 인터셉터 설정
axiosInstance.interceptors.request.use(
    (config) => {
        const userData = localStorage.getItem('userToken');
        if (userData) {
            const result = JSON.parse(userData);
            config.headers['Authorization'] = result.accessToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 설정
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        console.log(`Response url: ${response.config.url} \n ResponseData: ${JSON.stringify(response.data, null, 2)}`);
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const errorCode = error.response?.data?.errorCode;
        if (error.response?.status !== undefined) {
            if (!errorHandledRef.has(errorCode)) {
                errorHandledRef.add(errorCode); // 에러 코드 추가
                switch (error.response.status) {
                    case 401: // Unauthorized
                        await getNewToken(); // 토큰 갱신 함수 호출
                        return axiosInstance(originalRequest); // 원래 요청 재전송
                    case 403: // Forbidden
                        return Promise.reject(error.response.status);
                    default:
                        return Promise.reject(error.response.status);
                }
            }
        }

        return Promise.reject(error); // 기본 에러 처리
    }
);

// Axios 인스턴스 내보내기
export default axiosInstance;

// 토큰 재발급
const getNewToken = async () => {
    const userData = localStorage.getItem('userToken');
    if (userData) {
        const result = JSON.parse(userData);
        const memberInfo = getMemberInfo();
        const res = await axios({
            method: 'POST',
            url: `${MAIN_SERVER}/api/member/reIssue/${memberInfo?.loginBranch.branchName}/v1`,
            timeout: 5000,
            headers: {
                Accept: 'application/json',
                refreshToken: result.refreshToken,
            },
        })
            .then(function (response) {
                if (response.status == 200) {
                    const tokenData = response.data.tokenDTO;
                    if (tokenData.refreshToken == null) {
                        localStorage.setItem(
                            'userToken',
                            JSON.stringify({
                                accessToken: tokenData.accessToken,
                                accessTokenExp: tokenData.accessTokenExp,
                                refreshToken: result.refreshToken,
                                refreshTokenExp: result.refreshTokenExp,
                            })
                        );
                    } else {
                        localStorage.setItem(
                            'userToken',
                            JSON.stringify({
                                accessToken: tokenData.accessToken,
                                accessTokenExp: tokenData.accessTokenExp,
                                refreshToken: tokenData.refreshToken,
                                refreshTokenExp: tokenData.refreshTokenExp,
                            })
                        );
                    }
                    return tokenData;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                console.log('토큰 재발급' + err);
                localStorage.removeItem('userToken');
                // navigate('LoginMain', '', '403');
                switch (err.response.status) {
                    case 401:
                        return Promise.reject(err.response.status);
                    case 403:
                        // Alert.alert('경고', '비정상적인 접근이 감지됐습니다 ' + '\n 다시 로그인 해주세요.', [
                        //     {
                        //         text: '확인',
                        //         onPress: () => null,
                        //     },
                        // ]);
                        return Promise.reject(err.response.status);
                }
            });
    }
};
