import { useContext, useEffect, useRef, useState } from 'react';
import styles from '../../css/NinthSession.module.css';
import '../../../css/PublicSessionStyle.css';
import ReactPlayer from 'react-player';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    AdvantageAutomaticAccidents,
    AuthmaticThinkingGraph,
    AutomaticAccidentInspection,
    BottomFiveSeven,
    CognitiveError,
    LearnedFelt,
    RealictResults,
    SituationEmotion,
    SocraticQuestionAnswer,
    SocraticQuestionAnswerStep,
    SubmitFeedback,
    SubmitWorkbook,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { resetData, updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { decryptData } from '../../../../../../utils/croptojs';
import { ModalContext } from '../../../../../../context/ModalContext';
import { getMemberInfo } from '../../../../../../utils/GetMemberInfo';
import axiosInstance from '../../../../../../utils/AxiosInstanceJava';
import { SessionGuide, VideoPopup } from '../../component/FirstSessionComponent';
import { getSessionData } from '../../ts/SessionApi';

export const TherapistNinthSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const { twoButtonOpenModal, oneButtonOpenModal } = useContext(ModalContext);

    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('session');
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getSessionData', encryptedData],
        queryFn: () => getSessionData(encryptedData!),
        placeholderData: keepPreviousData,
    });

    const playerRef = useRef<ReactPlayer | null>(null);

    const playerPopUpRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);
    const [guidePopUpVisible, setGuidePopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0);

    const [videoUrl, setVideoUrl] = useState('https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT10.mp4');

    const [sessionVideoList, setSessionVideoList] = useState([
        {
            title: '세션1 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT1.mp4',
        },
        {
            title: '세션2 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT2.mp4',
        },
        {
            title: '세션3 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT3.mp4',
        },
        {
            title: '세션4 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT4.mp4',
        },
        {
            title: '세션5 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT5.mp4',
        },
        {
            title: '세션6 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT6.mp4',
        },
        {
            title: '세션7 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT7.mp4',
        },
        {
            title: '세션8 영상',
            url: 'https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT8.mp4',
        },
    ]);

    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 현재 루트 폰트 사이즈

    const [popupStyle, setPopupStyle] = useState<{ top: number; right: number; width: number; height: number }>({
        top: 120 / rootFontSize, // px를 rem으로 변환
        right: 32 / rootFontSize, // px를 rem으로 변환
        width: 460 / rootFontSize, // px를 rem으로 변환
        height: 258 / rootFontSize, // px를 rem으로 변환
    });

    const sessionRole = () => {
        twoButtonOpenModal(
            () => {},
            () => {
                saveSessionData();
            },
            '피드백 작성 완료 확인',
            '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
            '취소',
            '확인'
        );
    };

    // 피드백 제출
    const saveSessionData = async () => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .post(`/api/${memberInfo?.memberRole}/feedback/${decryptData(encryptedData!)}/v1`)
            .then((response) => {
                const res = response.data;
                if (res.result) {
                    switch (res.message) {
                        case 'SUBMIT_FEEDBACK_SUCCESS':
                            oneButtonOpenModal(
                                () => {
                                    refetch();
                                },
                                '',
                                '워크북 수정이 완료되었습니다.',
                                '확인'
                            );

                            break;
                    }
                }
            });
    };

    const handleScroll = () => {
        if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            const currentScrollY = window.scrollY;

            // 아래로 스크롤할 때
            if (currentScrollY > lastScrollY.current) {
                // 컴포넌트가 화면에 보이지 않으면 팝업을 켭니다.
                if (rect.bottom < 600 || rect.top > window.innerHeight) {
                    // 현재 팝업이 보이지 않을 때만 상태 업데이트
                    // showPopup이 false일 때만 PIP 활성화
                    if (!showPopup) {
                        setShowPopup(true);
                    }
                    // activatePIP();
                }
            }
            // 위로 스크롤할 때
            else {
                // 컴포넌트가 화면에 보이면 팝업을 끕니다.
                if (rect.top < window.innerHeight && rect.bottom > 0) {
                    if (showPopup) {
                        // showPopup이 true일 때만 PIP 비활성화
                        // setPlaying(false);
                        setShowPopup(false);
                        // deactivatePIP();
                    }
                }
            }

            // 마지막 스크롤 위치 업데이트
            lastScrollY.current = currentScrollY;
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation(); // 비디오 드래그 이벤트와 겹치지 않도록

        const startX = e.clientX;
        const startY = e.clientY;

        const root = document.documentElement;
        const computedStyle = getComputedStyle(root);
        const rootFontSize = parseFloat(computedStyle.fontSize); // 현재 루트 폰트 사이즈

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const padding = rootFontSize * 2; // 패딩 설정

            // popupStyle의 top과 right을 rem에서 px로 변환
            const currentTop = popupStyle.top * rootFontSize;
            const currentRight = popupStyle.right * rootFontSize;

            const newTop = currentTop + (moveEvent.clientY - startY);
            const newRight = currentRight - (moveEvent.clientX - startX); // 오른쪽 기준으로 변경

            const maxTop = window.innerHeight - (popupStyle.height * rootFontSize + padding);
            const maxRight = window.innerWidth - (popupStyle.width * rootFontSize + padding);

            const constrainedTop = Math.min(Math.max(padding, newTop), maxTop);
            const constrainedRight = Math.min(Math.max(padding, newRight), maxRight);

            setPopupStyle((prev) => ({
                ...prev,
                top: constrainedTop / rootFontSize, // rem으로 변환하여 설정
                right: constrainedRight / rootFontSize, // rem으로 변환하여 설정
            }));
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleResizeMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation();

        const startWidth = popupStyle.width * rootFontSize; // rem을 px로 변환
        const startHeight = popupStyle.height * rootFontSize; // rem을 px로 변환
        const startX = e.clientX;
        const startY = e.clientY;

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const newWidth = startWidth - (moveEvent.clientX - startX);
            const newHeight = newWidth / (16 / 9); // 비율 유지

            setPopupStyle((prev) => ({
                ...prev,
                width: newWidth > 100 ? newWidth / rootFontSize : 100 / rootFontSize, // 최소 너비 설정
                height: newHeight > 100 ? newHeight / rootFontSize : 372 / rootFontSize, // 최소 높이 설정
            }));
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    useEffect(() => {
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showPopup]); // 빈 배열을 사용하여 한 번만 실행

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);
    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        if (!showPopup) {
            setPlaybackTime(progress.playedSeconds);
        }
    };

    const SelectVideo = () => {
        return (
            <div className={styles.video_list_container}>
                {sessionVideoList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            setVideoUrl(item.url);
                        }}
                        className={`${styles.vidoe_list_button} ${
                            videoUrl === item.url ? styles.activate : styles.deactivate
                        }`}
                    >
                        <span
                            className={`${
                                videoUrl === item.url ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'
                            }`}
                            style={{
                                color: videoUrl === item.url ? '#3A5074' : '#9D9FA2',
                            }}
                        >
                            {item.title}
                        </span>
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className={'therapist_main_container'}>
            <div
                ref={targetRef}
                style={{
                    marginBottom: '2.25rem',
                }}
            >
                <SelectVideo />
                <ReactPlayer
                    ref={playerRef}
                    url="https://exqibabapqbf17923502.cdn.ntruss.com/ydTest/CBT10.mp4"
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            {showPopup && popUpVisible && (
                <VideoPopup
                    showPopup={playing}
                    playerPopUpRef={playerPopUpRef}
                    onProgress={handleProgress}
                    currentTime={playbackTime}
                    popupStyle={popupStyle}
                    onMouseDown={handleMouseDown}
                    onResizeMouseDown={handleResizeMouseDown}
                    updatePopUpVisible={(visible: boolean) => setPopUpVisible(visible)}
                    videoUrl="https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT2.mp4"
                />
            )}
            <SituationEmotion />
            <ThoughtSiuation />
            <BottomFiveSeven />
            <CognitiveError />
            <SocraticQuestionAnswerStep />
            <AuthmaticThinkingGraph />
            <RealictResults />
            <AdvantageAutomaticAccidents />
            <AutomaticAccidentInspection />
            <LearnedFelt />
            <SubmitFeedback onClick={sessionRole} />
        </div>
    );
};
