import { Toggle } from '../../component/PublicComponent';
import styles from './css/NotificationSetting.module.css';

export const NotificationSetting = () => {
    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">알림 설정</div>
            <div className={styles.content_container}>
                <div className={styles.item_container}>
                    <div className={styles.item}>
                        <span className="text_22_NotoSansKR_Bold">
                            <span className="text_22_Inter_Bold">PC </span>웹 푸시알림
                        </span>
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            ※ 이름, 휴대폰 번호, 성별 변경이 필요하신 경우 휴대폰 본인인증을 완료하시면 자동으로 일괄
                            변경됩니다.
                        </span>
                    </div>
                    <Toggle fn={() => {}} />
                </div>
            </div>
        </div>
    );
};
