import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Identity = () => {
    const location = useLocation();
    useEffect(() => {
        if (window.opener) {
            const params = new URLSearchParams(location.search);
            const enc_data = params.get('enc_data');
            const token_version_id = params.get('token_version_id');
            const integrity_value = params.get('integrity_value');
            const updateIdentity = JSON.stringify({
                enc_data: enc_data,
                token_version_id: token_version_id,
                integrity_value: integrity_value,
            });
            console.log(updateIdentity);
            localStorage.setItem('identity_data', updateIdentity);
            window.opener.location.href = 'https://yd-icbt.co.kr/public/signup'; // 부모 페이지로 이동
            window.close(); // 팝업 창 닫기 (선택 사항)
        }
    }, []);

    return <div>Identity</div>;
};
