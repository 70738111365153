import styles from '../../css/FourthSession.module.css';
import Img1 from '../../../../../../assets/face/session4/session4_1.png';
import Img2 from '../../../../../../assets/face/session4/session4_2.png';
import Img_quiz_1 from '../../../../../../assets/face/session4/session4_quiz_1.png';
import Img_quiz_2 from '../../../../../../assets/face/session4/session4_quiz_2.png';
import Img_quiz_3 from '../../../../../../assets/face/session4/session4_quiz_3.png';
import Img_quiz_4 from '../../../../../../assets/face/session4/session4_quiz_4.png';

import { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    BottomFiveSeven,
    CircleCheckBox,
    CognitiveError,
    SituationEmotion,
    SubmitWorkbook,
    ThoughtSiuation,
} from '../../component/PublicSessionComponent';
import { useLocation } from 'react-router-dom';
import { Fields, resetData, updateSessionPage } from '../../../../../../features/session/sessionReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from '../../../../../../context/ModalContext';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getAssignData, getSessionData } from '../../ts/SessionApi';
import { RootState } from '../../../../../../store';
import { VideoPopup } from '../../component/FirstSessionComponent';
import axiosInstance from '../../../../../../utils/AxiosInstanceJava';
import { decryptData } from '../../../../../../utils/croptojs';

export const AssignedFourthSession = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const encryptedData = queryParams.get('session');
    const payment = queryParams.get('payment');
    const currentSession = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const { registerSessionId, fields, workBookSubmitted } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal, twoButtonOpenModal } = useContext(ModalContext);

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['getAssignData', encryptedData],
        queryFn: () => getAssignData(encryptedData!),
        enabled: encryptedData !== null,
        placeholderData: keepPreviousData,
    });

    const playerRef = useRef<ReactPlayer | null>(null);
    const playerPopUpRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // 초기 볼륨
    const [playbackTime, setPlaybackTime] = useState(0);
    const [popUpVisible, setPopUpVisible] = useState<boolean>(true);

    const targetRef: any = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const lastScrollY = useRef(0); // useRef로 상태를 관리

    const [quizList, setQuizList] = useState([
        {
            url: Img_quiz_1,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_2,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_3,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
        {
            url: Img_quiz_4,
            answerList: [
                {
                    title: '독심술',
                    select: false,
                },
                {
                    title: '정진적 여과',
                    select: false,
                },
                {
                    title: '명명하기',
                    select: false,
                },
                {
                    title: '당위진술',
                    select: false,
                },
            ],
        },
    ]);

    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 현재 루트 폰트 사이즈

    const [popupStyle, setPopupStyle] = useState<{ top: number; right: number; width: number; height: number }>({
        top: 120 / rootFontSize, // px를 rem으로 변환
        right: 32 / rootFontSize, // px를 rem으로 변환
        width: 561 / rootFontSize, // px를 rem으로 변환
        height: 316 / rootFontSize, // px를 rem으로 변환
    });

    // TextImage + DrawingImage Save
    const exportPDF = async () => {
        if (contentRef.current) {
            // 1. 콘텐츠의 너비와 높이 측정
            const contentWidth = contentRef.current.offsetWidth; // 콘텐츠 너비
            const contentHeight = contentRef.current.scrollHeight; // 콘텐츠 전체 높이

            // 2. 높은 해상도로 캡처
            const canvasImage = await html2canvas(contentRef.current, {
                scale: 1,
            });

            const imageData = canvasImage.toDataURL('image/jpeg', 0.7); // jpeg 포맷 사용

            // 3. PDF 생성
            const pdf = new jsPDF({
                orientation: 'portrait', // 세로 방향으로 설정
                unit: 'mm',
                format: [contentWidth * 0.264583, contentHeight * 0.264583], // 픽셀을 mm로 변환
                // putOnlyUsedFonts: true,
                // floatPrecision: 16,
            });

            // 4. 이미지 추가
            pdf.addImage(imageData, 'JPEG', 0, 0, contentWidth * 0.264583, contentHeight * 0.264583); // 비율 유지하여 추가
            // 5. PDF 저장
            pdf.save(`session_page${page}.pdf`);
        }
    };

    const sessionRole = () => {
        const fieldData: any = {
            // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 상황
            situation: fields.situation,

            // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정
            emotionIcon: fields.emotionIcon,

            // (sessionNo, 2,3,4,5,6,7,8,9) 자동사고 기록지 / 감정 점수
            emotionScore: fields.emotionScore,

            // (sessionNo, 3,4,5,6,7,8,9) 어떤 생각이 떠올랐나요?
            thought: fields.thought,

            // (sessionNo, 3,4,5,6,7,8,9) 최악의 경우, 어떤 일이 일어날 것이라는 생각이 들었나요?
            worstCaseScenario: fields.worstCaseScenario,

            // (sessionNo, 3,4,5,6,7,8,9) 그때 떠오른 이미지는
            image: fields.image,

            // (sessionNo, 3,4,5,6,7,8,9) 내가 어떤 사람으로 보여질까?
            selfPerception: fields.selfPerception,

            // (sessionNo, 3,4,5,6,7,8,9) 상황에 대한 생각 + 생각으로 인한 결과(자동사고)
            situationThoughts: fields.situationThoughts,

            // (sessionNo, 3,4,5,6,7,8,9) 생각을 믿는 정도
            trustInThoughts: fields.trustInThoughts,

            // (sessionNo, 4,5,6,7,8,9,10) 인지오류
            cognitiveErrors: fields.cognitiveErrors,
        };

        let hasWhitespace = false;

        // 각 key에 대해 patient가 공백인지 확인
        Object.keys(fieldData).forEach((key) => {
            const values = fieldData[key as keyof Fields]; // key를 keyof Fields로 캐스팅

            values.forEach((item: any) => {
                if (item.patient === '' || /^\s*$/.test(item.patient)) {
                    // 공백이 있으면 모달 창 띄우기
                    hasWhitespace = true; // 공백이 있음을 표시
                }
            });
        });

        if (hasWhitespace) {
            oneButtonOpenModal(() => {}, '', '워크북내 모든 입력란에 작성해 주세요.', '확인');
        } else {
            twoButtonOpenModal(
                () => {},
                () => {
                    saveSessionData(fieldData);
                },
                '',
                '입력하신 모든 내용은 제출 완료 이후 수정이 불가합니다.\n정말 이대로 제출하시겠습니까?',
                '취소',
                '확인'
            );
        }
    };

    // 워크북 제출
    const saveSessionData = async (fieldData: any) => {
        const requestData = {
            registerAssignmentId: decryptData(encryptedData!),
            fields: fieldData,
            workBookSubmitted: true,
        };

        // 조건에 따라 POST 또는 PUT 요청을 보냄
        const method = workBookSubmitted ? 'put' : 'post';

        await axiosInstance[method](`/api/workBook/field/v1`, requestData).then((response) => {
            const res = response.data;
            if (res.result) {
                switch (res.message) {
                    case 'SESSION_FIELD_SAVE_SUCCESS':
                        if (workBookSubmitted) {
                            oneButtonOpenModal(
                                () => {
                                    refetch();
                                },
                                '',
                                '워크북 수정이 완료되었습니다.',
                                '확인'
                            );
                        } else {
                            refetch();
                        }
                        break;
                }
            }
        });
    };

    const handleScroll = () => {
        if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            const currentScrollY = window.scrollY;

            // 아래로 스크롤할 때
            if (currentScrollY > lastScrollY.current) {
                // 컴포넌트가 화면에 보이지 않으면 팝업을 켭니다.
                if (rect.bottom < 600 || rect.top > window.innerHeight) {
                    // 현재 팝업이 보이지 않을 때만 상태 업데이트
                    // showPopup이 false일 때만 PIP 활성화
                    if (!showPopup) {
                        setShowPopup(true);
                    }
                    // activatePIP();
                }
            }
            // 위로 스크롤할 때
            else {
                // 컴포넌트가 화면에 보이면 팝업을 끕니다.
                if (rect.top < window.innerHeight && rect.bottom > 0) {
                    if (showPopup) {
                        // showPopup이 true일 때만 PIP 비활성화
                        // setPlaying(false);
                        setShowPopup(false);
                        // deactivatePIP();
                    }
                }
            }

            // 마지막 스크롤 위치 업데이트
            lastScrollY.current = currentScrollY;
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation(); // 비디오 드래그 이벤트와 겹치지 않도록

        const startX = e.clientX;
        const startY = e.clientY;

        const root = document.documentElement;
        const computedStyle = getComputedStyle(root);
        const rootFontSize = parseFloat(computedStyle.fontSize); // 현재 루트 폰트 사이즈

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const padding = rootFontSize * 2; // 패딩 설정

            // popupStyle의 top과 right을 rem에서 px로 변환
            const currentTop = popupStyle.top * rootFontSize;
            const currentRight = popupStyle.right * rootFontSize;

            const newTop = currentTop + (moveEvent.clientY - startY);
            const newRight = currentRight - (moveEvent.clientX - startX); // 오른쪽 기준으로 변경

            const maxTop = window.innerHeight - (popupStyle.height * rootFontSize + padding);
            const maxRight = window.innerWidth - (popupStyle.width * rootFontSize + padding);

            const constrainedTop = Math.min(Math.max(padding, newTop), maxTop);
            const constrainedRight = Math.min(Math.max(padding, newRight), maxRight);

            setPopupStyle((prev) => ({
                ...prev,
                top: constrainedTop / rootFontSize, // rem으로 변환하여 설정
                right: constrainedRight / rootFontSize, // rem으로 변환하여 설정
            }));
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleResizeMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation();

        const startWidth = popupStyle.width * rootFontSize; // rem을 px로 변환
        const startHeight = popupStyle.height * rootFontSize; // rem을 px로 변환
        const startX = e.clientX;
        const startY = e.clientY;

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const newWidth = startWidth - (moveEvent.clientX - startX);
            const newHeight = newWidth / (16 / 9); // 비율 유지

            setPopupStyle((prev) => ({
                ...prev,
                width: newWidth > 100 ? newWidth / rootFontSize : 100 / rootFontSize, // 최소 너비 설정
                height: newHeight > 100 ? newHeight / rootFontSize : 372 / rootFontSize, // 최소 높이 설정
            }));
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    useEffect(() => {
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
        return () => {
            // 컴포넌트 언마운트 시 이벤트 리스너 제거
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showPopup]); // 빈 배열을 사용하여 한 번만 실행

    useEffect(() => {
        dispatch(resetData());
        window.scrollTo(0, 0);
        if (data) {
            if (Object.keys(data.fields).length !== 0) {
                dispatch(updateSessionPage(data));
            }
        }
    }, [data]);
    const handlePlay = () => {
        setPlaying(!playing); // 재생 중
    };

    // 동영상의 재생 시간 업데이트
    const handleProgress = (progress: { playedSeconds: number }) => {
        if (!showPopup) {
            setPlaybackTime(progress.playedSeconds);
        }
    };

    return (
        <div className={styles.main_container}>
            <div ref={targetRef}>
                <ReactPlayer
                    ref={playerRef}
                    url="https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT4.mp4"
                    height="auto" // 비디오 높이
                    width="100%"
                    playing={playing}
                    onPlay={handlePlay}
                    onPause={handlePlay}
                    volume={volume}
                    onProgress={handleProgress}
                    pip={false}
                    controls // 기본 컨트롤 비활성화
                    stopOnUnmount={true}
                />
            </div>
            {showPopup && popUpVisible && (
                <VideoPopup
                    showPopup={playing}
                    playerPopUpRef={playerPopUpRef}
                    onProgress={handleProgress}
                    currentTime={playbackTime}
                    popupStyle={popupStyle}
                    onMouseDown={handleMouseDown}
                    onResizeMouseDown={handleResizeMouseDown}
                    updatePopUpVisible={(visible: boolean) => setPopUpVisible(visible)}
                    videoUrl="https://ygjrfs5p6224.edge.naverncp.com/workBook/CBT4.mp4"
                />
            )}

            <SituationEmotion />

            <ThoughtSiuation />
            <BottomFiveSeven />
            <CognitiveError />
            <SubmitWorkbook onClick={sessionRole} />
        </div>
    );
};
