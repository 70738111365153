import { SpanText } from '../../../../../component/PublicComponent';
import Postit from '../../../../../assets/face/session2/session2_postit.png';
import styles from '../css/TenthSession.module.css';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as Icon_pencil } from '../../../../../assets/face/session8/icon_pencil.svg';
import { ReactComponent as Icon_session10_bubble } from '../../../../../assets/face/session10/icon_session10_bubble.svg';

import { ReactComponent as Icon_gold_medal } from '../../../../../assets/face/session10/icon_gold_medal.svg';
import { ReactComponent as Icon_silver_medal } from '../../../../../assets/face/session10/icon_silver_medal.svg';
import { ReactComponent as Icon_bronze_medal } from '../../../../../assets/face/session10/icon_bronze_medal.svg';
import Img1 from '../../../../../assets/face/session10/session10_plan.png';
import Img2 from '../../../../../assets/face/session1/session1_brain.png';
import non_title_brain from '../../../../../assets/face/session10/session10_nontitle_brain.png';
import { TherapistThoughtFeelInput, ThoughtFeelInput } from './FirstSessionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { updateBrain } from '../../../../../features/faceSessionReducer';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import {
    changeFeedbackState,
    deleteFeedback,
    updateSessionField,
} from '../../../../../features/session/sessionReducer';
import { updateField } from '../../../../../features/session/sessionReducer';
import { deleteSessionFeedback, saveSessionFeedback } from '../ts/SessionApi';
import { WriteFeedbackTextarea } from './FeedbackComponent';
import { ModalContext } from '../../../../../context/ModalContext';

export const PreventionRecurrence = () => {
    const dispatch = useDispatch();
    const memberInfo = getMemberInfo();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);
    const openFeedback = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            const emotional = fields.emotionalReactions[index];
            dispatch(
                updateField({
                    index: index,
                    key: 'emotionalReactions',
                    value: { ...emotional, visible: !emotional.visible },
                })
            );
        }
    };

    const onChangeEmotionalReactions = (index: number, text: string) => {
        const result = fields.emotionalReactions[index];

        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: index,
                        key: 'emotionalReactions',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: index,
                        key: 'emotionalReactions',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    if (finished) {
                        oneButtonOpenModal(
                            () => {
                                dispatch(
                                    updateSessionField({
                                        index: index,
                                        key: key,
                                        value: field,
                                        therapistName: memberInfo?.memberName!,
                                    })
                                );
                            },
                            '',
                            '피드백 수정이 완료되었습니다.',
                            '확인'
                        );
                    } else {
                        dispatch(
                            updateSessionField({
                                index: index,
                                key: key,
                                value: field,
                                therapistName: memberInfo?.memberName!,
                            })
                        );
                    }
                }
            });
        }
    };

    const cancel = (fieldId: number, field: any, key: string, index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            if (field[index].feedbackCheck) {
                const extractDataByKey = () => {
                    return {
                        [key]: field,
                    };
                };
                deleteSessionFeedback(fieldId, index, extractDataByKey()).then((response) => {
                    if (response.result) {
                        dispatch(deleteFeedback({ index: index, key: key, value: field }));
                    }
                });
            } else {
                openFeedback(index);
            }
        }
    };

    return (
        <div className={styles.prevention_recurrence}>
            <SpanText className="text_32_NotoSansKR_Bold" color="#41465A" text="Booster Session" />
            <div className={styles.middle_title}>
                <SpanText
                    className="text_22_NotoSansKR_Bold"
                    color="#626466"
                    text="1. 증상이 다시 나타날 수 있음을 인정합니다."
                />
                <SpanText
                    className="text_22_NotoSansKR_Bold"
                    color="#626466"
                    text="2. 회복 시 도움이 된 방법은 앞으로도 도움이 됩니다."
                />
                <SpanText
                    className="text_22_NotoSansKR_Bold"
                    color="#626466"
                    text="3. 바뀐 생각의 패턴을 계속 연습해 봅시다."
                />
            </div>
            <div className={styles.content_container}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <Icon_pencil className={styles.icon_pencil} />
                    <SpanText className="text_22_NotoSansKR_Bold" color="#626466" text="재발방지 일기" />
                </div>
                <div className={styles.experiences_content_container}>
                    <div className={styles.experiences_content}>
                        <div className={styles.postit_container}>
                            <img alt="postit" src={Postit} className={styles.postit} />
                            <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 신체적 반응</div>
                        </div>
                        <div className={styles.experiences_input_container}>
                            <textarea
                                className={`${styles.experiences_input} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onDoubleClick={() => openFeedback(0)}
                                onChange={(e) => onChangeEmotionalReactions(0, e.target.value)}
                                value={fields.emotionalReactions[0].patient}
                            />
                            {fields.emotionalReactions[0].visible && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeEmotionalReactions(0, text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.emotionalReactions,
                                            fields.emotionalReactions,
                                            'emotionalReactions',
                                            0
                                        )
                                    }
                                    cancel={() =>
                                        cancel(
                                            fieldIds.emotionalReactions,
                                            fields.emotionalReactions,
                                            'emotionalReactions',
                                            0
                                        )
                                    }
                                    height="19.06rem"
                                    data={fields.emotionalReactions[0]}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.experiences_content}>
                        <div className={styles.postit_container}>
                            <img alt="postit" src={Postit} className={styles.postit} />
                            <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 행동적 반응</div>
                        </div>
                        <div className={styles.experiences_input_container}>
                            <textarea
                                className={`${styles.experiences_input} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onDoubleClick={() => openFeedback(1)}
                                onChange={(e) => onChangeEmotionalReactions(1, e.target.value)}
                                value={fields.emotionalReactions[1].patient}
                            />
                            {fields.emotionalReactions[1].visible && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeEmotionalReactions(1, text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.emotionalReactions,
                                            fields.emotionalReactions,
                                            'emotionalReactions',
                                            1
                                        )
                                    }
                                    cancel={() =>
                                        cancel(
                                            fieldIds.emotionalReactions,
                                            fields.emotionalReactions,
                                            'emotionalReactions',
                                            1
                                        )
                                    }
                                    height="19.06rem"
                                    data={fields.emotionalReactions[1]}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.experiences_content}>
                        <div className={styles.postit_container}>
                            <img alt="postit" src={Postit} className={styles.postit} />
                            <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 인지적 반응</div>
                        </div>
                        <div className={styles.experiences_input_container}>
                            <textarea
                                className={`${styles.experiences_input} text_18_NotoSansKR_Regular`}
                                readOnly={readOnly}
                                onDoubleClick={() => openFeedback(2)}
                                onChange={(e) => onChangeEmotionalReactions(2, e.target.value)}
                                value={fields.emotionalReactions[2].patient}
                            />
                            {fields.emotionalReactions[2].visible && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeEmotionalReactions(2, text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.emotionalReactions,
                                            fields.emotionalReactions,
                                            'emotionalReactions',
                                            2
                                        )
                                    }
                                    cancel={() =>
                                        cancel(
                                            fieldIds.emotionalReactions,
                                            fields.emotionalReactions,
                                            'emotionalReactions',
                                            2
                                        )
                                    }
                                    height="19.06rem"
                                    data={fields.emotionalReactions[2]}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Icon_session10_bubble className={styles.icon_session10_bubble} />
            </div>
        </div>
    );
};

export const CognitiveErrorStatistics = () => {
    const { fields } = useSelector((state: RootState) => state.session);
    const cognitiveList = [
        {
            title: '흑백논리의 오류',
            content:
                "전부 혹은 전문의 사고 (All of nothing) 라고 불리는 흑백논리는 선택지 사이에 다수의 연속적 개념이 있다고 생각하기보다 흑과 백처럼 ‘이것' 아니면 ‘저것', 두 개의 범주로 나누어 상황을 본다.",
            select: false,
        },
        {
            title: '재앙화',
            content: '미래에 대한 예상을 현실적 고려 없이 파국적이고 부정적으로 생각하는 오류이다.',
            select: false,
        },
        {
            title: '명명하기',
            content:
                '라벨링(Labeling)이라고도 하는 명명하기는 자신이나 다른 사람 혹은 어떤 상황에 고정적이며 부정적인 이름을 붙인다.',
            select: false,
        },
        {
            title: '과대평가/과소평가',
            content: '어떤 상황이나 자신을 평가할 때 부정적인 측면을 강조하고 긍정적인 측면을 최소화한다.',
            select: false,
        },
        {
            title: '감정적 추론',
            content: '근거를 고려하지 않거나 무시하고 자신의 감정의 느낌에 따라 결론을 내린다.',
            select: false,
        },
        {
            title: '정신적 여과',
            content:
                "‘선택적 추상', ‘필터링'이라고 하며 전체 상황을 보는 대신에 자신이 집중하는 세세한 것에 지나치게 관심을 가진다.",
            select: false,
        },
        {
            title: '독심술의 오류',
            content: '다른사람들이 생각하는 것을 자신이 알 수 있다고 생각하는 오류이다.',
            select: false,
        },
        { title: '지나친 일반화', content: '우연히 일어난 사건을 일반화시켜 부정적인 결론을 내린다.', select: false },
        {
            title: '자기 탓',
            content:
                '다른 사람의 부정적인 행동의 이유에 대해 타당한 근거를 고려하지 않고 자신 때문에 타인이 부정적인 행동을 하는 것이라 믿는다.',
            select: false,
        },
        {
            title: '당위진술',
            content: '행동에 대한 확실하고 고정된 사고를 가지고 있으며, 충족되지 못할 때 얼마나 나쁜지 과대평가한다.',
            select: false,
        },
        { title: '터널시야', content: '어떤 상황의 부정적인 측면만을 본다.', select: false },
    ];

    const groupByFeedback = () => {
        const grouped: any = {};

        // 피드백을 기준으로 그룹화
        fields.cognitiveErrors.forEach((item) => {
            const feedback = item.feedback;

            // 피드백 값에 해당하는 그룹이 없으면 새로 생성
            if (!grouped[feedback]) {
                grouped[feedback] = [];
            }
            grouped[feedback].push(item);
        });

        // 객체를 배열로 변환
        const groupedArray = Object.entries(grouped).map(([feedback, items]) => ({
            count: Number(feedback), // 피드백 값을 숫자로 변환
            items,
        }));

        // 피드백 값으로 내림차순 정렬
        return groupedArray.sort((a, b) => b.count - a.count);
    };

    const Medal: any = ({ medal }: { medal: number }) => {
        switch (medal) {
            case 0:
                return <Icon_gold_medal width={'5.75rem'} height={'5.375rem'} />;
            case 1:
                return <Icon_silver_medal width={'5.75rem'} height={'5.375rem'} />;
            case 2:
                return <Icon_bronze_medal width={'5.75rem'} height={'5.375rem'} />;
        }
    };

    return (
        <div className={styles.cognitive_error_statistics}>
            <span
                className="text_32_NotoSansKR_Bold"
                style={{
                    color: '#41465A',
                }}
            >
                상위{' '}
                <span
                    className="text_32_Inter_Bold"
                    style={{
                        color: '#41465A',
                    }}
                >
                    3
                </span>
                개의 선택된 인지오류
            </span>
            <div className={styles.content_container}>
                {groupByFeedback().map((value: any, index) => {
                    return (
                        <div key={index.toString()} className={styles.statistics_content}>
                            <Medal medal={index} />
                            <div className={styles.statistics_input}>
                                <div>
                                    {value.items.map((val: any, idx: number) => (
                                        <span className="text_24_NotoSansKR_Bold">
                                            {idx !== 0 && ', '}
                                            {cognitiveList[Number(val.patient)].title}
                                        </span>
                                    ))}
                                </div>
                                <span
                                    className="text_22_NotoSansKR_Medium"
                                    style={{
                                        color: '#9D9FA2',
                                    }}
                                >
                                    <span
                                        className="text_22_Inter_Medium"
                                        style={{
                                            color: '#9D9FA2',
                                        }}
                                    >
                                        {value.count}
                                    </span>
                                    회
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={styles.img_plan_container}>
                <img alt="img1" src={Img1} className={styles.img} />
            </div>
        </div>
    );
};

export const ThoughtFeelInputAnswer = () => {
    const dispatch = useDispatch();
    const { inputData } = useSelector((state: RootState) => state.faceSession);
    const onChageBrain = (index: number, patient: string, therapist: string) => {
        dispatch(updateBrain({ index, patient, therapist }));
    };
    return (
        <div
            style={{
                display: 'flex',
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}
        >
            {inputData.brain.map((item, index) => (
                <div
                    key={index.toString()}
                    className={`${styles.thought_feel_input_container} ${
                        styles[`thought_feel_input_container${index}`] || ''
                    }`}
                >
                    <textarea
                        className={styles.thought_feel_input}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value, '')}
                    />
                    <textarea
                        className={`${styles.thought_feel_input} ${styles.therapist}`}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value, '')}
                    />
                </div>
            ))}
        </div>
    );
};

export const ThoughtFeelAnswer = () => {
    return (
        <div className={styles.thought_feel}>
            <div className={styles.thought_feel_container}>
                <img alt="brain" src={Img2} className={styles.img} />
                <TherapistThoughtFeelInput />
            </div>
        </div>
    );
};

export const ThoughtFeel = () => {
    return (
        <div className={styles.thought_feel_non_title}>
            <div className={styles.thought_feel_container}>
                <img alt="brain" src={non_title_brain} className={styles.img} />
                <ThoughtFeelInput />
            </div>
        </div>
    );
};
