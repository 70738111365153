import styled from 'styled-components';

import { ReactComponent as Icon_arrowbotom } from '../../../../assets/member/icon_arrowbottom.svg';
import { ReactComponent as Icon_check } from '../../../../assets/member/signup/icon_check.svg';
import { ReactComponent as Icon_organ } from '../../../../assets/public/icon_organ.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useEffect, useState } from 'react';
import { updateMemberOrgan } from '../../../../features/loginReducer';
import axiosInstance from '../../../../utils/AxiosInstanceJava';

interface DropDownButtonProps {
    $isSelected: boolean;
}

const DropDown = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 1.75rem;
`;

const DropDownButton = styled.button<DropDownButtonProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: 11.5rem;
    height: 3rem;
    padding: 0.75rem 1.25rem;
    gap: 0.5rem;
    border-radius: 100px;
    color: #626466;
    outline: ${(props) => (props.$isSelected ? '0.1875rem solid #3A5074' : '0.0625rem solid #e3e5ea')};
    background: #fff;
`;

const CustomUl = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    width: 11.5rem;
    padding: 1.25rem 1.5rem;
    gap: 1.5rem;
    top: 3.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
`;

const CustomLi = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    justify-content: space-between;
`;

export const SelectOrganizationLogin = () => {
    const dispatch = useDispatch();
    const memberType = useSelector((state: RootState) => state.memberType);
    const [organList, setOrganList] = useState<string[]>(['부산점', '창원점', '예정']);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);

    const [selectedOrgan, setSelectedOrgan] = useState<string>('');

    if (memberType.type === 0) return null;

    const DrowDownItem = () => {
        const selectItem = (item: string) => {
            if (item.includes('점')) {
                setSelectedOrgan(item);
                dispatch(updateMemberOrgan(item));
            }
            setIsSelected(!isSelected);
            setDropdownVisible(!dropdownVisible);
        };

        return (
            <CustomUl>
                {organList.map((item, index) => (
                    <CustomLi key={index.toString()}>
                        <button
                            onClick={() => {
                                if (index !== organList.length - 1) {
                                    selectItem(item);
                                }
                            }}
                            style={{
                                color: index === organList.length - 1 ? '#D2D5D9' : '#020202',
                            }}
                            className={`text_16_NotoSansKR_Medium`}
                        >
                            {item}
                        </button>
                        {selectedOrgan === item && <Icon_check width={'1.25rem'} height={'1.25rem'} />}
                    </CustomLi>
                ))}
            </CustomUl>
        );
    };

    const visible = () => {
        setIsSelected(!isSelected);
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <DropDown style={{}}>
            <DropDownButton $isSelected={isSelected} className="text_16_NotoSansKR_Medium" onClick={visible}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.25rem',
                    }}
                >
                    <Icon_organ width={'1.5rem'} height={'1.5rem'} />
                    <span
                        className={'text_16_NotoSansKR_Medium'}
                        style={{
                            color: selectedOrgan === '' ? '#9D9FA2' : '#020202',
                        }}
                    >
                        {selectedOrgan === '' ? '소속기관 선택' : selectedOrgan}
                    </span>
                </div>
                <Icon_arrowbotom width={'0.75rem'} height={'0.75rem'} />
            </DropDownButton>
            {dropdownVisible && <DrowDownItem />}
        </DropDown>
    );
};
