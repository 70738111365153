import { useContext, useState } from 'react';
import styles from './css/MenuComponent.module.css';
import styled from 'styled-components';
import { ReactComponent as Icon_naver_cafe } from '../assets/leftNavigation/icon_naver_cafe.svg';
import { ReactComponent as Icon_cafe_banner } from '../assets/leftNavigation/icon_cafe_banner.svg';
import { ReactComponent as Icon_session } from '../assets/face/icon_session.svg';
import { ReactComponent as Icon_file } from '../assets/face/icon_file.svg';
import { ReactComponent as Icon_user } from '../assets/leftNavigation/icon_user.svg';
import { ReactComponent as Icon_calendar } from '../assets/leftNavigation/icon_calendar.svg';
import { ReactComponent as Icon_payment } from '../assets/leftNavigation/icon_payment.svg';
import { ReactComponent as Icon_authority } from '../assets/leftNavigation/icon_authority.svg';
import { ReactComponent as Icon_program } from '../assets/leftNavigation/icon_program.svg';
import { ReactComponent as Icon_sales } from '../assets/leftNavigation/icon_sales.svg';
import { ReactComponent as Icon_user_statictics } from '../assets/leftNavigation/icon_user_statistics.svg';
import { ReactComponent as Icon_calendar_color_filter } from '../assets/leftNavigation/icon_calendar_color_filter.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../utils/croptojs';
import { updateSelectFilter } from '../features/reservationReducer';
import { resetData } from '../features/session/sessionReducer';
import { exportPDF } from '../pages/home/cbt/session/ts/SessionApi';
import { ModalContext } from '../context/ModalContext';
import { resetBlackBoardData } from '../features/session/blackboardReducer';

interface MenuButtonProps {
    $isSelectedMenu: number;
    $menu: number;
    $isSelectedAssignedMenu: number;
    $activate: boolean;
}

const TherapistMenuButton = styled.div<{
    $isSelectedMenu: { title: string; url: string };
    $menu: { title: string; url: string };
}>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0.687rem 1.0625rem;
    gap: 1rem;
    ${(props) => (props.$menu.title === '매출 현황' || props.$menu.title === '회원별 통계' ? 'color: #D2D5D9' : '')}
    ${(props) =>
        props.$isSelectedMenu.url === props.$menu.url
            ? `border-radius: 0.625rem; background-color: #E9F0FD; color: #3A5074`
            : ''}
`;

const PatientMenu = styled.div<{ $isSelectedMenu: string; $menu: string }>`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    padding: 0.3125rem 0;
    width: 6.9375rem;
    ${(props) =>
        props.$isSelectedMenu === props.$menu
            ? 'border-radius: 7px; background: linear-gradient(90deg, #4D717F 0%, #313E6D 100%); color:#fff;'
            : 'color: #9D9FA2;'}
`;

const SessionMenuButton = styled.div<MenuButtonProps>`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0.687rem 1.0625rem;
    border-radius: 0.625rem;
    gap: 1rem;
    ${(props) =>
        props.$isSelectedAssignedMenu === 0 && props.$isSelectedMenu === props.$menu
            ? `color: #3A5074; background-color: #E9F0FD`
            : 'color: #D2D5D9'}
`;

const SessionAssignedMenu = styled.div<MenuButtonProps>`
    display: flex;
    margin-top: 0.94rem;
    width: 100%;
    height: 2.875rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 0.625rem;
    ${(props) =>
        props.$activate && props.$isSelectedAssignedMenu !== 0 && props.$isSelectedMenu === props.$menu
            ? `color: #3A5074; background-color: #E9F0FD`
            : 'color: #D2D5D9'}
`;

// MANGAE MENU
const manageMenuList = [
    { title: '회원', url: 'assignedMember', icon: Icon_user },
    { title: '예약 현황', url: 'reservationStatus', icon: Icon_calendar },
];

const officailManageMenuList = [
    { title: '회원', url: 'assignedMember', icon: Icon_user },
    { title: '예약 현황', url: 'reservationStatus', icon: Icon_calendar },
    { title: '결제 내역', url: 'paymentDetails', icon: Icon_payment },
];

const administratorSETTINGSMenuList = [{ title: '권한', url: 'authority', icon: Icon_authority }];

const representativeSETTINGSMenuLIst = [
    { title: '권한', url: 'authority', icon: Icon_authority },
    { title: '프로그램', url: 'program', icon: Icon_program },
];

const officialANALYTICSMenuList = [
    { title: '매출 현황', url: '', icon: Icon_sales },
    { title: '회원별 통계', url: '', icon: Icon_user_statictics },
];

// 회원(환자)
export const PatientLeftMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get('page');
    const program = queryParams.get('program');

    const visible = useSelector((state: RootState) => state.navVisible);
    const { menus } = useSelector((state: RootState) => state.sessionMenu);
    const { message } = useSelector((state: RootState) => state.session);
    const menuList = ['대면', '비대면'];

    const additionalPayment = message === 'REMAINING_TIMES_ZERO' ? true : false;

    const currentCBT = location.pathname.includes('/face')
        ? 'face'
        : location.pathname.includes('/nonface')
        ? 'nonface'
        : null;

    const [isSelectedMenu, setIsSelectedMenu] = useState<string>(currentCBT === 'face' ? '대면' : '비대면');
    const [isSelectedSessionMenu, setIsSelectedSessionMenu] = useState<number>(Number(encryptedData!));

    const [isSelectedAssignedMenu, setIsSelectedAssignedMenu] = useState<number>(
        location.pathname.includes('/session/assigned') ? Number(encryptedData!) : 0
    );
    // 세션 이동
    const moveSession = (page: string, sessionId: string, programId: string) => {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('session', encryptData(sessionId));
        params.append('program', encryptData(programId));
        return `session?${params}`;
    };

    // 세션 과제 이동
    const moveAssignedSession = (page: string, assignedSessionId: string, programId: string) => {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('session', encryptData(assignedSessionId));
        params.append('program', encryptData(programId));
        return `session/assigned?${params}`;
    };

    const SessionRender = () => {
        return (
            <ul className={styles.menu_list_container}>
                {menus.map((item, index) => (
                    <li className={styles.li_menu} key={index.toString()}>
                        <Link
                            to={
                                item.sessionAccess
                                    ? moveSession(
                                          item.sessionNo.toString(),
                                          item.registerSessionId.toString(),
                                          item.registerId.toString()
                                      )
                                    : '#'
                            }
                            onClick={(e) => {
                                if (item.sessionAccess) {
                                    setIsSelectedSessionMenu(item.sessionNo);
                                    setIsSelectedAssignedMenu(0);
                                } else if (additionalPayment) {
                                    // 추가결제 페이지이동
                                    navigate('/yd/patient/program/select');
                                } else {
                                    e.preventDefault();
                                }
                            }}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <SessionMenuButton
                                $isSelectedMenu={Number(encryptedData)}
                                $isSelectedAssignedMenu={isSelectedAssignedMenu}
                                $menu={item.sessionNo}
                                $activate={location.pathname.includes('/face')}
                            >
                                <Icon_session
                                    className={styles.icon_menu}
                                    color={
                                        item.sessionAccess
                                            ? isSelectedSessionMenu === item.sessionNo
                                                ? '#3A5074'
                                                : '#020202'
                                            : '#D2D5D9'
                                    }
                                />
                                <span
                                    className={`${
                                        isSelectedSessionMenu === item.sessionNo
                                            ? 'text_16_NotoSansKR_Bold'
                                            : 'text_16_NotoSansKR_Medium'
                                    }`}
                                    style={{
                                        color: item.sessionAccess
                                            ? isSelectedSessionMenu === item.sessionNo
                                                ? '#3A5074'
                                                : '#020202'
                                            : '#D2D5D9',
                                    }}
                                >
                                    세션{item.sessionNo}
                                </span>
                            </SessionMenuButton>
                        </Link>
                        {item.sessionNo !== 1 &&
                            item.sessionNo !== 10 &&
                            location.pathname.includes('/face') &&
                            encryptedData! === item.sessionNo.toString() &&
                            item.assignAccess && (
                                <Link
                                    to={
                                        item.sessionAccess
                                            ? moveAssignedSession(
                                                  item.assignmentNo.toString(),
                                                  item.registerAssignmentId.toString(),
                                                  item.registerId.toString()
                                              )
                                            : '#'
                                    }
                                    onClick={(e) => {
                                        if (item.sessionAccess) {
                                            setIsSelectedAssignedMenu(item.assignmentNo);
                                        } else {
                                            e.preventDefault();
                                        }
                                    }}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <SessionAssignedMenu
                                        $isSelectedMenu={Number(encryptedData)}
                                        $isSelectedAssignedMenu={isSelectedAssignedMenu}
                                        $menu={item.assignmentNo}
                                        $activate={location.pathname.includes('/session/assigned')}
                                    >
                                        <Icon_file
                                            className={styles.icon_menu}
                                            color={
                                                item.assignAccess
                                                    ? isSelectedAssignedMenu === item.assignmentNo
                                                        ? '#3A5074'
                                                        : '#020202'
                                                    : '#D2D5D9'
                                            }
                                        />
                                        <span
                                            className={`${
                                                isSelectedAssignedMenu === item.assignmentNo
                                                    ? 'text_16_NotoSansKR_Bold'
                                                    : 'text_16_NotoSansKR_Medium'
                                            }`}
                                            style={{
                                                color: item.assignAccess
                                                    ? isSelectedAssignedMenu === item.assignmentNo
                                                        ? '#3A5074'
                                                        : '#020202'
                                                    : '#D2D5D9',
                                            }}
                                        >
                                            세션{item.assignmentNo} 과제
                                        </span>
                                    </SessionAssignedMenu>
                                </Link>
                            )}
                    </li>
                ))}
            </ul>
        );
    };

    const MenuRender = () => {
        return (
            <div className={styles.menu_container}>
                {menuList.map((item, index) => (
                    <PatientMenu
                        key={index.toString()}
                        className={isSelectedMenu === item ? `text_16_NotoSansKR_Bold` : `text_16_NotoSansKR_Medium`}
                        $isSelectedMenu={isSelectedMenu}
                        $menu={item}
                    >
                        {item}
                    </PatientMenu>
                ))}
            </div>
        );
    };

    return (
        <nav className={`${styles.sidebar} ${visible.navVisible ? styles.open : styles.closed}`}>
            <div
                className={styles.main_container}
                style={{
                    height: '100%',
                }}
            >
                <div>
                    <MenuRender />
                    <div
                        className="text_14_Inter_Bold"
                        style={{
                            color: '#9D9FA2',
                            padding: '0 0.75rem',
                        }}
                    >
                        SESSION
                    </div>
                    <SessionRender />
                </div>
                <button
                    className={`${styles.naver_cafe_button} text_16_NotoSansKR_Bold`}
                    onClick={() =>
                        window.open(
                            'https://cafe.naver.com/ca-fe/cafes/30912489/menus/108/articles/write?boardType=L',
                            '_blank'
                        )
                    }
                >
                    <Icon_cafe_banner className={styles.icon_cafe_banner} />
                </button>
            </div>
        </nav>
    );
};

// 치료자
export const TherapistLeftMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const visible = useSelector((state: RootState) => state.navVisible);
    const { blackboardVisible, contentRef } = useSelector((state: RootState) => state.blackboard);
    const { twoButtonOpenModal } = useContext(ModalContext);
    const { pathname } = useLocation();
    const currentPath = pathname.split('/');
    const params = new URLSearchParams(window.location.search);
    const session = params.get('session');
    const [isSelectedTherapistMenu, setIsSelectedSessionMenu] = useState<{ title: string; url: string }>({
        title: '회원',
        url: currentPath[3],
    });

    // 페이지 이동
    const movePage = (item: { title: string; url: string; icon: any }) => {
        if (blackboardVisible) {
            twoButtonOpenModal(
                () => {},
                () => {
                    setIsSelectedSessionMenu(item);
                    exportPDF(contentRef, session!, dispatch(resetBlackBoardData()));
                    navigate(item.url);
                },
                '전자칠판 종료',
                '제출하지 않고 페이지를 벗어날 경우, 입력한 내용은 저장되지 않습니다. 하단의 저장 버튼 또는 페이지 내 상단 전자칠판 종료 버튼을 눌러 저장을 완료해 주세요.',
                '취소',
                '저장'
            );
        } else {
            setIsSelectedSessionMenu(item);
            navigate(item.url);
        }
    };

    const MenuRender = ({ menuName }: { menuName: string }) => {
        return (
            <div style={{}}>
                <div
                    className="text_14_Inter_Bold"
                    style={{
                        color: '#9D9FA2',
                        padding: '0 0.75rem',
                    }}
                >
                    {menuName}
                </div>
                <ul className={styles.menu_list_container}>
                    {manageMenuList.map((item: { title: string; url: string; icon: any }, index: number) => (
                        <li className={styles.li_menu} key={index.toString()}>
                            <Link
                                to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    movePage(item);
                                }}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                <TherapistMenuButton
                                    className={
                                        isSelectedTherapistMenu.url === item.url
                                            ? `text_16_NotoSansKR_Bold`
                                            : `text_16_NotoSansKR_Medium`
                                    }
                                    $isSelectedMenu={isSelectedTherapistMenu}
                                    $menu={item}
                                >
                                    <item.icon
                                        className={styles.icon_menu}
                                        color={isSelectedTherapistMenu.url === item.url ? '#3A5074' : '#020202'}
                                    />
                                    {item.title}
                                </TherapistMenuButton>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <nav
            className={`${styles.sidebar} ${visible.navVisible ? styles.open : styles.closed}`}
            style={{
                height: window.innerHeight,
            }}
        >
            <div className={styles.main_container}>
                <div>
                    <MenuRender menuName="MANAGE" />
                </div>
                <CalendarFilters />
                <button
                    className={`${styles.naver_cafe_button} text_16_NotoSansKR_Bold`}
                    onClick={() =>
                        window.open(
                            'https://cafe.naver.com/ca-fe/cafes/30912489/menus/108/articles/write?boardType=L',
                            '_blank'
                        )
                    }
                >
                    <Icon_cafe_banner className={styles.icon_cafe_banner} />
                </button>
            </div>
        </nav>
    );
};

// 매니저
export const ManagerLeftMenu = () => {
    const visible = useSelector((state: RootState) => state.navVisible);
    const { pathname } = useLocation();
    const currentPath = pathname.substring(pathname.lastIndexOf('/') + 1);
    const [isSelectedTherapistMenu, setIsSelectedSessionMenu] = useState<{ title: string; url: string }>({
        title: '회원',
        url: currentPath,
    });

    const MenuRender = ({ menuName }: { menuName: string }) => {
        let menu: { title: string; url: string; icon: any }[];
        switch (menuName) {
            case 'MANAGE':
                menu = officailManageMenuList;
                break;
            case 'ANALYTICS':
                menu = officialANALYTICSMenuList;
                break;
        }

        return (
            <>
                <div
                    className="text_14_Inter_Bold"
                    style={{
                        color: '#9D9FA2',
                        padding: '0 0.75rem',
                    }}
                >
                    {menuName}
                </div>
                <ul className={styles.menu_list_container}>
                    {menu!.map((item: { title: string; url: string; icon: any }, index: number) => (
                        <li className={styles.li_menu} key={index.toString()}>
                            <Link
                                to={item.title === '매출 현황' || item.title === '회원별 통계' ? '' : item.url}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                <TherapistMenuButton
                                    onClick={() => {
                                        console.log(item);
                                        setIsSelectedSessionMenu(item);
                                    }}
                                    className={
                                        isSelectedTherapistMenu.url === item.url
                                            ? `text_16_NotoSansKR_Bold`
                                            : `text_16_NotoSansKR_Medium`
                                    }
                                    $isSelectedMenu={isSelectedTherapistMenu}
                                    $menu={item}
                                >
                                    <item.icon
                                        className={styles.icon_menu}
                                        color={isSelectedTherapistMenu.url === item.url ? '#3A5074' : '#020202'}
                                    />
                                    {item.title}
                                </TherapistMenuButton>
                            </Link>
                        </li>
                    ))}
                </ul>
            </>
        );
    };
    return (
        <nav className={`${styles.sidebar} ${visible.navVisible ? styles.open : styles.closed}`}>
            <div className={styles.main_container}>
                <div>
                    <MenuRender menuName="MANAGE" />
                    <MenuRender menuName="ANALYTICS" />
                </div>
                <CalendarFilters />
                <button
                    className={`${styles.naver_cafe_button} text_16_NotoSansKR_Bold`}
                    onClick={() =>
                        window.open(
                            'https://cafe.naver.com/ca-fe/cafes/30912489/menus/108/articles/write?boardType=L',
                            '_blank'
                        )
                    }
                >
                    <Icon_cafe_banner className={styles.icon_cafe_banner} />
                </button>
            </div>
        </nav>
    );
};

// 관리자
export const AdministratorLeftMenu = () => {
    const visible = useSelector((state: RootState) => state.navVisible);
    const { pathname } = useLocation();
    const currentPath = pathname.substring(pathname.lastIndexOf('/') + 1);
    const [isSelectedTherapistMenu, setIsSelectedSessionMenu] = useState<{ title: string; url: string }>({
        title: '회원',
        url: currentPath,
    });

    const MenuRender = ({ menuName }: { menuName: string }) => {
        let menu: { title: string; url: string; icon: any }[];
        switch (menuName) {
            case 'MANAGE':
                menu = officailManageMenuList;
                break;
            case 'SETTINGS':
                menu = administratorSETTINGSMenuList;
                break;
            case 'ANALYTICS':
                menu = officialANALYTICSMenuList;
                break;
        }

        return (
            <>
                <div
                    className="text_14_Inter_Bold"
                    style={{
                        color: '#9D9FA2',
                        padding: '0 0.75rem',
                    }}
                >
                    {menuName}
                </div>
                <ul className={styles.menu_list_container}>
                    {menu!.map((item: { title: string; url: string; icon: any }, index: number) => (
                        <li className={styles.li_menu} key={index.toString()}>
                            <Link
                                to={item.title === '매출 현황' || item.title === '회원별 통계' ? '' : item.url}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                <TherapistMenuButton
                                    onClick={() => {
                                        setIsSelectedSessionMenu(item);
                                    }}
                                    className={
                                        isSelectedTherapistMenu.url === item.url
                                            ? `text_16_NotoSansKR_Bold`
                                            : `text_16_NotoSansKR_Medium`
                                    }
                                    $isSelectedMenu={isSelectedTherapistMenu}
                                    $menu={item}
                                >
                                    <item.icon
                                        className={styles.icon_menu}
                                        color={isSelectedTherapistMenu.url === item.url ? '#3A5074' : '#020202'}
                                    />
                                    {item.title}
                                </TherapistMenuButton>
                            </Link>
                        </li>
                    ))}
                </ul>
            </>
        );
    };

    return (
        <nav className={`${styles.sidebar} ${visible.navVisible ? styles.open : styles.closed}`}>
            <div className={styles.main_container}>
                <div>
                    <MenuRender menuName="MANAGE" />
                    <MenuRender menuName="SETTINGS" />
                    <MenuRender menuName="ANALYTICS" />
                </div>
                <CalendarFilters />
                <button
                    className={`${styles.naver_cafe_button} text_16_NotoSansKR_Bold`}
                    onClick={() =>
                        window.open(
                            'https://cafe.naver.com/ca-fe/cafes/30912489/menus/108/articles/write?boardType=L',
                            '_blank'
                        )
                    }
                >
                    <Icon_cafe_banner className={styles.icon_cafe_banner} />
                </button>
            </div>
        </nav>
    );
};

// 시스템 관리자
export const RepresentativeLeftMenu = () => {
    const visible = useSelector((state: RootState) => state.navVisible);
    const { pathname } = useLocation();
    const currentPath = pathname.substring(pathname.lastIndexOf('/') + 1);
    const [isSelectedTherapistMenu, setIsSelectedSessionMenu] = useState<{ title: string; url: string }>({
        title: '회원',
        url: currentPath,
    });

    const MenuRender = ({ menuName }: { menuName: string }) => {
        let menu: { title: string; url: string; icon: any }[];
        switch (menuName) {
            case 'MANAGE':
                menu = officailManageMenuList;
                break;
            case 'SETTINGS':
                menu = representativeSETTINGSMenuLIst;
                break;
            case 'ANALYTICS':
                menu = officialANALYTICSMenuList;
                break;
        }

        return (
            <>
                <div
                    className="text_14_Inter_Bold"
                    style={{
                        color: '#9D9FA2',
                        padding: '0 0.75rem',
                    }}
                >
                    {menuName}
                </div>
                <ul className={styles.menu_list_container}>
                    {menu!.map((item: { title: string; url: string; icon: any }, index: number) => (
                        <li className={styles.li_menu} key={index.toString()}>
                            <Link
                                to={item.title === '매출 현황' || item.title === '회원별 통계' ? '' : item.url}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                <TherapistMenuButton
                                    onClick={() => {
                                        setIsSelectedSessionMenu(item);
                                    }}
                                    className={
                                        isSelectedTherapistMenu.url === item.url
                                            ? `text_16_NotoSansKR_Bold`
                                            : `text_16_NotoSansKR_Medium`
                                    }
                                    $isSelectedMenu={isSelectedTherapistMenu}
                                    $menu={item}
                                >
                                    <item.icon
                                        className={styles.icon_menu}
                                        color={isSelectedTherapistMenu.url === item.url ? '#3A5074' : '#020202'}
                                    />
                                    {item.title}
                                </TherapistMenuButton>
                            </Link>
                        </li>
                    ))}
                </ul>
            </>
        );
    };
    return (
        <nav className={`${styles.sidebar} ${visible.navVisible ? styles.open : styles.closed}`}>
            <div className={styles.main_container}>
                <div>
                    <MenuRender menuName="MANAGE" />
                    <MenuRender menuName="SETTINGS" />
                    <MenuRender menuName="ANALYTICS" />
                </div>
                <CalendarFilters />
                <button
                    className={`${styles.naver_cafe_button} text_16_NotoSansKR_Bold`}
                    onClick={() =>
                        window.open(
                            'https://cafe.naver.com/ca-fe/cafes/30912489/menus/108/articles/write?boardType=L',
                            '_blank'
                        )
                    }
                >
                    <Icon_cafe_banner className={styles.icon_cafe_banner} />
                </button>
            </div>
        </nav>
    );
};

export const CalendarFilters = () => {
    const dispatch = useDispatch();
    const { selectFilter } = useSelector((state: RootState) => state.events);
    const filters = [
        { title: '대면 CBT (유형1)', color: '#78BF79' },
        { title: '대면 CBT (유형2)', color: '#4D717F' },
        { title: '이완치료', color: '#DCBB67' },
        { title: 'TMS', color: '#7D6ADE' },
        { title: '심리상담', color: '#DC63C2' },
    ];

    const { pathname } = useLocation();
    const currentSession = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (currentSession !== 'reservationStatus') return null;

    const changeFilter = (filter: string) => {
        let arrary = [...selectFilter];
        const index = arrary.indexOf(filter);
        if (index > -1) {
            arrary.splice(index, 1);
        } else {
            arrary.push(filter);
        }

        dispatch(updateSelectFilter(arrary));
    };

    return (
        <div className={styles.calendar_color_filter_container}>
            <div
                className="text_14_Inter_Bold"
                style={{
                    color: '#9D9FA2',
                    padding: '0 0.75rem',
                }}
            >
                FILTERS
            </div>
            <div className={styles.item_container}>
                {filters.map((item, index) => (
                    <button onClick={() => changeFilter(item.title)} className={styles.item}>
                        <Icon_calendar_color_filter
                            className={styles.icon_menu}
                            color={selectFilter.includes(item.title) ? item.color : '#FFF'}
                        />
                        <div className="text_16_NotoSansKR_Medium">{item.title}</div>
                    </button>
                ))}
            </div>
        </div>
    );
};
