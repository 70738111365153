import ReactModal from 'react-modal';

import styles from './css/SymptomSelectModal.module.css';
import LogoPng from '../../../../assets/member/login/logo.png';
import axiosInstance from '../../../../utils/AxiosInstanceJava';
import { ReactComponent as Icon_depression } from '../../../../assets/face/icon_depression.svg';
import { ReactComponent as Icon_burnout } from '../../../../assets/face/icon_burnout.svg';
import { ReactComponent as Icon_anxiety } from '../../../../assets/face/icon_anxiety.svg';
import { ReactComponent as Icon_perfectionism } from '../../../../assets/face/icon_perfectionism.svg';
import { ReactComponent as Icon_impulsivity } from '../../../../assets/face/icon_impulsivity.svg';
import { ReactComponent as Icon_relationships } from '../../../../assets/face/icon_relationships.svg';
import { ReactComponent as Icon_obsession } from '../../../../assets/face/icon_obsession.svg';
import { ReactComponent as Icon_stress } from '../../../../assets/face/icon_stress.svg';
import { ReactComponent as Icon_panic } from '../../../../assets/face/icon_panic.svg';
import { ReactComponent as Icon_support } from '../../../../assets/face/icon_support.svg';
import { useContext, useEffect } from 'react';
import { ModalContext } from '../../../../context/ModalContext';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { getTherapistList } from '../../official/assignedMember/ts/AssignedMemberApi';
import { useQuery } from '@tanstack/react-query';
import { decryptData } from '../../../../utils/croptojs';

export const SymptomSelectModal = () => {
    const customModalStyles: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            zIndex: 130,
        },
        content: {
            border: 'none',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const queryParams = new URLSearchParams(window.location.search);
    const currentUrl = window.location.pathname;
    const program = queryParams.get('program');

    const { twoButtonOpenModal, onConfirm, symptomOpenModal, symptomVisible } = useContext(ModalContext);

    useEffect(() => {
        // 모달이 열릴 때 스크롤 비활성화
        if (symptomVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset'; // 모달이 닫힐 때 스크롤 활성화
        }

        // 컴포넌트 언마운트 시에도 스크롤을 원래대로 되돌림
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [symptomVisible]);

    const symptomList = [
        { title: '우울', icon: Icon_depression },
        { title: '불안', icon: Icon_anxiety },
        { title: '번아웃', icon: Icon_burnout },
        { title: '완벽주의', icon: Icon_perfectionism },
        { title: '충동성', icon: Icon_impulsivity },
        { title: '강박', icon: Icon_obsession },
        { title: '대인관계', icon: Icon_relationships },
        { title: '스트레스', icon: Icon_stress },
        { title: '공황', icon: Icon_panic },
        { title: '기타', icon: Icon_support },
    ];

    const handleSymptomSelect = async (symptom: string) => {
        await axiosInstance
            .put(`/api/workBook/chief/v1`, {
                registerId: decryptData(program!),
                chiefComplaint: symptom,
            })
            .then((response) => {
                if (response.data.result) {
                    // if (currentUrl.includes('/nonface')) {
                    //     window.location.href = `/yd/patient/face/sessionMap?${queryParams}}`;
                    // }
                    symptomOpenModal(false);
                }
            });
    };

    const SymptomListRender = () => {
        return (
            <ul className={styles.symptom_container}>
                {symptomList.map((item, index) => (
                    <li key={index.toString()}>
                        <button
                            className={`${styles.symptom_button} text_18_NotoSansKR_Medium`}
                            onClick={() =>
                                twoButtonOpenModal(
                                    () => {},
                                    () => {
                                        handleSymptomSelect(item.title);
                                    },
                                    '주호소 증상 선택 확인',
                                    `${item.title}을 선택한 것이 맞으십니까?`,
                                    '취소',
                                    '확인'
                                )
                            }
                        >
                            <item.icon
                                style={{
                                    width: '2.5rem',
                                    height: '2.5rem',
                                }}
                            />
                            {item.title}
                        </button>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <ReactModal
            style={customModalStyles}
            isOpen={symptomVisible}
            shouldCloseOnOverlayClick
            appElement={document.getElementById('root') as HTMLElement}
            onRequestClose={() => {
                console.log('hihihi');
            }}
        >
            <div className={styles.modal_content_container}>
                <img src={LogoPng} className={styles.logo} />
                <div
                    className="text_16_NotoSansKR_Bold"
                    style={{
                        color: '#9D9FA2',
                    }}
                >
                    <span
                        className="text_16_Inter_Bold"
                        style={{
                            color: '#9D9FA2',
                        }}
                    >
                        YD
                    </span>
                    인지행동 치료
                </div>
                <div className={`${styles.middle_title} text_22_NotoSansKR_Bold`}>주호소 증상을 선택해 주세요!</div>
                <div className={`${styles.content_title} text_18_NotoSansKR_Medium`}>
                    세션을 진행하기 앞서 치료를 통해 가장 개선하고 싶은 증상 한 가지를 선택해 주세요.
                </div>
                <SymptomListRender />
            </div>
        </ReactModal>
    );
};
