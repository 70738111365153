import { useContext, useState } from 'react';
import styles from './css/NotificationComponent.module.css';
import { getNotification, NotificationType } from '../pages/public/ts/Public';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { encryptData } from '../utils/croptojs';
import { useDispatch } from 'react-redux';
import { updateNotificationVisible } from '../features/headerReducer';
import axiosInstance from '../utils/AxiosInstanceJava';
import { ModalContext } from '../context/ModalContext';
import { getMemberInfo } from '../utils/GetMemberInfo';

export const Notification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = new URLSearchParams();
    const { twoButtonOpenModal } = useContext(ModalContext);

    const [isSelected, setIsSelected] = useState<string>('all');
    const [categoryList, setCategoryList] = useState([
        { title: '전체', category: 'all' },
        { title: '세션', category: 'session' },
        { title: '가입신청', category: 'register' },
        { title: '결제요청', category: 'payment' },
    ]);

    const { data, isLoading, error, refetch } = useQuery<NotificationType>({
        queryKey: ['getNotification'],
        queryFn: () => getNotification(),
        placeholderData: keepPreviousData,
    });

    // 가입신청 승인/거절
    const signupApproval = async (approvalCheck: boolean, pushAlarmId: number) => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .put(`/api/${memberInfo?.memberRole}/approve/v1`, {
                pushAlarmId: pushAlarmId,
                approvalCheck: approvalCheck,
            })
            .then((response) => {
                if (response.data.result) {
                    twoButtonOpenModal(
                        () => {},
                        () => {
                            navigate('/yd/official/authority');
                        },
                        '',
                        '해당 직원에게 권한을 부여하시겠습니까?',
                        '아니요',
                        '네'
                    );
                }
            });
    };

    // 결제요청 승인/거절

    const paymentApproval = async (pushData: any) => {
        const memberInfo = getMemberInfo();
        await axiosInstance
            .put(`/api/${memberInfo?.memberRole}/payment/v1`, {
                pushAlarmId: pushData.pushAlarmId,
                payPrice: 0,
                discountPrice: 0,
                approvalCheck: false,
                separateCount: 0,
            })
            .then((response) => {
                refetch();
            });
    };

    const moveToPaymentOptions = (pushData: any) => {
        dispatch(updateNotificationVisible(false));
        const params = new URLSearchParams();
        const paymentData = JSON.stringify({
            pushAlarmId: pushData.pushAlarmId,
            workBookProductId: pushData.idxInfo.workBookProductId,
            patientMemberId: pushData.idxInfo.patientMemberId,
        });
        params.append('paymentData', paymentData);
        navigate(`paymentOptions?${params}`);
    };

    const moveToPageHadle = (item: any) => {
        dispatch(updateNotificationVisible(false));
        switch (item.pushType) {
            case 'face-session':
                params.append('page', item.idxInfo.sessionNo.toString());
                params.append('session', encryptData(item.idxInfo.idx));
                params.append('program', encryptData(item.idxInfo.registerId));
                return navigate(`/yd/patient/face/session?${params}`);

            case 'non-face-session':
                params.append('page', item.idxInfo.sessionNo.toString());
                params.append('session', encryptData(item.idxInfo.idx));
                params.append('program', encryptData(item.idxInfo.registerId));
                return navigate(`/yd/patient/nonface/session?${params}`);
            case 'no-cbt':
                return;
            case 'face-assignment':
                params.append('page', item.idxInfo.sessionNo.toString());
                params.append('session', encryptData(item.idxInfo.idx));
                params.append('program', encryptData(item.idxInfo.registerId));
                return navigate(`/yd/patient/face/session/assigned?${params}`);
            case 'non-face-assignment':
                params.append('page', item.idxInfo.sessionNo.toString());
                params.append('session', encryptData(item.idxInfo.idx));
                params.append('program', encryptData(item.idxInfo.registerId));
                return navigate(`/yd/patient/nonface/session/assigned?${params}`);
            case 'memberDetail':
                const queryString = `data=${encodeURIComponent(encryptData(item.memberId))}`;
                return navigate(`detail?${queryString}`);

            case 'register':
                return '/register';
            case 'payment':
                return '/payment';
        }
    };

    const changePushType = (type: string) => {
        switch (type) {
            case 'all':
                return '전체';
            case 'session':
                return '세션';
            case 'register':
                return '가입신청';
            case 'payment':
                return '결제요청';
        }
    };

    const changePushTypeColor = (type: string) => {
        switch (type) {
            case 'all':
                return '#4D717F';
            case 'session':
                return '#3a5074';
            case 'register':
                return '#3a5074';
            case 'payment':
                return '#4D717F';
        }
    };

    const changePushTypeBgColor = (type: string) => {
        switch (type) {
            case 'register':
                return '#E4F5F8';
            case 'payment':
                return '#E9F0FD';
        }
    };

    const pushTypeFilter = data?.pushAlarms.filter((item) => item.category === isSelected);

    const CategoryMenu = () => {
        return (
            <div className={styles.menu_container}>
                {categoryList.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => setIsSelected(item.category)}
                        className={`${styles.menu_button} ${
                            isSelected === item.category ? styles.activate : styles.deactivate
                        } ${isSelected === item.category ? 'text_18_NotoSansKR_Bold' : 'text_18_NotoSansKR_Medium'}`}
                    >
                        <span>{item.title}</span>
                    </button>
                ))}
            </div>
        );
    };

    const NotificationDeactivateButton = ({ pushData }: { pushData: any }) => {
        return (
            <div className={styles.approval_refusal_container}>
                <div className={styles.approval_refusal_button}>
                    <span
                        className="text_16_NotoSansKR_Bold"
                        style={{
                            color: '#9D9FA2',
                        }}
                    >
                        {changePushType(pushData.type)}
                        {pushData.approvalCheck === 1 ? '승인 완료' : '거절 완료'}
                    </span>
                </div>
            </div>
        );
    };

    const NotificationActivateButton = ({ pushData }: { pushData: any }) => {
        return (
            <div className={styles.approval_refusal_container}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (pushData.pushType === 'register') {
                                signupApproval(false, pushData.pushAlarmId);
                            } else {
                                paymentApproval(pushData);
                            }
                        }}
                        className={styles.refusal_button}
                    >
                        <span className="text_16_NotoSansKR_Bold" style={{ color: '#020202' }}>
                            거절
                        </span>
                    </button>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (pushData.pushType === 'register') {
                                signupApproval(true, pushData.pushAlarmId);
                            } else {
                                moveToPaymentOptions(pushData);
                            }
                        }}
                        className={styles.approval_button}
                    >
                        <span className="text_16_NotoSansKR_Bold" style={{ color: '#FFF' }}>
                            {pushData.pushType === 'payment' ? '결제 옵션 설정' : '승인'}
                        </span>
                    </button>
                </div>
            </div>
        );
    };

    const NotificationContent = () => {
        return (
            <div className={styles.content}>
                {(isSelected !== 'all' ? pushTypeFilter : data?.pushAlarms)?.map((item, index) => (
                    <div
                        key={index.toString()}
                        onClick={(e) => {
                            if (item.pushType !== 'register' && item.pushType !== 'payment') {
                                e.preventDefault();
                                e.stopPropagation();
                                moveToPageHadle(item);
                            }
                        }}
                        className={styles.content_item}
                    >
                        <div className={styles.content_item_title_container}>
                            {item.category !== 'all' && (
                                <div
                                    className={styles.content_item_pushtype}
                                    style={{
                                        color: changePushTypeColor(item.category),
                                        backgroundColor: changePushTypeBgColor(item.category),
                                    }}
                                >
                                    <span className="text_16_NotoSansKR_Medium">{changePushType(item.category)}</span>
                                </div>
                            )}
                            <span className="text_18_NotoSansKR_Medium">{item.title}</span>
                        </div>
                        <span className="text_16_NotoSansKR_Regular">{item.content}</span>
                        {(item.pushType === 'register' || item.pushType === 'payment') && item.approvalCheck === 0 && (
                            <NotificationActivateButton pushData={item} />
                        )}
                        {(item.pushType === 'register' || item.pushType === 'payment') &&
                            (item.approvalCheck === 1 || item.approvalCheck === 2) && (
                                <NotificationDeactivateButton pushData={item} />
                            )}
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            {item.regDate}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.modal_main_container}>
            <div className={styles.title_container}>
                <span className="text_28_NotoSansKR_Bold">알림</span>
                <span
                    className="text_18_NotoSansKR_Bold"
                    style={{
                        color: '#3A5074',
                    }}
                >
                    모두 읽음 표시
                </span>
            </div>
            <div className={styles.content_container}>
                <CategoryMenu />
                <div
                    style={{
                        width: '100%',
                        height: '34.25rem',
                        overflowY: 'scroll',
                    }}
                >
                    <NotificationContent />
                    <div className={`${styles.exp_date} text_18_NotoSansKR_Regular`}>
                        알림은 최대{' '}
                        <span
                            className="text_18_Inter_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            30
                        </span>
                        일간 보관됩니다.
                    </div>
                </div>
            </div>
        </div>
    );
};
