import { useContext, useState } from 'react';
import styles from '../css/SecondSession.module.css';

import Postit from '../../../../../assets/face/session2/session2_postit.png';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    changeFeedbackState,
    deleteFeedback,
    updateField,
    updateSessionField,
} from '../../../../../features/session/sessionReducer';
import { WriteFeedbackTextarea } from './FeedbackComponent';
import { deleteSessionFeedback, saveSessionFeedback } from '../ts/SessionApi';
import { ModalContext } from '../../../../../context/ModalContext';

export const ExperiencesEmotion = () => {
    const dispatch = useDispatch();
    const memberInfo = getMemberInfo();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);
    const openFeedback = (index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            const emotional = fields.emotionalReactions[index];
            dispatch(
                updateField({
                    index: index,
                    key: 'emotionalReactions',
                    value: { ...emotional, visible: !emotional.visible },
                })
            );
        }
    };

    const onChangeEmotionalReactions = (index: number, text: string) => {
        const result = fields.emotionalReactions[index];

        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: index,
                        key: 'emotionalReactions',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: index,
                        key: 'emotionalReactions',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    if (finished) {
                        oneButtonOpenModal(
                            () => {
                                dispatch(
                                    updateSessionField({
                                        index: index,
                                        key: key,
                                        value: field,
                                        therapistName: memberInfo?.memberName!,
                                    })
                                );
                            },
                            '',
                            '피드백 수정이 완료되었습니다.',
                            '확인'
                        );
                    } else {
                        dispatch(
                            updateSessionField({
                                index: index,
                                key: key,
                                value: field,
                                therapistName: memberInfo?.memberName!,
                            })
                        );
                    }
                }
            });
        }
    };
    const cancel = (fieldId: number, field: any, key: string, index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            if (field[index].feedbackCheck) {
                const extractDataByKey = () => {
                    return {
                        [key]: field,
                    };
                };
                deleteSessionFeedback(fieldId, index, extractDataByKey()).then((response) => {
                    if (response.result) {
                        dispatch(deleteFeedback({ index: index, key: key, value: field }));
                    }
                });
            } else {
                openFeedback(index);
            }
        }
    };

    return (
        <div className={styles.experiences_emotion_container}>
            <div
                className="text_32_NotoSansKR_Bold"
                style={{
                    color: '#41465A',
                }}
            >
                내가 경험하는 감정의 반응
            </div>
            <div className={styles.experiences_content_container}>
                <div className={styles.experiences_content}>
                    <div className={styles.postit_container}>
                        <img alt="postit" src={Postit} className={styles.postit} />
                        <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 신체적 반응</div>
                    </div>
                    <div className={styles.experiences_input_container}>
                        <textarea
                            className={styles.experiences_input}
                            readOnly={readOnly}
                            onDoubleClick={() => openFeedback(0)}
                            onChange={(e) => onChangeEmotionalReactions(0, e.target.value)}
                            value={fields.emotionalReactions[0].patient}
                        />
                        {fields.emotionalReactions[0].visible && (
                            <WriteFeedbackTextarea
                                onChange={(text: string) => onChangeEmotionalReactions(0, text)}
                                save={() =>
                                    saveFeedback(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        0
                                    )
                                }
                                cancel={() =>
                                    cancel(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        0
                                    )
                                }
                                height="19.06rem"
                                data={fields.emotionalReactions[0]}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.experiences_content}>
                    <div className={styles.postit_container}>
                        <img alt="postit" src={Postit} className={styles.postit} />
                        <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 행동적 반응</div>
                    </div>
                    <div className={styles.experiences_input_container}>
                        <textarea
                            className={styles.experiences_input}
                            readOnly={readOnly}
                            onDoubleClick={() => openFeedback(1)}
                            onChange={(e) => onChangeEmotionalReactions(1, e.target.value)}
                            value={fields.emotionalReactions[1].patient}
                        />
                        {fields.emotionalReactions[1].visible && (
                            <WriteFeedbackTextarea
                                onChange={(text: string) => onChangeEmotionalReactions(1, text)}
                                save={() =>
                                    saveFeedback(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        1
                                    )
                                }
                                cancel={() =>
                                    cancel(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        1
                                    )
                                }
                                height="19.06rem"
                                data={fields.emotionalReactions[1]}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.experiences_content}>
                    <div className={styles.postit_container}>
                        <img alt="postit" src={Postit} className={styles.postit} />
                        <div className={`${styles.postit_title} text_22_NotoSansKR_Bold`}>감정의 인지적 반응</div>
                    </div>
                    <div className={styles.experiences_input_container}>
                        <textarea
                            className={styles.experiences_input}
                            readOnly={readOnly}
                            onDoubleClick={() => openFeedback(2)}
                            onChange={(e) => onChangeEmotionalReactions(2, e.target.value)}
                            value={fields.emotionalReactions[2].patient}
                        />
                        {fields.emotionalReactions[2].visible && (
                            <WriteFeedbackTextarea
                                onChange={(text: string) => onChangeEmotionalReactions(2, text)}
                                save={() =>
                                    saveFeedback(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        2
                                    )
                                }
                                cancel={() =>
                                    cancel(
                                        fieldIds.emotionalReactions,
                                        fields.emotionalReactions,
                                        'emotionalReactions',
                                        2
                                    )
                                }
                                height="19.06rem"
                                data={fields.emotionalReactions[2]}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
