import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Schedule {
    calendarId: number;
    patientMemberInfo: { memberId: number; memberName: string };
    therapistNames: string[];
    registerSessionId: number;
    workBookName: string;
    sessionProgress: number;
    memo: string;
    reservationDate: string;
    startTime: string;
    endTime: string;
    originStartTime: string;
    originEndTime: string;
    timePickerModalState: string;
    reservationStatus: number;
    noShow: boolean;

    timePickerVisible: boolean;
    programPickerVisible: boolean;
    patientPickerVisible: boolean;
    scheduleMoreVisible: { day: string; bool: boolean };
}

const initialState: Schedule = {
    calendarId: 0,
    patientMemberInfo: { memberId: 0, memberName: '' },
    therapistNames: [],
    registerSessionId: 0,
    workBookName: '',
    sessionProgress: 0,
    memo: '',
    reservationDate: '',
    startTime: '',
    endTime: '',
    originStartTime: '',
    originEndTime: '',
    timePickerModalState: '',
    reservationStatus: -1,
    noShow: false,
    timePickerVisible: false,
    programPickerVisible: false,
    patientPickerVisible: false,
    scheduleMoreVisible: { day: '', bool: false },
};

export const scheduleModalSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        updateCalendarId: (state, action: PayloadAction<number>) => {
            state.calendarId = action.payload;
        },
        updateTimePickerVisible: (state, action: PayloadAction<{ visible: boolean; type: string }>) => {
            // if (state.timePickerModalState === '') {
            //     state.timePickerModalState = 'left';
            // } else if (state.timePickerModalState === 'left') {
            //     state.timePickerModalState = 'right';
            // } else {
            //     state.timePickerModalState = 'left';
            // }
            state.timePickerModalState = action.payload.type;
            state.timePickerVisible = action.payload.visible;
        },
        updateProgramPickerVisible: (state, action: PayloadAction<boolean>) => {
            state.programPickerVisible = action.payload;
        },
        updatePatientPickerVisible: (state, action: PayloadAction<boolean>) => {
            state.patientPickerVisible = action.payload;
        },
        updateScheduleMoreVisible: (state, action: PayloadAction<{ day: string; bool: boolean }>) => {
            state.scheduleMoreVisible = action.payload;
        },
        updateReservationDate: (state, action: PayloadAction<string>) => {
            state.reservationDate = action.payload;
        },
        updateReserveTime: (
            state,
            action: PayloadAction<{
                startTime: string;
                originStartTime: string;
                endTime: string;
                originEndTime: string;
            }>
        ) => {
            const { startTime, originStartTime, endTime, originEndTime } = action.payload;
            if (startTime === '') {
                state.endTime = endTime;
                state.originEndTime = originEndTime;
            } else if (endTime === '') {
                state.startTime = startTime;
                state.originStartTime = originStartTime;
            } else {
                state.endTime = endTime;
                state.originEndTime = originEndTime;
                state.startTime = startTime;
                state.originStartTime = originStartTime;
            }
            state.timePickerVisible = false;
        },
        updateSelectProgarm: (
            state,
            action: PayloadAction<{
                registerSessionId: number;
                workBookName: string;
                therapistNames: string[];
                sessionProgress: number;
            }>
        ) => {
            const { registerSessionId, workBookName, therapistNames, sessionProgress } = action.payload;

            state.registerSessionId = registerSessionId;
            state.workBookName = workBookName;
            state.therapistNames = therapistNames;
            state.sessionProgress = sessionProgress;
        },
        updateSelectPatient: (state, action: PayloadAction<{ memberId: number; memberName: string }>) => {
            const { memberId, memberName } = action.payload;
            state.patientMemberInfo.memberId = memberId;
            state.patientMemberInfo.memberName = memberName;
            state.patientPickerVisible = false;
        },
        updateMemo: (state, action: PayloadAction<string>) => {
            state.memo = action.payload;
        },
        updateReservationStatus: (state, action: PayloadAction<number>) => {
            state.reservationStatus = action.payload;
        },
        resetModal: (state, action: PayloadAction) => {
            state.timePickerVisible = false;
            state.programPickerVisible = false;
            state.patientPickerVisible = false;
            state.scheduleMoreVisible = { day: '', bool: false };
        },
        resetScheduleData: () => initialState,
    },
});

export const {
    updateCalendarId,
    updateTimePickerVisible,
    updateReservationDate,
    updateReserveTime,
    updateProgramPickerVisible,
    updateSelectProgarm,
    updatePatientPickerVisible,
    updateSelectPatient,
    updateScheduleMoreVisible,
    updateMemo,
    updateReservationStatus,
    resetModal,
    resetScheduleData,
} = scheduleModalSlice.actions;
export default scheduleModalSlice.reducer;
