import React, { useContext, useEffect, useState } from 'react';

import styles from './css/AssignedMember.module.css';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import {
    AssignedTherapist,
    ChartTitle,
    MemberInfoButton,
    NameChartNumber,
    ProgramType,
    RecentCompleteFeedback,
    SearchContainer,
    SelectAssignedTherapist,
    SessionCompleteDate,
    SessionProgress,
} from './AssignedMemberComponent.tsx/AssignedMemberComponent';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import axiosInstance from '../../../../utils/AxiosInstanceJava';
import { ReactComponent as Icon_arrowleft } from '../../../../assets/public/icon_arrowleft.svg';
import { ReactComponent as Icon_arrowright } from '../../../../assets/member/signup/icon_arrowright.svg';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { RepresentativeMemberList, getRepresentativeMemberList } from './ts/AssignedMemberApi';
import { ModalContext } from '../../../../context/ModalContext';
import { useDispatch } from 'react-redux';
import { updateSelectProgram, updateSelectTherapist } from '../../../../features/selectTherapistModalReducer';
import { encryptData } from '../../../../utils/croptojs';

const ChartTitleItem = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    ${(props) => (props.$index === 0 ? '' : 'border-top: 0.0625rem solid #e3e5ea;')}
`;

const ChartTitleItemList = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    flexdirection: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    // ${(props) => (props.$index === props.$lastIndex ? '' : 'border-bottom: 0.0625rem solid #e3e5ea;')}
`;

const officialChartTitleList = [
    '이름(차트번호)',
    '프로그램유형',
    '세션 진행도',
    '세션 완료일',
    '최근 완료된 피드백',
    '담당 상담사',
    '',
];

export const RepresentativeAssignedMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageNum, setPageNum] = useState<number>(0);
    const [feedbackCheck, setFeedbackCheck] = useState<boolean>(false);

    const { selectTherapistVisible, selectTherapistOpenModal } = useContext(ModalContext);
    const [isSelectedTitle, setIsSelectedTitle] = useState<{ companyId: number; branchName: string; count: number }>({
        companyId: 0,
        branchName: '모든 기관',
        count: 0,
    });

    const { data, error, isLoading, refetch } = useQuery<RepresentativeMemberList>({
        queryKey: ['representativeMemberList', { companyId: isSelectedTitle.companyId, pageNum }],
        queryFn: () => getRepresentativeMemberList(isSelectedTitle.companyId, pageNum),
        placeholderData: keepPreviousData,
    });

    if (isLoading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;
    if (!data) return <div>No data available</div>; // data가 없을 때 처리

    const moveToPatientCBT = (programType: string, sessionPage: number, sessionId: number, registerId: number) => {
        const params = new URLSearchParams();
        params.append('page', sessionPage.toString());
        params.append('session', encryptData(sessionId));
        params.append('program', encryptData(registerId));
        if (programType === 'non-face') {
            navigate(`patientCBT/nonface/session?${params}`);
        } else if (programType === 'face') {
            navigate(`patientCBT/face/session?${params}`);
        }
    };
    const nextPage = () => {
        if (data?.patientList.totalPages! - 1 < pageNum) {
            setPageNum(pageNum + 1);
        }
    };

    const prevPage = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1);
        }
    };

    const TitleRender = () => {
        return (
            <div className={styles.middle_title_container}>
                {data?.branchCategories.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            setIsSelectedTitle(item);
                            refetch();
                        }}
                        className={`${styles.middle_title} ${
                            isSelectedTitle.branchName === item.branchName ? styles.activate : styles.deactivate
                        } ${
                            isSelectedTitle.branchName === item.branchName
                                ? 'text_18_NotoSansKR_Bold'
                                : 'text_18_NotoSansKR_Medium'
                        }`}
                    >
                        {item.branchName}({item.count})
                    </button>
                ))}
            </div>
        );
    };

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {officialChartTitleList.map((item, index) => (
                        <ChartTitle key={index.toString()} title={item} />
                    ))}
                </div>
                {data.patientList.content.length > 0 ? (
                    <ul className={styles.chart_content_container}>
                        {data?.patientList.content.map((item, index) => (
                            <>
                                <ChartTitleItem
                                    key={index.toString()}
                                    $index={index}
                                    $lastIndex={item.workBookInfos.length - 1}
                                >
                                    <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                                    <ProgramType programType={item.workBookInfos[0].workBookName} />
                                    <SessionProgress
                                        sessionProgress={item.workBookInfos[0].finishedCnt}
                                        maxProgress={item.workBookInfos[0].totalCnt}
                                        moveToPatientCBT={() =>
                                            moveToPatientCBT(
                                                item.workBookInfos[0].workBookType,
                                                item.workBookInfos[0].finishedCnt,
                                                item.workBookInfos[0].registerSessionId,
                                                item.workBookInfos[0].registerId
                                            )
                                        }
                                        workBookName={item.workBookInfos[0].workBookName}
                                    />
                                    <SessionCompleteDate completeDate={item.workBookInfos[0].finishedDate} />
                                    <RecentCompleteFeedback recentCompleteSession={item.workBookInfos[0].recentFeed} />
                                    {item.workBookInfos[0].therapistList.length === 0 ? (
                                        <SelectAssignedTherapist
                                            selectTherapistVisible={selectTherapistVisible}
                                            selectTherapistOpenModal={(visible: boolean) => {
                                                dispatch(
                                                    updateSelectProgram({
                                                        registerId: item.workBookInfos[0].registerId,
                                                        type: item.workBookInfos[0].workBookName,
                                                    })
                                                );
                                                dispatch(
                                                    updateSelectTherapist({
                                                        therapistId: 0,
                                                        therapist: item.workBookInfos[0].therapistList[0].therapistName,
                                                    })
                                                );
                                                selectTherapistOpenModal(visible);
                                            }}
                                        />
                                    ) : (
                                        <AssignedTherapist
                                            therapist={item.workBookInfos[0].therapistList[0].therapistName}
                                        />
                                    )}
                                    <MemberInfoButton memberId={item.memberId} />
                                </ChartTitleItem>
                                {item.workBookInfos.map((value, idx) => {
                                    return (
                                        idx !== 0 && (
                                            <ChartTitleItemList
                                                key={idx.toString() + 'child'}
                                                $index={idx}
                                                $lastIndex={data?.patientList.content.length - 1}
                                            >
                                                <NameChartNumber name={''} chartNumber={null} />
                                                <ProgramType programType={value.workBookName} />
                                                <SessionProgress
                                                    sessionProgress={value.finishedCnt}
                                                    maxProgress={value.totalCnt}
                                                    moveToPatientCBT={moveToPatientCBT}
                                                    workBookName={value.workBookName}
                                                />
                                                <SessionCompleteDate completeDate={value.finishedDate} />
                                                <RecentCompleteFeedback recentCompleteSession={value.recentFeed} />
                                                {value.therapistList.length === 0 ? (
                                                    <SelectAssignedTherapist
                                                        selectTherapistVisible={selectTherapistVisible}
                                                        selectTherapistOpenModal={(visible: boolean) => {
                                                            dispatch(
                                                                updateSelectProgram({
                                                                    registerId: value.registerId,
                                                                    type: value.workBookName,
                                                                })
                                                            );
                                                            dispatch(
                                                                updateSelectTherapist({
                                                                    therapistId: 0,
                                                                    therapist: value.therapistList[0].therapistName,
                                                                })
                                                            );
                                                            selectTherapistOpenModal(visible);
                                                        }}
                                                    />
                                                ) : (
                                                    <AssignedTherapist
                                                        therapist={value.therapistList[0].therapistName}
                                                    />
                                                )}
                                                <MemberInfoButton memberId={null} />
                                            </ChartTitleItemList>
                                        )
                                    );
                                })}
                            </>
                        ))}
                    </ul>
                ) : (
                    <div className={styles.chart_nondata_container}>
                        <span
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            아직 담당 중인 회원이 없습니다.
                        </span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <TitleRender />
            <div className={styles.content_container}>
                <SearchContainer
                    feedbackCheck={feedbackCheck}
                    changeFeedbackCheck={() => setFeedbackCheck(!feedbackCheck)}
                />
                <AssignedRender />
            </div>
            <div className={styles.page_container}>
                <button onClick={prevPage} className={styles.button}>
                    <Icon_arrowleft
                        className={`${styles.icon_arrow} ${pageNum > 0 ? styles.activate : styles.deactivate}`}
                    />
                </button>
                <span
                    className="text_18_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    Page{pageNum + 1}
                </span>
                <button onClick={nextPage} className={styles.button}>
                    <Icon_arrowright
                        className={`${styles.icon_arrow} ${
                            data?.patientList.totalPages! - 1 === pageNum ? styles.deactivate : styles.activate
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};
