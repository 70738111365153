import styles from '../css/FirstSession.module.css';
import '../../session/css/Feedback.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import Img_goal from '../../../../../assets/face/session1/session1_goal.png';
import PopUpImg from '../../../../../assets/face/session1/session1_test.png';
import { ReactComponent as Icon_x } from '../../../../../assets/public/icon_x.svg';

import ReactPlayer from 'react-player';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getMemberInfo } from '../../../../../utils/GetMemberInfo';
import {
    changeFeedbackState,
    deleteFeedback,
    updateField,
    updateSessionField,
} from '../../../../../features/session/sessionReducer';
import { WriteFeedbackTextarea } from './FeedbackComponent';
import { deleteSessionFeedback, saveSessionFeedback } from '../ts/SessionApi';
import { MouseEventHandler, useContext } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';

interface CurrentSession {
    currentSession: string;
}

/* 대면 환자 뇌 인풋박스 */
export const ThoughtFeelInput = () => {
    const dispatch = useDispatch();
    const memberInfo = getMemberInfo();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);
    const onChageBrain = (index: number, patient: string) => {
        dispatch(
            updateField({
                index,
                key: 'brain',
                value: { ...fields.brain[index], patient },
            })
        );
    };
    return (
        <div className={styles.patient_brain_container}>
            {fields.brain.map((item, index) => (
                <div
                    key={index.toString()}
                    className={`${styles.thought_feel_input_container} ${
                        styles[`thought_feel_input_container${index}`] || ''
                    }`}
                >
                    <textarea
                        className={`${styles.thought_feel_input} text_18_NotoSansKR_Regular`}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value)}
                        readOnly={readOnly}
                    />
                </div>
            ))}
        </div>
    );
};

/* 대면 환자 뇌 인풋박스 */
export const TherapistThoughtFeelInput = () => {
    const dispatch = useDispatch();
    const memberInfo = getMemberInfo();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);
    const onChageBrain = (index: number, patient: string) => {
        dispatch(
            updateField({
                index,
                key: 'changeBrain',
                value: { ...fields.changeBrain[index], patient },
            })
        );
    };

    return (
        <div className={styles.patient_brain_container}>
            {fields.changeBrain.map((item, index) => (
                <div
                    key={index.toString()}
                    className={`${styles.thought_feel_input_container} ${
                        styles[`thought_feel_input_container${index}`] || ''
                    }`}
                >
                    <textarea
                        className={`${styles.thought_feel_input} text_18_NotoSansKR_Regular`}
                        value={item.patient}
                        maxLength={30}
                        onChange={(e) => onChageBrain(index, e.target.value)}
                        readOnly={readOnly}
                    />
                </div>
            ))}
        </div>
    );
};

export const DesiredSymptomsAndReasons = ({ currentSession }: CurrentSession) => {
    const dispatch = useDispatch();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const { feedbackVisible } = useSelector((state: RootState) => state.blackboard);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);

    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'desiredSymptomChange':
                    const desired = fields.desiredSymptomChange[0];
                    dispatch(
                        updateField({
                            index: 0,
                            key: 'desiredSymptomChange',
                            value: { ...desired, visible: !desired.visible },
                        })
                    );
                    break;

                case 'psychologicalBarriers':
                    const psychological = fields.psychologicalBarriers[index];
                    dispatch(
                        updateField({
                            index: index,
                            key: 'psychologicalBarriers',
                            value: { ...psychological, visible: !psychological.visible },
                        })
                    );
                    break;
            }
        }
    };

    const onChangeDesiredSymptomChange = (text: string, type: string) => {
        const result = fields.desiredSymptomChange[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: 0,
                        key: 'desiredSymptomChange',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: 0,
                        key: 'desiredSymptomChange',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const onChangePsychologicalBarriers = (index: number, text: string) => {
        const result = fields.psychologicalBarriers[index];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: index,
                        key: 'psychologicalBarriers',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: index,
                        key: 'psychologicalBarriers',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    if (finished) {
                        oneButtonOpenModal(
                            () => {
                                dispatch(
                                    updateSessionField({
                                        index: index,
                                        key: key,
                                        value: field,
                                        therapistName: memberInfo?.memberName!,
                                    })
                                );
                            },
                            '',
                            '피드백 수정이 완료되었습니다.',
                            '확인'
                        );
                    } else {
                        dispatch(
                            updateSessionField({
                                index: index,
                                key: key,
                                value: field,
                                therapistName: memberInfo?.memberName!,
                            })
                        );
                    }
                }
            });
        }
    };

    const cancel = (fieldId: number, field: any, key: string, index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            if (field[index].feedbackCheck) {
                const extractDataByKey = () => {
                    return {
                        [key]: field,
                    };
                };
                deleteSessionFeedback(fieldId, index, extractDataByKey()).then((response) => {
                    if (response.result) {
                        dispatch(deleteFeedback({ index: index, key: key, value: field }));
                    }
                });
            } else {
                openFeedback(index, key);
            }
        }
    };

    return (
        <div className={styles.symptom_barrier}>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    1. 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유를 적어주세요.
                </div>
                <textarea
                    onDoubleClick={() => openFeedback(0, 'desiredSymptomChange')}
                    className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`}
                    value={fields.desiredSymptomChange[0].patient}
                    onChange={(e) => onChangeDesiredSymptomChange(e.target.value, 'patient')}
                    readOnly={readOnly}
                />
                {fields.desiredSymptomChange[0].visible && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeDesiredSymptomChange(text, 'therapist')}
                        save={() =>
                            saveFeedback(
                                fieldIds.desiredSymptomChange,
                                fields.desiredSymptomChange,
                                'desiredSymptomChange',
                                0
                            )
                        }
                        cancel={() =>
                            cancel(
                                fieldIds.desiredSymptomChange,
                                fields.desiredSymptomChange,
                                'desiredSymptomChange',
                                0
                            )
                        }
                        height="19.06rem"
                        data={fields.desiredSymptomChange[0]}
                    />
                )}
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    2. 치료에 방해되는 심리적 장애물이 있다면?
                </div>
                <div
                    style={{
                        padding: '0 8.12rem',
                    }}
                >
                    <img alt="goal" src={Img_goal} className={styles.img} />
                </div>
                <div className={styles.barrier_input_container}>
                    {fields.psychologicalBarriers.map((item, index) => (
                        <div key={index.toString()} className={styles.barrier_input_box}>
                            <textarea
                                key={index.toString()}
                                onDoubleClick={() => openFeedback(index, 'psychologicalBarriers')}
                                className={`${styles.barrier_input} text_18_NotoSansKR_Regular`}
                                value={item.patient}
                                onChange={(e) => onChangePsychologicalBarriers(index, e.target.value)}
                                readOnly={readOnly}
                            />
                            {item.visible && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangePsychologicalBarriers(index, text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.psychologicalBarriers,
                                            fields.psychologicalBarriers,
                                            'psychologicalBarriers',
                                            index
                                        )
                                    }
                                    cancel={() =>
                                        cancel(
                                            fieldIds.psychologicalBarriers,
                                            fields.psychologicalBarriers,
                                            'psychologicalBarriers',
                                            index
                                        )
                                    }
                                    height="19.06rem"
                                    data={item}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const AdvantagesAndGoals = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);
    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'myStrengths':
                    const strengths = fields.myStrengths[0];
                    dispatch(
                        updateField({
                            index: 0,
                            key: 'myStrengths',
                            value: { ...strengths, visible: !strengths.visible },
                        })
                    );
                    break;

                case 'therapyGoals':
                    const goals = fields.therapyGoals[index];
                    dispatch(
                        updateField({
                            index: index,
                            key: 'therapyGoals',
                            value: { ...goals, visible: !goals.visible },
                        })
                    );
                    break;
            }
        }
    };

    const onChangeMyStrengths = (index: number, text: string) => {
        const result = fields.myStrengths[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: 0,
                        key: 'myStrengths',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: 0,
                        key: 'myStrengths',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const onChangeTherapyGoals = (index: number, text: string) => {
        const result = fields.therapyGoals[index];

        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: index,
                        key: 'therapyGoals',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: index,
                        key: 'therapyGoals',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    if (finished) {
                        oneButtonOpenModal(
                            () => {
                                dispatch(
                                    updateSessionField({
                                        index: index,
                                        key: key,
                                        value: field,
                                        therapistName: memberInfo?.memberName!,
                                    })
                                );
                            },
                            '',
                            '피드백 수정이 완료되었습니다.',
                            '확인'
                        );
                    } else {
                        dispatch(
                            updateSessionField({
                                index: index,
                                key: key,
                                value: field,
                                therapistName: memberInfo?.memberName!,
                            })
                        );
                    }
                }
            });
        }
    };

    const cancel = (fieldId: number, field: any, key: string, index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            if (field[index].feedbackCheck) {
                const extractDataByKey = () => {
                    return {
                        [key]: field,
                    };
                };
                deleteSessionFeedback(fieldId, index, extractDataByKey()).then((response) => {
                    if (response.result) {
                        dispatch(deleteFeedback({ index: index, key: key, value: field }));
                    }
                });
            } else {
                openFeedback(index, key);
            }
        }
    };

    const Step = ({ step }: { step: number }) => {
        return (
            <div
                className="text_24_NotoSansKR_Medium"
                style={{
                    color: '#626466',
                }}
            >
                <span
                    className="text_24_Inter_Medium"
                    style={{
                        color: '#626466',
                    }}
                >
                    {step}
                </span>
                단계
            </div>
        );
    };

    return (
        <div className={styles.advantage_goal}>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    3. 나의 장점은?
                </div>
                <textarea
                    className={`${styles.advantage_input} text_18_NotoSansKR_Regular`}
                    value={fields.myStrengths[0].patient}
                    onDoubleClick={() => openFeedback(0, 'myStrengths')}
                    onChange={(e) => onChangeMyStrengths(0, e.target.value)}
                    readOnly={readOnly}
                />
                {fields.myStrengths[0].visible && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeMyStrengths(0, text)}
                        save={() => saveFeedback(fieldIds.myStrengths, fields.myStrengths, 'myStrengths', 0)}
                        cancel={() => cancel(fieldIds.myStrengths, fields.myStrengths, 'myStrengths', 0)}
                        height="19.06rem"
                        data={fields.myStrengths[0]}
                    />
                )}
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    4. 치료를 위한 단계별 목표 정하기
                </div>
                <div className={styles.advantage_goal_content}>
                    {fields.therapyGoals.map((item, index) => (
                        <div
                            key={index.toString()}
                            className={`${styles.step_container} ${
                                index === 0 ? styles.padding_one : index === 1 ? styles.padding_two : ''
                            }`}
                        >
                            <Step step={index + 1} />
                            <div
                                className={`${
                                    index === 0 ? styles.step_one : index === 1 ? styles.step_two : styles.step_three
                                }`}
                            ></div>
                            <textarea
                                className={`${styles.goal_input} text_18_NotoSansKR_Regular`}
                                value={item.patient}
                                onDoubleClick={() => openFeedback(index, 'therapyGoals')}
                                onChange={(e) => onChangeTherapyGoals(index, e.target.value)}
                                readOnly={readOnly}
                            />
                            {item.visible && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangeTherapyGoals(index, text)}
                                    save={() =>
                                        saveFeedback(fieldIds.therapyGoals, fields.therapyGoals, 'therapyGoals', index)
                                    }
                                    cancel={() =>
                                        cancel(fieldIds.therapyGoals, fields.therapyGoals, 'therapyGoals', index)
                                    }
                                    height="19.06rem"
                                    data={item}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const VideoPopup = ({
    showPopup,
    playerPopUpRef,
    currentTime,
    updatePopUpVisible,
    popupStyle,
    onMouseDown,
    onResizeMouseDown,
    videoUrl,
}: {
    showPopup: boolean;
    playerPopUpRef: any;
    onProgress: any;
    currentTime: number;
    updatePopUpVisible: Function;
    popupStyle: { top: number; right: number; width: number; height: number };
    onMouseDown: MouseEventHandler<HTMLDivElement>;
    onResizeMouseDown: MouseEventHandler<HTMLDivElement>;
    videoUrl: string;
}) => {
    let test = false;
    return (
        <div
            style={{
                display: 'flex',
                position: 'fixed',
                top: `${popupStyle.top}rem`,
                right: `${popupStyle.right}rem`,
                width: `${popupStyle.width}rem`,
                height: `${popupStyle.height}rem`,
                zIndex: 1000,
                cursor: 'move',
            }}
            onMouseDown={onMouseDown}
        >
            <div className={styles.video_container}>
                <ReactPlayer
                    ref={playerPopUpRef}
                    url={videoUrl} // 동일한 동영상 URL
                    height="100%" // 비디오 높이
                    width="auto"
                    playing={showPopup}
                    // onProgress={onProgress}
                    progressInterval={100} // 100ms마다 업데이트
                    controls={true}
                    playbackRate={1} // 재생 속도 설정
                    style={{
                        borderRadius: '1.25rem',
                    }}
                    onReady={(player) => {
                        if (!test) {
                            player.seekTo(currentTime); // 원래 비디오의 재생 시간으로 설정
                        }
                        test = true;
                    }}
                />
                <button
                    className={styles.close_button}
                    onClick={() => {
                        updatePopUpVisible(false);
                    }}
                >
                    <Icon_x className={styles.icon_x} />
                </button>
            </div>
            <div className={styles.video_resize_box} onMouseDown={onResizeMouseDown} />
        </div>
    );
};

export const SessionGuide = ({ imgUrl, updatePopUpVisible }: { imgUrl: string; updatePopUpVisible: Function }) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: `8.125rem`,
                left: '2rem',
                width: '30rem',
                zIndex: 200,
            }}
        >
            <div className={styles.guide_container}>
                <img
                    src={imgUrl}
                    style={{
                        width: '100%',
                    }}
                />
                <button
                    className={styles.close_button}
                    onClick={() => {
                        updatePopUpVisible(false);
                    }}
                >
                    <Icon_x className={styles.icon_x} />
                </button>
            </div>
        </div>
    );
};

/* 비대면 */
export const DesiredSymptomsAdvantageGoal = () => {
    const dispatch = useDispatch();
    const { fieldIds, fields, workBookSubmitted, finished } = useSelector((state: RootState) => state.session);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const memberInfo = getMemberInfo();
    const readOnly = memberInfo?.memberRole !== 'user' || (memberInfo.memberRole === 'user' && finished);

    const openFeedback = (index: number, type: string) => {
        if (memberInfo?.memberRole === 'therapist') {
            switch (type) {
                case 'desiredSymptomChange':
                    const desired = fields.desiredSymptomChange[0];
                    dispatch(
                        updateField({
                            index: 0,
                            key: 'desiredSymptomChange',
                            value: { ...desired, visible: !desired.visible },
                        })
                    );
                    break;

                case 'psychologicalBarriers':
                    const psychological = fields.psychologicalBarriers[index];
                    dispatch(
                        updateField({
                            index: index,
                            key: 'psychologicalBarriers',
                            value: { ...psychological, visible: !psychological.visible },
                        })
                    );
                    break;
                case 'myStrengths':
                    const strengths = fields.myStrengths[0];
                    dispatch(
                        updateField({
                            index: 0,
                            key: 'myStrengths',
                            value: { ...strengths, visible: !strengths.visible },
                        })
                    );
                    break;

                case 'mySpecificGoals':
                    const goals = fields.mySpecificGoals[index];
                    dispatch(
                        updateField({
                            index: index,
                            key: 'mySpecificGoals',
                            value: { ...goals, visible: !goals.visible },
                        })
                    );
                    break;
            }
        }
    };

    const onChangeDesiredSymptomChange = (text: string, type: string) => {
        const result = fields.desiredSymptomChange[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: 0,
                        key: 'desiredSymptomChange',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: 0,
                        key: 'desiredSymptomChange',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const onChangePsychologicalBarriers = (index: number, text: string) => {
        const result = fields.psychologicalBarriers[index];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: index,
                        key: 'psychologicalBarriers',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: index,
                        key: 'psychologicalBarriers',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const onChangeMyStrengths = (index: number, text: string) => {
        const result = fields.myStrengths[0];
        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: 0,
                        key: 'myStrengths',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: 0,
                        key: 'myStrengths',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const onChangeMySpecificGoals = (index: number, text: string) => {
        const result = fields.mySpecificGoals[index];

        switch (memberInfo?.memberRole) {
            case 'therapist':
                dispatch(
                    updateField({
                        index: index,
                        key: 'mySpecificGoals',
                        value: { ...result, feedback: text },
                    })
                );
                break;
            default:
                dispatch(
                    updateField({
                        index: index,
                        key: 'mySpecificGoals',
                        value: { ...result, patient: text },
                    })
                );
                break;
        }
    };

    const saveFeedback = (fieldId: number, field: any, key: string, index: number) => {
        if (field[index].feedbackCheck) {
            dispatch(changeFeedbackState({ index: index, key: key, value: field }));
        } else {
            const extractDataByKey = () => {
                return {
                    [key]: field,
                };
            };
            saveSessionFeedback(fieldId, extractDataByKey()).then((response) => {
                if (response.result) {
                    if (finished) {
                        oneButtonOpenModal(
                            () => {
                                dispatch(
                                    updateSessionField({
                                        index: index,
                                        key: key,
                                        value: field,
                                        therapistName: memberInfo?.memberName!,
                                    })
                                );
                            },
                            '',
                            '피드백 수정이 완료되었습니다.',
                            '확인'
                        );
                    } else {
                        dispatch(
                            updateSessionField({
                                index: index,
                                key: key,
                                value: field,
                                therapistName: memberInfo?.memberName!,
                            })
                        );
                    }
                }
            });
        }
    };

    const cancel = (fieldId: number, field: any, key: string, index: number) => {
        if (memberInfo?.memberRole === 'therapist') {
            if (field[index].feedbackCheck) {
                const extractDataByKey = () => {
                    return {
                        [key]: field,
                    };
                };
                deleteSessionFeedback(fieldId, index, extractDataByKey()).then((response) => {
                    if (response.result) {
                        dispatch(deleteFeedback({ index: index, key: key, value: field }));
                    }
                });
            } else {
                openFeedback(index, key);
            }
        }
    };

    return (
        <div className={styles.symptom_barrier}>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    1. 인지행동치료 후에 가장 변화하고 싶은 증상과 그 이유를 적어주세요.
                </div>
                <textarea
                    onDoubleClick={() => openFeedback(0, 'desiredSymptomChange')}
                    className={`${styles.desired_symptom_input} text_18_NotoSansKR_Regular`}
                    value={fields.desiredSymptomChange[0].patient}
                    onChange={(e) => onChangeDesiredSymptomChange(e.target.value, 'patient')}
                    readOnly={readOnly}
                />
                {fields.desiredSymptomChange[0].visible && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeDesiredSymptomChange(text, 'therapist')}
                        save={() =>
                            saveFeedback(
                                fieldIds.desiredSymptomChange,
                                fields.desiredSymptomChange,
                                'desiredSymptomChange',
                                0
                            )
                        }
                        cancel={() =>
                            cancel(
                                fieldIds.desiredSymptomChange,
                                fields.desiredSymptomChange,
                                'desiredSymptomChange',
                                0
                            )
                        }
                        height="19.06rem"
                        data={fields.desiredSymptomChange[0]}
                    />
                )}
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    2. 변화를 막는 심리적 장애물이 있다면?
                </div>
                <div
                    style={{
                        padding: '0 8.12rem',
                    }}
                >
                    <img alt="goal" src={Img_goal} className={styles.img} />
                </div>
                <div className={styles.barrier_input_container}>
                    {fields.psychologicalBarriers.map((item, index) => (
                        <div key={index.toString()} className={styles.barrier_input_box}>
                            <textarea
                                key={index.toString()}
                                onDoubleClick={() => openFeedback(index, 'psychologicalBarriers')}
                                className={`${styles.barrier_input} text_18_NotoSansKR_Regular`}
                                value={item.patient}
                                onChange={(e) => onChangePsychologicalBarriers(index, e.target.value)}
                                readOnly={readOnly}
                            />
                            {item.visible && (
                                <WriteFeedbackTextarea
                                    onChange={(text: string) => onChangePsychologicalBarriers(index, text)}
                                    save={() =>
                                        saveFeedback(
                                            fieldIds.psychologicalBarriers,
                                            fields.psychologicalBarriers,
                                            'psychologicalBarriers',
                                            index
                                        )
                                    }
                                    cancel={() =>
                                        cancel(
                                            fieldIds.psychologicalBarriers,
                                            fields.psychologicalBarriers,
                                            'psychologicalBarriers',
                                            index
                                        )
                                    }
                                    height="19.06rem"
                                    data={item}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    3. 나의 장점은?
                </div>
                <textarea
                    className={`${styles.advantage_input} text_18_NotoSansKR_Regular`}
                    value={fields.myStrengths[0].patient}
                    onDoubleClick={() => openFeedback(0, 'myStrengths')}
                    onChange={(e) => onChangeMyStrengths(0, e.target.value)}
                    readOnly={readOnly}
                />
                {fields.myStrengths[0].visible && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeMyStrengths(0, text)}
                        save={() => saveFeedback(fieldIds.myStrengths, fields.myStrengths, 'myStrengths', 0)}
                        cancel={() => cancel(fieldIds.myStrengths, fields.myStrengths, 'myStrengths', 0)}
                        height="19.06rem"
                        data={fields.myStrengths[0]}
                    />
                )}
            </div>
            <div className={styles.desired_symptom_container}>
                <div
                    className="text_22_NotoSansKR_Bold"
                    style={{
                        color: '#626466',
                    }}
                >
                    4. 구체적인 나의 목표는?
                </div>
                <textarea
                    className={`${styles.advantage_input} text_18_NotoSansKR_Regular`}
                    value={fields.mySpecificGoals[0].patient}
                    onDoubleClick={() => openFeedback(0, 'mySpecificGoals')}
                    onChange={(e) => onChangeMySpecificGoals(0, e.target.value)}
                    readOnly={readOnly}
                />
                {fields.mySpecificGoals[0].visible && (
                    <WriteFeedbackTextarea
                        onChange={(text: string) => onChangeMySpecificGoals(0, text)}
                        save={() =>
                            saveFeedback(fieldIds.mySpecificGoals, fields.mySpecificGoals, 'mySpecificGoals', 0)
                        }
                        cancel={() => cancel(fieldIds.mySpecificGoals, fields.mySpecificGoals, 'mySpecificGoals', 0)}
                        height="19.06rem"
                        data={fields.mySpecificGoals[0]}
                    />
                )}
            </div>
        </div>
    );
};
