import React, { useContext, useEffect, useState } from 'react';

import styles from './css/Signup.module.css';

import CryptoJS from 'crypto-js';
import { Illustration } from '../component/Illustration';
import { SignupInput } from './component/SignupInput';
import logoPng from '../../../assets/member/login/logo.png';
import { ReactComponent as Icon_checkbox } from '../../../assets/member/icon_checkbox.svg';
import { ReactComponent as Icon_checkedbox } from '../../../assets/member/icon_checkedbox.svg';
import { ReactComponent as Icon_check } from '../../../assets/member/signup/icon_check.svg';
import { ReactComponent as Icon_arrowright } from '../../../assets/member/signup/icon_arrowright.svg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ModalContext } from '../../../context/ModalContext';
import { MemberTypeMenu } from '../login/component/MemberTypeMenu';
import { MainButton } from '../component/MainButton';
import { useDispatch, useSelector } from 'react-redux';
import { resetSignup, updateUserIdentityInfo } from '../../../features/signupReducer';
import { RootState } from '../../../store';
import axiosInstance from '../../../utils/AxiosInstanceJava';

interface MemberTypeButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $policyAllAgreeCheck: boolean; // 추가적인 prop 정의
}

export const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signup = useSelector((state: RootState) => state.signup);
    const memberType = useSelector((state: RootState) => state.memberType);
    const { oneButtonOpenModal } = useContext(ModalContext);
    const policyAry = [
        { title: '개인정보처리방침 동의', url: '/public/privacyPolicy' },
        { title: '민감정보 처리 동의', url: '/public/sensitivenInfo' },
        { title: '고유식별정보 처리 동의', url: '/public/uniqueIdentifier' },
    ];

    const [policyCheckedAry, setPolicyCheckedAry] = useState<{ id: number; agree: boolean }[]>([
        { id: 0, agree: false },
        { id: 1, agree: false },
        { id: 2, agree: false },
    ]);

    const decryptData = (encryptedData: string, key: string, iv: string): string => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        // Convert the WordArray to a Uint8Array
        const wordArrayToUint8Array = (wordArray: CryptoJS.lib.WordArray): Uint8Array => {
            const words = wordArray.words;
            const sigBytes = wordArray.sigBytes;
            const u8 = new Uint8Array(sigBytes);
            for (let i = 0; i < sigBytes; i++) {
                u8[i] = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
            }
            return u8;
        };

        const u8Array = wordArrayToUint8Array(bytes);
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(u8Array);
    };

    const isValidBirthdate = (birthdate: string): boolean => {
        // 정규식으로 YYYYMMDD 형식 확인
        const dateRegex = /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/;
        if (!dateRegex.test(birthdate)) {
            return false;
        }

        // 날짜 유효성 및 14세 이상 확인
        const year = parseInt(birthdate.substring(0, 4), 10);
        const month = parseInt(birthdate.substring(4, 6), 10) - 1; // 월은 0부터 시작
        const day = parseInt(birthdate.substring(6, 8), 10);

        const birthDateObj = new Date(year, month, day);
        if (
            birthDateObj.getFullYear() !== year ||
            birthDateObj.getMonth() !== month ||
            birthDateObj.getDate() !== day
        ) {
            return false; // 유효하지 않은 날짜
        }

        const today = new Date();
        const age = today.getFullYear() - year;
        if (age < 14 || (age === 14 && today < new Date(year + 14, month, day))) {
            return false; // 14세 미만
        }

        return true;
    };

    useEffect(() => {
        const identityData = localStorage.getItem('identity_data');
        const keyData = sessionStorage.getItem('identity_key');
        if (identityData && keyData) {
            const { enc_data } = JSON.parse(identityData);
            const { key, iv } = JSON.parse(keyData);
            const decryptedData = decryptData(enc_data, key, iv);
            console.log('Decrypted Data:', decryptedData);
            const data = JSON.parse(decryptedData);
            data.utf8_name = decodeURIComponent(data.utf8_name);
            if (isValidBirthdate(data.birthdate)) {
                dispatch(updateUserIdentityInfo(data));
            } else {
                oneButtonOpenModal(
                    () => {},
                    '',
                    `14세 이상부터 회원가입이 가능하며, 14세 미만의 경우 해당 기관으로 연락 바랍니다.\n(부산점: 051-747-8005)`,
                    '확인'
                );
            }
        }
    }, []);

    const policyAllAgreeCheck = policyCheckedAry.every((item) => item.agree);

    const policyCheck = (id: number) => {
        setPolicyCheckedAry((prevAry) =>
            prevAry.map((item) => (item.id === id ? { ...item, agree: !item.agree } : item))
        );
    };

    const policyAllAgree = () => {
        if (policyAllAgreeCheck) {
            setPolicyCheckedAry([
                { id: 0, agree: false },
                { id: 1, agree: false },
                { id: 2, agree: false },
            ]);
        } else {
            setPolicyCheckedAry([
                { id: 0, agree: true },
                { id: 1, agree: true },
                { id: 2, agree: true },
            ]);
        }
    };

    const identityVeification = async () => {
        try {
            const { form_chk }: any = document;
            const left = window.innerWidth / 2 - 500 / 2;
            const top = window.innerHeight / 2 - 800 / 2;
            const option = `'width=500, height=600, fullscreen=no, menubar=no, status=no, toolbar=no,titlebar=yes, location=no, scrollbar=no'left=${left}, top=${top}`;
            const returnUrl = 'https://yd-icbt.co.kr/public/auth/identity';
            const res = await axiosInstance.post(`/api/auth/nice/token/v1`, {
                returnurl: returnUrl,
            });

            const identity_key = JSON.stringify({
                iv: res.data.iv,
                key: res.data.key,
                hmac_key: res.data.hmac_key,
                req_no: res.data.req_no,
            });

            sessionStorage.setItem('identity_key', identity_key);
            if (form_chk && res.data) {
                window.open('', 'popupChk', option);
                form_chk.target = 'popupChk';
                form_chk.enc_data.value = res.data.enc_data;
                form_chk.token_version_id.value = res.data.token_version_id;
                form_chk.integrity_value.value = res.data.integrity_value;
                form_chk.iv.value = res.data.iv;
                form_chk.key.value = res.data.key;
                form_chk.hmac_key.value = res.data.hmac_key;
                document.body.appendChild(form_chk);
                form_chk.submit();
                document.body.removeChild(form_chk);
            }
        } catch (error) {
            console.error('Error during identity verification:', error);
            oneButtonOpenModal(() => {}, '', '본인인증 중 오류가 발생했습니다.', '확인');
        }
    };

    const dupliAccountCheck = async () => {
        await axiosInstance
            .post('/api/auth/check/account/v1', {
                memberAccount: signup.memberAccount,
            })
            .then((response) => {
                if (response.data.result) {
                    signUp();
                } else {
                    oneButtonOpenModal(() => {}, '', '이미 가입된 계정입니다.', '확인');
                }
            });
    };

    const signUpRule = () => {
        let message = '';
        const accountRegex = /^(?=.*[a-z])[a-z\d]{4,}$/;
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{4,}$/;
        const emailAddressRegex = /^(?!-)([a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,}$/;

        const falseCount = policyCheckedAry.filter((item) => item.agree === false).length;
        if (signup.snsType !== 'normal') {
            return dupliAccountCheck();
        } else {
            if (signup.identityInfo[0] === '') {
                message = '본인인증을 진행해 주세요.';
            } else if (signup.emailAddress === '' || signup.memberEmail === '') {
                message = '이메일 주소를 입력해 주세요.';
            } else if (!emailAddressRegex.test(signup.emailAddress.replace('@', ''))) {
                message = '올바르지 않은 이메일 형식입니다.';
            } else if (!accountRegex.test(signup.memberAccount)) {
                message = '올바르지 않은 아이디 형식입니다. (특수문자, 띄어쓰기, 한글 불가)';
            } else if (!passwordRegex.test(signup.memberPassword)) {
                // message = '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상 입력 가능)';
                message =
                    '올바르지 않은 비밀번호 형식입니다. (띄어쓰기 미포함 최소 4자리 이상,영문 소문자, 영문 대문자, 숫자, 특수문자 포함)';
            } else if (falseCount >= 2) {
                message = '필수 약관에 동의해 주세요.';
            } else if (memberType.type === 1 && signup.selectedOrgan.length === 0) {
                message = '소속기관을 선택해 주세요. (복수선택 가능)';
            }

            if (message !== '') {
                return oneButtonOpenModal(
                    () => {
                        message = '';
                    },
                    '',
                    message,
                    '확인'
                );
            } else {
                return dupliAccountCheck();
            }
        }
    };

    const signUp = async () => {
        let message = '';
        message = '14세 미만 회원가입 불가';
        const identityData = localStorage.getItem('identity_data');
        const identityKey = sessionStorage.getItem('identity_key');
        if (identityData && identityKey) {
            const identity = JSON.parse(identityData);
            const key = JSON.parse(identityKey);

            const userData = {
                niceAuthData: { ...identity, ...key },
                memberAccount: signup.memberAccount,
                memberEmail: signup.memberEmail + '@' + signup.emailAddress,
                memberPassword: signup.snsType === 'kakao' ? '' : signup.memberPassword,
                memberName: signup.identityInfo[0],
                memberPhone: signup.identityInfo[1],
                memberBirth: signup.identityInfo[2],
                memberGender: Number(signup.identityInfo[3]),
                snsType: signup.snsType === 'kakao' ? 'kakao' : 'normal',
                memberRole: memberType.type === 0 ? 'user' : 'employee',
                companyList: signup.selectedOrgan,
                termsOfUse: policyCheckedAry[0].agree,
                privacyConsent: policyCheckedAry[1].agree,
                infoThird: policyCheckedAry[2].agree,
            };

            await axiosInstance
                .post('/api/auth/signUp/v1', userData)
                .then((response) => {
                    localStorage.removeItem('identity_data');
                    sessionStorage.removeItem('identity_key');
                    if (response.data.message === 'SIGN_UP_SUCCESS') {
                        if (memberType.type === 0) {
                            oneButtonOpenModal(
                                () => {
                                    window.location.href = '/public/login';
                                },
                                '',
                                '회원가입이 정상적으로 완료되었습니다.',
                                '확인'
                            );
                        } else if (memberType.type === 1) {
                            oneButtonOpenModal(
                                () => {
                                    window.location.href = '/public/login';
                                },
                                '',
                                '회원가입 신청이 정상적으로 완료되었습니다. 관리자가 확인 후 승인까지 영업시간 내에 완료될 수 있으며 승인 완료 후 본 서비스를 이용하실 수 있습니다.',
                                '확인'
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        // oneButtonOpenModal(() => {}, '', message, '확인');
    };

    const PolicyContent = () => {
        return (
            <div className={styles.policy_checkbox_container}>
                {policyAry.map((item, index) => (
                    <div className={styles.policy_content_container} key={index.toString()}>
                        <div className={styles.policy_content}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    policyCheck(index);
                                }}
                            >
                                {policyCheckedAry[index].agree ? (
                                    <Icon_checkedbox width={'1.5rem'} height={'1.5rem'} />
                                ) : (
                                    <Icon_checkbox width={'1.5rem'} height={'1.5rem'} />
                                )}
                            </button>
                            <button
                                onClick={() => navigate(item.url)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span className="text_18_NotoSansKR_Regular">{item.title}</span>
                                <Icon_arrowright className={styles.icon_arrowright} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.view_container}>
                <div className={styles.content_container}>
                    <img className={styles.logo} src={logoPng} />
                    <div>
                        <div className={styles.login_title}>
                            <span className="text_40_Inter_Bold">YD iCBT </span>
                            <span className="text_40_NotoSansKR_Bold">회원가입</span>
                        </div>
                        <div className={`${styles.signup_title} text_18_NotoSansKR_Regular`}>
                            <span
                                style={{
                                    color: '#626466',
                                }}
                            >
                                계정이 있으신가요?
                            </span>
                            <button
                                className={'text_18_NotoSansKR_Regular'}
                                onClick={() => {
                                    dispatch(resetSignup());
                                    navigate('/public/login');
                                }}
                            >
                                <span
                                    style={{
                                        color: '#3A5074',
                                        textDecorationLine: 'underline',
                                    }}
                                >
                                    로그인 하기
                                </span>
                            </button>
                        </div>
                    </div>
                    <MemberTypeMenu menuList={['일반회원', '직원']} selectedIndex={memberType.type} reset={() => {}} />
                    <div className={styles.identity_container}>
                        <span className="text_18_NotoSansKR_Bold">본인인증</span>
                        <span
                            className="text_18_NotoSansKR_Regular"
                            style={{
                                color: '#626466',
                            }}
                        >
                            더 안전한 서비스 이용을 위해 최초 1회 휴대폰 본인인증을 진행해 주세요.
                        </span>
                        <button
                            onClick={identityVeification}
                            className={`${styles.identity_button} text_18_NotoSansKR_Bold`}
                        >
                            휴대폰 본인인증
                        </button>
                    </div>
                    <SignupInput />
                    <PolicyButton $policyAllAgreeCheck={policyAllAgreeCheck} onClick={policyAllAgree}>
                        <Icon_check width={'1.25rem'} height={'1.25rem'} color="" />
                        <span
                            className="text_18_NotoSansKR_Bold"
                            style={{
                                color: policyAllAgreeCheck ? '#3A5074' : '#D2D5D9',
                            }}
                        >
                            아래 내용에 모두 동의합니다.
                        </span>
                    </PolicyButton>
                    <PolicyContent />
                    <MainButton title={'회원가입'} onClick={signUpRule} />
                </div>
            </div>
            <Illustration />
            <form name="form_chk" method="GET" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb">
                <input type="hidden" name="m" value="service" />
                <input type="hidden" name="token_version_id" value="" />
                <input type="hidden" name="enc_data" value="" />
                <input type="hidden" name="integrity_value" value="" />
                <input type="hidden" name="iv" value="" />
                <input type="hidden" name="key" value="" />
                <input type="hidden" name="hmac_key" value="" />
            </form>
        </div>
    );
};

const PolicyButton = styled.button<MemberTypeButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2.5rem;
    gap: 0.5rem;
    padding: 1.18rem 0 1.18rem 0;
    border-radius: 0.625rem;
    border: ${(props) => (props.$policyAllAgreeCheck ? '0.0625rem solid #3A5074' : '0.0625rem solid #e3e5ea')};
    color: ${(props) => (props.$policyAllAgreeCheck ? '#3A5074' : '#D2D5D9')};
    background: #fff;
`;
