import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SelectTherapistModal {
    visible: boolean;
    selectProgram: { registerId: number; type: string };
    selectTherapist: { therapistId: number; therapist: string };
}

const initialState: SelectTherapistModal = {
    visible: false,
    selectProgram: { registerId: 0, type: '' },
    selectTherapist: { therapistId: 0, therapist: '' },
};

export const selectTherapistModalSlice = createSlice({
    name: 'selectTherapistModal',
    initialState,
    reducers: {
        updateSelectTherapist: (state, action: PayloadAction<{ therapistId: number; therapist: string }>) => {
            state.selectTherapist = action.payload;
        },
        updateSelectProgram: (state, action: PayloadAction<{ registerId: number; type: string }>) => {
            state.selectProgram = action.payload;
        },
        updateSelectTherapistVisible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
    },
});

export const { updateSelectTherapist, updateSelectTherapistVisible, updateSelectProgram } = selectTherapistModalSlice.actions;
export default selectTherapistModalSlice.reducer;
