import React, { useContext, useEffect, useState } from 'react';

import styles from './css/AssignedMember.module.css';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import {
    AssignedTherapist,
    ChartTitle,
    MemberInfoButton,
    NameChartNumber,
    ProgramType,
    RecentCompleteFeedback,
    SearchContainer,
    SelectAssignedTherapist,
    SessionCompleteDate,
    SessionProgress,
} from './AssignedMemberComponent.tsx/AssignedMemberComponent';
import { getMemberInfo } from '../../../../utils/GetMemberInfo';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { RepresentativeMemberList, getAdministratorMemberList } from './ts/AssignedMemberApi';
import { ModalContext } from '../../../../context/ModalContext';

const ChartTitleItem = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    ${(props) => (props.$index === props.$lastIndex ? '' : 'border-bottom: 0.0625rem solid #e3e5ea;')}
`;

const ChartTitleItemList = styled.li<{ $index: number; $lastIndex: number }>`
    display: flex;
    flexdirection: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 3.3125rem;
    padding: 0.75rem 2.1875rem;
    // ${(props) => (props.$index === props.$lastIndex ? '' : 'border-bottom: 0.0625rem solid #e3e5ea;')}
`;

const officialChartTitleList = [
    '이름(차트번호)',
    '프로그램유형',
    '세션 진행도',
    '세션 완료일',
    '최근 완료된 피드백',
    '담당 상담사',
    '',
];

export const AdministratorAssignedMember = () => {
    const navigate = useNavigate();
    const memberInfo = getMemberInfo();
    const [pageNum, setPageNum] = useState<number>(0);
    const [feedbackCheck, setFeedbackCheck] = useState<boolean>(false);

    const { selectTherapistVisible, selectTherapistOpenModal } = useContext(ModalContext);
    const { data, error, isLoading, refetch } = useQuery<RepresentativeMemberList>({
        queryKey: ['administratorMemberList', { companyId: memberInfo?.loginBranch.companyId, pageNum }],
        queryFn: () => getAdministratorMemberList(memberInfo?.loginBranch.companyId!, pageNum),
        placeholderData: keepPreviousData,
    });

    if (isLoading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;
    if (!data) return <div>No data available</div>; // data가 없을 때 처리

    const moveToPatientCBT = () => {};

    const AssignedRender = () => {
        return (
            <div>
                <div className={`${styles.chart_title_container} text_16_NotoSansKR_Medium`}>
                    {officialChartTitleList.map((item, index) => (
                        <ChartTitle key={index.toString()} title={item} />
                    ))}
                </div>
                {data?.patientList.content.length > 0 ? (
                    <ul className={styles.chart_content_container}>
                        {data?.patientList.content.map((item, index) => (
                            <>
                                <ChartTitleItem
                                    key={index.toString()}
                                    $index={index}
                                    $lastIndex={item.workBookInfos.length - 1}
                                >
                                    <NameChartNumber name={item.memberName} chartNumber={item.memberId} />
                                    <ProgramType programType={item.workBookInfos[0].workBookName} />
                                    <SessionProgress
                                        sessionProgress={item.workBookInfos[0].finishedCnt}
                                        maxProgress={item.workBookInfos[0].totalCnt}
                                        moveToPatientCBT={moveToPatientCBT}
                                        workBookName={item.workBookInfos[0].workBookName}
                                    />
                                    <SessionCompleteDate completeDate={item.workBookInfos[0].finishedDate} />
                                    <RecentCompleteFeedback recentCompleteSession={item.workBookInfos[0].recentFeed} />
                                    {item.workBookInfos[0].therapistList.length === 0 ? (
                                        <SelectAssignedTherapist
                                            selectTherapistVisible={selectTherapistVisible}
                                            selectTherapistOpenModal={(visible: boolean) =>
                                                selectTherapistOpenModal(visible)
                                            }
                                        />
                                    ) : (
                                        <AssignedTherapist
                                            therapist={item.workBookInfos[0].therapistList[0].therapistName}
                                        />
                                    )}
                                    <MemberInfoButton memberId={item.memberId} />
                                </ChartTitleItem>
                                {item.workBookInfos.map((value, idx) => (
                                    <ChartTitleItemList
                                        key={index.toString()}
                                        $index={idx}
                                        $lastIndex={data?.patientList.content.length - 1}
                                    >
                                        <NameChartNumber name={''} chartNumber={null} />
                                        <ProgramType programType={value.workBookName} />
                                        <SessionProgress
                                            sessionProgress={value.finishedCnt}
                                            maxProgress={value.totalCnt}
                                            moveToPatientCBT={moveToPatientCBT}
                                            workBookName={value.workBookName}
                                        />
                                        <SessionCompleteDate completeDate={value.finishedDate} />
                                        <RecentCompleteFeedback recentCompleteSession={value.recentFeed} />
                                        {value.therapistList.length === 0 ? (
                                            <SelectAssignedTherapist
                                                selectTherapistVisible={selectTherapistVisible}
                                                selectTherapistOpenModal={(visible: boolean) =>
                                                    selectTherapistOpenModal(visible)
                                                }
                                            />
                                        ) : (
                                            <AssignedTherapist therapist={value.therapistList[0].therapistName} />
                                        )}
                                        <MemberInfoButton memberId={null} />
                                    </ChartTitleItemList>
                                ))}
                            </>
                        ))}
                    </ul>
                ) : (
                    <div className={styles.chart_nondata_container}>
                        <span
                            className="text_18_NotoSansKR_Medium"
                            style={{
                                color: '#9D9FA2',
                            }}
                        >
                            아직 담당 중인 회원이 없습니다.
                        </span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.main_container}>
            <div className="text_32_NotoSansKR_Bold">회원</div>
            <div className={styles.middle_title_container}>
                <div
                    className={`${styles.middle_title} text_18_NotoSansKR_Bold`}
                    style={{
                        color: '#3A5074',
                    }}
                >
                    담당 중인 회원
                    <span>({data?.patientList.content.length})</span>
                </div>
            </div>
            <div className={styles.content_container}>
                <SearchContainer
                    feedbackCheck={feedbackCheck}
                    changeFeedbackCheck={() => setFeedbackCheck(!feedbackCheck)}
                />
                <AssignedRender />
            </div>
        </div>
    );
};
