import React, { useContext, useEffect, useState } from 'react';

import styles from './css/MainProgram.module.css';
import { BasicHeader, PatientHeader } from '../../../component/HeaderComponent';
import { ReactComponent as Icon_check } from '../../../assets/member/signup/icon_check.svg';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utils/AxiosInstanceJava';
import { FooterBasicComponent, FooterComponent } from '../../../component/FooterComponent';
import { ModalContext } from '../../../context/ModalContext';
import Loading from '../../../assets/payment/icon_payment_loading.gif';
import { encryptData } from '../../../utils/croptojs';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getProgramList, ProductByCategory } from '../ts/payment';
import { getMemberInfo } from '../../../utils/GetMemberInfo';

interface ProgramList {
    workBookProductId: number;
    discountPrice: number;
    price: number;
    productDescription: string;
    productName: string;
    workBookList: {
        workBookId: number;
        type: string;
        workBookName: string;
    }[];
}

interface ProgramItemButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $isSelected: number; // 추가적인 prop 정의
    $index: number;
}

const ProgramItemButton = styled.button<ProgramItemButtonProps>`
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    padding: 1.75rem 2.25rem;
    gap: 1.75rem;
    border-radius: 0.93rem;
    outline: ${(props) => (props.$isSelected === props.$index ? '0.1875rem solid #3A5074' : '0.0625rem solid #e3e5ea')};
    background: ${(props) => (props.$isSelected === props.$index ? '#F6F9FF' : '#FFF')};
`;

export const ProgramSelect = () => {
    const navigate = useNavigate();
    const { oneButtonOpenModal } = useContext(ModalContext);

    const [programList, setProgramList] = useState<ProgramList[]>([]);
    const [isSelected, setIsSelected] = useState<number>(-1);
    const [approvalRequest, setApprovalRequest] = useState<boolean>(false);
    const [dots, setDots] = useState<string>('');
    const [payCheck, setPayCheck] = useState<boolean>(true);
    const [selectProgramCategory, setSelectProgramCategory] = useState<string>('패키지');

    const programCategory = ['패키지', '단품', '단회'];

    const { data, isLoading, error } = useQuery<ProductByCategory>({
        queryKey: ['getProgramList'],
        queryFn: () => getProgramList(),
        placeholderData: keepPreviousData,
    });

    // useEffect(() => {
    //     paymentApprovalRequest();
    // }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (approvalRequest) {
                paymentApprovalRequest();
            }
        }, 5000); // 5000ms = 5초

        // if (!approvalRequest) {
        //     paymentApprovalRequest();
        // }

        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
    }, [approvalRequest]);

    useEffect(() => {
        if (approvalRequest) {
            const interval = setInterval(() => {
                setDots((prevDots) => {
                    if (prevDots.length < 3) {
                        return prevDots + '.';
                    }
                    return ''; // 3개 점이 넘어가면 다시 초기화
                });
            }, 500); // 0.5초마다 점 추가

            return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
        } else {
            setDots(''); // approvalRequest가 false일 경우 점 초기화
        }
    }, [approvalRequest]);

    if (isLoading) {
        return <div>loading....</div>;
    }

    if (!data) {
        return <div></div>;
    }

    const programCategoryList = data.productByCategory.filter((item) => item.category === selectProgramCategory);

    const selectProgram = () => {
        window.location.href = `/yd/patient/program/payment`;
    };

    const paymentApprovalRole = () => {
        if (isSelected === -1) {
            oneButtonOpenModal(() => {}, '', '프로그램을 선택해 주세요.', '확인');
        } else {
            paymentApprovalRequest();
        }
    };

    const paymentApprovalRequest = async () => {
        const requestData =
            !payCheck && isSelected !== -1
                ? {
                      workBookProductId: programCategoryList[0].productList[isSelected].workBookProductId,
                      productName: programCategoryList[0].productList[isSelected].productName,
                      payCheck: payCheck,
                      requestPayData: false,
                  }
                : {
                      workbookProductId: '',
                      productName: '',
                      payCheck: payCheck,
                      requestPayData: false,
                  };
        await axiosInstance.post(`/api/member/pay/v1`, requestData).then((response) => {
            const res = response.data;
            if (response.data.result) {
                switch (res.message) {
                    case 'PAY_REQUEST_SUCCESS':
                        if (!approvalRequest) {
                            oneButtonOpenModal(
                                () => {},
                                '',
                                `결제 승인요청이 완료되었습니다.\n잠시만 기다려주세요.`,
                                '확인'
                            );
                        }
                        setApprovalRequest(true);
                        break;
                    case 'PAYMENT_PENDING':
                        if (isSelected !== -1 && !approvalRequest) {
                            oneButtonOpenModal(() => {}, '', `결제 승인 대기중 입니다.\n잠시만 기다려 주세요.`, '확인');
                        }
                        setApprovalRequest(true);
                        break;
                    case 'PAYMENT_DECLINED':
                        setApprovalRequest(false);
                        oneButtonOpenModal(
                            () => {},
                            '',
                            `결제요청이 관리자에 의해 거절되었습니다.\n도움이 필요하신 경우 해당 기관으로 연락 바랍니다.\n(부산점: 051-747-8005)`,
                            '확인'
                        );
                        break;
                    case 'PAYMENT_APPROVED':
                        setApprovalRequest(false);
                        selectProgram();
                        break;
                }
            } else {
                switch (res.message) {
                    case 'PRODUCT_IS_NOT_EXIST':
                    case 'ADMIN_IS_NOT_EXIST':
                        oneButtonOpenModal(
                            () => {},
                            '',
                            `결제 승인요청이 실패하였습니다.\n다시 시도해 주세요.\n도움이 필요하신 경우 해당 기관으로 연락 바랍니다.\n(부산점: 051-747-8005)`,
                            '확인'
                        );
                        break;
                    case 'THIS_PRODUCT_ALREADY_PAY':
                        break;
                }
            }
        });
    };

    const ProgramListCategory = () => {
        return (
            <div className={styles.program_category_list_container}>
                {programCategory.map((item, index) => (
                    <button
                        key={index.toString()}
                        onClick={() => {
                            setSelectProgramCategory(item);
                            setIsSelected(-1);
                        }}
                        className={`${styles.button} ${
                            item === selectProgramCategory ? styles.activate : styles.deactivate
                        } text_24_NotoSansKR_Bold`}
                        style={{
                            color: item === selectProgramCategory ? '#FFF' : '#9D9FA2',
                        }}
                    >
                        {item}
                    </button>
                ))}
            </div>
        );
    };

    const ProgramListRender = () => {
        return (
            <div className={styles.program_container}>
                {programCategoryList[0].productList.map((item: any, index: number) => (
                    <ProgramItemButton
                        key={index.toString()}
                        $isSelected={isSelected}
                        $index={index}
                        className={styles.program_button}
                        onClick={() => {
                            setIsSelected(index);
                            setPayCheck(false);
                        }}
                    >
                        <>
                            <Icon_check
                                className={styles.icon_check}
                                color={isSelected === index ? '#3a5074' : '#d2d5d9'}
                            />
                            <div className={styles.title}>
                                <span
                                    className="text_22_NotoSansKR_Medium"
                                    style={{
                                        color: isSelected === index ? '#3a5074' : '#626466',
                                    }}
                                >
                                    {item.productName}
                                </span>
                                <span
                                    className="text_18_NotoSansKR_Regular"
                                    style={{
                                        color: isSelected === index ? '#3a5074' : '#9D9FA2',
                                    }}
                                >
                                    진행회기: {item.productDescription}
                                </span>
                            </div>
                        </>

                        <div className={styles.price}>
                            {item.discountPrice !== item.price && (
                                <span
                                    className="text_18_Inter_Medium"
                                    style={{
                                        color: '#D2D5D9',
                                        textDecoration: 'line-through',
                                    }}
                                >
                                    {item.price / 10000}
                                    <span
                                        className="text_18_NotoSansKR_Medium"
                                        style={{
                                            color: '#D2D5D9',
                                        }}
                                    >
                                        만원
                                    </span>
                                </span>
                            )}
                            <span className="text_24_Inter_Bold">
                                {item.discountPrice ? item.discountPrice / 10000 : item.price / 10000}
                                <span className="text_24_NotoSansKR_Bold">만원</span>
                            </span>
                        </div>
                    </ProgramItemButton>
                ))}
            </div>
        );
    };

    return (
        <div>
            <PatientHeader title="" />
            <div className={styles.main_container}>
                <div className={`${styles.main_title} text_32_NotoSansKR_Bold`}>
                    참여하고 싶으신 프로그램을 선택해 주세요.
                </div>
                <ProgramListCategory />
                <ProgramListRender />
                <button
                    onClick={paymentApprovalRole}
                    className={`${styles.program_select_button} ${
                        approvalRequest ? styles.loading : ''
                    } text_18_NotoSansKR_Bold`}
                >
                    {approvalRequest ? (
                        <div className={`${styles.approval_loading_container}`}>
                            <img src={Loading} className={styles.approval_loading} />
                            <span
                                className="text_18_NotoSansKR_Bold"
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                결제 요청 승인 대기중
                            </span>
                        </div>
                    ) : (
                        '선택완료'
                    )}
                </button>
                <div
                    style={{
                        width: '75rem',
                        marginTop: '3.75rem',
                    }}
                >
                    <FooterBasicComponent />
                </div>
            </div>
        </div>
    );
};
